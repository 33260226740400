import {useEffect} from "react";
import {DashboardLayout} from "components/Layout";
import {useQueryParams} from "hooks/useQueryParams";
import {useGenereateTokenZoom} from "hooks/Doctor/Zoom";
import {useToast} from "hooks/useToast";
import {convertErrorMessageFormat} from "utils/converter";

export function HomeDokterPage() {
	const query = useQueryParams();
	const {mutate} = useGenereateTokenZoom();
	const {showToast} = useToast();

	useEffect(() => {
		const code = query.get("code");

		if (code) {
			mutate(
				{code},
				{
					onSuccess: () => {
						showToast("success", "Zoom berhasil ditautkan", 3000);
					},
					onError: res => {
						showToast(
							"error",
							convertErrorMessageFormat(res.response.status, res.response.data.message),
							null,
						);
					},
				},
			);
		}
	}, []);

	return (
		<DashboardLayout>
			<div class="text-bold text-dark mb-4">Home Dashboard</div>
		</DashboardLayout>
	);
}
