import {useState} from "react";
import {useQuery} from "react-query";
import axios from "services/axios";

async function getPatients(id, filter) {
	const {data} = await axios.get(`/patient`, {params: filter});

	return data;
}

export function usePatients(idHospital) {
	const [filter, filterPatients] = useState({
		page: 1,
		search: null,
		limit: 10,
		idHospital,
	});
	const [patientId, patientById] = useState(null);
	const fallback = [];
	const {
		data = fallback,
		isLoading,
		isError,
		error,
	} = useQuery(["patients", patientId, filter], async () => getPatients(patientId, filter));

	return {data, isLoading, isError, error, filter, filterPatients, patientById};
}

// Fetch Hospital By ID

export const fetchPendaftaranPasienByID = patientId => {
	return axios.get(`/patient/${patientId}`).then(res => res.data);
};

export function useDetailPendaftaranPasien(patientId) {
	return useQuery({
		queryKey: patientId && ["patients", patientId],
		queryFn: key => fetchPendaftaranPasienByID(patientId),
		refetchOnMount: false,
		refetchOnWindowFocus: false,
		retry: false,
		enabled: !!patientId,
	});
}
