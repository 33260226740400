import {useMutation, useQueryClient} from "react-query";
import axios from "services/axios";

export function useAddForm() {
	const queryClient = useQueryClient();

	const {mutate, isLoading, isError, data, error} = useMutation(
		async data => await axios.post("/form/create", data),
		{
			onSuccess: () => {
				queryClient.invalidateQueries("formList");
				queryClient.removeQueries("formList");
			},
		},
	);

	return {mutate, isLoading, isError, data, error};
}

export function useEditForm(id) {
	const queryClient = useQueryClient();

	const {mutate, isLoading, isError, data, error} = useMutation(
		async data => await axios.post(`/form/update/${id}`, data),
		{
			onSuccess: () => {
				queryClient.invalidateQueries("formList");
				queryClient.removeQueries("formList");
			},
		},
	);

	return {mutate, isLoading, isError, data, error};
}

export function useDeleteForm() {
	const queryClient = useQueryClient();

	return useMutation(formData => axios.post("/form/delete", formData).then(res => res.data), {
		onSuccess: () => {
			queryClient.invalidateQueries("formList");
			queryClient.removeQueries("formList");
		},
	});
}
