import {useQuery} from "react-query";
import axios from "services/axios";
import qs from "query-string";

export const QUERY = "rs_position";

export function useListAvailablePosition() {
	const result = useQuery([QUERY], () => axios.get(`position/list`).then(res => res.data));

	return result;
}

export const fetchPositions = filter => {
	const query = qs.stringify(filter);

	return axios.get(`position/?sortBy=id.desc&${query}`).then(res => res.data);
};

export function useListPositionById(filter) {
	return useQuery({
		queryKey: [QUERY, filter],
		refetchOnMount: false,
		queryFn: () => fetchPositions(filter),
		enabled: !!Object.keys(filter).length,
	});
}
