import {useHistory, useParams} from "react-router-dom";
import {DashboardLayout} from "components/Layout";
import {SubmitButton} from "components/Button";
import {CircleLoader} from "components/Loader";
import {FormCard} from "components/Form";
import {useDetailLayananPoliklinikTindakan} from "hooks/AdminHospital/LayananPoliklinikTindakan";
import {convertToCurency} from "utils/converter";

export function LayananPoliklinikTindakanDetailPage() {
	const history = useHistory();
	const {id} = useParams();
	const {data: detailData, isLoading: loadingFetcing} = useDetailLayananPoliklinikTindakan(id);

	if (loadingFetcing) {
		return (
			<DashboardLayout>
				<FormCard backTitle="Daftar list kategori tindakan" title="Detail Layanan Poliklinik Tindakan">
					<CircleLoader />
				</FormCard>
			</DashboardLayout>
		);
	}

	return (
		<DashboardLayout>
			<FormCard
				backTitle="Daftar List Layanan Poliklinik Tindakan"
				title={`Detail Data Layanan Poliklinik Tindakan ${detailData?.data?.Action?.title}`}
				onBackButtonClick={() => history.push("/sub-admin-divisi/layanan-poliklinik-tindakan/list")}>
				<div class="row gx-2">
					<div class="col-sm-12 col-12">
						<div class="setting-item">
							<label class="setting-label ">Unit</label>
							<p className="text-bold text-grey">{detailData?.data?.Unit?.name || "-"}</p>
						</div>
					</div>
				</div>
				<div class="row gx-2 mb-5">
					<div class="col-sm-6 col-6">
						<div class="setting-item">
							<label class="setting-label ">Poliklinik</label>
							<p className="text-bold text-grey">{detailData?.data?.Poliklinik?.name || "-"}</p>
						</div>
					</div>
					<div class="col-sm-6 col-6">
						<div class="setting-item">
							<label class="setting-label ">Divisi</label>
							<p className="text-bold text-grey">{detailData?.data?.Division?.name || "-"}</p>
						</div>
					</div>
					<div class="col-sm-6 col-6">
						<div class="setting-item">
							<label class="setting-label ">Kategori Tindakan</label>
							<p className="text-bold text-grey">{detailData?.data?.ActionCategory?.title || "-"}</p>
						</div>
						<div class="setting-item">
							<label class="setting-label ">Tindakan</label>
							<p className="text-bold text-grey">{detailData?.data?.Action?.title || "-"}</p>
						</div>
						<div class="setting-item">
							<label class="setting-label ">Harga Beli</label>
							<p className="text-bold text-grey">
								Rp. {convertToCurency(detailData?.data?.buyPrice?.toString())}
							</p>
						</div>
						<div class="setting-item">
							<label class="setting-label ">Harga Jual</label>
							<p className="text-bold text-grey">
								Rp. {convertToCurency(detailData?.data?.sellPrice?.toString())}
							</p>
						</div>
						<div class="setting-item">
							<label class="setting-label ">Status Produk</label>
							<p className="text-bold text-grey">
								{detailData?.data?.statusProduct === 1 ? "Habis Dipakai" : "Tidak Habis Dipakai"}
							</p>
						</div>
						<div class="setting-item">
							<label class="setting-label ">Stok</label>
							<p className="text-bold text-grey">{detailData?.data?.stock}</p>
						</div>
					</div>
				</div>

				<div class="text-center my-3">
					<SubmitButton
						className="btn btn-orange d-inline-block mw-250"
						text="Ubah Data Ini"
						onClick={() =>
							history.push(`/sub-admin-divisi/layanan-poliklinik-tindakan/edit/${detailData?.data?.id}`)
						}
					/>
				</div>
			</FormCard>
		</DashboardLayout>
	);
}
