import {FormCard} from "components/Form";
import {DashboardLayout} from "components/Layout";
import {ModalConfirmation} from "components/Modal";
import {useArticleUser} from "hooks/SuperAdmin/Article";
import {useSuspendArticleUser} from "hooks/SuperAdmin/Article/User/useMutationArticleUser";
import {useToast} from "hooks/useToast";
import {useEffect, useState} from "react";
import {Button} from "react-bootstrap";
import {useHistory, useParams} from "react-router-dom";
import {convertErrorMessageFormat} from "utils/converter";
import {loadLocalAssets} from "utils/loader";

const UserDokterDetailPage = () => {
	const {type, id} = useParams();
	const history = useHistory();
	const {data: detailStaff, setArticleUserId, refetch, isRefetching} = useArticleUser("staff");
	const suspendMutation = useSuspendArticleUser();
	const [showSuspendModal, setShowSuspendModal] = useState(false);
	const {showToast} = useToast();

	const isLoading = isRefetching || suspendMutation.isLoading;

	const [detail, setDetail] = useState({});

	useEffect(() => {
		if (id) {
			setArticleUserId(id);
		}
	}, [id]);

	useEffect(() => {
		if (detailStaff?.success) {
			setDetail({...detailStaff?.data?.staffDetail, ...detailStaff?.data?.userDetail});
		}
	}, [detailStaff]);

	const updateSuspendStatus = () => {
		if (detailStaff?.data?.userDetail?.id && !isLoading) {
			suspendMutation.mutate(
				{userId: detailStaff?.data?.userDetail?.id, isSuspend: !detail?.isSuspend},
				{
					onSuccess: () => {
						refetch();
						toggleShowSuspendModal();
						showToast(
							"success",
							`Data ${type} ${detail?.firstName} berhasil ${detail?.isSuspend ? "unsuspend" : "suspend"}`,
							3000,
						);
					},
					onError: res => {
						toggleShowSuspendModal();
						showToast(
							"error",
							convertErrorMessageFormat(res.response.status, res.response.data.message),
							null,
						);
					},
				},
			);
		}
	};

	const toggleShowSuspendModal = () => setShowSuspendModal(!showSuspendModal);

	return (
		<DashboardLayout>
			<ModalConfirmation
				approveLabel="Ya"
				description={`Apakah Anda yakin ingin  ${detail?.isSuspend ? "unsuspend" : "suspend"} data ${type} "${
					detail?.firstName
				}"?`}
				loading={isLoading}
				rejectLabel="Batal"
				visible={showSuspendModal}
				onApprove={updateSuspendStatus}
				onClose={toggleShowSuspendModal}
				onReject={toggleShowSuspendModal}
			/>
			<FormCard
				action={
					<Button
						style={{textTransform: "capitalize"}}
						variant="outline-danger"
						onClick={toggleShowSuspendModal}>
						{detail?.isSuspend ? "Unsuspend" : "Suspend"} {type}
					</Button>
				}
				backTitle="Daftar List User"
				title={`Detail Data`}
				// title={`Detail Data ${detailPendaftaranPasien?.data?.firstName} ${detailPendaftaranPasien?.data?.lastName}`}
				onBackButtonClick={history.goBack}>
				<div class="row gx-2 mb-5">
					<div class="col-sm-12 col-lg-6">
						<div class="setting-item">
							<label class="setting-label ">Foto Profil</label>
							<div class="change-img mb-4">
								<div class="for-img-change">
									<div class="bx-img-change">
										<img
											alt="Profile"
											class="img-fluid"
											src={
												detail?.profilePhoto
													? detail?.profilePhoto
													: loadLocalAssets("img/user-default.jpg")
											}
										/>
									</div>
								</div>
							</div>
						</div>
						<div class="setting-item">
							<label class="setting-label ">Title</label>
							<p className="text-bold text-grey">{detail?.title}</p>
						</div>
						<div class="setting-item">
							<label class="setting-label ">Nama Depan</label>
							<p className="text-bold text-grey">{detail?.firstName}</p>
						</div>
						<div class="setting-item">
							<label class="setting-label ">Nama Belakang</label>
							<p className="text-bold text-grey">{detail?.lastName}</p>
						</div>
						<div class="setting-item">
							<label class="setting-label ">Nama Panggilan</label>
							<p className="text-bold text-grey">{detail?.nickname}</p>
						</div>
						<div class="setting-item">
							<label class="setting-label ">Email</label>
							<p className="text-bold text-grey">{detail?.email}</p>
						</div>
						<div class="setting-item">
							<label class="setting-label ">Nomor Telepon</label>
							<p className="text-bold text-grey">{detail?.phoneNumber}</p>
						</div>
						<div class="setting-item">
							<label class="setting-label ">Usia</label>
							<p className="text-bold text-grey">{detail?.dobText}</p>
						</div>
					</div>

					<div class="col-sm-12 col-lg-6">
						<div class="setting-item">
							<label class="setting-label ">Status Karyawan</label>
							<p className="text-bold text-grey">{detail?.employeeStatusText}</p>
						</div>
						<div class="setting-item">
							<label class="setting-label ">NIP</label>
							<p className="text-bold text-grey">{detail?.nip}</p>
						</div>
						<div class="setting-item">
							<label class="setting-label ">No STR</label>
							<p className="text-bold text-grey">{detail?.noStr}</p>
						</div>
					</div>
				</div>
			</FormCard>
		</DashboardLayout>
	);
};

export {UserDokterDetailPage};
