import {useQuery} from "react-query";
import {useState} from "react";
import axios from "services/axios";
import qs from "query-string";

export const QUERY = "rs_medicine_unit";

async function getMedicineUnits(id, filter) {
	if (!id) {
		const {data} = await axios.get(`/medicine/unit`, {params: {...filter, sortBy: "id.desc"}});

		return data;
	}
	const {data} = await axios.get(`/medicine/unit/${id}`);

	return data;
}

export function useListAvailableMedicineUnit(hospitalId) {
	const [filter, filterMedicineUnits] = useState({
		page: 1,
		search: null,
		limit: 10,
		hospitalId,
	});
	const [medicineUnitId, medicineUnitById] = useState(null);
	const fallback = [];
	const {
		data = fallback,
		isLoading,
		isError,
		error,
	} = useQuery([QUERY, filter, medicineUnitId], async () => getMedicineUnits(medicineUnitId, filter));

	return {data, isLoading, isError, error, filter, filterMedicineUnits, medicineUnitById};
}

export const fetchMedicineUnit = filter => {
	const query = qs.stringify(filter);

	return axios.get(`medicine/unit/?sortBy=id.desc&${query}`).then(res => res.data);
};

export function useListMedicineUnitById(filter) {
	return useQuery({
		queryKey: [QUERY, filter],
		queryFn: key => fetchMedicineUnit(filter),
		refetchOnMount: false,
		enabled: !!Object.keys(filter).length,
	});
}

export function useMedicineUnitOptions({hospitalId}) {
	const result = useQuery(
		["medicineUnitOptionsHospital"],
		async () =>
			await axios
				.get(`/medicine/unit`, {params: {hospitalId, isActive: true, sortBy: "id.desc"}})
				.then(res => res.data),
	);

	return result;
}
