import {useState} from "react";
import {useQuery} from "react-query";
import axios from "services/axios";
import qs from "query-string";

async function getUnits(id, filter) {
	if (!id) {
		const {data} = await axios.get(`/unit`, {params: {...filter, sortBy: "id.desc"}});

		return data;
	}
	const {data} = await axios.get(`/unit/${id}`);

	return data;
}

export function useUnit() {
	const [filter, filterUnits] = useState({
		page: 1,
		search: null,
		limit: 10,
	});
	const [unitId, unitById] = useState(null);
	const fallback = [];
	const {
		data = fallback,
		isLoading,
		isError,
		error,
	} = useQuery(["unit", filter, unitId], async () => getUnits(unitId, filter));

	return {data, isLoading, isError, error, filter, filterUnits, unitById};
}

export function useUnitOptions() {
	const result = useQuery(["unit"], async () => await axios.get(`/unit`).then(res => res.data));

	return result;
}

// GET POSITION BY ID

export const fetchUnit = unitId => {
	return axios.get(`unit/${unitId}`).then(res => res.data);
};

export function useDetailUnit(unitId) {
	return useQuery({
		queryKey: unitId && ["unit", unitId],
		queryFn: key => fetchUnit(unitId),
		refetchOnMount: false,
		refetchOnWindowFocus: false,
		enabled: !!unitId,
	});
}

export async function getUnitsByHospital(hospitalId) {
	const {data} = await axios.get(`/unit`, {params: {hospitalId, isActive: true}});

	return data;
}

export const QUERY_HOSPITAL_UNIT = "unit";
export function useHospitalUnits(filter) {
	return useQuery([QUERY_HOSPITAL_UNIT, filter], () =>
		axios.get(`/unit?${qs.stringify(filter)}`).then(res => res.data),
	);
}
