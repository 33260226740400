import axios from "services/axios";
import {useMutation, useQueryClient} from "react-query";

import {QUERY} from "./useQueryMedicine";

export function useAddHospitalMedicine() {
	return useMutation(values => axios.post("/medicine/hospital/create", values).then(res => res.data));
}
export function useUpdateHospitalMedicine() {
	const queryClient = useQueryClient();

	return useMutation(formData => axios.post("/medicine/hospital/update", formData).then(res => res.data), {
		onSuccess: () => {
			queryClient.invalidateQueries(QUERY);
			queryClient.removeQueries(QUERY);
			queryClient.invalidateQueries("medicineOptionsHospital");
			queryClient.removeQueries("medicineOptionsHospital");
			queryClient.invalidateQueries("medicines");
			queryClient.removeQueries("medicines");
		},
	});
}

export function useDeleteHospitalMedicine() {
	const queryClient = useQueryClient();

	return useMutation(formData => axios.post("/medicine/hospital/delete", formData).then(res => res.data), {
		onSuccess: () => {
			queryClient.invalidateQueries(QUERY);
			queryClient.removeQueries(QUERY);
			queryClient.invalidateQueries("medicineOptionsHospital");
			queryClient.removeQueries("medicineOptionsHospital");
			queryClient.invalidateQueries("medicines");
			queryClient.removeQueries("medicines");
		},
	});
}

export function useChangeDataStatus() {
	const queryClient = useQueryClient();

	return useMutation(formData => axios.post("/medicine/change_status", formData).then(res => res.data), {
		onSuccess: () => {
			queryClient.invalidateQueries("medicines");
			queryClient.removeQueries("medicines");
		},
	});
}
