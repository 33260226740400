import {FormCard} from "components/Form";
import {DashboardLayout} from "components/Layout";
import {CircleLoader} from "components/Loader";
import {useDetailVendor} from "hooks/SuperAdmin/Vendor";
import {useHistory, useParams} from "react-router-dom";
import {loadLocalAssets} from "utils/loader";

const MasterVendorDetailPage = () => {
	const history = useHistory();
	const {id} = useParams();
	const {data: detailVendor, isLoading: loadingFetcing} = useDetailVendor(id);

	if (loadingFetcing) {
		return (
			<DashboardLayout>
				<FormCard backTitle="Daftar list vendor" title="Detail Vendor">
					<CircleLoader />
				</FormCard>
			</DashboardLayout>
		);
	}

	return (
		<DashboardLayout>
			<FormCard
				backTitle="Daftar list vendor"
				title="Detail Vendor"
				onBackButtonClick={() => history.push("/master-vendor")}>
				<div class="row gx-2 mb-5">
					<div class="col-12 col-lg-6">
						<div class="setting-item">
							<label class="setting-label">Kode Vendor</label>
							<p className="text-bold text-grey">{detailVendor?.data?.code}</p>
						</div>
					</div>
					<div class="col-12 col-lg-6">
						<div class="setting-item">
							<label class="setting-label">Nama Vendor</label>
							<p className="text-bold text-grey">{detailVendor?.data?.name}</p>
						</div>
					</div>
					<div class="col-12 col-lg-6">
						<div class="setting-item">
							<label class="setting-label">Email</label>
							<p className="text-bold text-grey">{detailVendor?.data?.email || "-"}</p>
						</div>
					</div>
					<div class="col-12 col-lg-6">
						<div class="setting-item">
							<label class="setting-label">No Telepon</label>
							<p className="text-bold text-grey">
								{detailVendor?.data?.phoneNumber ? `+${detailVendor?.data?.phoneNumber}` : "-"}
							</p>
						</div>
					</div>
					<div class="col-12 col-lg-6">
						<div class="setting-item">
							<label class="setting-label ">Logo</label>
							<div class="change-img mb-4">
								<div class="for-img-change">
									<div class="bx-img-change">
										<img
											alt="Logo Vendor"
											class="img-fluid"
											src={
												detailVendor?.data?.icon
													? detailVendor?.data?.icon
													: loadLocalAssets("img/user-default.jpg")
											}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</FormCard>
		</DashboardLayout>
	);
};

export {MasterVendorDetailPage};
