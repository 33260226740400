import {ButtonAddTable} from "components/Button";
import {DashboardLayout} from "components/Layout";
import {ModalConfirmation} from "components/Modal";
import {BasicTable, SearchBox} from "components/Table";
import {useDeleteForumCategory, useForumCategories} from "hooks/SuperAdmin/ForumCategory";
import {useStateCallback} from "hooks/useStateCallback";
import {useToast} from "hooks/useToast";
import {useMemo, useState} from "react";
import {useHistory} from "react-router-dom";
import {convertErrorMessageFormat} from "utils/converter";

const MasterForumCategoryList = () => {
	const [searchKeyword, setSearchKeyword] = useState("");
	const [dataLimit, setDataLimit] = useState(10);
	const {data, isLoading, filter, setFilter} = useForumCategories();
	const [showConfirmModal, setShowConfirmModal] = useState({show: false, type: ""});
	const [selectedItem, setSelectedItem] = useStateCallback({id: null, name: null});
	const {mutate: deleteForumCategory, isLoading: isDeleting} = useDeleteForumCategory(selectedItem.id);
	const {showToast} = useToast();

	const history = useHistory();
	const columns = useMemo(
		() => [
			{
				Header: "No",
				accessor: "idSerial",
			},
			{
				Header: "Kategori",
				accessor: "title",
				maxWidth: 300,
			},
			{
				centered: true,
				Header: "Aksi",
				accessor: "id",
				width: "200px",
				Cell: ({row}) => (
					<div className="d-flex align-items-center justify-content-center gap-2">
						<a
							class="text-orange wait-pay"
							style={{cursor: "pointer"}}
							onClick={() => history.push(`/forum-category/show/${row?.values?.id}`)}>
							<span class="icon-ico-eye-open ft-18" />
						</a>
						<a
							class="text-orange wait-pay"
							style={{cursor: "pointer"}}
							onClick={() => history.push(`/forum-category/edit/${row?.values?.id}`)}>
							<span class="icon-ico-pencil ft-18" />
						</a>
						<a
							class="text-orange wait-pay"
							style={{cursor: "pointer"}}
							title={!row.original.can_delete && "Data tidak dapat dihapus karena sudah digunakan"}
							onClick={() => {
								setSelectedItem(row?.values, () => setShowConfirmModal({show: true, type: "delete"}));
							}}>
							<span class="icon-ico-delete ft-18" />
						</a>
					</div>
				),
			},
		],
		[],
	);

	const dataWithIDSerial =
		data?.data?.rows?.map((item, index) => ({
			...item,
			idSerial: (data?.data?.currentPage - 1) * dataLimit + index + 1,
		})) || [];

	const handleDeleteData = () => {
		deleteForumCategory(
			{id: selectedItem.id},
			{
				onSuccess: () => {
					showToast("success", `Data kategori ${selectedItem.title} berhasil dihapus`, 3000);
				},
				onError: error => {
					showToast(
						"error",
						convertErrorMessageFormat(error.response.status, error.response.data.message),
						null,
					);
				},
				onSettled: () => {
					window.scrollTo(0, 0);
					setShowConfirmModal({show: false, type: ""});
				},
			},
		);
	};

	const handleConfirm = () => {
		if (showConfirmModal.type === "delete") {
			handleDeleteData();
		}
	};

	return (
		<DashboardLayout>
			<ModalConfirmation
				approveLabel="Ya"
				description={`Apakah Anda yakin ingin menghapus data kategori "${selectedItem?.title}"?`}
				loading={isDeleting}
				rejectLabel="Batal"
				visible={showConfirmModal.show}
				onApprove={handleConfirm}
				onClose={() => setShowConfirmModal({show: false, type: ""})}
				onReject={() => setShowConfirmModal({show: false, type: ""})}
			/>
			<div class="text-bold text-dark mb-4">Data Kategori Forum</div>
			<SearchBox
				rightElement={
					<ButtonAddTable
						text="Tambah Kategori Forum"
						onClick={() => history.push("/forum-category/tambah")}
					/>
				}
				onSearch={keyword => {
					setSearchKeyword(keyword);
					setFilter({...filter, page: 1, limit: dataLimit, search: keyword});
				}}
			/>

			<BasicTable
				hideTitleOnMobile
				columns={columns}
				currentPage={data?.data?.currentPage}
				data={dataWithIDSerial}
				limit={dataLimit}
				loading={isLoading}
				striped={true}
				totalItems={data?.data?.totalItems}
				totalPage={data?.data?.totalPages}
				onLimitChange={limitValue => {
					setDataLimit(limitValue);
					setFilter({...filter, page: 1, limit: limitValue, search: searchKeyword});
				}}
				onPageChange={selectedPage =>
					setFilter({...filter, page: selectedPage, limit: dataLimit, search: searchKeyword})
				}
			/>
		</DashboardLayout>
	);
};

export {MasterForumCategoryList};
