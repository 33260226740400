export function FormCard({
	children,
	containerClassName = "box-white setting-content h-100 no-border p24",
	title = "Title",
	backTitle = "Back",
	action = null,
	onBackButtonClick = () => {},
}) {
	return (
		<div class={containerClassName}>
			<div class="d-flex justify-content-between flex-wrap align-items-center mb-4">
				<div class="ttl-20">{title}</div>
				{action ? action : null}
			</div>

			<hr />

			<div class="text-bold mb-4">
				<a class="text-dark" style={{cursor: "pointer"}} onClick={onBackButtonClick}>
					<span class="icon-ico-back me-2" />
				</a>
				{backTitle}
			</div>
			{children}
		</div>
	);
}
