import {useState} from "react";
import {useQuery} from "react-query";
import axios from "services/axios";

async function getGroupMemberList(id, params) {
	if (!id) {
		const {data} = await axios.get(`/group-member`, {params});

		return data;
	}
	const {data} = await axios.get(`/group-member/${id}`);

	return data;
}

export function useGroupMemberList() {
	const [filter, setFilter] = useState({
		page: 1,
		search: null,
		type: 1,
		limit: 10,
		sortBy: "id.desc",
		// sortBy: "order.asc",
	});
	const fallback = [];
	const {
		data = fallback,
		isLoading,
		isError,
		error,
	} = useQuery(["groupMember", filter], async () => getGroupMemberList(null, filter));

	return {data, isLoading, isError, error, filter, setFilter};
}

export function useGroupMemberDetail(groupMemberId) {
	return useQuery({
		queryKey: groupMemberId && ["groupMember", groupMemberId],
		queryFn: key => getGroupMemberList(groupMemberId),
		refetchOnMount: false,
		refetchOnWindowFocus: false,
		enabled: !!groupMemberId,
	});
}

async function getGroupMemberListUser(id, params) {
	const {data} = await axios.get(`/group-member/${id}/list-member`, {params});

	return data;
}

export function useGroupMemberListUser(id) {
	const [filter, setFilter] = useState({
		page: 1,
		search: null,
		type: 1,
		limit: 10,
		sortBy: "id.desc",
		// sortBy: "order.asc",
	});
	const fallback = [];
	const {
		data = fallback,
		isLoading,
		isError,
		error,
	} = useQuery(["groupMember", filter, id, "list-user"], async () => getGroupMemberListUser(id, filter));

	return {data, isLoading, isError, error, filter, setFilter};
}
