import {useQuery} from "react-query";
import axios from "services/axios";

export const QUERY = "appointment";

export const fetchMedicalRecord = medicalRecordId => {
	return axios.get(`/medical_record/${medicalRecordId}`).then(res => res.data);
};

export function useDetailMedicalRecord(medicalRecordId) {
	return useQuery({
		queryKey: medicalRecordId && [QUERY, medicalRecordId],
		queryFn: key => fetchMedicalRecord(medicalRecordId),
		refetchOnMount: false,
		refetchOnWindowFocus: false,
		enabled: !!medicalRecordId,
	});
}
