import * as Yup from "yup";

const phoneRegExp = /^\+?([ -]?\d+)+|\(\d+\)([ -]\d+)$/g;
const emailRegExp = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
const emailAndPhoneNumRegExp = new RegExp(`^(${emailRegExp.source}|${phoneRegExp.source})$`);

export const LoginSchemaValidation = Yup.object().shape({
	username: Yup.mixed()
		.required("Email atau No HP Wajib diisi")
		.test("username", "Format alamat email atau No HP tidak valid", value => {
			return emailAndPhoneNumRegExp.test(value);
		}),
	password: Yup.string().required("Kata sandi wajib di isi"),
});
