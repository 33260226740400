import {useQuery} from "react-query";
import {useState} from "react";
import axios from "services/axios";
import qs from "query-string";

export const QUERY = "rs_medicine_category";

async function getMedicineCategories(id, filter) {
	if (!id) {
		const {data} = await axios.get(`/medicine/category`, {params: {...filter, sortBy: "id.desc"}});

		return data;
	}
	const {data} = await axios.get(`/medicine/category/${id}`);

	return data;
}

export function useListAvailableMedicineCategory(hospitalId, initialFilters) {
	const [filter, filterMedicineCategories] = useState({
		page: 1,
		search: null,
		limit: 10,
		hospitalId,
		...initialFilters,
	});
	const [medicineCategoryId, medicineCategoryById] = useState(null);
	const fallback = [];
	const {
		data = fallback,
		isLoading,
		isError,
		error,
	} = useQuery([QUERY, filter, medicineCategoryId], async () => getMedicineCategories(medicineCategoryId, filter));

	return {data, isLoading, isError, error, filter, filterMedicineCategories, medicineCategoryById};
}

export const fetchMedicineCategory = filter => {
	const query = qs.stringify(filter);

	return axios.get(`medicine/category/?sortBy=id.desc&${query}`).then(res => res.data);
};

export function useListMedicineCategoryById(filter) {
	return useQuery({
		queryKey: [QUERY, filter],
		queryFn: key => fetchMedicineCategory(filter),
		refetchOnMount: false,
		enabled: !!Object.keys(filter).length,
	});
}

export function useMedicineCategoryOptions({hospitalId}, enabled = true) {
	const result = useQuery(
		["medicineCategoryOptionsHospital", hospitalId],
		async () =>
			await axios
				.get(`/medicine/category`, {params: {hospitalId, isActive: true, sortBy: "id.desc"}})
				.then(res => res.data),
		{
			enabled: enabled && !!hospitalId,
		},
	);

	return result;
}
