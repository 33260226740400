/* eslint-disable no-unused-vars */
import {useState, useEffect, useRef, useMemo} from "react";
import clsx from "clsx";
import {useHistory, useParams} from "react-router-dom";
import {Formik, FieldArray, Field} from "formik";
import {DashboardLayout} from "components/Layout";
import {loadLocalAssets} from "utils/loader";
import {convertErrorMessageFormat} from "utils/converter";
import {useEditPendaftaranPasien, useDetailPendaftaranPasien, useAddAddress} from "hooks/PendaftaranPasien";
import {SubmitButton, ToggleSwitch} from "components/Button";
import {BasicDropdown, PatientDropdown, GoogleSugestionDropdown} from "components/Dropdown";
import {CircleLoader} from "components/Loader";
import {FormCard} from "components/Form";
import {useListPatient} from "hooks/AdminAdmission/Patient";
import {useUploadFile, useRelations, useTitles} from "hooks/Misc";
import {useToast} from "hooks/useToast";
import {Collapse} from "react-collapse";
import {EditPendaftaranPasienFormSchemaValidation} from "config/form/FormValidation/PendaftaranPasien";
import {getStaffsByRole} from "hooks/SuperAdmin/Staff";
import {useRole} from "hooks/Misc";
import {ProfileImageInitial} from "components/ProfileImage";
import {converISOtoDate} from "utils/date";
import PhoneInput from "react-phone-number-input";
import {useTagOptions} from "hooks/SuperAdmin/Tag";

const FormSchemaValidation = EditPendaftaranPasienFormSchemaValidation;

const FormInitialValues = {
	title: "Mr",
	firstName: "",
	lastName: "",
	nickname: "",
	username: "",
	email: "",
	phoneNumber: "",
	nik: "",
	password: "",
	profilePhoto: undefined,
	gender: "male",
	maritalStatus: "single",
	dateOfBirth: null,
	remark: "",
	relations: [],
	relationsNew: [],
	emergencyContacts: [],
	addressPayment: {
		address: "",
		addressDetail: "",
		addressType: "payment",
	},
	addressShipment: {
		firstName: "",
		lastName: "",
		email: "",
		companyName: "",
		phoneNumber: "",
		country: "",
		province: "",
		city: "",
		postalCode: "",
		address: "",
		addressDetail: "",
		addressType: "shipment",
		latitude: "",
		longitude: "",
	},
	emailInvoice: "",
	referralType: null,
	referralIdUser: null,
};
const genderOptions = [
	{label: "Male", value: "male"},
	{label: "Female", value: "female"},
];
const maritalStatusOptions = [
	{label: "Single", value: "single"},
	{label: "Married", value: "married"},
];

export function PendaftaranPasienEditPage() {
	const {data: patients, isLoading: isFethingPatients} = useListPatient({page: 1, limit: ""});
	const {data: titles, isLoading: isFethingTitles} = useTitles([]);
	const {data: relations, isLoading: isFethingRelations} = useRelations([]);
	const [patientsOptions, setPatientsOptions] = useState([]);
	const [infoOpen, setInfoOpen] = useState(true);
	const [tagOpen, setTagOpen] = useState(false);
	const [selectedTag, setSelectedTag] = useState({selected: [], priority: 0});
	const [contactOpen, setContactOpen] = useState(false);
	const [shipmentOpen, setShipmentOpen] = useState(false);
	const [paymentOpen, setPaymentOpen] = useState(false);
	const [comunicationOpen, setComunicationOpen] = useState(false);
	const [otherOpen, setOtherOpen] = useState(false);
	const [referralOpen, setReferralOpen] = useState(false);
	const [logoPreview, setLogoPreview] = useState(null);
	const formikRef = useRef();
	const history = useHistory();
	const {mutate: uploadFile} = useUploadFile();
	const {showToast} = useToast();
	const [titleOptions, setTitleOptions] = useState([]);
	const [emergencyContactOptions, setEmergencyContactOptions] = useState([]);
	const [roleOptions, setRoleOptions] = useState([]);
	const [roleSelected, setRoleSelected] = useState();
	const [referalOptions, setReferalOptions] = useState([]);
	const {data: roles, isLoading: isLoadingRoleOptions} = useRole();
	const params = useParams();
	const {id} = params;

	const {data: detailPendaftaranPasien, isLoading: isFetchingDetailPendaftaranPasien} =
		useDetailPendaftaranPasien(id);
	const {mutate: editPendaftaranByID, isLoading} = useEditPendaftaranPasien();
	const {mutate: addAddress, isLoading: isLoadingAddAddress} = useAddAddress();
	const {data: tagData} = useTagOptions();

	const tagOptions = useMemo(() => {
		if (tagData?.success) {
			return tagData.data.rows.map(x => ({
				label: `${x.title} ${x.discount}%`,
				value: x.id,
			}));
		}

		return [];
	}, [tagData]);

	useEffect(() => {
		if (detailPendaftaranPasien?.data?.id) {
			const {patient} = detailPendaftaranPasien?.data;
			const {addressPayment} = patient || {};
			const {addressShipment} = patient || {};
			const {emergencyContacts} = patient || [];
			const {relations} = patient || [];

			delete detailPendaftaranPasien.data.password;

			Object.keys(FormInitialValues).forEach(key => {
				formikRef?.current.setFieldValue(key, detailPendaftaranPasien?.data?.[key]);
				if (key === "title" && !detailPendaftaranPasien?.data?.[key]) {
					formikRef?.current.setFieldValue(key, "Mr");
				}
				if (key === "dateOfBirth")
					formikRef?.current.setFieldValue(
						key,
						detailPendaftaranPasien?.data?.[key]
							? converISOtoDate(detailPendaftaranPasien?.data?.[key], "YYYY-MM-DD")
							: null,
					);
			});
			patient &&
				Object.keys(patient).forEach(key => {
					formikRef?.current.setFieldValue(key, patient[key]);
				});
			addressPayment &&
				Object.keys(addressPayment).forEach(key => {
					formikRef?.current.setFieldValue(`addressPayment.${key}`, addressPayment[key]);
				});
			addressShipment &&
				Object.keys(addressShipment).forEach(key => {
					formikRef?.current.setFieldValue(`addressShipment.${key}`, addressShipment[key]);
				});
			emergencyContacts &&
				emergencyContacts.forEach((item, index) => {
					formikRef?.current.setFieldValue(`emergencyContacts.${index}.relation`, item.relation);
					formikRef?.current.setFieldValue(`emergencyContacts.${index}.phoneNumber`, item.phoneNumber);
				});
			relations &&
				relations.forEach((item, index) => {
					formikRef?.current.setFieldValue(`relations.${index}.idUser`, item.idUser);
					formikRef?.current.setFieldValue(
						`relations.${index}.label`,
						`${item?.userDetail?.firstName} ${item?.userDetail?.lastName}`,
					);
				});
			formikRef.current.setFieldValue("relationsNew", []);
			setLogoPreview(detailPendaftaranPasien?.data?.profilePhoto);

			const {tags} = detailPendaftaranPasien?.data;

			setSelectedTag({
				selected: tags?.map(t => t.tagId),
				priority: tags?.find(t => t.piority)?.tagId || 0,
			});
		}
	}, [detailPendaftaranPasien]);

	const addNewAddress = value => {
		return addAddress(value, {
			onSuccess: () => {
				return true;
			},
			onError: res => {
				showToast("error", convertErrorMessageFormat(res.response.status, res.response.data.message), null);
				window.scrollTo(0, 0);
			},
		});
	};

	const handleSubmit = formValue => {
		const {profilePhoto, ...formDataValue} = formValue;

		formDataValue.addressPayment = {
			id: formDataValue?.addressPayment?.id,
			address: formDataValue?.addressPayment?.address,
			addressDetail: formDataValue?.addressPayment?.addressDetail,
			latitude: formDataValue?.addressPayment?.latitude,
			longitude: formDataValue?.addressPayment?.longitude,
			addressType: "payment",
		};

		let formData = new FormData();

		const tags = selectedTag.selected.map(tag => ({
			tagId: tag,
			piority: selectedTag.priority === tag,
		}));

		if (profilePhoto?.name) {
			formData.append("file", profilePhoto);

			uploadFile(formData, {
				onSuccess: result => {
					editPendaftaranByID(
						{
							idUser: id,
							profilePhoto: result.data?.file,
							...formDataValue,
							relations: [...formDataValue.relations, ...formDataValue.relationsNew],
							relationsNew: undefined,
							id: undefined,
							tags,
						},
						{
							onSuccess: () => {
								if (!formData.addressPayment.id) {
									addNewAddress({
										...formData.addressPayment,
										addressType: "payment",
										idPatient: detailPendaftaranPasien?.data?.patient?.id,
									});
								}

								if (!formData.addressShipment.id) {
									addNewAddress({
										...formData.addressShipment,
										addressType: "shipment",
										idPatient: detailPendaftaranPasien?.data?.patient?.id,
									});
								}

								showToast("success", "Data pasien berhasil diperbarui", 3000);
								history.push("/pendaftaran-pasien");
							},
							onError: res => {
								showToast(
									"error",
									convertErrorMessageFormat(res.response.status, res.response.data.message),
									null,
								);
								window.scrollTo(0, 0);
							},
						},
					);
				},
				onError: res => {
					showToast("error", convertErrorMessageFormat(res.response.status, res.response.data.message), null);
					window.scrollTo(0, 0);
				},
			});
		} else {
			editPendaftaranByID(
				{
					idUser: id,
					profilePhoto,
					...formDataValue,
					relations: [...formDataValue.relations, ...formDataValue.relationsNew],
					relationsNew: undefined,
					id: undefined,
					tags,
				},
				{
					onSuccess: () => {
						if (!formDataValue.addressPayment.id) {
							addNewAddress({
								...formDataValue.addressPayment,
								addressType: "payment",
								idPatient: detailPendaftaranPasien?.data?.patient?.id,
							});
						}

						if (!formDataValue.addressShipment.id) {
							addNewAddress({
								...formDataValue.addressShipment,
								addressType: "shipment",
								idPatient: detailPendaftaranPasien?.data?.patient?.id,
							});
						}
						showToast("success", "Data pasien berhasil diperbarui", 3000);
						history.push("/pendaftaran-pasien");
					},
					onError: res => {
						showToast(
							"error",
							convertErrorMessageFormat(res.response.status, res.response.data.message),
							null,
						);
						window.scrollTo(0, 0);
					},
				},
			);
		}
	};

	const handleUploadLogo = e => {
		const file = e.target.files[0];
		const reader = new FileReader();

		reader.onload = () => {
			setLogoPreview(reader.result);
		};
		reader.readAsDataURL(file);
		formikRef.current.setFieldValue("profilePhoto", file);
	};

	// When patiens data is fetched, update to state

	useEffect(() => {
		if (patients?.success) {
			setPatientsOptions(
				patients?.data?.rows?.map(patient => ({
					value: patient?.patient?.id + "",
					label: `${patient?.firstName} ${patient?.lastName} [${patient?.patient?.medicalRecordNumber}]`,
					firstName: patient?.firstName,
				})),
			);
		}
	}, [patients]);

	useEffect(() => {
		if (titles?.success) {
			setTitleOptions(
				titles?.data?.map(title => ({
					value: title,
					label: title,
				})),
			);
		}
	}, [titles]);

	useEffect(() => {
		if (relations?.success) {
			setEmergencyContactOptions(
				relations?.data?.map(relation => ({
					value: relation,
					label: relation,
				})),
			);
		}
	}, [relations]);

	useEffect(() => {
		if (roles?.success) {
			const roleData = roles.data.map(role => ({
				value: role.id,
				label: role.name,
			}));

			setRoleOptions([{value: "", label: ""}, ...roleData]);
		}
	}, [roles]);

	useEffect(() => {
		if (roleSelected) {
			getStaffsByRole(roleSelected).then(res => {
				const staffData = res.data.rows.map(staff => ({
					value: staff.id,
					label: `${staff.firstName} ${staff.lastName}`,
				}));

				staffData.length ? setReferalOptions([{value: "", label: ""}, ...staffData]) : setReferalOptions([]);
			});
		} else {
			setReferalOptions([]);
		}
	}, [roleSelected]);

	const setDucplicateAddress = (isDuplicated, data) => {
		if (isDuplicated) {
			formikRef.current.setFieldValue("addressShipment", {...data, addressType: "shipment"});
		} else {
			formikRef.current.setFieldValue("addressShipment", {
				id: formikRef.current?.values?.addressShipment?.id,
				firstName: formikRef.current?.values?.addressShipment?.firstName,
				lastName: formikRef.current?.values?.addressShipment?.lastName,
				email: formikRef.current?.values?.addressShipment?.email,
				companyName: formikRef.current?.values?.addressShipment?.companyName,
				phoneNumber: formikRef.current?.values?.addressShipment?.phoneNumber,
				country: "",
				province: "",
				city: "",
				postalCode: formikRef.current?.values?.addressShipment?.postalCode,
				address: "",
				addressDetail: formikRef.current?.values?.addressShipment?.addressDetail,
				addressType: "shipment",
			});
		}
	};

	if (isFetchingDetailPendaftaranPasien) {
		return (
			<DashboardLayout>
				<FormCard backTitle="Daftar List Pasien" title="Edit Data Rumah Sakit">
					<CircleLoader />
				</FormCard>
			</DashboardLayout>
		);
	}

	const findPatientId = value => {
		if (!value) return null;

		return patientsOptions.find(x => x.firstName === value)?.label || value;
	};

	return (
		<DashboardLayout>
			<Formik
				initialValues={FormInitialValues}
				innerRef={formikRef}
				validationSchema={FormSchemaValidation}
				onSubmit={handleSubmit}>
				{({handleSubmit, handleBlur, handleChange, values, errors, touched, setFieldValue}) => (
					<div class="box-white setting-content h-100 no-border p24">
						<div class="d-flex justify-content-between flex-wrap align-items-center mb-4">
							<div class="ttl-20">Ubah Pasien</div>
						</div>

						<hr />

						<div class="text-bold mb-4">
							<a
								class="text-dark"
								style={{cursor: "pointer"}}
								onClick={() => history.push("/pendaftaran-pasien")}>
								<span class="icon-ico-back me-2" />
							</a>
							Daftar List Pasien
						</div>

						<div id="accordion">
							<div class="card">
								<div
									class="card-header d-flex justify-content-between"
									id="headingOne"
									style={{cursor: "pointer"}}
									onClick={() => setInfoOpen(!infoOpen)}>
									<h3 class="mb-0">
										<button className="btn" onClick={() => setInfoOpen(!infoOpen)}>
											Info Patient
										</button>
									</h3>
									<h3 style={{"margin-top": "-10px"}}>&#8964;</h3>
								</div>
								<Collapse isOpened={infoOpen}>
									<div>
										<div class="card-body">
											<div class="row gx-2 mb-5">
												<div class="col-sm-6 col-6">
													<div class="setting-item">
														<label class="setting-label required">Title</label>
														<BasicDropdown
															className={clsx(
																"form-control",
																errors?.title && "form-control-error",
															)}
															data={titleOptions}
															name="title"
															value={values?.title}
															onSelect={fieldValue => {
																setFieldValue("title", fieldValue);
															}}
														/>
														{errors.title && (
															<p className="form-error-item-message">{errors.title}</p>
														)}
													</div>
												</div>
												<div class="col-sm-6 col-6" />
												<div class="col-sm-6 col-6">
													<div class="setting-item">
														<label class="setting-label required">First Name</label>
														<input
															className={clsx(
																"form-control",
																errors?.firstName &&
																	touched?.firstName &&
																	"form-control-error",
															)}
															disabled={isLoading}
															name="firstName"
															placeholder=""
															type="text"
															value={values?.firstName}
															onBlur={handleBlur}
															onChange={handleChange}
														/>
														{errors.firstName && touched?.firstName && (
															<p className="form-error-item-message">
																{errors.firstName}
															</p>
														)}
													</div>
												</div>
												<div class="col-sm-6 col-6">
													<div class="setting-item">
														<label class="setting-label required">Last Name</label>
														<input
															class={clsx(
																"form-control",
																errors?.lastName &&
																	touched?.lastName &&
																	"form-control-error",
															)}
															disabled={isLoading}
															name="lastName"
															placeholder=""
															type="text"
															value={values?.lastName}
															onBlur={handleBlur}
															onChange={handleChange}
														/>
														{errors.lastName && touched?.lastName && (
															<p className="form-error-item-message">{errors.lastName}</p>
														)}
													</div>
												</div>
												<div class="col-sm-6 col-6">
													<div class="setting-item">
														<label class="setting-label required">Nick Name</label>
														<input
															class={clsx(
																"form-control",
																errors?.nickname &&
																	touched?.nickname &&
																	"form-control-error",
															)}
															disabled={isLoading}
															name="nickname"
															placeholder=""
															type="text"
															value={values?.nickname}
															onBlur={handleBlur}
															onChange={handleChange}
														/>
														{errors.nickname && touched?.nickname && (
															<p className="form-error-item-message">{errors.nickname}</p>
														)}
													</div>
												</div>
												<div class="col-sm-6 col-6">
													<div class="setting-item">
														<label class="setting-label required">Username</label>
														<input
															class={clsx(
																"form-control",
																errors?.username &&
																	touched?.username &&
																	"form-control-error",
															)}
															disabled={isLoading}
															name="username"
															placeholder=""
															type="text"
															value={values?.username}
															onBlur={handleBlur}
															onChange={handleChange}
														/>
														{errors.username && touched?.username && (
															<p className="form-error-item-message">{errors.username}</p>
														)}
													</div>
												</div>
												<div class="col-sm-6">
													<div class="setting-item">
														<label class="setting-label">Date of Birth</label>
														<input
															class={clsx(
																"form-control",
																errors?.dateOfBirth &&
																	touched?.dateOfBirth &&
																	"form-control-error",
															)}
															max={new Date().toISOString().split("T")[0]}
															name="dateOfBirth"
															placeholder=""
															type="date"
															value={values?.dateOfBirth}
															onBlur={handleBlur}
															onChange={handleChange}
														/>
														{errors.dateOfBirth && touched?.dateOfBirth && (
															<p className="form-error-item-message">
																{errors.dateOfBirth}
															</p>
														)}
													</div>
												</div>
												<div class="col-sm-6 col-6">
													<div class="setting-item">
														<label class="setting-label required">Gender</label>
														<BasicDropdown
															className={clsx(
																"form-control",
																errors?.gender &&
																	touched?.gender &&
																	"form-control-error",
															)}
															data={genderOptions}
															name="gender"
															value={values?.gender}
															onSelect={fieldValue => {
																setFieldValue("gender", fieldValue);
															}}
														/>
														{errors.gender && touched?.gender && (
															<p className="form-error-item-message">{errors.gender}</p>
														)}
													</div>
												</div>
												<div class="col-sm-6 col-6 d-none">
													<div class="setting-item">
														<label class="setting-label">Password</label>
														<input
															class={clsx(
																"form-control",
																errors?.password &&
																	touched?.password &&
																	"form-control-error",
															)}
															disabled={isLoading}
															name="password"
															placeholder=""
															type="password"
															value={values?.password}
															onBlur={handleBlur}
															onChange={handleChange}
														/>
														{errors.password && touched?.password && (
															<p className="form-error-item-message">{errors.password}</p>
														)}
													</div>
												</div>
												<div class="col-sm-6 col-6">
													<div class="setting-item">
														<label class="setting-label">ID Number/KTP</label>
														<input
															class={clsx(
																"form-control",
																errors?.nik && touched?.nik && "form-control-error",
															)}
															disabled={isLoading}
															name="nik"
															placeholder=""
															type="text"
															value={values?.nik}
															onBlur={handleBlur}
															onChange={e => {
																const value = e.target.value.replace(/\D/g, "");

																setFieldValue("nik", value);
															}}
														/>
														{errors.nik && touched?.nik && (
															<p className="form-error-item-message">{errors.nik}</p>
														)}
													</div>
												</div>
												<div class="col-sm-6 col-6">
													<div class="setting-item">
														<label class="setting-label required">Marital Status</label>
														<BasicDropdown
															class={clsx(
																"form-control",
																errors?.maritalStatus &&
																	touched?.maritalStatus &&
																	"form-control-error",
															)}
															data={maritalStatusOptions}
															name="maritalStatus"
															onSelect={fieldValue => {
																setFieldValue("maritalStatus", fieldValue);
															}}
														/>
														{errors.maritalStatus && touched?.maritalStatus && (
															<p className="form-error-item-message">
																{errors.maritalStatus}
															</p>
														)}
													</div>
												</div>
												<div class="col-sm-6 col-6">
													<div class="setting-item">
														<FieldArray name="relations">
															{({insert, remove, push}) => (
																<div>
																	{values?.relations?.length > 0 &&
																		values?.relations?.map((relation, index) => (
																			<div
																				key={index}
																				className="row align-items-center">
																				<div className="col">
																					<div class="setting-item">
																						<label class="setting-label required">
																							{`Relation ${index + 1}`}
																						</label>
																						<input
																							disabled
																							className="form-control"
																							type="text"
																							value={
																								values.relations[index]
																									.label
																							}
																						/>
																					</div>
																				</div>
																				<div className="col">
																					<button
																						class="btn btn-close"
																						type="button"
																						onClick={() => remove(index)}
																					/>
																				</div>
																			</div>
																		))}
																</div>
															)}
														</FieldArray>
														<FieldArray name="relationsNew">
															{({insert, remove, push}) => (
																<div>
																	{values.relationsNew.length > 0 &&
																		values.relationsNew.map((relation, index) => (
																			<div
																				key={index}
																				className="row align-items-center">
																				<div className="col">
																					<div class="setting-item">
																						<PatientDropdown
																							data={patientsOptions}
																							inputText={findPatientId(
																								relation.idUser,
																							)}
																							label={`Relation ${
																								values.relations
																									.length +
																								index +
																								1
																							}`}
																							loading={isFethingPatients}
																							placeholder="Ketikan nama pasien.."
																							searchKeyValue={["label"]}
																							value={findPatientId(
																								relation.idUser,
																							)}
																							onChange={selectedValue => {
																								setFieldValue(
																									`relationsNew.${index}.idUser`,
																									selectedValue.value,
																								);
																							}}
																							onInput={e => {
																								if (!e) {
																									setFieldValue(
																										`relationsNew.${index}.idUser`,
																										null,
																									);
																								}
																								setFieldValue(
																									`relationsNew.${index}.idUser`,
																									e,
																								);
																							}}
																						/>
																					</div>
																				</div>
																				<div className="col">
																					<button
																						class="btn btn-close"
																						type="button"
																						onClick={() => remove(index)}
																					/>
																				</div>
																			</div>
																		))}
																	<button
																		className="btn btn-success"
																		type="button"
																		onClick={() => push({idUser: ""})}>
																		Add Relations
																	</button>
																</div>
															)}
														</FieldArray>
													</div>
												</div>
												<div class="col-sm-12">
													<div class="setting-item">
														<label class="setting-label">Image</label>
														<div class="change-img mb-4">
															<div class="for-img-change">
																<div class="bx-img-change">
																	{!values?.profilePhoto && (
																		<ProfileImageInitial
																			height={108}
																			isMale={values?.gender === "male"}
																			text={`${values?.firstName[0]}${values?.lastName[0]}`}
																			width={108}
																		/>
																	)}
																	{values?.profilePhoto && (
																		<img
																			alt=""
																			className="img-fluid"
																			src={
																				logoPreview
																					? logoPreview
																					: loadLocalAssets(
																							"img/user-default.jpg",
																					  )
																			}
																		/>
																	)}
																</div>
															</div>
															<div class="for-info-change">
																<div class="upload-img-patient">
																	<input
																		id="upload"
																		type="file"
																		onChange={handleUploadLogo}
																	/>
																	<label class="btn-upload-img-patient" for="upload">
																		<img
																			alt=""
																			class="img-fluid me-2"
																			src={loadLocalAssets("img/icn-cam.svg")}
																		/>
																		Ubah foto profil
																	</label>
																</div>
																<div class="ft-12 text-grey">
																	*Ukuran file: maks 5 Mb (.JPG .JPEG .PNG)
																</div>
															</div>
														</div>
														{errors.profilePhoto && touched?.profilePhoto && (
															<p className="form-error-item-message">
																{errors.profilePhoto}
															</p>
														)}
													</div>
												</div>
												<div class="col-sm-12">
													<div class="setting-item">
														<label class="setting-label">Remark/Note</label>
														<textarea
															class={clsx(
																"form-control",
																errors?.remark &&
																	touched?.remark &&
																	"form-control-error",
															)}
															name="remark"
															placeholder="Write something about ..."
															rows="5"
															spellcheck="false"
															value={values?.remark}
															onBlur={handleBlur}
															onChange={handleChange}
														/>
														{errors.remark && touched?.remark && (
															<p className="form-error-item-message">{errors.remark}</p>
														)}
													</div>
												</div>
											</div>
										</div>
									</div>
								</Collapse>
							</div>
						</div>
						<div id="accordion">
							<div class="card mt-5">
								<div
									class="card-header d-flex justify-content-between"
									id="headingOne"
									style={{cursor: "pointer"}}
									onClick={() => setContactOpen(!contactOpen)}>
									<h3 class="mb-0">
										<button className="btn" onClick={() => setContactOpen(!contactOpen)}>
											Contact Information
										</button>
									</h3>
									<h3 style={{"margin-top": "-10px"}}>&#8964;</h3>
								</div>
								<Collapse isOpened={contactOpen}>
									<div>
										<div class="card-body">
											<div class="row gx-2 mb-5">
												<div class="col-sm-6 col-6">
													<div class="setting-item">
														<label class="setting-label required">Phone Number</label>
														<PhoneInput
															className={clsx(
																touched?.phoneNumber &&
																	errors?.phoneNumber &&
																	"form-control-error",
															)}
															countryCallingCodeEditable={false}
															defaultCountry="ID"
															international={true}
															name="phoneNumber"
															placeholder="Nomor Telepon"
															value={`+${values.phoneNumber}`}
															onBlur={handleBlur}
															onChange={val => {
																setFieldValue("phoneNumber", val?.replace(/\D/g, ""));
															}}
														/>
														{errors.phoneNumber && touched?.phoneNumber && (
															<p className="form-error-item-message">
																{errors.phoneNumber}
															</p>
														)}
													</div>
												</div>
												<div class="col-sm-6 col-6">
													<div class="setting-item">
														<label class="setting-label required">Email</label>
														<input
															class={clsx(
																"form-control",
																errors?.email && touched?.email && "form-control-error",
															)}
															disabled={isLoading}
															name="email"
															placeholder=""
															type="email"
															value={values?.email}
															onBlur={handleBlur}
															onChange={handleChange}
														/>
														{errors.email && touched?.email && (
															<p className="form-error-item-message">{errors.email}</p>
														)}
													</div>
												</div>
											</div>
										</div>
									</div>
								</Collapse>
							</div>
						</div>
						<div id="accordion">
							<div class="card mt-5">
								<div
									class="card-header d-flex justify-content-between"
									id="headingOne"
									style={{cursor: "pointer"}}
									onClick={() => setPaymentOpen(!paymentOpen)}>
									<h3 class="mb-0">
										<button className="btn" onClick={() => setPaymentOpen(!paymentOpen)}>
											Payment Address Information
										</button>
									</h3>
									<h3 style={{"margin-top": "-10px"}}>&#8964;</h3>
								</div>
								<Collapse isOpened={paymentOpen}>
									<div>
										<div class="card-body">
											<div class="row gx-2 mb-5">
												<div class="col-sm-12">
													<div class="setting-item">
														<label class="setting-label required">Address</label>
														<GoogleSugestionDropdown
															defaultValue={values?.addressPayment?.address}
															full={true}
															onSelect={(place, component) => {
																setFieldValue("addressPayment.address", place);
																setFieldValue(
																	"addressPayment.country",
																	component.country,
																);
																setFieldValue(
																	"addressPayment.province",
																	component.administrative_area_level_1,
																);
																setFieldValue(
																	"addressPayment.city",
																	component.administrative_area_level_2,
																);
																// eslint-disable-next-line prettier/prettier
																setFieldValue("addressPayment.latitude", component.lat);
																setFieldValue(
																	"addressPayment.longitude",
																	component.long,
																);
															}}
														/>
														{errors.addressPayment?.address &&
															touched?.addressPayment?.address && (
																<p className="form-error-item-message">
																	{errors.addressPayment?.address}
																</p>
															)}
													</div>
												</div>
												<div class="col-sm-12">
													<div class="setting-item">
														<label class="setting-label">Address Detail</label>
														<Field
															className={clsx(
																"form-control",
																errors?.addressPayment?.addressDetail &&
																	touched?.addressPayment?.addressDetail &&
																	"form-control-error",
															)}
															component="textarea"
															name="addressPayment.addressDetail"
															rows="5"
															spellcheck={false}
															value={values?.addressPayment?.addressDetail}
														/>
														{errors.addressPayment?.addressDetail &&
															touched?.addressPayment?.addressDetail && (
																<p className="form-error-item-message">
																	{errors.addressPayment?.addressDetail}
																</p>
															)}
													</div>
												</div>
											</div>
										</div>
									</div>
								</Collapse>
							</div>
						</div>
						<div id="accordion">
							<div class="card mt-5">
								<div
									class="card-header d-flex justify-content-between"
									id="headingOne"
									style={{cursor: "pointer"}}
									onClick={() => setShipmentOpen(!shipmentOpen)}>
									<h3 class="mb-0">
										<button className="btn" onClick={() => setShipmentOpen(!shipmentOpen)}>
											Shipment Address Information
										</button>
									</h3>
									<h3 style={{"margin-top": "-10px"}}>&#8964;</h3>
								</div>
								<Collapse isOpened={shipmentOpen}>
									<div>
										<div class="card-body">
											<div class="row gx-2 mb-5">
												<div class="col-sm-12 col-12">
													<div class="setting-item alig-items-center">
														<input
															className=""
															id="copy_address"
															type="checkbox"
															onClick={e => {
																setDucplicateAddress(
																	e.target.checked,
																	values.addressPayment,
																);
															}}
														/>
														<label class="setting-label ms-2" htmlFor="copy_address">
															Same With Payment Address
														</label>
													</div>
												</div>
												<div class="col-sm-6 col-6">
													<div class="setting-item">
														<label class="setting-label required">First Name</label>
														<input
															className={clsx(
																"form-control",
																errors?.addressShipment?.firstName &&
																	touched?.addressShipment?.firstName &&
																	"form-control-error",
															)}
															disabled={isLoading}
															name="addressShipment.firstName"
															placeholder=""
															type="text"
															value={values?.addressShipment?.firstName}
															onBlur={handleBlur}
															onChange={handleChange}
														/>
														{errors.addressShipment?.firstName &&
															touched?.addressShipment?.firstName && (
																<p className="form-error-item-message">
																	{errors.addressShipment?.firstName}
																</p>
															)}
													</div>
												</div>
												<div class="col-sm-6 col-6">
													<div class="setting-item">
														<label class="setting-label required">Last Name</label>
														<input
															class={clsx(
																"form-control",
																errors?.addressShipment?.lastName &&
																	touched?.addressShipment?.lastName &&
																	"form-control-error",
															)}
															disabled={isLoading}
															name="addressShipment.lastName"
															placeholder=""
															type="text"
															value={values?.addressShipment?.lastName}
															onBlur={handleBlur}
															onChange={handleChange}
														/>
														{errors.addressShipment?.lastName &&
															touched?.addressShipment?.lastName && (
																<p className="form-error-item-message">
																	{errors.addressShipment?.lastName}
																</p>
															)}
													</div>
												</div>
												<div class="col-sm-6 col-6">
													<div class="setting-item">
														<label class="setting-label required">Email</label>
														<input
															class={clsx(
																"form-control",
																errors?.addressShipment?.email &&
																	touched?.addressShipment?.email &&
																	"form-control-error",
															)}
															disabled={isLoading}
															name="addressShipment.email"
															placeholder=""
															type="email"
															value={values?.addressShipment?.email}
															onBlur={handleBlur}
															onChange={handleChange}
														/>
														{errors.addressShipment?.email &&
															touched?.addressShipment?.email && (
																<p className="form-error-item-message">
																	{errors.addressShipment?.email}
																</p>
															)}
													</div>
												</div>
												<div class="col-sm-6 col-6">
													<div class="setting-item">
														<label class="setting-label">Company Name</label>
														<input
															class={clsx(
																"form-control",
																errors?.addressShipment?.companyName &&
																	touched?.addressShipment?.companyName &&
																	"form-control-error",
															)}
															disabled={isLoading}
															name="addressShipment.companyName"
															placeholder=""
															type="text"
															value={values?.addressShipment?.companyName}
															onBlur={handleBlur}
															onChange={handleChange}
														/>
														{errors.addressShipment?.companyName &&
															touched?.addressShipment?.companyName && (
																<p className="form-error-item-message">
																	{errors.addressShipment?.companyName}
																</p>
															)}
													</div>
												</div>
												<div class="col-sm-6 col-6">
													<div class="setting-item">
														<label class="setting-label required">Phone Number</label>
														<PhoneInput
															className={clsx(
																errors?.addressShipment?.phoneNumber &&
																	touched?.addressShipment?.phoneNumber &&
																	"form-control-error",
															)}
															countryCallingCodeEditable={false}
															defaultCountry="ID"
															international={true}
															name="addressShipment.phoneNumber"
															placeholder="Nomor Telepon"
															value={`+${values?.addressShipment?.phoneNumber}`}
															onBlur={handleBlur}
															onChange={val => {
																setFieldValue(
																	"addressShipment.phoneNumber",
																	val?.replace(/\D/g, ""),
																);
															}}
														/>
														{errors.addressShipment?.phoneNumber &&
															touched?.addressShipment?.phoneNumber && (
																<p className="form-error-item-message">
																	{errors.addressShipment?.phoneNumber}
																</p>
															)}
													</div>
												</div>
												<div class="col-sm-12">
													<div class="setting-item">
														<label class="setting-label required">Address</label>
														<GoogleSugestionDropdown
															defaultValue={values?.addressShipment?.address}
															full={true}
															onSelect={(place, component) => {
																setFieldValue("addressShipment.address", place);
																setFieldValue(
																	"addressShipment.country",
																	component.country,
																);
																setFieldValue(
																	"addressShipment.province",
																	component.administrative_area_level_1,
																);
																setFieldValue(
																	"addressShipment.city",
																	component.administrative_area_level_2,
																);
																setFieldValue(
																	"addressShipment.latitude",
																	component.lat,
																);
																setFieldValue(
																	"addressShipment.longitude",
																	component.long,
																);
															}}
														/>
														{errors.addressShipment?.address &&
															touched?.addressShipment?.address && (
																<p className="form-error-item-message">
																	{errors.addressShipment?.address}
																</p>
															)}
													</div>
												</div>
												<div class="col-sm-6 col-6">
													<div class="setting-item">
														<label class="setting-label required">Country</label>
														<GoogleSugestionDropdown
															defaultValue={values?.addressShipment?.country}
															disabled={true}
															isCountry={true}
															onSelect={place => {
																setFieldValue("addressShipment.country", place);
															}}
														/>
														{errors.addressShipment?.country &&
															touched?.addressShipment?.country && (
																<p className="form-error-item-message">
																	{errors.addressShipment?.country}
																</p>
															)}
													</div>
												</div>
												<div class="col-sm-6 col-6">
													<div class="setting-item">
														<label class="setting-label required">Province</label>
														<GoogleSugestionDropdown
															defaultValue={values?.addressShipment?.province}
															disabled={true}
															level={true}
															onSelect={place => {
																setFieldValue("addressShipment.province", place);
															}}
														/>
														{errors.addressShipment?.province &&
															touched?.addressShipment?.province && (
																<p className="form-error-item-message">
																	{errors.addressShipment?.province}
																</p>
															)}
													</div>
												</div>
												<div class="col-sm-6 col-6">
													<div class="setting-item">
														<label class="setting-label required">City</label>
														<GoogleSugestionDropdown
															defaultValue={values?.addressShipment?.city}
															disabled={true}
															level={2}
															onSelect={place => {
																setFieldValue("addressShipment.city", place);
															}}
														/>
														{errors.addressShipment?.city &&
															touched?.addressShipment?.city && (
																<p className="form-error-item-message">
																	{errors.addressShipment?.city}
																</p>
															)}
													</div>
												</div>
												<div class="col-sm-6 col-6">
													<div class="setting-item">
														<label class="setting-label required">Postal Code</label>
														<GoogleSugestionDropdown
															defaultValue={values?.addressShipment?.postalCode}
															type={"postal_code"}
															onSelect={place => {
																setFieldValue("addressShipment.postalCode", place);
															}}
														/>
														{errors.addressShipment?.postalCode &&
															touched?.addressShipment?.postalCode && (
																<p className="form-error-item-message">
																	{errors.addressShipment?.postalCode}
																</p>
															)}
													</div>
												</div>
												<div class="col-sm-12">
													<div class="setting-item">
														<label class="setting-label">Address Detail</label>
														<Field
															className={clsx(
																"form-control",
																errors?.addressShipment?.addressDetail &&
																	touched?.addressShipment?.addressDetail &&
																	"form-control-error",
															)}
															component="textarea"
															name="addressShipment.addressDetail"
															rows="5"
															spellcheck={false}
															value={values?.addressShipment?.addressDetail}
														/>
														{errors.addressShipment?.addressDetail &&
															touched?.addressShipment?.addressDetail && (
																<p className="form-error-item-message">
																	{errors.addressShipment?.addressDetail}
																</p>
															)}
													</div>
												</div>
											</div>
										</div>
									</div>
								</Collapse>
							</div>
						</div>
						<div id="accordion">
							<div class="card mt-5">
								<div
									class="card-header d-flex justify-content-between"
									id="headingOne"
									style={{cursor: "pointer"}}
									onClick={() => setComunicationOpen(!comunicationOpen)}>
									<h3 class="mb-0">
										<button className="btn" onClick={() => setComunicationOpen(!comunicationOpen)}>
											Comunication Preference
										</button>
									</h3>
									<h3 style={{"margin-top": "-10px"}}>&#8964;</h3>
								</div>
								<Collapse isOpened={comunicationOpen}>
									<div>
										<div class="card-body">
											<div class="row gx-2 mb-5">
												<div class="col-sm-12 col-12">
													<div class="setting-item">
														<label class="setting-label">Comunication Preference</label>
														<br />
														<small>
															Send information to sms & email when an appointment create,
															invoice, etc
														</small>
														<div className="col-sm-6 col-6">
															<label>
																<Field
																	className="me-2"
																	name="communicationPreferences"
																	type="checkbox"
																	value="email"
																/>
																Email
															</label>
														</div>
														<div class="col-sm-6 col-6">
															<label>
																<Field
																	className="me-2"
																	name="communicationPreferences"
																	type="checkbox"
																	value="sms"
																/>
																SMS
															</label>
														</div>
													</div>
												</div>
												<div class="col-sm-6 col-6">
													<div class="setting-item">
														<label class="setting-label">Email Invoice</label>
														<input
															class={clsx(
																"form-control",
																errors?.emailInvoice &&
																	touched?.emailInvoice &&
																	"form-control-error",
															)}
															disabled={isLoading}
															name="emailInvoice"
															placeholder=""
															type="email"
															value={values?.emailInvoice}
															onBlur={handleBlur}
															onChange={handleChange}
														/>
														{errors.emailInvoice && touched?.emailInvoice && (
															<p className="form-error-item-message">
																{errors.emailInvoice}
															</p>
														)}
													</div>
												</div>
											</div>
										</div>
									</div>
								</Collapse>
							</div>
						</div>
						<div id="accordion">
							<div class="card mt-5">
								<div
									class="card-header d-flex justify-content-between"
									id="headingOne"
									style={{cursor: "pointer"}}
									onClick={() => setOtherOpen(!otherOpen)}>
									<h3 class="mb-0">
										<button className="btn" onClick={() => setOtherOpen(!otherOpen)}>
											Other Information
										</button>
									</h3>
									<h3 style={{"margin-top": "-10px"}}>&#8964;</h3>
								</div>
								<Collapse isOpened={otherOpen}>
									<div>
										<div class="card-body">
											<div class="row gx-2 mb-5">
												<div class="col-sm-12 col-12">
													<div class="setting-item">
														<FieldArray name="emergencyContacts">
															{({insert, remove, push}) => (
																<div>
																	{values?.emergencyContacts?.length > 0 &&
																		values?.emergencyContacts.map(
																			(relation, index) => (
																				<div
																					key={index}
																					className="row align-items-center">
																					<div className="col">
																						<div class="setting-item">
																							<label class="setting-label">
																								Phone Number
																							</label>
																							<input
																								className="form-control"
																								disabled={isLoading}
																								name={`emergencyContacts.${index}.phoneNumber`}
																								placeholder=""
																								type="number"
																								value={
																									values
																										?.emergencyContacts[
																										index
																									]?.phoneNumber
																								}
																								onBlur={handleBlur}
																								onChange={handleChange}
																							/>
																							{errors.emergencyContacts?.[
																								index
																							]?.phoneNumber &&
																								touched
																									?.emergencyContacts?.[
																									index
																								]?.phoneNumber && (
																									<p className="form-error-item-message">
																										{
																											errors
																												.emergencyContacts?.[
																												index
																											]
																												?.phoneNumber
																										}
																									</p>
																								)}
																						</div>
																					</div>
																					<div className="col">
																						<div class="setting-item">
																							<label class="setting-label">
																								Relation
																							</label>
																							<BasicDropdown
																								className="form-control"
																								data={
																									emergencyContactOptions
																								}
																								name={`emergencyContacts.${index}.relation`}
																								value={
																									values
																										?.emergencyContacts[
																										index
																									]?.relation
																								}
																								onSelect={fieldValue => {
																									setFieldValue(
																										`emergencyContacts.${index}.relation`,
																										fieldValue,
																									);
																								}}
																							/>
																						</div>
																					</div>
																					<div className="col">
																						<button
																							class="btn btn-close"
																							type="button"
																							onClick={() =>
																								remove(index)
																							}
																						/>
																					</div>
																				</div>
																			),
																		)}
																	<button
																		className="btn btn-success"
																		type="button"
																		onClick={() =>
																			push({relation: "", phoneNumber: ""})
																		}>
																		Add Emergency Contacts
																	</button>
																</div>
															)}
														</FieldArray>
													</div>
												</div>
											</div>
										</div>
									</div>
								</Collapse>
							</div>
						</div>
						<div id="accordion">
							<div class="card mt-5">
								<div
									class="card-header d-flex justify-content-between"
									id="headingOne"
									style={{cursor: "pointer"}}
									onClick={() => setReferralOpen(!referralOpen)}>
									<h3 class="mb-0">
										<button className="btn" onClick={() => setReferralOpen(!referralOpen)}>
											Referral Source
										</button>
									</h3>
									<h3 style={{"margin-top": "-10px"}}>&#8964;</h3>
								</div>
								<Collapse isOpened={referralOpen}>
									<div>
										<div class="card-body">
											<div class="row gx-2 mb-5">
												<div class="col-sm-6 col-6">
													<div class="setting-item">
														<label class="setting-label">Referal Type</label>
														<BasicDropdown
															className={clsx(
																"form-control",
																errors?.referralType && "form-control-error",
															)}
															data={roleOptions}
															name="referralType"
															value={values?.referralType}
															onSelect={fieldValue => {
																setFieldValue("referralType", fieldValue);
																setRoleSelected(fieldValue);
															}}
														/>
														{errors.referral?.referralType && touched?.referralType && (
															<p className="form-error-item-message">
																{errors.referral?.referralType}
															</p>
														)}
													</div>
												</div>
												<div class="col-sm-6 col-6">
													<div class="setting-item">
														<label class="setting-label">Referal User</label>
														<BasicDropdown
															className={clsx(
																"form-control",
																errors?.referralIdUser && "form-control-error",
															)}
															data={referalOptions}
															name="referralIdUser"
															value={values?.referralIdUser}
															onSelect={fieldValue => {
																setFieldValue("referralIdUser", fieldValue.value);
															}}
														/>
														{errors.refer?.referralIdUser && touched?.referralIdUser && (
															<p className="form-error-item-message">
																{errors.refer?.referralIdUser}
															</p>
														)}
													</div>
												</div>
											</div>
										</div>
									</div>
								</Collapse>
							</div>
						</div>
						<div id="accordion">
							<div class="card mt-5">
								<div
									class="card-header d-flex justify-content-between"
									id="headingOne"
									style={{cursor: "pointer"}}
									onClick={() => setTagOpen(!tagOpen)}>
									<h3 class="mb-0">
										<button className="btn" onClick={() => setTagOpen(!tagOpen)}>
											Info Tag Discount
										</button>
									</h3>
									<h3 style={{"margin-top": "-10px"}}>&#8964;</h3>
								</div>
								<Collapse isOpened={tagOpen}>
									<div>
										<div class="card-body">
											<div class="row gx-2 mb-5">
												{tagOptions?.map(opt => {
													return (
														<div key={opt.value} class="col-sm-12 col-12">
															<div class="setting-item">
																<div class="form-check">
																	<input
																		checked={selectedTag.selected.includes(
																			opt.value,
																		)}
																		class="form-check-input"
																		type="checkbox"
																		onChange={e => {
																			if (
																				selectedTag.selected.includes(opt.value)
																			) {
																				setSelectedTag({
																					...selectedTag,
																					selected:
																						selectedTag.selected.filter(
																							x => x !== opt.value,
																						),
																				});
																			} else {
																				setSelectedTag({
																					...selectedTag,
																					selected: [
																						...selectedTag.selected,
																						opt.value,
																					],
																				});
																			}
																		}}
																	/>
																	<div className="d-flex align-items-center justify-content-between gap-2">
																		<label
																			class="text-green text-normal roboto"
																			for="check-1"
																			style={{maxWidth: "90%"}}>
																			{opt.label}
																		</label>
																		<div title="Priority">
																			<ToggleSwitch
																				isActive={
																					selectedTag.priority === opt.value
																				}
																				size="sm"
																				onChange={() => {
																					setSelectedTag({
																						...selectedTag,
																						priority:
																							selectedTag.priority ===
																							opt.value
																								? 0
																								: opt.value,
																					});
																				}}
																			/>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													);
												})}
											</div>
										</div>
									</div>
								</Collapse>
							</div>
						</div>
						<div class="text-center my-3">
							<button
								class="btn btn-green d-inline-block mw-250 m-1"
								disabled={!FormSchemaValidation.isValidSync(values)}>
								Generate QR code
							</button>
							<SubmitButton
								className="btn btn-orange d-inline-block mw-250 m-1"
								disabled={!FormSchemaValidation.isValidSync(values)}
								loading={isLoading}
								text="Save"
								onClick={handleSubmit}
							/>
						</div>
					</div>
				)}
			</Formik>
		</DashboardLayout>
	);
}
