import {useMemo, useState} from "react";
import {useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {DashboardLayout} from "components/Layout";
import {BasicTable, SearchBox} from "components/Table";
import {ButtonAddTable} from "components/Button";
import {useDoctorsByHospital} from "hooks/Doctor";
import {isMobile} from "react-device-detect";

export function DoctorListPage() {
	const selectedHospital = useSelector(state => state.HospitalsReducer.selectedHospital);
	const [dataLimit, setDataLimit] = useState(10);
	const [searchKeyword, setSearchKeyword] = useState("");
	const {data: doctors, isLoading, filter, filterDoctors} = useDoctorsByHospital(selectedHospital?.Hospital?.id);

	const history = useHistory();
	const columns = useMemo(
		() => [
			{
				Header: "No",
				accessor: "idSerial",
			},
			// {
			// 	Header: "Status Karyawan",
			// 	accessor: "staff.employeeStatusText",
			// },
			{
				Header: "Kode NIP",
				accessor: "staff.nip",
			},
			{
				Header: "Nama Depan",
				accessor: "firstName",
			},
			{
				Header: "Nama Belakang",
				accessor: "lastName",
			},
			{
				Header: "No Telp",
				accessor: "phoneNumber",
			},
			{
				Header: "Usia",
				accessor: "dobText",
				Cell: ({row}) => row?.values?.["dobText"] || "-",
			},
			{
				Header: "Aksi",
				accessor: "id",
				Cell: ({row}) => (
					<>
						<a
							class="text-orange wait-pay"
							style={{cursor: "pointer"}}
							onClick={() => history.push(`/hospital/doctor/show/${row?.original?.staff?.id}`)}>
							<span class="icon-ico-eye-open ft-18" />
						</a>
						<a
							class="text-orange wait-pay"
							style={{cursor: "pointer"}}
							onClick={() => history.push(`/hospital/staff/edit/${row?.original?.staff?.id}`)}>
							<span class="icon-ico-pencil ft-18" />
						</a>
					</>
				),
			},
		],
		[],
	);

	const columnsMobile = useMemo(
		() => [
			{
				Header: "Nama Karyawan",
				accessor: "firstName",
				Cell: ({row}) => (
					<div class="col-9">
						<div class="text-bold">{`${row?.original?.firstName} ${row?.original?.lastName}`}</div>
					</div>
				),
			},
			{
				Header: "Aksi",
				accessor: "id",
				Cell: ({row}) => (
					<>
						<a
							class="text-orange wait-pay"
							style={{cursor: "pointer"}}
							onClick={() => history.push(`/hospital/doctor/show/${row?.original?.staff?.id}`)}>
							<span class="icon-ico-eye-open ft-18" />
						</a>
						<a
							class="text-orange wait-pay"
							style={{cursor: "pointer"}}
							onClick={() => history.push(`/hospital/staff/edit/${row?.original?.staff?.id}`)}>
							<span class="icon-ico-pencil ft-18" />
						</a>
					</>
				),
			},
		],
		[],
	);

	const dataWithIDSerial =
		doctors?.data?.rows?.map((item, index) => ({
			...item,
			idSerial: (doctors?.data?.currentPage - 1) * dataLimit + index + 1,
		})) || [];

	return (
		<DashboardLayout>
			<>
				<div class="text-bold text-dark mb-4">Data Dokter</div>
				<SearchBox
					rightElement={
						<ButtonAddTable text="Tambah Dokter" onClick={() => history.push("/hospital/staff/tambah")} />
					}
					onSearch={keyword => {
						filterDoctors({...filter, search: keyword, page: 1, limit: dataLimit});
						setSearchKeyword(keyword);
					}}
				/>

				<BasicTable
					columns={isMobile ? columnsMobile : columns}
					currentPage={doctors?.data?.currentPage}
					data={dataWithIDSerial}
					limit={dataLimit}
					loading={isLoading}
					striped={true}
					title="Data Dokter"
					totalItems={doctors?.data?.totalItems}
					totalPage={doctors?.data?.totalPages}
					onLimitChange={limit => {
						filterDoctors({...filter, limit, page: 1, search: searchKeyword});
						setDataLimit(limit);
					}}
					onPageChange={selectedPage =>
						filterDoctors({...filter, page: selectedPage, limit: dataLimit, search: searchKeyword})
					}
				/>
			</>
		</DashboardLayout>
	);
}
