import {Col, Row} from "react-bootstrap";
import {loadLocalAssets} from "utils/loader";

export const ArticleItem = ({item}) => {
	const removeImagesFromContent = htmlContent => {
		const parser = new DOMParser();
		const doc = parser.parseFromString(htmlContent, "text/html");

		// Remove all <img> elements
		const images = doc.querySelectorAll("img");

		images.forEach(img => img.remove());

		return doc.body.innerHTML;
	};

	const cleanContent = removeImagesFromContent(item.content);

	return (
		<div className="article-post box-grey d-flex flex-column p-3 gap-4">
			<div className="d-flex align-items-center gap-2">
				{item.user.profilePhoto ? (
					<img alt="" className="article-post-profile-picture" src={item.user.profilePhoto} />
				) : (
					<img
						alt=""
						className="article-post-profile-picture"
						src={loadLocalAssets("img/default_avatar.png")}
					/>
				)}

				<div className="d-flex flex-column">
					<span className="text-green fw-semibold">{item.user.firstName}</span>
					<div className="fw-medium">{item.publishDate}</div>
				</div>
			</div>
			<Row>
				<Col md={6}>
					<img alt={item.title} className="w-100 article-post-thumbnail" src={item.thumbnail} />
				</Col>
				<Col className="d-flex flex-column gap-0" md={6}>
					<span className="text-green fw-semibold  m-0">{item.title}</span>

					<div dangerouslySetInnerHTML={{__html: cleanContent}} className="article-post-content" />
				</Col>
			</Row>
		</div>
	);
};
