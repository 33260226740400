import {useMutation, useQueryClient} from "react-query";
import axios from "services/axios";

export function useAddBanner() {
	const queryClient = useQueryClient();

	const {mutate, isLoading, isError, data, error} = useMutation(
		async data => await axios.post("/banner/create", data),
		{
			onSuccess: () => {
				queryClient.invalidateQueries("bannerList");
				queryClient.removeQueries("bannerList");
			},
		},
	);

	return {mutate, isLoading, isError, data, error};
}

export function useEditBanner() {
	const queryClient = useQueryClient();

	const {mutate, isLoading, isError, data, error} = useMutation(
		async data => await axios.post(`/banner/update`, data),
		{
			onSuccess: () => {
				queryClient.invalidateQueries("bannerList");
				queryClient.removeQueries("bannerList");
			},
		},
	);

	return {mutate, isLoading, isError, data, error};
}

export function useDeleteBanner() {
	const queryClient = useQueryClient();

	return useMutation(bannerData => axios.post("/banner/delete", bannerData).then(res => res.data), {
		onSuccess: () => {
			queryClient.invalidateQueries("bannerList");
			queryClient.removeQueries("bannerList");
		},
	});
}
