import {useState} from "react";
import {useQuery} from "react-query";
import axios from "services/axios";

async function getPositions(id, filter) {
	if (!id) {
		const {data} = await axios.get(`/position`, {params: {...filter, sortBy: "id.desc"}});

		return data;
	}
	const {data} = await axios.get(`/position/${id}`);

	return data;
}

export function useJabatan() {
	const [filter, filterJabatans] = useState({
		page: 1,
		search: null,
		limit: 10,
	});
	const [jabatanId, jabatanById] = useState(null);
	const fallback = [];
	const {
		data = fallback,
		isLoading,
		isError,
		error,
	} = useQuery(["jabatan", filter, jabatanId], async () => getPositions(jabatanId, filter));

	return {data, isLoading, isError, error, filter, filterJabatans, jabatanById};
}

// GET POSITION BY ID

export const fetchJabatan = jabatanId => {
	return axios.get(`position/${jabatanId}`).then(res => res.data);
};

export function useDetailJabatan(jabatanId) {
	return useQuery({
		queryKey: jabatanId && ["jabatan", jabatanId],
		queryFn: key => fetchJabatan(jabatanId),
		refetchOnMount: false,
		refetchOnWindowFocus: false,
		enabled: !!jabatanId,
	});
}

export function useJabatanOptions() {
	const result = useQuery(["jabatan"], async () => await axios.get(`/position`).then(res => res.data));

	return result;
}

export async function getJabatanByHospital(hospitalId) {
	const {data} = await axios.get(`/position`, {params: {hospitalId, isActive: true}});

	return data;
}
