import axios from "services/axios";
import {useMutation, useQueryClient} from "react-query";

import {QUERY} from "./useQueryPosition";

export function useAddPosition() {
	const queryClient = useQueryClient();

	return useMutation(formData => axios.post("/position/add_position", formData).then(res => res.data), {
		onSuccess: () => {
			queryClient.invalidateQueries(QUERY);
			queryClient.removeQueries(QUERY);
		},
	});
}

export function useChangeDataStatus() {
	const queryClient = useQueryClient();

	return useMutation(formData => axios.post("/position/change_status_position", formData).then(res => res.data), {
		onSuccess: () => {
			queryClient.invalidateQueries(QUERY);
			queryClient.removeQueries(QUERY);
		},
	});
}
