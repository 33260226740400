import {SubmitButton} from "components/Button";

import {ModalContainer} from "./ModalContainer";

export function ModalInfo({
	visible,
	onClose,
	onApprove,
	title = "Konfirmasi",
	description = "Are you sure want to delete this data?",
	loading,
	confirmationModalElement,
	approveLabel = "Yes",
}) {
	return (
		<ModalContainer visible={visible}>
			<div class="box-popup w550">
				<div class="d-flex justify-content-between align-items-center mb-4">
					<div class="text-bold text-dark">{title}</div>
					<div class="for-close">
						<button class="btn btn-close" disabled={loading} type="button" onClick={onClose} />
					</div>
				</div>

				<div class="popup-content">
					<div class="col-sm-12">
						{confirmationModalElement ? (
							confirmationModalElement
						) : (
							<div class=" text-bold text-grey">{description}</div>
						)}
					</div>
					<div className="d-flex justify-content-around mt-4">
						<SubmitButton
							className="btn btn-green r8 w-45"
							loading={loading}
							text={approveLabel}
							onClick={onApprove}
						/>
					</div>
				</div>
			</div>
		</ModalContainer>
	);
}
