import {useQuery} from "react-query";
import axios from "services/axios";
import qs from "query-string";

export const QUERY = "konsultasiList";

export function useListKonsultasi(filter) {
	const result = useQuery([QUERY, filter], () =>
		axios.get(`/consultation?${qs.stringify(filter)}`).then(res => res.data),
	);

	return result;
}

export const fetchKonsultasiByID = id => {
	return axios.get(`/consultation/${id}`).then(res => res.data);
};

export function useDetailKonsultasi(id) {
	return useQuery({
		queryKey: id && [QUERY, id],
		queryFn: key => fetchKonsultasiByID(id),
		refetchOnMount: false,
		refetchOnWindowFocus: false,
		enabled: !!id,
	});
}

export function useKonsultasiOptions() {
	const result = useQuery([QUERY], async () => await axios.get(`/consultation`).then(res => res.data));

	return result;
}
