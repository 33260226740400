import {BasicTable, SearchBox} from "components/Table";
import CONFIG from "config/env";
import {useArticleReport} from "hooks/SuperAdmin/Article";
import {useMemo, useState} from "react";
import {useHistory} from "react-router-dom";
import {getUserDetailUrl} from "utils/string";

const TabReportStatus = () => {
	const [searchKeyword, setSearchKeyword] = useState("");
	const [dataLimit, setDataLimit] = useState(10);
	const {data, isLoading, setFilter} = useArticleReport("post", "status");

	const history = useHistory();
	const columns = useMemo(
		() => [
			{
				Header: "No",
				accessor: "idSerial",
			},
			{
				Header: "Author Post",
				accessor: "author",
				Cell: ({row}) => {
					return (
						<div className="d-flex align-items-center gap-2">
							<div>
								{row?.original?.user?.firstName} - {row?.original?.user?.email}
							</div>
							<a
								class="text-orange wait-pay d-flex"
								style={{cursor: "pointer"}}
								onClick={() => history.push(getUserDetailUrl(row?.original?.user))}>
								<span class="icon-ico-eye-open ft-18" />
							</a>
						</div>
					);
				},
			},
			{
				Header: "Title Post",
				accessor: "title",
				Cell: ({row}) => {
					return (
						<div className="d-flex align-items-center gap-2">
							<div>{row?.original?.title}</div>
							<a
								class="text-orange wait-pay d-flex"
								href={`${CONFIG.endpoint.PATIENT_URL}/artikel/${row?.original?.slug}`}
								rel="noreferrer"
								target="_blank">
								<span class="icon-ico-eye-open ft-18" />
							</a>
						</div>
					);
				},
			},
			{
				Header: "Aksi",
				accessor: "action",
				Cell: ({row}) => (
					<a
						class="text-orange wait-pay d-flex"
						style={{cursor: "pointer"}}
						onClick={() => history.push(`/report-status/status/${row?.original?.id}`)}>
						<span class="icon-ico-eye-open ft-18" />
					</a>
				),
			},
		],
		[],
	);

	const dataWithIDSerial =
		data?.data?.rows?.map((item, index) => ({
			...item,
			idSerial: (data?.data?.currentPage - 1) * dataLimit + index + 1,
		})) || [];

	return (
		<div>
			<h1 className="m-0 mb-3">Report Status</h1>
			<SearchBox
				rightElement={null}
				onSearch={keyword => {
					setSearchKeyword(keyword);
					setFilter({page: 1, limit: dataLimit, search: keyword});
				}}
			/>
			<BasicTable
				columns={columns}
				currentPage={data?.data?.currentPage}
				data={dataWithIDSerial}
				limit={dataLimit}
				loading={isLoading}
				striped={true}
				title="Data Report Post"
				totalItems={data?.data?.totalItems}
				totalPage={data?.data?.totalPages}
				onLimitChange={limitValue => {
					setDataLimit(limitValue);
					setFilter({page: 1, limit: limitValue, search: searchKeyword});
				}}
				onPageChange={selectedPage => setFilter({page: selectedPage, limit: dataLimit, search: searchKeyword})}
			/>
		</div>
	);
};

export {TabReportStatus};
