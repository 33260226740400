import {ModalConfirmation} from "components/Modal";
import {useState} from "react";
import {useBroadcast} from "hooks/Misc/useQueryBroadcast";
import {useToast} from "hooks/useToast";

import {SubmitButton} from "./SubmitButton";

const BroadcastButton = () => {
	const {showToast} = useToast();
	const {isLoading, refetch} = useBroadcast();

	const [showModal, setShowModal] = useState(false);

	const toggleModal = () => setShowModal(!showModal);

	const handleConfirm = () => {
		refetch().then(response => {
			if (response.isSuccess) {
				showToast("success", "Pesan berhasil dikirim kepada seluruh pasien");
				toggleModal();
			}
		});
	};

	return (
		<div class="min-w-20 btn-add-container">
			<div class="row gx-2 align-items-center justify-content-end">
				<SubmitButton text="Kirim Info" onClick={toggleModal} />
			</div>
			<ModalConfirmation
				approveLabel="Kirim Whatsapp"
				description="Kirim informasi download aplikasi ProMedik kepada seluruh pasien via WhatsApp"
				loading={isLoading}
				noReject={true}
				visible={showModal}
				onApprove={handleConfirm}
				onClose={toggleModal}
			/>
		</div>
	);
};

export {BroadcastButton};
