import ReactDOM from "react-dom/client";
import {ExtensionTag, NodeExtension} from "remirror";
import {loadLocalAssets} from "utils/loader";
import {uploadFile} from "hooks/Misc";

import {getNodeCoordinates} from "../util";

const ImagePlaceholder = ({onChange}) => {
	const handleOnSelectImage = event => {
		if (event.target.classList.value.includes("image-placeholder")) {
			const input = document.createElement("input");

			input.type = "file";
			input.accept = "image/*";

			input.onchange = async event => {
				const file = event.target.files[0];

				if (file) {
					let formData = new FormData();

					formData.append("file", file);

					const response = await uploadFile(formData);

					if (response.success) {
						onChange(response.data.file);
					}
				}
			};

			input.click();
		}
	};

	return (
		<div className="image-placeholder" onClick={handleOnSelectImage}>
			<img alt="placeholder-image" src={loadLocalAssets("img/upload-image-icon.svg")} />

			<span>Click to select an image</span>
		</div>
	);
};

class ImageUploadExtension extends NodeExtension {
	get name() {
		return "image";
	}

	createTags() {
		return [ExtensionTag.Block];
	}

	createNodeSpec(extra, override) {
		return {
			...override,
			attrs: {src: {default: null}, ...extra.defaults()},
			content: "",
			draggable: false,
			selectable: true,
			isolation: true,
			atom: true,
			parseDOM: [
				{tag: "img", getAttrs: dom => ({src: dom.getAttribute("src")})},
				{tag: "div[data-placeholder-image]", getAttrs: extra.parse},
				...(override.parseDOM ?? []),
			],
			toDOM: node => {
				const {src} = node.attrs;

				if (src) return ["img", {src, class: "responsive-image"}];

				return ["div", {"data-placeholder-image": true}];
			},
		};
	}

	createNodeViews() {
		return (node, view, getPos) => {
			if (node.attrs.src) return null;

			const container = document.createElement("div");

			container.classList.add("position-relative");

			const root = ReactDOM.createRoot(container);

			const handleChange = newSrc => {
				const tr = view.state.tr.setNodeMarkup(getPos(), null, {
					...node.attrs,
					src: newSrc,
				});

				view.dispatch(tr);
			};

			root.render(<ImagePlaceholder onChange={handleChange} />);

			return {
				dom: container,
				update: nextNode => {
					if (nextNode.attrs.src !== node.attrs.src) {
						root.render(<img alt="upload-image" className="responsive-image" src={nextNode.attrs.src} />);
					}

					return true;
				},
			};
		};
	}
}

export function UnplashButton({view, state, node, onClick}) {
	const coords = getNodeCoordinates({view, state}, "image", node?.attrs?.src === null, node.index);

	const containerPos = document.querySelector(".editor-container")?.offsetTop ?? 0;

	const {top} = coords?.coords ?? {};

	const handleOnClick = () => {
		if (typeof onClick === "function") onClick(coords?.pos);
	};

	if (!coords || coords?.node?.attrs?.html) return null;

	return (
		<button
			className="editor-banner-btn-icon position-absolute m-2"
			style={{top: top - containerPos + window.scrollY, zIndex: 99}}
			onClick={handleOnClick}>
			<img alt="logo" className="img-fluid" src={loadLocalAssets("img/ri_unsplash-fill.svg")} />
		</button>
	);
}

export const ImageUpload = new ImageUploadExtension();
