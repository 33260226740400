import clsx from "clsx";
import {useHistory} from "react-router-dom";
import {Formik} from "formik";
import * as Yup from "yup";
import {DashboardLayout} from "components/Layout";
import {SubmitButton} from "components/Button";
import {useAddUnit} from "hooks/SuperAdmin/Unit";
import {useToast} from "hooks/useToast";
import {convertErrorMessageFormat} from "utils/converter";

const FormSchemaValidation = Yup.object().shape({
	name: Yup.string().required("Nama unit wajib di isi"),
});
const FormInitialValues = {name: ""};

export function MasterUnitAddPage() {
	const {showToast} = useToast();
	const history = useHistory();
	const {mutate, isLoading} = useAddUnit();
	const handleSubmit = formData => {
		mutate(formData, {
			onSuccess: () => {
				showToast("success", "Data berhasil ditambahkan", 3000);
				history.push("/master-unit");
			},
			onError: res => {
				showToast("error", convertErrorMessageFormat(res.response.status, res.response.data.message), null);
			},
		});
	};

	return (
		<DashboardLayout>
			<Formik initialValues={FormInitialValues} validationSchema={FormSchemaValidation} onSubmit={handleSubmit}>
				{({handleSubmit, handleBlur, handleChange, values, errors}) => (
					<div class="box-white setting-content h-100 no-border p24">
						<div class="d-flex justify-content-between flex-wrap align-items-center mb-4">
							<div class="ttl-20">Tambah Unit Baru</div>
						</div>

						<hr />

						<div class="text-bold mb-4">
							<a
								class="text-dark"
								style={{cursor: "pointer"}}
								onClick={() => history.push("/master-unit")}>
								<span class="icon-ico-back me-2" />
							</a>
							Daftar List Unit
						</div>

						<div class="row gx-2 mb-5">
							<div class="col-sm-6 col-6">
								<div class="setting-item">
									<label class="setting-label required">Nama Unit</label>
									<input
										class={clsx("form-control", errors?.name && "form-control-error")}
										disabled={isLoading}
										name="name"
										placeholder=""
										type="text"
										value={values?.name}
										onBlur={handleBlur}
										onChange={handleChange}
									/>
									{errors.name && <p className="form-error-item-message">{errors.name}</p>}
								</div>
							</div>
						</div>

						<div class="text-center my-3">
							<SubmitButton
								className="btn btn-orange d-inline-block mw-250"
								disabled={!FormSchemaValidation.isValidSync(values)}
								loading={isLoading}
								text="Simpan"
								onClick={handleSubmit}
							/>
						</div>
					</div>
				)}
			</Formik>
		</DashboardLayout>
	);
}
