import {useState} from "react";
import {useQuery} from "react-query";
import axios from "services/axios";

async function getArticleReport(id, filter, type) {
	if (!id) {
		const {data} = await axios.get(`/report/${type}`, {params: {...filter, sortBy: "id.desc"}});

		return data;
	}

	const {data} = await axios.get(`/${type}/${id}`);

	return data;
}

async function getDiscussionReport(id, filter, type) {
	if (!id) {
		const {data} = await axios.get(`/report/discussion/${type}`, {params: {...filter, sortBy: "id.desc"}});

		return data;
	}

	const {data} = await axios.get(`/${type}/${id}`);

	return data;
}

export const QUERY_ARTICLE_REPORT = "article-report";
export const QUERY_DISCUSSION_REPORT = "discussion-report";

export const useArticleReport = type => {
	const [filter, setFilter] = useState({
		page: 1,
		search: null,
		limit: 10,
	});
	const [articleReportId, setArticleReportId] = useState(null);
	const {
		data = [],
		isLoading,
		isError,
		error,
	} = useQuery([QUERY_ARTICLE_REPORT, filter, articleReportId], async () =>
		getArticleReport(articleReportId, filter, type),
	);

	return {data, isLoading, isError, error, filter, setFilter, setArticleReportId};
};

export const useDiscussionReport = type => {
	const [filter, setFilter] = useState({
		page: 1,
		search: null,
		limit: 10,
	});
	const [discussionReportId, setDiscussionReportId] = useState(null);
	const {
		data = [],
		isLoading,
		isError,
		error,
	} = useQuery([QUERY_ARTICLE_REPORT, filter, discussionReportId], async () =>
		getDiscussionReport(discussionReportId, filter, type),
	);

	return {data, isLoading, isError, error, filter, setFilter, setDiscussionReportId};
};

async function getDetailArticleReport(id, type) {
	const {data} = await axios.get(`/report/${id}/type/${type}`);

	return data;
}

async function getDetailDiscussionReport(id, type) {
	const {data} = await axios.get(`/report/discussion/${id}/type/${type}`);

	return data;
}

export function useDetailArticleReport(id, type) {
	return useQuery([QUERY_ARTICLE_REPORT, id, type], () => getDetailArticleReport(id, type), {enabled: !!id});
}

export function useDetailDiscussionReport(id, type) {
	return useQuery([QUERY_DISCUSSION_REPORT, id, type], () => getDetailDiscussionReport(id, type), {enabled: !!id});
}
