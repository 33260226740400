import {useState} from "react";
import {useQuery} from "react-query";
import axios from "services/axios";

async function getLayananPoliklinikTindakan(id, filter) {
	if (!id) {
		const {data} = await axios.get(`/action_division`, {params: {...filter, sortBy: "id.desc"}});

		return data;
	}
	const {data} = await axios.get(`/action_division/${id}`);

	return data;
}

export function useLayananPoliklinikTindakan(hospitalId) {
	const [filter, filterLayananPoliklinikTindakan] = useState({
		page: 1,
		search: null,
		limit: 10,
		hospitalId,
	});
	const [layananPoliklinikTindakanId, layananPoliklinikTindakanById] = useState(null);
	const fallback = [];
	const {
		data = fallback,
		isLoading,
		isError,
		error,
	} = useQuery(["layananPoliklinikTindakan", filter, layananPoliklinikTindakanId], async () =>
		getLayananPoliklinikTindakan(layananPoliklinikTindakanId, filter),
	);

	return {data, isLoading, isError, error, filter, filterLayananPoliklinikTindakan, layananPoliklinikTindakanById};
}

export const fetchLayananPoliklinikTindakan = layananPoliklinikTindakanId => {
	return axios.get(`/action_division/${layananPoliklinikTindakanId}`).then(res => res.data);
};

export function useDetailLayananPoliklinikTindakan(layananPoliklinikTindakanId) {
	return useQuery({
		queryKey: layananPoliklinikTindakanId && ["layananPoliklinikTindakan", layananPoliklinikTindakanId],
		queryFn: key => fetchLayananPoliklinikTindakan(layananPoliklinikTindakanId),
		refetchOnMount: false,
		refetchOnWindowFocus: false,
		enabled: !!layananPoliklinikTindakanId,
	});
}

// options

export function useLayananPoliklinikTindakanOptions(filter = {}) {
	const result = useQuery(
		["layananPoliklinikTindakanOptions", filter],
		async () => await axios.get(`/action_division`, {params: {...filter}}).then(res => res.data),
	);

	return result;
}
