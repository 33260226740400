/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import dayjs from "dayjs";
import {useMemo, useState, useEffect} from "react";
import {useHistory} from "react-router-dom";
import {isMobile} from "react-device-detect";
import {DashboardLayout} from "components/Layout";
import {BasicTable, SearchBox} from "components/Table";
import {useAppointment} from "hooks/Doctor/Appointment";
import {DatePicker} from "components/DatePicker";
import {PatientDropdown} from "components/Dropdown";
import {useHospitalsOptions} from "hooks/SuperAdmin/Hospital";
import {useSelector} from "react-redux";
import {useToast} from "hooks/useToast";

const ZoomRedirect = ({row}) => {
	const {showToast} = useToast();

	if (row?.original?.type === "online") {
		if (row?.original?.transaction?.paymentStatus == "paid") {
			return (
				<a
					class="text-primary wait-pay"
					href={row?.original?.meetingUrl?.startUrl}
					rel="noreferrer"
					target="_blank">
					<span class="icon-ico-zoom ft-18" />
				</a>
			);
		} else {
			return (
				<a
					class="text-primary wait-pay"
					style={{cursor: "pointer"}}
					onClick={() => {
						showToast(
							"error",
							"Telekonsul belum dapat dilakukan dikarenakan appointment belum dibayarkan oleh pasien",
							null,
						);
					}}>
					<span class="icon-ico-zoom ft-18" />
				</a>
			);
		}
	} else {
		return null;
	}
};

export function AppointmentListPage() {
	const userProfile = useSelector(state => state.AuthReducer.profile);
	const [searchKeyword, setSearchKeyword] = useState("");
	const [dataLimit, setDataLimit] = useState(10);
	const [locationOptions, setLocationOptions] = useState([]);
	const {data, isLoading, filterAppointments, filter} = useAppointment(userProfile.id);
	const {hospitalOptions: locations, isLoading: isLoadingLocationOptions} = useHospitalsOptions();

	useEffect(() => {
		if (locations.length) {
			setLocationOptions([{label: " Semua", value: ""}, ...locations]);
		}
	}, [locations]);

	const history = useHistory();
	const columns = useMemo(
		() => [
			{
				Header: "No",
				accessor: "idSerial",
			},
			{
				Header: "Nama Pasien",
				accessor: "patient.fullName",
				Cell: ({row}) => (
					<>
						<span
							className={
								row?.original?.type === "offline"
									? "text-purple ft-18 icon-ico-hospital me-2"
									: "text-primary ft-18 icon-ico-zoom me-2"
							}
						/>{" "}
						{row?.original?.patient?.fullName}
					</>
				),
			},
			{
				Header: "Layanan",
				accessor: "doctor",
				Cell: ({row}) => row?.original?.doctor?.staff?.policlinic?.name || "-",
			},
			{
				Header: "Time and Date Appointment",
				accessor: "startTime",
				Cell: ({row}) => (
					<>
						{row?.original?.startTime} WIB - {dayjs(row?.original?.date).format("DD MMMM YYYY")}
					</>
				),
			},
			{
				Header: "Lokasi",
				accessor: "hospital.name",
				Cell: ({row}) => <>{row?.original?.type === "online" ? "Online" : row?.original?.hospital?.name}</>,
			},
			{
				Header: "Status",
				accessor: "patientStatus",
				Cell: ({row}) => {
					if (row?.original?.medicalRecord) {
						return <div className="box-status serve">Sudah dilayani</div>;
					}

					if (row?.original?.bookingStatus === "canceled") {
						return <div className="box-status not-serve">Dibatalkan</div>;
					}

					if (["pasien", "belum_dilayani", "sedang_dilayani"].includes(row?.values?.patientStatus)) {
						return <div className="box-status not-serve">Belum dilayani</div>;
					} else {
						return <div className="box-status serve">Sudah dilayani</div>;
					}
				},
			},
			{
				Header: "Aksi",
				accessor: "id",
				Cell: ({row}) => (
					<>
						<a
							class="text-orange wait-pay me-2"
							style={{cursor: "pointer"}}
							onClick={() => history.push(`/doctor/appointment/show/${row?.values?.id}`)}>
							<span class="icon-ico-eye-open ft-18" />
						</a>
						<ZoomRedirect row={row} />
					</>
				),
			},
		],
		[],
	);

	const columnsMobile = useMemo(
		() => [
			{
				Header: "Nama Pasien",
				accessor: "patient.fullName",
				Cell: ({row}) => {
					return (
						<div class="col-9">
							<div class="text-bold">{row?.original?.patient?.fullName}</div>
						</div>
					);
				},
			},
			{
				Header: "Waktu",
				accessor: "startTime",
				Cell: ({row}) => {
					return (
						<div class="col-9">
							<div class="text-bold">
								{row?.original?.startTime} WIB - {dayjs(row?.original?.date).format("DD MMMM YYYY")}
							</div>
						</div>
					);
				},
			},
			{
				Header: "Lokasi",
				accessor: "hospital.name",
				Cell: ({row}) => {
					return (
						<div class="col-9">
							<div class="text-bold">
								{row?.original?.type === "online" ? "Online" : row?.original?.hospital?.name}
							</div>
						</div>
					);
				},
			},
			{
				Header: "Action",
				accessor: "id",
				Cell: ({row}) => {
					return (
						<div className="col-3">
							<a
								class="text-orange wait-pay me-2"
								style={{cursor: "pointer"}}
								onClick={() => history.push(`/doctor/appointment/show/${row?.values?.id}`)}>
								<span class="icon-ico-eye-open ft-18" />
							</a>
							{row?.original?.type === "online" && (
								<a class="text-primary wait-pay" href={row?.original?.meetingUrl}>
									<span class="icon-ico-zoom ft-18" />
								</a>
							)}
						</div>
					);
				},
			},
		],
		[],
	);

	const dataWithIDSerial =
		data?.data?.rows?.map((item, index) => ({
			...item,
			idSerial: (data?.data?.currentPage - 1) * dataLimit + index + 1,
		})) || [];

	return (
		<DashboardLayout>
			<>
				<div class="text-bold text-dark mb-4">Appointment</div>
				<SearchBox
					rightElement={
						<div className="row col-8 align-items-center justify-content-end">
							<div class="col-lg-1">
								<p className="fw-bolder m-0">Filter</p>
							</div>
							<div class="col-lg-5 col-sm-12">
								<div className="form-filter position-relative">
									<DatePicker
										singleDatePicker
										onChange={selectedDate => {
											filterAppointments({
												...filter,
												date: dayjs(selectedDate, "MM/DD/YYYY").format("YYYY-MM-DD"),
											});
										}}>
										<input
											className="form-control"
											placeholder="Appointment Date"
											type="text"
											value={
												(filter.date &&
													dayjs(filter.date, "YYYY-MM-DD").format("DD MMMM YYYY")) ||
												""
											}
										/>
									</DatePicker>
									<div className="icon-right">
										<span className="icon-ico-date text-orange" />
									</div>
								</div>
							</div>
							<div class="col-lg-5 col-sm-12 pt-3">
								<div className="form-filter position-relative">
									<PatientDropdown
										data={locationOptions}
										loading={isLoadingLocationOptions}
										placeholder="Lokasi Rumah Sakit.."
										searchKeyValue={["label"]}
										onChange={selectedValue => {
											filterAppointments({...filter, idHospital: selectedValue.value});
										}}
									/>
									<div className="icon-right">
										<span className="icon-ico-pin text-secondary" />
									</div>
								</div>
							</div>
						</div>
					}
					onSearch={keyword => {
						setSearchKeyword(keyword);
						filterAppointments({page: 1, limit: dataLimit, search: keyword});
					}}
				/>

				<BasicTable
					columns={isMobile ? columnsMobile : columns}
					currentPage={data?.data?.currentPage}
					data={dataWithIDSerial}
					limit={dataLimit}
					loading={isLoading}
					striped={true}
					title="Data Appointment"
					totalItems={data?.data?.totalItems}
					totalPage={data?.data?.totalPages}
					onLimitChange={limitValue => {
						setDataLimit(limitValue);
						filterAppointments({page: 1, limit: limitValue, search: searchKeyword});
					}}
					onPageChange={selectedPage =>
						filterAppointments({page: selectedPage, limit: dataLimit, search: searchKeyword})
					}
				/>
			</>
		</DashboardLayout>
	);
}
