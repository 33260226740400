import clsx from "clsx";
import {SubmitButton} from "components/Button";
import {DashboardLayout} from "components/Layout";
import {Formik} from "formik";
import {useAddIndicatorReport, useEditIndicatorReport, useIndicatorReport} from "hooks/SuperAdmin/Article";
import {useToast} from "hooks/useToast";
import {useEffect, useRef} from "react";
import {useHistory, useParams} from "react-router-dom";
import {convertErrorMessageFormat} from "utils/converter";
import * as Yup from "yup";

const FormSchemaValidation = Yup.object().shape({
	title: Yup.string().required("Title wajib diisi"),
	description: Yup.string().required("Sub Desc wajib diisi"),
});
const FormInitialValues = {title: "", description: ""};

const SettingIndicatorReportActionPage = () => {
	const {showToast} = useToast();
	const history = useHistory();
	const {action, id} = useParams();
	const {mutate: onAdd, isLoading: isLoadingAdd} = useAddIndicatorReport();
	const {mutate: onEdit, isLoading: isLoadingEdit} = useEditIndicatorReport();
	const {data: detailIndicatorReport, setIndicatorId} = useIndicatorReport();
	const formikRef = useRef();

	useEffect(() => {
		if (id) {
			setIndicatorId(id);
		}
	}, [id]);

	useEffect(() => {
		if (detailIndicatorReport?.data?.id && action === "edit" && formikRef.current) {
			formikRef.current.setFieldValue("title", detailIndicatorReport.data.title);
			formikRef.current.setFieldValue("description", detailIndicatorReport.data.description);

			setTimeout(() => formikRef.current.validateForm(), 500);
		}
	}, [detailIndicatorReport, action, formikRef]);

	const handleSubmit = formData => {
		if (action === "tambah") {
			onAdd(formData, {
				onSuccess: () => {
					showToast("success", "Data berhasil ditambahkan", 3000);
					history.push("/setting-indicator-report");
				},
				onError: res => {
					showToast("error", convertErrorMessageFormat(res.response.status, res.response.data.message), null);
				},
			});
		}
		if (action === "edit") {
			onEdit(
				{id, ...formData},
				{
					onSuccess: () => {
						showToast("success", "Data berhasil diperbarui", 3000);
						history.push("/setting-indicator-report");
					},
					onError: res => {
						showToast(
							"error",
							convertErrorMessageFormat(res.response.status, res.response.data.message),
							null,
						);
					},
				},
			);
		}
	};

	return (
		<DashboardLayout>
			<Formik
				initialValues={FormInitialValues}
				innerRef={formikRef}
				validationSchema={FormSchemaValidation}
				onSubmit={handleSubmit}>
				{({handleSubmit, handleBlur, handleChange, values, errors}) => (
					<div class="box-white setting-content h-100 no-border p24">
						<div class="d-flex justify-content-between flex-wrap align-items-center mb-4">
							<div class="ttl-20">Setting Indicator report</div>
						</div>

						<hr />

						<div class="text-bold mb-4">
							<a
								class="text-dark"
								style={{cursor: "pointer"}}
								onClick={() => history.push("/setting-indicator-report")}>
								<span class="icon-ico-back me-2" />
							</a>
							Daftar List Setting Indicator Report
						</div>

						<div class="row gx-2 mb-5">
							<div class="col-6">
								<div class="setting-item">
									<label
										class={clsx("setting-label", {
											required: action !== "detail",
										})}>
										Title
									</label>
									{action === "detail" ? (
										<p className="text-bold text-grey">{detailIndicatorReport?.data?.title}</p>
									) : (
										<>
											<input
												class={clsx("form-control", errors?.title && "form-control-error")}
												disabled={isLoadingAdd || isLoadingEdit}
												name={`title`}
												placeholder=""
												type="text"
												value={values?.title}
												onBlur={handleBlur}
												onChange={handleChange}
											/>
											{errors?.title && (
												<p className="form-error-item-message">{errors?.title}</p>
											)}
										</>
									)}
								</div>
							</div>
							<div class="col-6">
								<div class="setting-item">
									<label
										class={clsx("setting-label", {
											required: action !== "detail",
										})}>
										Sub Desc
									</label>
									{action === "detail" ? (
										<p className="text-bold text-grey">
											{detailIndicatorReport?.data?.description}
										</p>
									) : (
										<>
											<textarea
												class={clsx(
													"form-control",
													errors?.description && "form-control-error",
												)}
												disabled={isLoadingAdd || isLoadingEdit}
												name={`description`}
												placeholder=""
												rows={5}
												type="text"
												value={values?.description}
												onBlur={handleBlur}
												onChange={handleChange}
											/>
											{errors?.description && (
												<p className="form-error-item-message">{errors?.description}</p>
											)}
										</>
									)}
								</div>
							</div>
						</div>

						{action !== "detail" ? (
							<div class="text-center my-3">
								<SubmitButton
									className="btn btn-orange d-inline-block mw-250"
									disabled={!FormSchemaValidation.isValidSync(values)}
									loading={isLoadingAdd || isLoadingEdit}
									text="Simpan"
									onClick={handleSubmit}
								/>
							</div>
						) : null}
					</div>
				)}
			</Formik>
		</DashboardLayout>
	);
};

export {SettingIndicatorReportActionPage};
