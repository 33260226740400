import {useState} from "react";
import {useQuery} from "react-query";
import axios from "services/axios";

async function getArticleReport(id, filter, type, articleType) {
	const params = {...filter, sortBy: "id.desc"};

	if (articleType) {
		params.type = articleType;
		params.article_type = articleType;
	}

	if (!id) {
		const {data} = await axios.get(`/report/${type}`, {params});

		return data;
	}

	const {data} = await axios.get(`/${type}/${id}`);

	return data;
}

export const QUERY_ARTICLE_REPORT = "article-report";

export const useArticleReport = (type, articleType = null) => {
	const [filter, setFilter] = useState({
		page: 1,
		search: null,
		limit: 10,
	});
	const [articleReportId, setArticleReportId] = useState(null);
	const {
		data = [],
		isLoading,
		isError,
		error,
	} = useQuery([QUERY_ARTICLE_REPORT, filter, articleReportId, articleType], async () =>
		getArticleReport(articleReportId, filter, type, articleType),
	);

	return {data, isLoading, isError, error, filter, setFilter, setArticleReportId};
};

async function getDetailArticleReport(id, type) {
	const {data} = await axios.get(`/report/${id}/type/${type}`);

	return data;
}

export function useDetailArticleReport(id, type) {
	return useQuery([QUERY_ARTICLE_REPORT, id, type], () => getDetailArticleReport(id, type), {enabled: !!id});
}
