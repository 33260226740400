import {useState} from "react";
import {useQuery} from "react-query";
import axios from "services/axios";

async function getScheduleWorks(id, filter, userId) {
	const {data} = await axios.get(`/schedule_work`, {params: {...filter, userId}});

	return data;
}

export function useScheduleWorks(userId) {
	const [filter, filterScheduleWorks] = useState({
		page: 1,
		search: null,
		limit: 10,
	});
	const [scheduleWorkId, scheduleWorkById] = useState(null);
	const fallback = [];
	const {
		data = fallback,
		isLoading,
		isError,
		error,
	} = useQuery(["scheduleWorks", scheduleWorkId, filter], async () =>
		getScheduleWorks(scheduleWorkId, filter, userId),
	);

	return {data, isLoading, isError, error, filter, filterScheduleWorks, scheduleWorkById};
}

// Fetch Hospital By ID

export const fetchScheduleWorkByID = scheduleWorkId => {
	return axios.get(`/schedule_work/${scheduleWorkId}`).then(res => res.data);
};

export function useDetailScheduleWork(scheduleWorkId) {
	return useQuery({
		queryKey: scheduleWorkId && ["scheduleWorks", scheduleWorkId],
		queryFn: key => fetchScheduleWorkByID(scheduleWorkId),
		refetchOnMount: true,
		refetchOnWindowFocus: true,
		enabled: !!scheduleWorkId,
	});
}
