import clsx from "clsx";
import {SubmitButton} from "components/Button";
import {FormCard} from "components/Form";
import {DashboardLayout} from "components/Layout";
import {CircleLoader} from "components/Loader";
import {Formik} from "formik";
import {useListKategoriPemeriksaan} from "hooks/SuperAdmin/KategoriPemeriksaan";
import {useDetailPemeriksaanPenunjang} from "hooks/SuperAdmin/PemeriksaanPenunjang";
import {useUpdatePemeriksaanPenunjang} from "hooks/SuperAdmin/PemeriksaanPenunjang/useMutationPemeriksaanPenunjang";
import {useToast} from "hooks/useToast";
import {useEffect, useMemo, useRef, useState} from "react";
import {useHistory, useRouteMatch} from "react-router-dom";
import {convertErrorMessageFormat} from "utils/converter";
import {v4 as uuidv4} from "uuid";
import * as Yup from "yup";

import FormFormulaPemeriksaanPenunjang from "./FormFormulaPemeriksaanPenunjang";

const FormSchemaValidation = Yup.object().shape({
	checkupCategoryId: Yup.string().required("Kategori pemeriksaan wajib di isi"),
	title: Yup.string().required("Nama pemeriksaan wajib di isi"),
});

const FormInitialValues = {
	checkupCategoryId: "",
	title: "",
};

const deleteFormulaButtonStyle = {
	top: 0,
	right: 0,
};

export function MasterPemeriksaanPenunjangEditPage() {
	const {showToast} = useToast();
	const history = useHistory();
	const matchParams = useRouteMatch();
	const {params} = matchParams;
	const {id} = params;
	const formikRef = useRef();

	const {isLoading: isFetching, data} = useDetailPemeriksaanPenunjang(id);
	const detailPemeriksaanPenunjang = data?.data;
	const hasFormulas = useMemo(
		() => Boolean(detailPemeriksaanPenunjang?.CheckupSupportDetails?.length),
		[detailPemeriksaanPenunjang],
	);

	const [formulaForms, setFormulaForms] = useState([
		{
			id: uuidv4(),
			isValid: false,
			isNew: true,
			values: null,
		},
	]);

	useEffect(() => {
		if (detailPemeriksaanPenunjang?.CheckupSupportDetails?.length) {
			const updated = detailPemeriksaanPenunjang.CheckupSupportDetails.map(formula => {
				return {id: formula.id, isValid: true, isNew: false, values: formula};
			});

			setFormulaForms(updated);
		}
	}, [detailPemeriksaanPenunjang]);

	const isEligibleToSubmit = useMemo(() => {
		return formulaForms.every(form => form.isValid);
	}, [formulaForms]);

	const listKategoriPemeriksaanQuery = useListKategoriPemeriksaan();
	const listKategoriPemeriksaanOptions = useMemo(() => {
		const arr = listKategoriPemeriksaanQuery.data?.data?.rows ?? [];

		if (arr.length === 0) return [];

		return arr.map(kategori => ({value: kategori.id, label: kategori.name}));
	}, [listKategoriPemeriksaanQuery.data]);

	const {mutate, isLoading} = useUpdatePemeriksaanPenunjang();
	const handleSubmit = formData => {
		const {checkupCategoryId, title} = formData;

		const initialFormulaIDs = detailPemeriksaanPenunjang.CheckupSupportDetails.map(e => e.id);
		const formulaIDs = formulaForms.map(e => e.id);

		const deletedFormulas = initialFormulaIDs.filter(value => formulaIDs.indexOf(value) === -1);

		const payload = {
			id: detailPemeriksaanPenunjang?.id,
			checkupCategoryId: parseInt(checkupCategoryId),
			title,
			formulas: formulaForms.map(formula => {
				const {values} = formula;

				return {
					id: formula.isNew ? null : formula.id,
					diffFactor: values["diffFactor"],
					limit: values["limit"] || null,
					gender: values["gender"] || null,
					diffValueFirst: values["diffValueFirst"] ? parseInt(values["diffValueFirst"]) : null,
					diffUnitFirst: values["diffUnitFirst"] || null,
					diffValueLast: values["diffValueLast"] ? parseInt(values["diffValueLast"]) : null,
					diffUnitLast: values["diffUnitLast"] || null,
					referenceLimit: values["referenceLimit"],
					referenceValueFirst: parseInt(values["referenceValueFirst"]),
					referenceValueLast: values["referenceValueLast"] ? parseInt(values["referenceValueLast"]) : null,
					referenceUnit: values["referenceUnit"],
				};
			}),
			deleted_formulas: deletedFormulas,
		};

		mutate(payload, {
			onSuccess: () => {
				showToast("success", "Data berhasil diperbarui", 3000);
				history.push("/master-pemeriksaan-penunjang");
			},
			onError: res => {
				showToast("error", convertErrorMessageFormat(res.response.status, res.response.data.message), null);
			},
		});
	};

	const handleAddFormula = () => {
		setFormulaForms(formulaForms.concat([{id: uuidv4(), isNew: true, isValid: false, values: null}]));
	};

	useEffect(() => {
		const initializeFormValues = () => {
			if (detailPemeriksaanPenunjang) {
				const {title, checkupCategoryId} = detailPemeriksaanPenunjang;

				formikRef.current.setFieldValue("title", title);
				formikRef.current.setFieldValue("checkupCategoryId", checkupCategoryId);

				setTimeout(() => {
					formikRef.current.setFieldTouched("title", true);
					formikRef.current.setFieldTouched("checkupCategoryId", true);
				});
			}
		};

		initializeFormValues();
	}, [detailPemeriksaanPenunjang]);

	if (isFetching) {
		return (
			<DashboardLayout>
				<FormCard title="Edit Kategori Pemeriksaan">
					<CircleLoader />
				</FormCard>
			</DashboardLayout>
		);
	}

	return (
		<DashboardLayout>
			<Formik
				initialValues={FormInitialValues}
				innerRef={formikRef}
				validationSchema={FormSchemaValidation}
				onSubmit={handleSubmit}>
				{({handleSubmit, handleBlur, handleChange, values, errors}) => {
					return (
						<div className="box-white setting-content h-100 no-border p24">
							<div className="d-flex justify-content-between flex-wrap align-items-center mb-4">
								<div className="ttl-20">Tambah Pemeriksaan Penunjang Baru</div>
							</div>

							<hr />

							<div className="text-bold mb-4">
								<a
									className="text-dark"
									style={{cursor: "pointer"}}
									onClick={() => history.push("/master-pemeriksaan-penunjang")}>
									<span className="icon-ico-back me-2" />
								</a>
								Daftar List Pemeriksaan Penunjang
							</div>

							<div className="row gx-2 mb-5">
								<div className="col-sm-12 col-lg-6  col-xs-12">
									<div className="setting-item">
										<label className="setting-label required">Kategori Pemeriksaan</label>
										<select
											className={clsx(
												"form-control",
												errors?.checkupCategoryId && "form-control-error",
											)}
											disabled={isLoading}
											name="checkupCategoryId"
											value={values?.checkupCategoryId}
											onBlur={handleBlur}
											onChange={handleChange}>
											<option value="">Pilih Kategori Pemeriksaan</option>
											{listKategoriPemeriksaanOptions.map(option => (
												<option value={option.value}>{option.label}</option>
											))}
										</select>
										{errors.checkupCategoryId && (
											<p className="form-error-item-message">{errors.checkupCategoryId}</p>
										)}
									</div>
								</div>

								<div className="col-sm-12 col-lg-6  col-xs-12">
									<div className="setting-item">
										<label className="setting-label required">Nama Pemeriksaan</label>
										<input
											className={clsx("form-control", errors?.title && "form-control-error")}
											disabled={isLoading}
											name="title"
											placeholder="Contoh: FSH"
											type="text"
											value={values?.title}
											onBlur={handleBlur}
											onChange={handleChange}
										/>
										{errors.title && <p className="form-error-item-message">{errors.title}</p>}
									</div>
								</div>

								{hasFormulas &&
									formulaForms.map((form, idx) => (
										<div key={form.id} className="position-relative">
											<FormFormulaPemeriksaanPenunjang
												initialValues={detailPemeriksaanPenunjang.CheckupSupportDetails[idx]}
												isLoading={isLoading}
												validBaseForm={Boolean(values?.checkupCategoryId && values?.title)}
												onChange={(isValid, formValues) => {
													setFormulaForms(prev =>
														prev.map(e => {
															if (e.id === form.id)
																return {
																	...e,
																	isValid: isValid,
																	values: formValues,
																};

															return e;
														}),
													);
												}}
											/>
											{idx > 0 && (
												<button
													class="btn text-orange wait-pay position-absolute"
													style={deleteFormulaButtonStyle}
													onClick={() => {
														setFormulaForms(prev => prev.filter(e => e.id !== form.id));
													}}>
													<span class="icon-ico-delete ft-18" />
												</button>
											)}

											{idx < formulaForms.length - 1 && <hr />}
										</div>
									))}

								{isEligibleToSubmit && (
									<button
										className="btn btn-green d-block ml-auto"
										disabled={!isEligibleToSubmit}
										onClick={handleAddFormula}>
										Tambah Formula
									</button>
								)}
							</div>

							<div className="text-center my-3">
								<SubmitButton
									className="btn btn-orange d-inline-block mw-250"
									disabled={!FormSchemaValidation.isValidSync(values) || !isEligibleToSubmit}
									loading={isLoading}
									text="Simpan Perubahan"
									type="submit"
									onClick={handleSubmit}
								/>
							</div>
						</div>
					);
				}}
			</Formik>
		</DashboardLayout>
	);
}
