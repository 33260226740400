import axios from "services/axios";
import {useMutation, useQueryClient} from "react-query";

export function useAddHargaPendaftaranObat(options = {}) {
	return useMutation(values => axios.post("/medicine_registration/create", values).then(res => res.data));
}
export function useEditHargaPendaftaranObat(options = {}) {
	const queryClient = useQueryClient();

	return useMutation(formData => axios.post("/medicine_registration/update", formData).then(res => res.data), {
		onSuccess: () => {
			queryClient.invalidateQueries("hargaPendaftaranObat");
			queryClient.removeQueries("hargaPendaftaranObat");
		},
	});
}

export function useDeleteHargaPendaftaranObat(options = {}) {
	const queryClient = useQueryClient();

	return useMutation(formData => axios.post("/medicine_registration/delete", formData).then(res => res.data), {
		onSuccess: () => {
			queryClient.invalidateQueries("hargaPendaftaranObat");
			queryClient.removeQueries("hargaPendaftaranObat");
		},
	});
}
