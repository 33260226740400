/* eslint-disable no-unused-vars */
import {configureStore} from "@reduxjs/toolkit";
import {combineReducers} from "redux";
import {persistReducer, persistStore} from "redux-persist";
import storage from "redux-persist/lib/storage";
import {setAuthHeader, resetAuthHeader} from "services/axios";
import {isTokenExpired} from "utils/auth";

// Reducer
import authReducer, {logout} from "./actions/auth";
import hospitalReducer from "./actions/hospital";
import zoomReducer from "./actions/zoom";
import medicalRecordFormReducer from "./actions/medicalRecordForm";

const rootReducers = combineReducers({
	HospitalsReducer: hospitalReducer,
	AuthReducer: authReducer,
	ZoomReducer: zoomReducer,
	MedicalRecordFormReducer: medicalRecordFormReducer,
});

const persistConfig = {
	key: "APPLICATION_STATE",
	storage,
	whitelist: ["AuthReducer", "HospitalsReducer"],
};

const persistedReducer = persistReducer(persistConfig, rootReducers);

export const store = configureStore(
	{
		reducer: persistedReducer,
	},
	typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
);

export const persistor = persistStore(store, {}, () => {
	const state = store.getState();

	if (isTokenExpired(state)) {
		store.dispatch(logout());
		resetAuthHeader();
		localStorage.clear();
	} else {
		setAuthHeader(state.AuthReducer.accessToken);
	}
});
