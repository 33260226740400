import {memo} from "react";
import {isMobile} from "react-device-detect";

import {ModalContainer} from "./ModalContainer";

function ViewPDF({visible, onClose, title = "Document Viewer", fileSrc = null, loading}) {
	const contentRenderer = () => {
		if (!fileSrc) return null;

		if (isMobile)
			return (
				<iframe
					className="w-100 h-100"
					id="file-lab-viewer"
					src={`${fileSrc}#toolbar=0`}
					title={title}
					type="application/pdf"
				/>
			);

		return <embed height="100%" src={`${fileSrc}#toolbar=0`} type="application/pdf" width="100%" />;
	};

	return (
		<ModalContainer visible={visible}>
			<div class="box-popup w650">
				<div class="d-flex justify-content-between align-items-center mb-4">
					<div class="text-bold text-dark">{title}</div>
					<div class="for-close">
						<button class="btn btn-close" disabled={loading} type="button" onClick={onClose} />
					</div>
				</div>

				<div class="popup-content">
					<div class="col-sm-12">
						<div className="box-view-result">
							{!fileSrc && <p>No url file</p>}

							{contentRenderer()}
						</div>
					</div>
				</div>
			</div>
		</ModalContainer>
	);
}

export const ModalViewPDF = memo(ViewPDF);
