import {useQuery} from "react-query";
import axios from "services/axios";
import qs from "query-string";

export const QUERY = "poliklinik";

export function useListPoliklinik(filter) {
	const result = useQuery([QUERY, filter], () =>
		axios.get(`/poliklinik?${qs.stringify(filter)}`).then(res => res.data),
	);

	return result;
}

export const fetchPoliklinikByID = id => {
	return axios.get(`/poliklinik/${id}`).then(res => res.data);
};

export function useDetailPoliklinik(id) {
	return useQuery({
		queryKey: id && [QUERY, id],
		queryFn: key => fetchPoliklinikByID(id),
		refetchOnMount: false,
		refetchOnWindowFocus: false,
		enabled: !!id,
	});
}

export function usePoliklinikOptions() {
	const result = useQuery([QUERY], async () => await axios.get(`/poliklinik`).then(res => res.data));

	return result;
}
