import axios from "services/axios";
import {useMutation, useQueryClient} from "react-query";

export function useAddLayananPoliklinikTindakan(options = {}) {
	return useMutation(values => axios.post("/action_division/create", values).then(res => res.data));
}
export function useEditLayananPoliklinikTindakan(options = {}) {
	const queryClient = useQueryClient();

	return useMutation(formData => axios.post("/action_division/update", formData).then(res => res.data), {
		onSuccess: () => {
			queryClient.invalidateQueries("layananPoliklinikTindakan");
			queryClient.removeQueries("layananPoliklinikTindakan");
		},
	});
}

export function useDeleteLayananPoliklinikTindakan(options = {}) {
	const queryClient = useQueryClient();

	return useMutation(formData => axios.post("/action_division/delete", formData).then(res => res.data), {
		onSuccess: () => {
			queryClient.invalidateQueries("layananPoliklinikTindakan");
			queryClient.removeQueries("layananPoliklinikTindakan");
		},
	});
}

export function useApprovalLayananPoliklinikTindakan(options = {}) {
	const queryClient = useQueryClient();

	return useMutation(formData => axios.post("/action_division/action_requested", formData).then(res => res.data), {
		onSuccess: () => {
			queryClient.invalidateQueries("layananPoliklinikTindakan");
			queryClient.removeQueries("layananPoliklinikTindakan");
		},
	});
}
