import {Switch} from "react-router-dom";
import {useSelector} from "react-redux";

import Route from "./RouteWrapper";
import {AllRoutes} from "./routes";

export default function AppRoutes() {
	const selectedRoleId = useSelector(state => state.AuthReducer.roleId) || 0;
	const rsMany = useSelector(state => state.AuthReducer?.profile?.staff?.rsMany) || [];
	const selectedRs = rsMany.find(item => item.roleId === selectedRoleId);

	return (
		<Switch>
			{AllRoutes[selectedRoleId].map(route => {
				if (selectedRoleId === 4 && selectedRs?.unitId === 1) {
					return (
						<Route
							key={route.name}
							component={route.component}
							exact={route.exact}
							isPrivate={route.isPrivate}
							path={route.path}
						/>
					);
				}
				if (selectedRoleId !== 4) {
					return (
						<Route
							key={route.name}
							component={route.component}
							exact={route.exact}
							isPrivate={route.isPrivate}
							path={route.path}
						/>
					);
				}

				return (
					<Route
						key={route.name}
						component={route.component}
						exact={route.exact}
						isPrivate={route.isPrivate}
						path={route.path}
					/>
				);
			})}
		</Switch>
	);
}
