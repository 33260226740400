import {SubmitButton} from "components/Button";

import {ModalContainer} from "./ModalContainer";

export function ModalResetPassword({
	visible,
	onClose,
	onConfirmByOtp,
	onConfirmByEmail,
	onConfirmByWhatsapp,
	title = "Konfirmasi",
	description = "",
	loading,
	confirmationModalElement,
}) {
	return (
		<ModalContainer visible={visible}>
			<div class="box-popup w550">
				<div class="d-flex justify-content-between align-items-center mb-4">
					<div class="text-bold text-dark">{title}</div>
					<div class="for-close">
						<button class="btn btn-close" disabled={loading} type="button" onClick={onClose} />
					</div>
				</div>

				<div class="popup-content">
					<div class="col-sm-12">
						{confirmationModalElement ? (
							confirmationModalElement
						) : (
							<>
								<div class=" text-bold text-grey">{description}</div>
								<br />
								{/* <div class=" text-bold text-grey">Silahkan pilih metode reset password</div> */}
							</>
						)}
					</div>
					<div className="d-flex justify-content-around gap-2 mt-4">
						<SubmitButton
							className="btn btn-green r8 w-45"
							loading={loading}
							text={"No Telepon"}
							onClick={onConfirmByOtp}
						/>
						<SubmitButton
							className="btn btn-green r8 w-45"
							loading={loading}
							text={"WhatsApp"}
							onClick={onConfirmByWhatsapp}
						/>
						<SubmitButton
							className="btn btn-green r8 w-45"
							loading={loading}
							text={"Email"}
							onClick={onConfirmByEmail}
						/>
					</div>
				</div>
			</div>
		</ModalContainer>
	);
}
