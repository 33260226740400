import {ScheduleListPage} from "pages/Doctor/Schedule/ScheduleList";
import {ScheduleAddPage} from "pages/Doctor/Schedule/ScheduleAdd";
import {ScheduleEditPage} from "pages/Doctor/Schedule/ScheduleEdit";
import {ScheduleSpecialWorkAddPage} from "pages/Doctor/Schedule/Work/ScheduleWorkAdd";
import {ScheduleSpecialWorkEditPage} from "pages/Doctor/Schedule/Work/ScheduleWorkEdit";
import {ScheduleSpecialHolidayAddPage} from "pages/Doctor/Schedule/Holiday/ScheduleHolidayAdd";
import {ScheduleSpecialHolidayEditPage} from "pages/Doctor/Schedule/Holiday/ScheduleHolidayEdit";
import {AppointmentListPage, AppointmentDetailPage} from "pages/Doctor/Appointment";
import {MedicalRecordCreatePage, MakeSchedulePage} from "pages/Doctor/Appointment/MedicalRecord";
import {PostList, PostAdd} from "pages/Doctor/Post";

export const dokterRoutes = [
	{
		name: "doctor-schedule-list",
		path: "/doctor/schedule/list",
		exact: true,
		isPrivate: true,
		component: ScheduleListPage,
	},
	{
		name: "doctor-schedule-add",
		path: "/doctor/schedule/add",
		exact: true,
		isPrivate: true,
		component: ScheduleAddPage,
	},
	{
		name: "doctor-schedule-add",
		path: "/doctor/schedule/edit/:id",
		exact: true,
		isPrivate: true,
		component: ScheduleEditPage,
	},
	{
		name: "doctor-schedule-work-add",
		path: "/doctor/schedule/work/add",
		exact: true,
		isPrivate: true,
		component: ScheduleSpecialWorkAddPage,
	},
	{
		name: "doctor-schedule-work-add",
		path: "/doctor/schedule/work/edit/:id",
		exact: true,
		isPrivate: true,
		component: ScheduleSpecialWorkEditPage,
	},
	{
		name: "doctor-schedule-work-add",
		path: "/doctor/schedule/holiday/add",
		exact: true,
		isPrivate: true,
		component: ScheduleSpecialHolidayAddPage,
	},
	{
		name: "doctor-schedule-work-add",
		path: "/doctor/schedule/holiday/edit/:id",
		exact: true,
		isPrivate: true,
		component: ScheduleSpecialHolidayEditPage,
	},
	{
		name: "doctor-appointment-list",
		path: "/doctor/appointment/list",
		exact: true,
		isPrivate: true,
		component: AppointmentListPage,
	},
	{
		name: "doctor-appointment-detail",
		path: "/doctor/appointment/show/:id",
		exact: true,
		isPrivate: true,
		component: AppointmentDetailPage,
	},
	{
		name: "doctor-appointment-medical-record-create",
		path: "/doctor/appointment/:id/medical-record/create",
		exact: true,
		isPrivate: true,
		component: MedicalRecordCreatePage,
	},
	{
		name: "doctor-appointment-medical-record-create",
		path: "/doctor/appointment/:id/medical-record/make-schedule",
		exact: true,
		isPrivate: true,
		component: MakeSchedulePage,
	},
	{
		name: "doctor-post-list",
		path: "/doctor/post/list",
		exact: true,
		isPrivate: true,
		component: PostList,
	},
	{
		name: "doctor-post-editor",
		path: "/doctor/post/editor/:id?",
		exact: true,
		isPrivate: true,
		component: PostAdd,
	},
];
