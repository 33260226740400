import axios from "services/axios";
import {useMutation, useQueryClient} from "react-query";

import {QUERY} from "./useQueryActionCategory";

export function useAddActionCategory() {
	const queryClient = useQueryClient();

	return useMutation(formData => axios.post("/action_category/add", formData).then(res => res.data), {
		onSuccess: () => {
			queryClient.invalidateQueries(QUERY);
			queryClient.removeQueries(QUERY);
		},
	});
}

export function useChangeDataStatus() {
	const queryClient = useQueryClient();

	return useMutation(
		formData => axios.post("/action_category/change_status_category", formData).then(res => res.data),
		{
			onSuccess: () => {
				queryClient.invalidateQueries(QUERY);
				queryClient.removeQueries(QUERY);
			},
		},
	);
}
