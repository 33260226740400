import clsx from "clsx";
import {ButtonAddTable} from "components/Button";
import {DashboardLayout} from "components/Layout";
import {ModalConfirmation} from "components/Modal";
import {BasicTable, SearchBox} from "components/Table";
import {useDeleteForm} from "hooks/SuperAdmin/Form";
import {useFormList} from "hooks/SuperAdmin/Form/useQueryForm";
import {useStateCallback} from "hooks/useStateCallback";
import {useToast} from "hooks/useToast";
import {useMemo, useState} from "react";
import {isMobile} from "react-device-detect";
import {useHistory} from "react-router-dom/cjs/react-router-dom";
import {Tab, TabList, Tabs} from "react-tabs";
import {convertErrorMessageFormat} from "utils/converter";

const FormListPage = () => {
	const history = useHistory();
	const {data, isLoading, setParams} = useFormList();

	const [searchKeyword, setSearchKeyword] = useState("");
	const [dataLimit, setDataLimit] = useState(10);
	const [tab, setTab] = useState(0);

	// Delete Data State
	const {mutate, isLoading: isDeletingData} = useDeleteForm();
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [deleteItemData, setDeleteItemData] = useStateCallback({id: null, "Hospital.name": null});
	const {showToast} = useToast();

	const columns = useMemo(
		() => [
			{
				Header: "No",
				accessor: "idSerial",
			},
			{
				Header: "Rumah Sakit",
				accessor: "Hospital.name",
				maxWidth: 300,
			},
			{
				Header: "Aksi",
				accessor: "id",
				Cell: ({row}) => (
					<>
						<a
							class="text-orange wait-pay"
							style={{cursor: "pointer"}}
							onClick={() => history.push(`/settings/form/show/${row?.values?.id}`)}>
							<span class="icon-ico-eye-open ft-18" />
						</a>
						<a
							class="text-orange wait-pay"
							style={{cursor: "pointer"}}
							onClick={() => history.push(`/settings/form/edit/${row?.values?.id}`)}>
							<span class="icon-ico-pencil ft-18" />
						</a>
						<a
							class={clsx("text-orange wait-pay")}
							style={{cursor: "pointer"}}
							title={!row.original.can_delete && "Data tidak dapat dihapus karena sudah digunakan"}
							onClick={() => {
								setDeleteItemData(row?.values, () => setShowDeleteModal(true));
							}}>
							<span class="icon-ico-delete ft-18" />
						</a>
					</>
				),
			},
		],
		[],
	);

	const dataWithIDSerial =
		data?.data?.rows?.map((item, index) => ({
			...item,
			idSerial: (data?.data?.currentPage - 1) * dataLimit + index + 1,
		})) || [];

	const handleDeleteData = () => {
		mutate(
			{id: deleteItemData.id},
			{
				onSuccess: () => {
					showToast("success", `Data form ${deleteItemData["Hospital.name"]} berhasil dihapus`, 3000);
					window.scrollTo(0, 0);
					setShowDeleteModal(false);
				},
				onError: res => {
					showToast("error", convertErrorMessageFormat(res.response.status, res.response.data.message), null);
				},
			},
		);
	};

	return (
		<DashboardLayout>
			<ModalConfirmation
				approveLabel="Ya"
				description={`Apakah Anda yakin ingin menghapus data form "${deleteItemData["Hospital.name"]}"?`}
				loading={isDeletingData}
				rejectLabel="Batal"
				visible={showDeleteModal}
				onApprove={handleDeleteData}
				onClose={() => setShowDeleteModal(false)}
				onReject={() => setShowDeleteModal(false)}
			/>
			<div class="text-bold text-dark mb-4">Form</div>
			<Tabs
				className="mb-0"
				selectedIndex={tab}
				onSelect={index => {
					setTab(index);
					setParams({search: searchKeyword, type: index + 1, page: 1, limit: dataLimit});
				}}>
				<div class="" style={isMobile ? {"overflow-x": "auto", "white-space": "nowrap"} : {}}>
					<TabList className="border-0">
						<Tab>Lab Awal</Tab>
						<Tab>Lab Akhir</Tab>
						<Tab>Inform Consent</Tab>
						<Tab>Resep</Tab>
						<Tab>Invoice</Tab>
					</TabList>
				</div>
			</Tabs>
			<SearchBox
				rightElement={
					<ButtonAddTable
						text="Tambah"
						onClick={() => history.push(`/settings/form/tambah?formType=${tab + 1}`)}
					/>
				}
				onSearch={keyword => {
					setSearchKeyword(keyword);
					setParams({search: keyword, type: tab + 1, limit: dataLimit, page: 1});
				}}
			/>

			<BasicTable
				columns={columns}
				currentPage={data?.data?.currentPage}
				data={dataWithIDSerial}
				limit={dataLimit}
				loading={isLoading}
				striped={true}
				title="Data Form"
				totalItems={data?.data?.totalItems}
				totalPage={data?.data?.totalPages}
				onLimitChange={limitValue => {
					setDataLimit(limitValue);
					setParams({search: searchKeyword, type: tab + 1, limit: limitValue, page: 1});
				}}
				onPageChange={selectedPage =>
					setParams({search: searchKeyword, type: tab + 1, limit: dataLimit, page: selectedPage})
				}
			/>
		</DashboardLayout>
	);
};

FormListPage.displayName = "FormListPage";
export {FormListPage};
