import axios from "services/axios";
import {useMutation, useQueryClient} from "react-query";

export function useAddScheduleWork(options = {}) {
	return useMutation(values => axios.post("/schedule_work/create", values).then(res => res.data));
}
export function useEditScheduleWork(options = {}) {
	const queryClient = useQueryClient();

	return useMutation(formData => axios.post("/schedule_work/update", formData).then(res => res.data), {
		onSuccess: () => {
			queryClient.invalidateQueries("schedule_works");
			queryClient.removeQueries("schedule_works");
		},
	});
}

export function useDeleteScheduleWork(options = {}) {
	const queryClient = useQueryClient();

	return useMutation(formData => axios.post("/schedule_work/delete", formData).then(res => res.data), {
		onSuccess: () => {
			queryClient.invalidateQueries("schedule_works");
			queryClient.removeQueries("schedule_works");
		},
	});
}
