import {ExtensionTag, NodeExtension} from "remirror";

class CalloutExtension extends NodeExtension {
	get name() {
		return "callout";
	}

	createTags() {
		return [ExtensionTag.Block];
	}

	createNodeSpec(extra, override) {
		return {
			...override,
			attrs: {
				...extra.defaults(),
			},
			group: "block",
			content: "block+",
			selectable: true,
			parseDOM: [
				{
					tag: "div.alert-box",
					getAttrs: () => ({
						...extra.parse,
					}),
				},
				...(override.parseDOM ?? []),
			],
			toDOM: () => ["div", {class: "alert-box", role: "alert"}, 0],
		};
	}
}

class CalloutHeaderExtension extends NodeExtension {
	get name() {
		return "calloutHeader";
	}

	createTags() {
		return [ExtensionTag.Block];
	}

	createNodeSpec(extra, override) {
		return {
			...override,
			attrs: {
				...extra.defaults(),
			},
			group: "block",
			content: "inline*",
			parseDOM: [
				{
					tag: "strong.alert-box-header",
					getAttrs: () => ({
						...extra.parse,
					}),
				},
				...(override.parseDOM ?? []),
			],
			toDOM: () => ["strong", {class: "alert-box-header"}, 0],
		};
	}
}

class CalloutContentExtension extends NodeExtension {
	get name() {
		return "calloutContent";
	}

	createTags() {
		return [ExtensionTag.Block];
	}

	createNodeSpec(extra, override) {
		return {
			...override,
			attrs: {
				...extra.defaults(),
			},
			group: "block",
			content: "inline*",
			parseDOM: [
				{
					tag: "p.alert-box-content",
					getAttrs: () => ({
						...extra.parse,
					}),
				},
				...(override.parseDOM ?? []),
			],
			toDOM: () => ["p", {class: "alert-box-content"}, 0],
		};
	}
}

export const Callout = new CalloutExtension();
export const CalloutHeader = new CalloutHeaderExtension();
export const CalloutContent = new CalloutContentExtension();
