import axios from "services/axios";
import {useMutation, useQueryClient} from "react-query";

export function useAddVendor() {
	return useMutation(values => axios.post("/v2/vendor/create", values).then(res => res.data));
}
export function useEditVendor() {
	return useMutation(values => axios.post("/v2/vendor/update", values).then(res => res.data));
}

export function useDeleteVendor() {
	const queryClient = useQueryClient();

	return useMutation(values => axios.post("/v2/vendor/delete", values).then(res => res.data), {
		onSuccess: () => {
			queryClient.invalidateQueries("vendor");
			queryClient.removeQueries("vendor");
		},
	});
}

export function useAddVendorPIC() {
	return useMutation(({id, formData}) => axios.post(`/v2/vendor/${id}/create`, formData).then(res => res.data));
}
export function useEditVendorPIC() {
	return useMutation(({id, picId, formData}) =>
		axios.post(`/v2/vendor/${id}/update/${picId}`, formData).then(res => res.data),
	);
}

export function useDeleteVendorPIC() {
	const queryClient = useQueryClient();

	return useMutation(
		({vendorId, picId}) => axios.post(`/v2/vendor/${vendorId}/${picId}/delete`).then(res => res.data),
		{
			onSuccess: () => {
				queryClient.invalidateQueries("vendorvendorPIC");
				queryClient.removeQueries("vendorPIC");
			},
		},
	);
}
