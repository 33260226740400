import {useQuery} from "react-query";
import axios from "services/axios";
import qs from "query-string";

export const QUERY_PEMERIKSAAN_PENUNJANG = "checkup_support";

export function useListPemeriksaanPenunjang(filter, queryOptions) {
	const result = useQuery(
		[QUERY_PEMERIKSAAN_PENUNJANG, filter],
		() => axios.get(`/checkup_support?${qs.stringify(filter)}`).then(res => res.data),
		{
			...queryOptions,
		},
	);

	return result;
}

export const fetchPemeriksaanPenunjangByID = id => {
	return axios.get(`/checkup_support/${id}`).then(res => res.data);
};

export function useDetailPemeriksaanPenunjang(id) {
	return useQuery({
		queryKey: id && [QUERY_PEMERIKSAAN_PENUNJANG, id],
		queryFn: () => fetchPemeriksaanPenunjangByID(id),
		refetchOnMount: false,
		refetchOnWindowFocus: false,
		enabled: !!id,
	});
}
