import {useState} from "react";
import {useQuery} from "react-query";
import axios from "services/axios";
import qs from "query-string";

export const QUERY = "rs_action_category";

async function getActionCategories(id, filter) {
	if (!id) {
		const {data} = await axios.get(`/action_category`, {params: {...filter, sortBy: "id.desc"}});

		return data;
	}
	const {data} = await axios.get(`/action_category/${id}`);

	return data;
}

export function useListAvailableActionCategory(hospitalId) {
	const [filter, filterActionCategories] = useState({
		page: 1,
		search: null,
		limit: 10,
		hospitalId,
	});
	const [actionCategoryId, actionCategoryById] = useState(null);
	const fallback = [];
	const {
		data = fallback,
		isLoading,
		isError,
		error,
	} = useQuery([QUERY, filter, actionCategoryId], async () => getActionCategories(actionCategoryId, filter));

	return {data, isLoading, isError, error, filter, filterActionCategories, actionCategoryById};
}

export const fetchActionCategory = filter => {
	const query = qs.stringify(filter);

	return axios.get(`action_category/?sortBy=id.desc&${query}`).then(res => res.data);
};

export function useListActionCategoryById(filter) {
	return useQuery({
		queryKey: [QUERY, filter],
		queryFn: key => fetchActionCategory(filter),
		refetchOnMount: false,
		enabled: !!Object.keys(filter).length,
	});
}
