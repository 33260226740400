import {ExtensionTag, NodeExtension} from "remirror";
import {useToast} from "hooks/useToast";

import {getNodeCoordinates} from "../util";

class YoutubeExtension extends NodeExtension {
	get name() {
		return "youtube";
	}

	createTags() {
		return [ExtensionTag.Block];
	}

	createNodeSpec(extra, override) {
		return {
			...override,
			attrs: {src: {default: null}, ...extra.defaults()},
			content: "",
			draggable: false,
			selectable: true,
			isolation: true,
			atom: true,
			parseDOM: [
				{tag: "div[data-placeholder-youtube]", getAttrs: extra.parse},
				{
					tag: "div.video-container",
					getAttrs: dom => ({src: dom.querySelector("iframe").getAttribute("src"), ...extra.parse}),
				},
				...(override.parseDOM ?? []),
			],
			toDOM: node => {
				const {src} = node.attrs;

				if (src)
					return [
						"div",
						{src, class: "video-container"},
						[
							"iframe",
							{
								src,
								title: "YouTube video player",
								allow: "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture",
								allowfullscreen: true,
							},
						],
					];

				return ["div", {"data-placeholder-youtube": true, class: "embed-placeholder"}];
			},
		};
	}
}

export function YoutubeField({view, state}) {
	const {showToast} = useToast();

	const node = state.doc.content.content.find(item => item.type.name === "youtube" && !item.attrs?.src);
	const coords = getNodeCoordinates({view, state}, "youtube", node?.attrs?.src === null);

	const containerPos = document.querySelector(".editor-container")?.offsetTop ?? 0;

	const {top} = coords?.coords ?? {};
	const {tr} = state ?? {};
	const {dispatch} = view ?? {};

	const handleOnBlur = () => {
		const node = state.doc.nodeAt(coords.pos);

		if (node) {
			const from = coords.pos;
			const to = coords.pos + node.nodeSize;
			const transaction = tr.delete(from, to);

			dispatch(transaction);
		}
	};

	const handleOnKeyDown = event => {
		if (event.key === "Enter") {
			const value = event.target.value;
			const reqex = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:watch\?v=|embed\/)|youtu\.be\/)([\w-]{11})/;
			const match = value.match(reqex);
			const url = match ? `https://www.youtube.com/embed/${match[1]}` : null;

			if (url) {
				const node = state.doc.nodeAt(coords.pos);

				if (node) {
					const to = coords.pos + node.nodeSize;
					const newNode = state.schema.nodes.youtube.create({src: url}, []);
					const transaction = tr
						.insert(to, newNode)
						.setSelection(tr.selection.constructor.near(tr.doc.resolve(to + 1)));

					dispatch(transaction.scrollIntoView());
				}
				event.currentTarget.blur();

				return;
			}

			showToast("error", "Terjadi masalah saat mengurai URL", 3000);
		}
	};

	if (!coords || coords?.node?.attrs?.src) return null;

	return (
		<input
			autoFocus
			className="form-control position-absolute embed-field"
			placeholder="Paste URL to add embedded content..."
			style={{top: top - containerPos + window.scrollY}}
			onBlur={handleOnBlur}
			onKeyDown={handleOnKeyDown}
		/>
	);
}

export const Youtube = new YoutubeExtension();
