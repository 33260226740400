import {useContext} from "react";
import clsx from "clsx";
import {ToastStateContext} from "store/context/ToastContext";

function getAlertClass(type) {
	switch (type) {
		case "success":
			return "alert-success";
		case "error":
			return "alert-danger";
		default:
			return "alert-warning";
	}
}

function getIcon(type) {
	switch (type) {
		case "success":
			return "icon-check-square";
		case "error":
			return "icon-alert-triangle";
		default:
			return "icon-alert-triangle";
	}
}

export function Toast() {
	const [state, dispatch] = useContext(ToastStateContext);

	return (
		<>
			{state.showToast && (
				<div
					class={clsx(
						"snackbar alert d-flex align-items-center justify-content-between",
						getAlertClass(state.type),
						state.showToast && "show",
					)}
					role="alert">
					<div className="d-flex align-items-center">
						<span class={clsx("mr10 ft-22", getIcon(state?.type))} />
						<div className="toast-message ">{state?.message}</div>
					</div>
					{!state.timeOut && (
						<div style={{cursor: "pointer"}} onClick={() => dispatch({type: "HIDE_TOAST"})}>
							<span className="icon-ico-close ft-22" />
						</div>
					)}
				</div>
			)}
		</>
	);
}
