import clsx from "clsx";
import {isMobile} from "react-device-detect";
import {useEffect, useState} from "react";
import {useSelector, useDispatch} from "react-redux";
import {setCurrentRole, setDisplayProfile, setToggleMenu} from "store/actions/auth";
import {setSelectedHospital} from "store/actions/hospital";
import {loadLocalAssets} from "utils/loader";
import {Toast} from "components/Toast";
import {ModalProfile} from "components/Modal";
import {useUserZoom} from "hooks/Doctor/Zoom";
import {setUserZoom} from "store/actions/zoom";

import {Sidemenu} from "./../Navbar";

// TODO: condition comparition still use "==" need to use "===" after API is ready

const BC = new BroadcastChannel("role_channel");
const roleValues = [
	{
		label: "Super Admin",
		value: "8",
	},
	{
		label: "Admission",
		value: "7",
	},
	{
		label: "Sub Admin Divisi",
		value: "4",
	},
	{
		label: "Sub Staff",
		value: "5",
	},
	{
		label: "Sub Supervisi",
		value: "6",
	},
	{
		label: "Rumah Sakit",
		value: "1",
	},
	{
		label: "Dokter",
		value: "2",
	},
	{
		label: "Perawat",
		value: "3",
	},
];

function SelectRole({options = roleValues, value, onChange = () => {}}) {
	const [showOptions, setShowOptions] = useState(false);
	const currentUserRoleAccessId = useSelector(state => state.AuthReducer.userRoles);
	const rolesOptions = roleValues.filter(item => currentUserRoleAccessId.includes(+item.value));

	return (
		<div class="role-select" id="desk-item">
			<div
				class={clsx("selectric-wrapper selectric-select-top selectric-below", showOptions && "selectric-open")}>
				<div class="selectric" onClick={() => setShowOptions(!showOptions)}>
					<span class="label">{value?.label}</span>
					<b class="button" />
				</div>
				<div class="selectric-items" style={{width: "180px"}}>
					<div class="selectric-scroll">
						<ul>
							<li />
							{rolesOptions?.map((option, index) => (
								<li
									key={`${option.label}-${index}`}
									className={clsx(option.value == value?.value && "selected")}
									onClick={() => {
										if (option.value != value?.value) {
											setShowOptions(false);
											onChange(option);
										}
									}}>
									{option.label}
								</li>
							))}
						</ul>
					</div>
				</div>
				<input class="selectric-input" tabIndex="0" />
			</div>
		</div>
	);
}

export function DashboardLayout({children}) {
	const dispatch = useDispatch();
	const {roleId: selectedRoleId, profile} = useSelector(state => state.AuthReducer);
	const isMenuOpen = useSelector(state => state.AuthReducer.isShowMenu);
	const {userZoom} = useSelector(state => state.ZoomReducer);
	const {data: dataZoom} = useUserZoom();

	const [isSettingOpen, setIsSettingOpen] = useState(false);

	const handleChangeRole = selected => {
		dispatch(setCurrentRole(+selected.value));
		// Set current role to selected Hospital
		const selectedHospital = profile?.staff?.rsMany?.find(item => item.roleId === +selected.value) || null;

		dispatch(setSelectedHospital(selectedHospital));
		BC.postMessage({type: setCurrentRole().type});
		window.location.replace("/");
		// setTimeout(() => {
		// }, [100]);
	};

	const handleDisplayProfile = () => {
		setIsSettingOpen(false);
		dispatch(setDisplayProfile(true));
	};

	const handleToggleMenu = newMenuStatus => () => {
		dispatch(setToggleMenu(newMenuStatus));
	};

	useEffect(() => {
		BC.onmessage = event => {
			if (event.data.type === setCurrentRole().type) {
				window.location.replace("/");
			}
		};
	}, []);

	useEffect(() => {
		if (dataZoom.success && !userZoom) {
			dispatch(setUserZoom(dataZoom.data));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dataZoom]);

	return (
		<div
			className={clsx(
				"main-wrapper",
				isMobile && "closed",
				!isMobile && !isMenuOpen && "closed",
				isMobile && !isMenuOpen && "show",
			)}>
			<ModalProfile />

			<div class="sidebar">
				<div class="nav-box">
					<div class="logo">
						<div class="for-btn">
							<button class="btn btn-menu" onClick={handleToggleMenu(!isMenuOpen)} />
						</div>
					</div>

					<div class="avatar-row">
						<a class="avatar" href="setting-staff.html">
							<img
								alt="avatar"
								class="img-fluid"
								src={profile?.profilePhoto || loadLocalAssets("img/default_avatar.png")}
							/>
						</a>
					</div>

					<div class="for-btm">
						<button class="btn btn-close-mobile p-1" onClick={handleToggleMenu(false)}>
							<span class="icon-ico-close" />
						</button>
					</div>

					<Sidemenu role={selectedRoleId} />
				</div>
			</div>

			{/* Page Content */}

			<div class="page-content">
				<header class="header">
					<div class="container-fluid">
						<div class="header-inner">
							<div class="header-left">
								<button class="btn btn-toggle p-1" onClick={() => handleToggleMenu(true)}>
									<span class="icon-ico-menu-mobile text-orange" />
								</button>
							</div>
							<div class="header-center">
								<div class="logo-for-m">
									<a href={null}>
										<img alt="logo" class="img-fluid" src={loadLocalAssets("img/logo.svg")} />
									</a>
								</div>
								<SelectRole
									options={roleValues}
									value={roleValues.find(item => item.value == selectedRoleId)}
									onChange={handleChangeRole}
								/>
							</div>
							<div class="header-right">
								<div class="d-flex align-items-center">
									<div class="for-notif">
										<button class="btn btn-notif">
											<span class="icon-ico-notif" />
										</button>

										{/* <div class="notif-status">39</div> */}
									</div>
									<div
										class={clsx(
											"selectric-wrapper selectric-select-top selectric-below",
											isSettingOpen && "selectric-open",
										)}>
										<div class="" onClick={() => setIsSettingOpen(!isSettingOpen)}>
											<a class="btn btn-profile" role="button">
												<img
													alt="Avatar"
													class="img-fluid"
													src={
														profile?.profilePhoto ||
														loadLocalAssets("img/default_avatar.png")
													}
												/>
											</a>
										</div>
										<div class="selectric-items" style={{width: 200, left: -120}}>
											<div class="selectric-scroll">
												<ul>
													<li />
													<li onClick={handleDisplayProfile}>Profile</li>
													{/* <li>Ubah Lokasi Praktek</li> */}
													<li>Ubah Kata Sandi</li>
												</ul>
											</div>
										</div>
										<input class="selectric-input" tabIndex="0" />
									</div>
								</div>
							</div>
						</div>
					</div>
				</header>

				<Toast />
				<div class="main-content">{children}</div>
			</div>
		</div>
	);
}
