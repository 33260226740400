import ZoomIntegration from "pages/Other/ZoomIntegration";

export const otherRoutes = [
	{
		name: "zoom-integration",
		path: "/zoom-integration",
		exact: true,
		isPrivate: false,
		component: ZoomIntegration,
	},
];
