import axios from "services/axios";
import {useMutation, useQueryClient} from "react-query";

export function useAddMedicine(options = {}) {
	return useMutation(values => axios.post("/v2/medicine/create", values).then(res => res.data));
}
export function useEditMedicine(options = {}) {
	const queryClient = useQueryClient();

	return useMutation(formData => axios.post("/v2/medicine/update", formData).then(res => res.data), {
		onSuccess: () => {
			queryClient.invalidateQueries("medicine");
			queryClient.removeQueries("medicine");
		},
	});
}

export function useDeleteMedicine(options = {}) {
	const queryClient = useQueryClient();

	return useMutation(formData => axios.post("/v2/medicine/delete", formData).then(res => res.data), {
		onSuccess: () => {
			queryClient.invalidateQueries("medicine");
			queryClient.removeQueries("medicine");
		},
	});
}
