/* eslint-disable no-unused-vars */
import * as Yup from "yup";

const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/gif", "image/png"];
const FILE_SIZE = 5242880; // 5MB

export const FormSchemaValidation = Yup.object().shape({
	title: Yup.string().required("Title wajib di isi"),
	order: Yup.string().required("Order wajib di isi"),
});
