import axios from "axios";
import CONFIG from "config/env";

const BASE_URL = CONFIG.endpoint.UNPLASH_API_ENDPOINT;
const API_KEY = CONFIG.endpoint.UNPLASH_API_KEY;
let instance = null;

function makeInstance() {
	if (instance) {
		return instance;
	}
	instance = axios.create({
		baseURL: BASE_URL,
		timeout: 60000,
		headers: {
			Authorization: `Client-ID ${API_KEY}`,
		},
	});

	return instance;
}

const unplashInstance = makeInstance();

export default unplashInstance;
