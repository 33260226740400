import {DashboardLayout} from "components/Layout";
import * as Yup from "yup";
import {Formik} from "formik";
import {useEffect, useRef} from "react";
import {convertErrorMessageFormat} from "utils/converter";
import {useToast} from "hooks/useToast";
import {useHistory, useParams} from "react-router-dom";
import {useDetailLayanan} from "hooks/SuperAdmin/LayananObat";
import {useSelector} from "react-redux";
import {
	useAddLayananObat,
	useEditLayananObat,
	useUpdatePriceLayananObat,
} from "hooks/SuperAdmin/LayananObat/useMutationLayananObat";
import {useUploadFile} from "hooks/Misc";

import {LayananInformasiObat} from "./components/InformasiObatForm";

const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/gif", "image/png"];
const FILE_SIZE = 5242880; // 5MB

const FormSchemaValidation = Yup.object().shape({
	vendorId: Yup.string(),
	hospitalId: Yup.string().required("Rumah Sakit wajib di isi"),
	divisionId: Yup.string().required("Divisi wajib di isi"),
	categoryId: Yup.string().required("Kategori Obat wajib di isi"),
	medicineId: Yup.string().required("Nama Obat wajib di isi"),
	details: Yup.array().of(
		Yup.object().shape({
			icon: Yup.mixed()
				.test("fileFormat", "Format logo tidak support, hanya menerima format .JPG .JPEG .PNG", value => {
					if (value === undefined || value === "" || value === null) return true;
					if (typeof value === "string") {
						return true;
					} else {
						return SUPPORTED_FORMATS.includes(value?.type);
					}
				})
				.test("fileSize", "Ukuran file untuk logo maksimal 5 MB", value => {
					if (value === undefined || value === "" || value === null) return true;
					if (typeof value === "string") {
						return true;
					} else {
						return value?.size <= FILE_SIZE;
					}
				})
				.nullable(),
			id: Yup.string(),
			unitId: Yup.string(),
			sediaanId: Yup.string(),
			qty: Yup.number(),
			price: Yup.number(),
			sellPrice: Yup.number(),
		}),
	),
	description: Yup.string(),
	createdBy: Yup.string(),
});

const FormInitialValues = {
	vendorId: "",
	hospitalId: "",
	description: "",
	divisionId: "",
	categoryId: "",
	medicineId: "",
	details: [],
	createdBy: "",
};

const MasterLayananObatDetail = () => {
	const {id, action} = useParams();
	const {showToast} = useToast();
	const history = useHistory();
	const formikRef = useRef();
	const {data} = useDetailLayanan(id);
	const {mutateAsync: uploadFile} = useUploadFile();
	const {mutate: queryAdd} = useAddLayananObat();
	const {mutate: queryEdit} = useEditLayananObat();
	const {mutate: queryUpdatePrice} = useUpdatePriceLayananObat();
	const userData = useSelector(state => state.HospitalsReducer.selectedHospital);

	const handleSubmit = async data => {
		let values = data;

		for (let i = 0; i < data.details.length; i++) {
			const detail = data.details[i];

			if (typeof detail.icon === "object") {
				let formData = new FormData();

				formData.append("file", detail.icon);

				await uploadFile(formData, {
					onSuccess: result => {
						values.details[i].icon = result.data?.file;
					},
				});
			}
		}

		if (action === "tambah") {
			queryAdd(
				{...values, status: 1},
				{
					onSuccess: () => {
						showToast("success", "Data berhasil ditambahkan", 3000);
						history.push("/layanan-obat");
					},
					onError: res => {
						showToast(
							"error",
							convertErrorMessageFormat(res.response.status, res.response.data.message),
							null,
						);
					},
				},
			);
		} else {
			if (data?.createdBy === "vendor") {
				queryUpdatePrice(
					{id, details: data?.details.map(o => ({id: o.id, sellPrice: o.sellPrice}))},
					{
						onSuccess: () => {
							showToast("success", "Data berhasil diperbarui", 3000);
							history.push("/layanan-obat");
						},
						onError: res => {
							showToast(
								"error",
								convertErrorMessageFormat(res.response.status, res.response.data.message),
								null,
							);
						},
					},
				);
			} else {
				queryEdit(
					{id, ...data},
					{
						onSuccess: () => {
							showToast("success", "Data berhasil diperbarui", 3000);
							history.push("/layanan-obat");
						},
						onError: res => {
							showToast(
								"error",
								convertErrorMessageFormat(res.response.status, res.response.data.message),
								null,
							);
						},
					},
				);
			}
		}
	};

	useEffect(() => {
		if (id) return;
		if (userData.roleId === 8) {
			formikRef?.current?.setFieldValue("createdBy", "superadmin");
		}
		if (userData.roleId === 1) {
			formikRef?.current?.setFieldValue("createdBy", "admin");
			formikRef?.current?.setFieldValue("hospitalId", userData?.hospitalId?.toString());
			formikRef?.current?.setFieldValue("divisionId", userData?.divisionId?.toString());
		}
	}, [userData]);

	return (
		<DashboardLayout>
			<div class="box-white setting-content h-100 no-border p24">
				<div class="d-flex justify-content-between flex-wrap align-items-center mb-4">
					<div class="ttl-20 text-capitalize">{action} Layanan Obat</div>
				</div>

				<div class="text-bold mb-4">
					<a class="text-dark cursor-pointer" onClick={() => history.push("/layanan-obat")}>
						<span class="icon-ico-back me-2" />
					</a>
					Daftar List Layanan Obat
				</div>

				<hr />
				<Formik
					initialValues={FormInitialValues}
					innerRef={formikRef}
					validationSchema={FormSchemaValidation}
					onSubmit={handleSubmit}>
					{({handleSubmit, values, errors, touched, setFieldValue, setFieldError}) => (
						<>
							{values && FormSchemaValidation ? (
								<LayananInformasiObat
									FormSchemaValidation={FormSchemaValidation}
									action={action}
									data={data}
									errors={errors}
									handleSubmit={handleSubmit}
									setFieldError={setFieldError}
									setFieldValue={setFieldValue}
									touched={touched}
									values={values}
								/>
							) : null}
						</>
					)}
				</Formik>
			</div>
		</DashboardLayout>
	);
};

export {MasterLayananObatDetail};
