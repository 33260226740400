export const dokterMenu = {
	top: [
		{
			id: "admin-dashboard",
			title: "Dashboard",
			icon: "icon-ico-dashboard",
			path: "/",
			submenu: [],
		},
		{
			id: "admin-post",
			title: "Post",
			icon: "icon-ico-pencil",
			path: "/doctor/post/list",
			submenu: [],
		},
		{
			id: "doctor-appointment",
			title: "Appointment",
			icon: "icon-ico-appointment",
			path: "/doctor/appointment/list",
			submenu: [],
		},
	],
	bottom: [
		{
			id: "dokter-schedule-list",
			title: "Schedule",
			icon: "icon-ico-calendar",
			path: "/doctor/schedule/list",
			submenu: [],
		},
	],
};
