/* eslint-disable eqeqeq */
import {useEffect, useState} from "react";

export function PromiseDropdown({
	name,
	promiseData,
	value,
	onSelect,
	onChange,
	disabled,
	className = "form-select",
	emptyText = "Data tidak ditemukan ",
	...props
}) {
	const [data, setData] = useState([]);

	useEffect(() => {
		if (promiseData) {
			promiseData.then(resp => {
				setData(resp);
			});
		}
	}, [promiseData]);

	return (
		<select class={className} disabled={disabled} value={value?.value} onChange={e => onSelect(e.target.value)}>
			{data.length &&
				data?.map((item, key) => (
					<option key={key} selected={item.value == value} value={item.value}>
						{item.label}
					</option>
				))}
			{!data.length && (
				<option className="text-center" disabled={true}>
					{emptyText}
				</option>
			)}
		</select>
	);
}
