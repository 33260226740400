/* eslint-disable no-unused-vars */
import {useTable, usePagination, useSortBy} from "react-table";
import {CircleLoader} from "components/Loader";
import {DataNotFound} from "components/StateIcon";
import statusColor from "config/patientStatus";
import clsx from "clsx";

import {Pagination} from "./Pagination";

export function BasicTable({
	columns = [],
	data = [],
	loading = false,
	title = "Table Mobile Title",
	hideTitleOnMobile = false,
	totalPage,
	totalItems,
	currentPage,
	limit = 10,
	onLimitChange,
	onPageChange,
	striped = false,
	disableSortBy = true,
	hidePagination = false,
}) {
	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		rows,
		prepareRow,

		setPageSize,
		state: {pageSize},
	} = useTable(
		{
			columns,
			data,
			initialState: {pageIndex: 0, pageSize: limit},
			manualPagination: true,
			pageCount: totalPage,
			defaultColumn: {
				maxWidth: "100%",
				width: "auto",
			},
			disableSortBy,
		},
		useSortBy,
		usePagination,
	);

	return (
		<div class="box-white ws">
			<div class="data-desktop">
				<div class="table-responsive">
					{loading ? (
						<CircleLoader />
					) : data.length ? (
						<table class={clsx("table bg-white ft-13", {"table-striped": striped})} {...getTableProps()}>
							<thead>
								{headerGroups.map(headerGroup => (
									<tr {...headerGroup.getHeaderGroupProps()}>
										{headerGroup.headers.map(column => (
											<th
												{...column.getHeaderProps(
													column.getSortByToggleProps({
														style: {
															...(column.minWidth && {minWidth: column.minWidth}),
															...(column.width && {width: column.width}),
															...(column.maxWidth && {maxWidth: column.maxWidth}),
															...(column.centered && {textAlign: "center"}),
														},
													}),
												)}>
												{column.render("Header")}
												<span>
													{column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : ""}
												</span>
											</th>
										))}
									</tr>
								))}
							</thead>
							<tbody {...getTableBodyProps()}>
								{rows.map((row, i) => {
									prepareRow(row);

									return (
										<tr {...row.getRowProps()}>
											{row.cells.map(cell => {
												return (
													<td
														{...cell.getCellProps({
															style: {
																...(cell.column.minWidth && {
																	minWidth: cell.column.minWidth,
																}),
																...(cell.column.width && {
																	width: cell.column.width,
																}),
																...(!!cell.column.maxWidth && {
																	maxWidth: cell.column.maxWidth,
																}),
																...(!!cell.column.centered && {
																	textAlign: "center",
																}),
															},
														})}>
														{cell.render("Cell")}
													</td>
												);
											})}
										</tr>
									);
								})}
							</tbody>
							{/* <tbody>
							<tr>
								<td>
									<span class="text-purple ft-18 icon-ico-hospital me-2" /> Leslie Alexander
								</td>
								<td>Layanan A</td>
								<td>13 : 00 WIB - 20 January 2022</td>
								<td>RS Sayyida</td>
								<td>
									<div class="box-status serve">Sudah Dilayani</div>
								</td>
								<td>
									<a class="text-orange wait-pay" href="appointment-detail.html">
										<span class="icon-ico-eye-open ft-18" />
									</a>
								</td>
							</tr>
						</tbody> */}
						</table>
					) : (
						<DataNotFound text="Maaf, data yang anda cari tidak ditemukan" />
					)}
				</div>
			</div>

			<div class="data-mobile">
				{!hideTitleOnMobile && <div class="text-center text-bold ft-16 p24 border-bottom">{title}</div>}
				<div class="p-2">
					{loading ? (
						<CircleLoader style={{height: "40vh"}} />
					) : data.length ? (
						<>
							{rows.map((row, i) => {
								prepareRow(row);

								return (
									<div class="dm-items" {...row.getRowProps()}>
										{row.cells.map(cell => {
											return cell.render("Cell");
										})}
									</div>
								);
							})}
						</>
					) : (
						<DataNotFound style={{height: "40vh"}} text="Maaf, data yang anda cari tidak ditemukan" />
					)}
				</div>
			</div>

			{!hidePagination && !loading && !!data.length && (
				<div class="table-bottom">
					<div class="show-data d-flex gap-4">
						<select
							class="form-select sm"
							value={pageSize}
							onChange={e => {
								setPageSize(Number(e.target.value));
								onLimitChange(Number(e.target.value));
							}}>
							{[10, 20, 30].map(pageSize => (
								<option key={pageSize} value={pageSize}>
									Show {pageSize}
								</option>
							))}
						</select>
						<div className="d-flex align-items-center">
							Menampilkan {(currentPage - 1) * pageSize + 1} -{" "}
							{currentPage < Math.ceil(totalItems / pageSize) ? currentPage * pageSize : totalItems} dari{" "}
							{totalItems} data
						</div>
					</div>
					<Pagination
						currentPage={currentPage}
						pageSize={pageSize}
						totalCount={totalItems}
						onPageChange={selectedPage => {
							onPageChange(selectedPage);
							window.scrollTo(0, 0);
						}}
					/>
				</div>
			)}
		</div>
	);
}
