import clsx from "clsx";

export function ToggleSwitch({size = "md", isActive, onChange}) {
	return (
		<button
			aria-pressed="true"
			autoComplete="on"
			class={clsx(`btn btn-${size} btn-toggle-switch`, isActive && "active")}
			data-toggle="button"
			type="button"
			onClick={() => onChange({status: !isActive})}>
			<div class="handle" />
		</button>
	);
}
