import _ from "lodash";
import {useState} from "react";
import {useQuery} from "react-query";
import axios from "services/axios";
import unplash from "services/unplash";

export const QUERY = "post";

export async function getPosts(filter) {
	const {data} = await axios.get(`/article`, {params: {sortBy: "DESC", ...filter}});

	return data;
}

export function usePost(userId) {
	const [filter, filterPosts] = useState({
		page: 1,
		search: null,
		limit: 10,
	});
	const fallback = [];
	const {
		data = fallback,
		isLoading,
		isError,
		error,
	} = useQuery([QUERY, filter], async () => getPosts({...filter, userId}));

	return {data, isLoading, isError, error, filter, filterPosts};
}

export const fetchPostByID = postId => {
	return axios.get(`/article/${postId}`).then(res => res.data);
};

export function useDetailPost(postId) {
	return useQuery({
		queryKey: postId && ["post", postId],
		queryFn: key => fetchPostByID(postId),
		refetchOnMount: false,
		refetchOnWindowFocus: false,
		enabled: !!postId,
	});
}

export async function getUnplashImages(params) {
	let link = "/photos";

	if (!_.isEmpty(params?.query)) {
		link = "/search/photos";
	}

	const {data} = await unplash.get(link, {params});

	return data;
}

export function useUnplashImage() {
	const [filter, filterUnplash] = useState({
		page: 1,
		query: null,
		per_page: 30,
	});
	const fallback = [];
	const {
		data = fallback,
		isLoading,
		isError,
		error,
	} = useQuery([QUERY, filter], async () => getUnplashImages({...filter}));

	return {data, isLoading, isError, error, filter, filterUnplash};
}
