import clsx from "clsx";
import {SubmitButton} from "components/Button";
import {FormCard} from "components/Form";
import {DashboardLayout} from "components/Layout";
import {CircleLoader} from "components/Loader";
import {Formik} from "formik";
import {useDetailForumCategory, useEditForumCategory} from "hooks/SuperAdmin/ForumCategory";
import {useToast} from "hooks/useToast";
import {useEffect, useRef} from "react";
import {useHistory, useParams} from "react-router-dom";
import {convertErrorMessageFormat} from "utils/converter";
import * as Yup from "yup";

const FormSchemaValidation = Yup.object().shape({
	title: Yup.string().required("Kategori wajib di isi"),
});
const FormInitialValues = {title: ""};

const MasterForumCategoryEdit = () => {
	const {showToast} = useToast();
	const history = useHistory();
	const params = useParams();
	const {id} = params;
	const {isLoading: loadingFetcing, data: detailForumCategory} = useDetailForumCategory(id);
	const {mutate, isLoading} = useEditForumCategory(id);
	const formikRef = useRef();

	const handleSubmit = formData => {
		mutate(
			{id, ...formData},
			{
				onSuccess: () => {
					showToast("success", "Data berhasil diperbarui", 3000);
					history.push("/forum-category");
				},
				onError: error => {
					showToast(
						"error",
						convertErrorMessageFormat(error.response.status, error.response.data.message),
						null,
					);
				},
			},
		);
	};

	useEffect(() => {
		if (detailForumCategory) {
			formikRef.current?.setFieldValue("title", detailForumCategory?.data?.title);
		}
	}, [detailForumCategory]);

	if (loadingFetcing) {
		return (
			<DashboardLayout>
				<FormCard title="Edit Kategori Forum">
					<CircleLoader />
				</FormCard>
			</DashboardLayout>
		);
	}

	return (
		<DashboardLayout>
			<Formik
				initialValues={FormInitialValues}
				innerRef={formikRef}
				validationSchema={FormSchemaValidation}
				onSubmit={handleSubmit}>
				{({handleSubmit, handleBlur, handleChange, values, errors, touched, isValid}) => (
					<div class="box-white setting-content h-100 no-border p24">
						<div class="d-flex justify-content-between flex-wrap align-items-center mb-4">
							<div class="ttl-20">Edit Kategori Forum</div>
						</div>

						<hr />

						<div class="text-bold mb-4">
							<a
								class="text-dark"
								style={{cursor: "pointer"}}
								onClick={() => history.push("/forum-category")}>
								<span class="icon-ico-back me-2" />
							</a>
							Daftar Kategori Forum
						</div>

						<div class="row gx-2 mb-5">
							<div class="col-sm-6 col-6">
								<div class="setting-item">
									<label class="setting-label required">Kategori</label>
									<input
										class={clsx(
											"form-control",
											errors?.title && touched.title && "form-control-error",
										)}
										disabled={isLoading}
										name="title"
										placeholder=""
										type="text"
										value={values?.title}
										onBlur={handleBlur}
										onChange={handleChange}
									/>
									{errors.title && touched.title && (
										<p className="form-error-item-message">{errors.title}</p>
									)}
								</div>
							</div>
						</div>

						<div class="text-center my-3">
							<SubmitButton
								className="btn btn-orange d-inline-block mw-250"
								disabled={!isValid}
								loading={isLoading}
								text="Simpan"
								onClick={handleSubmit}
							/>
						</div>
					</div>
				)}
			</Formik>
		</DashboardLayout>
	);
};

export {MasterForumCategoryEdit};
