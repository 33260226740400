export default function generateBlock(blockManager) {
	blockManager.add("default", {
		id: "default",
		label: `<div class="bg-secondary  h-8 mb-4 rounded text-dark">Default</div>
                    <div>Banner</div>`,
		category: "Default Banner",
		content: `<div
                        class="banner-container"
                        style="background-image: url(https://cdn.promedik.com/bg-banner.png)">
                        <h1
                            style="color: white; font-weight: 800; fontSize: 52px; position: absolute; top: 18%; left: 15%;">
                            <i>
                                Jadwalkan Konsultasi Online <br /> Dengan Dokter Favoritemu
                            </i>
                        </h1>
                        <a
                            style="padding-left: 24px; padding-right: 24px; padding-top: 12px; padding-bottom: 12px; background: rgba(255, 255, 255, 0.4); border-radius: 25px; color: white; position: absolute; top: 50%; left: 15%;"
                        >
                            Lihat Detail
                        </a>
                        <p style="color: white; position: absolute; top: 60%; left: 15%;">*S&K Berlaku</p>
                        <div style="position: absolute; top: 60%; left: 42%">
                            <div style="position: relative">
                                <p
                                    style="display: inline; color: white; font-weight: 800; font-size: 24px; margin-top: 0">
                                    PROMO
                                </p>
    
                                <div style="position: absolute; right: -90px; top: 8px;">
                                    <div style="position: relative">
                                        <h1
                                            style="color: white; fontWeight: 800; fontSize: 78px; margin: 0; transform: scale(1.8); marginLeft: 20%;"
                                        >
                                            40
                                        </h1>
                                        <img
                                            alt="disc"
                                            src="https://cdn.promedik.com/disc.png"
                                            style="position: absolute; right: -70px; top: -30px"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <img
                            alt="banner"
                            class=""
                            src="https://cdn.promedik.com/dokter-banner.png"
                            style="position: absolute; bottom: 0%; right: 15%; height: 90%"
                        />
                    </div>`,
	});

	blockManager.add("default-description", {
		id: "default-description",
		label: `<div class="bg-secondary  h-8 mb-4 rounded text-dark">Default</div>
                    <div>Description</div>`,
		category: "Default Description",
		content: `<div class="container p-0">
				<h1 class="fw-bold mb-1 fz-5" style="fontSize: 28;">
					Diskon IVF dengan ProMil Rp20jt
				</h1>
				<p class="text-grey" style="fontSize: 16;">
					Until Desember 2024
				</p>
				<hr />

				<section class="mb-5">
					<h2 class="mb-4" style="fontSize: 18; fontWeight: 700;">
						Syarat & Ketentuan
					</h2>
					<h3 class="mb-3" style="fontSize: 14;">
						Paket ProMil
					</h3>
					<div>
						<p class="mb-3">
							Memiliki buah hati merupabawah ini, bahwa dengan persiapan yang optimal akan menaikkan
							angkapasangan dapat memiliki buah hati tengan mudah.
						</p>
						<p class="mb-0">Faktor keberhasilan Program Hamil dipengaruhi oleh:</p>
						<ul class="mb-3">
							<li>Faktor Suami 45%</li>
							<li>Faktor Istri 45%</li>
							<li>Unexplained Infertility 10%</li>
						</ul>

						<p class="mb-0">
							Untuk hamil, dibutuhkan pertemuan sel telur dan sel sperma yang kemudian harus menempel di
							rahim dengin sempurna. Hal itu data dicapai melalui metode:
						</p>
						<ul class="mb-3">
							<li>Hubungan Alami Suami-Istri</li>
							<li>Inseminasi Buatan (IUI)</li>
							<li>Bayi Tabung (IVF)</li>
						</ul>

						<p class="mb-3">
							Namun ketiga metode tersebut tidak akan memberikan hasil yang optimal bila tidak disertakan
							persiapan terhadap suami dan istri dalam rangkaian Persiapan Program Hamil. Kita lihat pada
							grafik di bawah ini, bahwa dengan persiapan yang optimal akan menaikkan angka kerberhasilln
							Program Hamil secara significan.
						</p>
					</div>
					<img
						alt="pregnancy-program-graph"
						class="w-100"
						src="https://cdn.promedik.com/pregnancy-program-graph.png"
					/>
				</section>

				<section>
					<p class="mb-4">Ikutilah paket Program Hamil untuk hasil yang optimal:</p>
					<div class="row gap-3 gap-xl-0">
						<div class="col-12 col-xl-4 pe-xl-2">
							<div class="card">
								<div class="card-body">
									<h3 class="h6 fw-normal">PAKET PROMIL</h3>
									<h4 class="h6 fw-bold">SILVER</h4>
									<p>Rp 9,9jt/bln selama 6 bulan</p>

									<ul>
										<li>Konsultasi Dokter Spesialis</li>
										<li>Pemeriksaan Sperma</li>
										<li>Pemeriksaan Hormon</li>
										<li>Obat-obatan Andrologi</li>
									</ul>

									<a
										class="d-block w-100 rounded-pill btn-orange border-orange mt-3 cursor-pointer"
										href="https://invoice.xendit.co/od/promilplatinum">
										DAFTAR PROMIL SILVER
									</a>
								</div>
							</div>
						</div>

						<div class="col-12 col-xl-4 px-xl-2">
							<div class="card">
								<div class="card-body">
									<h3 class="h6 fw-normal">PAKET PROMIL</h3>
									<h4 class="h6 fw-bold">GOLD</h4>
									<p>Rp 12,9jt/bln selama 6 bulan</p>

									<ul>
										<li>Konsultasi Dokter Spesialis</li>
										<li>Pemeriksaan Sperma</li>
										<li>Pemeriksaan Hormon</li>
										<li>Obat-obatan Andrologi</li>
										<li>
											<b>DNA Fragmentasi</b>
										</li>
										<li>
											<b>Pemeriksaan Alergi</b>
										</li>
										<li>
											<b>USG Testis</b>
										</li>
									</ul>

									<a
										class="d-block w-100 rounded-pill btn-orange border-orange mt-3 cursor-pointer"
										href="https://invoice.xendit.co/od/promilgold">
										DAFTAR PROMIL GOLD
									</a>
								</div>
							</div>
						</div>

						<div class="col-12 col-xl-4 ps-xl-2">
							<div class="card">
								<div class="card-body">
									<h3 class="h6 fw-normal">PAKET PROMIL</h3>
									<h4 class="h6 fw-bold">PLATINUM</h4>
									<p>Rp 14,9jt/bln selama 6 bulan</p>

									<ul>
										<li>Konsultasi Dokter Spesialis</li>
										<li>Pemeriksaan Sperma</li>
										<li>Pemeriksaan Hormon</li>
										<li>Obat-obatan Andrologi</li>
										<li>DNA Fragmentasi</li>
										<li>Pemeriksaan Alergi</li>
										<li>USG Testis</li>
										<li>
											<b>Akupuntur 24 Sesi (Suami dan Istri)</b>
										</li>
										<li>
											<b>Diskon Bayi Tabung (IVF) sebesar Rp20jt</b>
										</li>
									</ul>

									<a
										class="d-block w-100 rounded-pill btn-orange border-orange mt-3 cursor-pointer"
										href="https://invoice.xendit.co/od/promilsilver">
										DAFTAR PROMIL PLATINUM
									</a>
								</div>
							</div>
						</div>
					</div>
				</section>
			</div>`,
	});

	blockManager.add("custom-component-container", {
		id: "custom-component-container",
		label: `<div class="border rounded py-4"></div>
                    <div class="mt-2">Banner Container</div>`,
		category: "Custom Component",
		content: `<div class="banner-container"></div>`,
	});
	blockManager.add("custom-component-button", {
		id: "custom-component-button",
		label: `<div class="btn btn-orange py-2 mb-2">Click</div>
                    <div>Button</div>`,
		category: "Custom Component",
		content: `<a class="btn btn-orange w-100">Click</a>`,
	});
	blockManager.add("custom-component-text", {
		id: "custom-component-text",
		label: `<div class="mb-2 fs-3">Text</div>
                    <div>Text</div>`,
		category: "Custom Component",
		content: `<p class="p-0 m-0" style="position: absolute; line-height: auto; font-size: 24px;">Type here</p>`,
	});
	blockManager.add("custom-component-image", {
		id: "custom-component-image",
		label: `<img src="/img/no-image-post@2x.png" style="height: 20px;" />
                    <div class="mt-2">Image</div>`,
		category: "Custom Component",
		content: `<img src="" />`,
	});

	// UL
	blockManager.add("unordered-list-container", {
		id: "unordered-list-container",
		label: `<ul class="border rounded">
                    <li> --- </li>
                    <li> --- </li>
                    <li> --- </li>
                </ul>
                    <div class="mt-2">Un-ordered List Container</div>`,
		category: "Custom Component",
		content: `<ul class="pb-4">
                    <li> <p class="p-0 m-0">Type Here </p></li>
                </ul>
                `,
	});
	blockManager.add("unordered-list-item", {
		id: "unordered-list-item",
		label: `<ul>
                    <li> --- </li>
                    <li> --- </li>
                    <li> --- </li>
                </ul>
                    <div class="mt-2">Un-ordered List Item</div>`,
		category: "Custom Component",
		content: `<li>
                    <p class="p-0 m-0">Type Here </p>
                </li>`,
	});

	// Bordered Container
	blockManager.add("bordered-container", {
		id: "bordered-container",
		label: `<div class="py-2 border rounded"></div>
                    <div class="mt-2">Bordered Container</div>`,
		category: "Custom Component",
		content: `<div style="min-height: 40px;" class="border rounded-2"></div>`,
	});

	// HR
	blockManager.add("hr", {
		id: "hr",
		label: `<hr />
                    <div class="mt-2">Hr</div>`,
		category: "Custom Component",
		content: `<hr class="w-100" />`,
	});

	// Heading
	blockManager.add("h1", {
		id: "h1",
		label: `<h5>Heading 1</h5>
                <div class="mt-2">Heading 1</div>`,
		category: "Heading",
		content: `<h1>Heading 1</h1>`,
	});
	blockManager.add("h2", {
		id: "h2",
		label: `<h5>Heading 2</h5>
                <div class="mt-2">Heading 2</div>`,
		category: "Heading",
		content: `<h2>Heading 2</h2>`,
	});
	blockManager.add("h3", {
		id: "h3",
		label: `<h5>Heading 3</h5>
                <div class="mt-2">Heading 3</div>`,
		category: "Heading",
		content: `<h3>Heading 3</h3>`,
	});
	blockManager.add("h4", {
		id: "h4",
		label: `<h5>Heading 4</h5>
                <div class="mt-2">Heading 4</div>`,
		category: "Heading",
		content: `<h4>Heading 4</h4>`,
	});
	blockManager.add("h5", {
		id: "h5",
		label: `<h5>Heading 5</h5>
                <div class="mt-2">Heading 5</div>`,
		category: "Heading",
		content: `<h5>Heading 5</h5>`,
	});
	blockManager.add("h6", {
		id: "h6",
		label: `<h5>Heading 6</h5>
                <div class="mt-2">Heading 6</div>`,
		category: "Heading",
		content: `<h6>Heading 6</h6>`,
	});
}
