import {ParagraphExtension} from "remirror/extensions";

class CustomParagraphExtension extends ParagraphExtension {
	createNodeSpec(extra, override) {
		const baseSpec = super.createNodeSpec(extra, override);

		return {
			...baseSpec,
			toDOM: () => {
				return ["p", {style: "text-align:justify;"}, 0];
			},
		};
	}
}

export const Paragraph = new CustomParagraphExtension();
