export function capitalizeFirstLetter(string) {
	if (string) {
		return string && string.charAt(0) && string.charAt(0).toUpperCase() + string.slice(1);
	}

	return string;
}

export function normalizeUsername(username) {
	const emailRegExp = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

	if (emailRegExp.test(username)) return username;
	if (username.startsWith("62")) return "0" + username.slice(2);
	if (username.startsWith("+62")) return "0" + username.slice(3);

	return username;
}

export function checkCurrentPath(path) {
	if (path.length > 1) {
		return path.substring(1);
	}

	return path;
}

export function getTranslatedKeyword(str) {
	switch (str) {
		case "pending": {
			return "Menunggu Pembayaran";
		}
		case "paid": {
			return "Sudah Dibayar";
		}
		case "expired": {
			return "Batal";
		}
		case "age": {
			return "Usia";
		}
		case "gender": {
			return "Jenis Kelamin";
		}
		case "menstrual_cycle": {
			return "Siklus Menstruasi";
		}
		case "eq": {
			return "Sama Dengan";
		}
		case "lt": {
			return "Kurang Dari";
		}
		case "gt": {
			return "Lebih Dari";
		}
		case "bw": {
			return "Di Antara";
		}
		case "men": {
			return "Pria";
		}
		case "women": {
			return "Wanita";
		}
		case "year": {
			return "Tahun";
		}
		case "month": {
			return "Bulan";
		}
		case "week": {
			return "Minggu";
		}
		case "day": {
			return "Hari";
		}
		default: {
			return "";
		}
	}
}

export const getFormattedGenderText = value => {
	if (value === "male") return "Laki-laki";
	if (value === "female") return "Perempuan";

	return value;
};

export const getUserDetailUrl = user => {
	let userId = user?.id;
	let userType = "pasien";

	if (user?.staff) {
		userId = user?.staff?.id;
		userType = "staff";

		if (user?.staff?.rsMany?.length && user?.staff?.rsMany?.find(role => role.roleId === 2)) {
			userType = "dokter";
		}
	}

	return `/user/${userType}/${userId}`;
};
