import {useMutation, useQueryClient} from "react-query";
import axios from "services/axios";

async function addHospital(data) {
	await axios.post("/hospital/create", data);
}

export function useAddHospital() {
	const queryClient = useQueryClient();

	const {mutate, isLoading, isError, data, error} = useMutation(data => addHospital(data), {
		onSuccess: () => {
			queryClient.invalidateQueries("hospitals");
			queryClient.removeQueries("hospitals");
		},
	});

	return {mutate, isLoading, isError, data, error};
}

export function useEditHospital(options = {}) {
	const queryClient = useQueryClient();

	return useMutation(values => axios.post("/hospital/update", values).then(res => res.data), {
		onSuccess: () => {
			queryClient.invalidateQueries("hospitals");
		},
	});
}

export function useDeleteHospital(options = {}) {
	const queryClient = useQueryClient();

	return useMutation(values => axios.post("/hospital/delete", values).then(res => res.data), {
		onSuccess: () => {
			queryClient.invalidateQueries("hospitals");
			queryClient.removeQueries("hospitals");
		},
	});
}
