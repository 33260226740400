import axios from "services/axios";
import {useMutation, useQueryClient} from "react-query";

export function useAddSediaan(options = {}) {
	return useMutation(values => axios.post("/sediaan/create", values).then(res => res.data));
}
export function useEditSediaan(options = {}) {
	const queryClient = useQueryClient();

	return useMutation(formData => axios.post("/sediaan/update", formData).then(res => res.data), {
		onSuccess: () => {
			queryClient.invalidateQueries("sediaan");
			queryClient.removeQueries("sediaan");
		},
	});
}

export function useDeleteSediaan(options = {}) {
	const queryClient = useQueryClient();

	return useMutation(formData => axios.post("/sediaan/delete", formData).then(res => res.data), {
		onSuccess: () => {
			queryClient.invalidateQueries("sediaan");
			queryClient.removeQueries("sediaan");
		},
	});
}

export function useChangeStatusSediaan() {
	const queryClient = useQueryClient();

	return useMutation(formData => axios.post("/sediaan/change_status_sediaan", formData).then(res => res.data), {
		onSuccess: () => {
			queryClient.invalidateQueries("sediaan");
			queryClient.removeQueries("sediaan");
		},
	});
}
