/* eslint-disable no-unused-vars */
import {useMemo, useState} from "react";
import dayjs from "dayjs";
import moment from "moment";
import {BasicTable} from "components/Table";
import {DatePicker} from "components/DatePicker";
import {ButtonAddTable, SubmitButton} from "components/Button";
import {useScheduleWorks} from "hooks/Doctor/ScheduleWork";
import {useScheduleHolidays} from "hooks/Doctor/ScheduleHoliday";
import {useSchedules} from "hooks/Doctor/Schedule";
import {isMobile} from "react-device-detect";

const daysDetail = [
	{
		id: 1,
		name: "Senin",
	},
	{
		id: 2,
		name: "Selasa",
	},
	{
		id: 3,
		name: "Rabu",
	},
	{
		id: 4,
		name: "Kamis",
	},
	{
		id: 5,
		name: "Jum'at",
	},
	{
		id: 6,
		name: "Sabtu",
	},
	{
		id: 7,
		name: "Minggu",
	},
];

export function ScheduleListPage({setTab, setScheduleId, userId}) {
	const [dataLimit, setDataLimit] = useState(10);
	const [searchKeyword, setSearchKeyword] = useState("");
	const {data: scheduleWorks, isLoading: isLoadingScheduleWorks, filterScheduleWorks} = useScheduleWorks(userId);
	const {
		data: scheduleHolidays,
		isLoading: isLoadingScheduleHolidays,
		filterScheduleHolidays,
	} = useScheduleHolidays(userId);
	const {data: schedules, isLoading: isLoadingSchedules, filterSchedules} = useSchedules(userId);
	const [filterDateScheduleWork, setFilterDateScheduleWork] = useState("");
	const [filterDateScheduleHoliday, setFilterDateScheduleHoliday] = useState("");

	const columnsSchedule = useMemo(
		() => [
			{
				Header: "No",
				accessor: "idSerial",
			},
			{
				Header: "Tipe Konsultasi",
				accessor: "type",
			},
			{
				Header: "Hari",
				accessor: "days",
				Cell: ({row}) => {
					let days = [];

					row?.values?.days?.forEach(day => {
						const dayText = daysDetail.filter(item => item.id == day)[0];

						days.push({text: dayText.name, id: dayText.id});
					});
					days.sort((a, b) => a.id - b.id);

					return (
						<p>
							{days.map((item, index) => (
								<>
									{item.text}
									{days.length === index + 1 ? "" : ", "}
								</>
							))}
						</p>
					);
				},
			},
			{
				Header: "Lokasi Praktik",
				accessor: "hospital.name",
			},
			{
				Header: "Aksi",
				accessor: "id",
				Cell: ({row}) => (
					<>
						<a
							class="text-orange wait-pay"
							style={{cursor: "pointer"}}
							onClick={() => {
								setScheduleId(row?.values?.id);
								setTab(2);
							}}>
							<span class="icon-ico-pencil ft-18" />
						</a>
					</>
				),
			},
		],
		[],
	);

	const columnsScheduleMobile = useMemo(
		() => [
			{
				Header: "Hari",
				accessor: "days",
				Cell: ({row}) => {
					let text = [];

					row?.values?.days?.forEach(day => {
						const dayText = daysDetail.filter(item => item.id == day)[0].name;

						text.push(dayText);
					});

					return (
						<div class="col-9">
							<div class="text-bold">
								<span
									className={
										row.original.type === "online" ? "icon-ico-zoom me-2" : "icon-ico-hospital me-2"
									}
								/>
								{text.map((item, index) => (
									<>
										{item}
										{text.length === index + 1 ? "" : ", "}
									</>
								))}
							</div>
							<small class="text-grey">{row?.original?.hospital?.name}</small>
						</div>
					);
				},
			},
			{
				Header: "Aksi",
				accessor: "id",
				Cell: ({row}) => (
					<>
						<a
							class="text-orange wait-pay"
							style={{cursor: "pointer"}}
							onClick={() => {
								setScheduleId(row?.values?.id);
								setTab(2);
							}}>
							<span class="icon-ico-pencil ft-18" />
						</a>
					</>
				),
			},
		],
		[],
	);

	const columnsScheduleWork = useMemo(
		() => [
			{
				Header: "No",
				accessor: "idSerial",
			},
			{
				Header: "Tipe Konsultasi",
				accessor: "type",
				Cell: ({row}) => {
					return <p>{row?.values?.type > 1 ? "offline" : "online"}</p>;
				},
			},
			{
				Header: "Tanggal",
				accessor: "startDate",
				Cell: ({row}) => {
					return (
						<p>
							{moment(row?.values?.startDate).format("DD/MM/YYYY")} {" - "}
							{moment(row?.original?.endDate).format("DD/MM/YYYY")}
						</p>
					);
				},
			},
			{
				Header: "Jam Praktik",
				accessor: "ScheduleWorkingTimes",
				Cell: ({row}) => (
					<>
						{row.values.ScheduleWorkingTimes?.map((schedule, index) => (
							<>
								{schedule.startTime} {" - "} {schedule.endTime}{" "}
								{row.values.ScheduleWorkingTimes.length !== index + 1 && ", "}
							</>
						))}
					</>
				),
			},
			{
				Header: "Lokasi Praktik",
				accessor: "Hospital.name",
			},
			{
				Header: "Aksi",
				accessor: "id",
				Cell: ({row}) => (
					<>
						<a
							class="text-orange wait-pay"
							style={{cursor: "pointer"}}
							onClick={() => {
								setScheduleId(row?.values?.id);
								setTab(3);
							}}>
							<span class="icon-ico-pencil ft-18" />
						</a>
					</>
				),
			},
		],
		[],
	);

	const columnsScheduleWorkMobile = useMemo(
		() => [
			{
				Header: "Tanggal",
				accessor: "startDate",
				Cell: ({row}) => {
					let text = [];

					row?.values?.days?.forEach(day => {
						const dayText = daysDetail.filter(item => item.id == day)[0].name;

						text.push(dayText);
					});

					return (
						<div class="col-9">
							<span
								className={row.original.type == 1 ? "icon-ico-zoom me-2" : "icon-ico-hospital me-2"}
							/>
							<div class="text-bold">
								{moment(row?.values?.startDate).format("DD/MM/YYYY")} {" - "}
								{moment(row?.original?.endDate).format("DD/MM/YYYY")}
							</div>
							<small class="text-grey">{row?.original?.Hospital?.name}</small>
						</div>
					);
				},
			},
			{
				Header: "Aksi",
				accessor: "id",
				Cell: ({row}) => (
					<>
						<a
							class="text-orange wait-pay"
							style={{cursor: "pointer"}}
							onClick={() => {
								setScheduleId(row?.values?.id);
								setTab(2);
							}}>
							<span class="icon-ico-pencil ft-18" />
						</a>
					</>
				),
			},
		],
		[],
	);

	const columnsScheduleHoliday = useMemo(
		() => [
			{
				Header: "No",
				accessor: "idSerial",
			},
			{
				Header: "Tanggal",
				accessor: "startDate",
				Cell: ({row}) => {
					return (
						<p>
							{moment(row?.values?.startDate).format("DD/MM/YYYY")} {" - "}
							{moment(row?.original?.endDate).format("DD/MM/YYYY")}
						</p>
					);
				},
			},
			{
				Header: "Jam Mulai - Jam Selesai",
				accessor: "ScheduleHolidayTimes",
				Cell: ({row}) => (
					<>
						{row.values.ScheduleHolidayTimes?.map((schedule, index) => (
							<>
								{schedule.startTime} {" - "} {schedule.endTime}{" "}
								{row.values.ScheduleHolidayTimes.length !== index + 1 && ", "}
							</>
						))}
					</>
				),
			},
			{
				Header: "Keterangan",
				accessor: "keterangan",
			},
			{
				Header: "Lokasi Praktik",
				accessor: "hospital.name",
			},
			{
				Header: "Aksi",
				accessor: "id",
				Cell: ({row}) => (
					<>
						<a
							class="text-orange wait-pay"
							style={{cursor: "pointer"}}
							onClick={() => {
								setScheduleId(row?.values?.id);
								setTab(4);
							}}>
							<span class="icon-ico-pencil ft-18" />
						</a>
					</>
				),
			},
		],
		[],
	);

	const columnsScheduleHolidayMobile = useMemo(
		() => [
			{
				Header: "Tanggal",
				accessor: "startDate",
				Cell: ({row}) => {
					let text = [];

					row?.values?.days?.forEach(day => {
						const dayText = daysDetail.filter(item => item.id == day)[0].name;

						text.push(dayText);
					});

					return (
						<div class="col-9">
							<div class="text-bold">
								{moment(row?.values?.startDate).format("DD/MM/YYYY")} {" - "}
								{moment(row?.original?.endDate).format("DD/MM/YYYY")}
							</div>
							<small class="text-grey">{row?.original?.keterangan}</small>
						</div>
					);
				},
			},
			{
				Header: "Aksi",
				accessor: "id",
				Cell: ({row}) => (
					<>
						<a
							class="text-orange wait-pay"
							style={{cursor: "pointer"}}
							onClick={() => {
								setScheduleId(row?.values?.id);
								setTab(2);
							}}>
							<span class="icon-ico-pencil ft-18" />
						</a>
					</>
				),
			},
		],
		[],
	);

	const dataScheduleWithIDSerial =
		schedules?.data?.rows?.map((item, index) => ({
			...item,
			idSerial: (schedules?.data?.currentPage - 1) * dataLimit + index + 1,
		})) || [];

	const dataScheduleWorkWithIDSerial =
		scheduleWorks?.data?.rows?.map((item, index) => ({
			...item,
			idSerial: (scheduleWorks?.data?.currentPage - 1) * dataLimit + index + 1,
		})) || [];

	const dataScheduleHolidayWithIDSerial =
		scheduleHolidays?.data?.rows?.map((item, index) => ({
			...item,
			idSerial: (scheduleHolidays?.data?.currentPage - 1) * dataLimit + index + 1,
		})) || [];

	return (
		<>
			<div className="mb-5">
				<div class="d-flex flex-wrap justify-content-between align-items-center">
					<div class="text-bold text-dark mb-4">Jadwal Praktik</div>
					<ButtonAddTable text="Tambah Jadwal Praktik" onClick={() => setTab(2)} />
				</div>

				<BasicTable
					columns={isMobile ? columnsScheduleMobile : columnsSchedule}
					currentPage={schedules?.data?.currentPage}
					data={dataScheduleWithIDSerial}
					limit={dataLimit}
					loading={isLoadingSchedules}
					striped={true}
					title="Data Dokter"
					totalItems={schedules?.data?.totalItems}
					totalPage={schedules?.data?.totalPages}
					onLimitChange={limit => {
						filterSchedules({limit, page: 1, search: searchKeyword});
						setDataLimit(limit);
					}}
					onPageChange={selectedPage =>
						filterSchedules({page: selectedPage, limit: dataLimit, search: searchKeyword})
					}
				/>
			</div>
			<div className="mb-5">
				<div class="d-flex flex-wrap justify-content-between align-items-center">
					<div class="text-bold text-dark mb-4">Jadwal Libur</div>
					<div
						class={`d-flex flex-wrap align-items-center row col-10 ${
							isMobile ? "w-100" : "justify-content-end"
						}`}>
						<div class={`col-lg-2 col-sm-12 d-flex flex-wrap align-items-center justify-content-end`}>
							<a
								className="text-green"
								style={{cursor: "pointer"}}
								onClick={() => {
									setFilterDateScheduleHoliday({});
									filterScheduleHolidays({
										page: 1,
										limit: dataLimit,
									});
								}}>
								Reset filter
							</a>
						</div>
						<div className={`form-filter position-relative col-lg-4 col-sm-12 ${isMobile ? "mb-2" : ""}`}>
							<DatePicker
								onChange={selectedDate => {
									setFilterDateScheduleHoliday(selectedDate);
									filterScheduleHolidays({
										page: 1,
										limit: dataLimit,
										endDate: selectedDate.endDate,
										startDate: selectedDate.startDate,
									});
								}}
								onHide={selectedDate => {
									if (filterDateScheduleHoliday.startDate) {
										setFilterDateScheduleHoliday(selectedDate);
									}
								}}>
								<input
									className="form-control"
									placeholder="Pilih tanggal"
									type="text"
									value={
										(filterDateScheduleHoliday.startDate &&
											dayjs(filterDateScheduleHoliday.startDate, "YYYY-MM-DD").format(
												"DD/MM/YYYY",
											) +
												" - " +
												dayjs(filterDateScheduleHoliday.endDate, "YYYY-MM-DD").format(
													"DD/MM/YYYY",
												)) ||
										""
									}
								/>
							</DatePicker>
							<div className="icon-right me-2">
								<span className="icon-ico-date text-orange" />
							</div>
						</div>
						<div class={"col-lg-4 col-sm-2"}>
							<SubmitButton
								className="w-100 btn btn-green"
								text="Tambah Jadwal Libur"
								onClick={() => setTab(4)}
							/>
						</div>
					</div>
				</div>

				<BasicTable
					columns={isMobile ? columnsScheduleHolidayMobile : columnsScheduleHoliday}
					currentPage={scheduleHolidays?.data?.currentPage}
					data={dataScheduleHolidayWithIDSerial}
					limit={dataLimit}
					loading={isLoadingScheduleHolidays}
					striped={true}
					title="Data Jadwal Libur"
					totalItems={scheduleHolidays?.data?.totalItems}
					totalPage={scheduleHolidays?.data?.totalPages}
					onLimitChange={limit => {
						filterScheduleHolidays({limit, page: 1, search: searchKeyword});
						setDataLimit(limit);
					}}
					onPageChange={selectedPage =>
						filterScheduleHolidays({page: selectedPage, limit: dataLimit, search: searchKeyword})
					}
				/>
			</div>
			<div className="mb-5">
				<div class="d-flex flex-wrap justify-content-between align-items-center">
					<div class="text-bold text-dark mb-4">Jadwal Masuk</div>
					<div
						class={`d-flex flex-wrap align-items-center row col-10 ${
							isMobile ? "w-100" : "justify-content-end"
						}`}>
						<div class={`col-lg-2 col-sm-12 d-flex flex-wrap align-items-center justify-content-end`}>
							<a
								className="text-green"
								style={{cursor: "pointer"}}
								onClick={() => {
									setFilterDateScheduleWork({});
									filterScheduleWorks({
										page: 1,
										limit: dataLimit,
									});
								}}>
								Reset filter
							</a>
						</div>
						<div className={`form-filter position-relative col-lg-4 col-sm-12 ${isMobile ? "mb-2" : ""}`}>
							<DatePicker
								onChange={selectedDate => {
									setFilterDateScheduleWork(selectedDate);
									filterScheduleWorks({
										page: 1,
										limit: dataLimit,
										endDate: selectedDate.endDate,
										startDate: selectedDate.startDate,
									});
								}}
								onHide={selectedDate => {
									if (filterDateScheduleWork.startDate) {
										setFilterDateScheduleWork(selectedDate);
									}
								}}>
								<input
									className="form-control"
									placeholder="Pilih tanggal"
									type="text"
									value={
										(filterDateScheduleWork.startDate &&
											dayjs(filterDateScheduleWork.startDate, "YYYY-MM-DD").format("DD/MM/YYYY") +
												" - " +
												dayjs(filterDateScheduleWork.endDate, "YYYY-MM-DD").format(
													"DD/MM/YYYY",
												)) ||
										""
									}
								/>
							</DatePicker>
							<div className="icon-right me-2">
								<span className="icon-ico-date text-orange" />
							</div>
						</div>
						<div class="col-lg-4 col-sm-12">
							<SubmitButton
								className="w-100 btn btn-green"
								text="Tambah Jadwal Masuk"
								onClick={() => setTab(3)}
							/>
						</div>
					</div>
				</div>

				<BasicTable
					columns={isMobile ? columnsScheduleWorkMobile : columnsScheduleWork}
					currentPage={scheduleWorks?.data?.currentPage}
					data={dataScheduleWorkWithIDSerial}
					limit={dataLimit}
					loading={isLoadingScheduleWorks}
					striped={true}
					title="Data Jadwal Masuk"
					totalItems={scheduleWorks?.data?.totalItems}
					totalPage={scheduleWorks?.data?.totalPages}
					onLimitChange={limit => {
						filterScheduleWorks({limit, page: 1, search: searchKeyword});
						setDataLimit(limit);
					}}
					onPageChange={selectedPage =>
						filterScheduleWorks({page: selectedPage, limit: dataLimit, search: searchKeyword})
					}
				/>
			</div>
		</>
	);
}
