/* eslint-disable no-unused-vars */
import {useEffect, useMemo, useState} from "react";
import {useHistory} from "react-router-dom";
import {isMobile} from "react-device-detect";
import {DashboardLayout} from "components/Layout";
import {BasicTable, SearchBox} from "components/Table";
import {ButtonAddTable} from "components/Button";
import {ModalConfirmation} from "components/Modal";
import {useToast} from "hooks/useToast";
import {useStateCallback} from "hooks/useStateCallback";
import {convertErrorMessageFormat, convertToCurency} from "utils/converter";
import {useDeleteHargaPendaftaranObat, useHargaPendaftaranObat} from "hooks/SuperAdmin/HargaPendaftaranObat";
import {useSelector} from "react-redux";

const HargaPendaftaranObatList = () => {
	const userData = useSelector(state => state.HospitalsReducer.selectedHospital);

	const [searchKeyword, setSearchKeyword] = useState("");
	const [dataLimit, setDataLimit] = useState(10);
	const {data, isLoading, filterHargaPendaftaranObat} = useHargaPendaftaranObat();

	// Delete Data State
	const {mutate, isLoading: isDeletingData} = useDeleteHargaPendaftaranObat();
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [deleteItemData, setDeleteItemData] = useStateCallback({id: null, "Hospital.name": null});
	const {showToast} = useToast();

	useEffect(() => {
		if (userData.roleId === 1) {
			filterHargaPendaftaranObat({page: 1, limit: 10, search: "", hospitalId: userData.hospitalId});
		}
	}, [userData]);

	const history = useHistory();
	const columns = useMemo(
		() => [
			{
				Header: "No",
				accessor: "idSerial",
			},
			{
				Header: "Rumah Sakit",
				accessor: "Hospital.name",
				maxWidth: 150,
			},
			{
				Header: "Harga Pendaftaran Obat Vendor",
				accessor: "price",
				Cell: ({row}) => {
					return <span>Rp {convertToCurency(row.values.price + "")}</span>;
				},
				maxWidth: 150,
			},
			{
				Header: "Aksi",
				accessor: "id",
				Cell: ({row}) => (
					<>
						{userData.roleId === 8 ? (
							<a
								class="text-orange wait-pay"
								style={{cursor: "pointer"}}
								onClick={() => history.push(`/harga-pendaftaran-obat/show/${row?.values?.id}`)}>
								<span class="icon-ico-eye-open ft-18" />
							</a>
						) : null}
						<a
							class="text-orange wait-pay"
							style={{cursor: "pointer"}}
							onClick={() => history.push(`/harga-pendaftaran-obat/edit/${row?.values?.id}`)}>
							<span class="icon-ico-pencil ft-18" />
						</a>
						{userData.roleId === 8 ? (
							<a
								class="text-orange wait-pay"
								style={{cursor: "pointer"}}
								onClick={() => {
									setDeleteItemData(row?.values, () => setShowDeleteModal(true));
								}}>
								<span class="icon-ico-delete ft-18" />
							</a>
						) : null}
					</>
				),
			},
		],
		[],
	);

	const columnsMobile = useMemo(
		() => [
			{
				Header: "Rumah Sakit",
				accessor: "Hospital.name",
			},
			{
				Header: "Harga Pendaftaran Obat Vendor",
				accessor: "price",
				Cell: ({row}) => {
					return <span>Rp {convertToCurency(row.values.price + "")}</span>;
				},
			},
			{
				Header: "Action",
				accessor: "id",
				Cell: ({row}) => {
					return (
						<div className="col-3">
							{userData.roleId === 8 ? (
								<a
									class="text-orange wait-pay"
									style={{cursor: "pointer"}}
									onClick={() => history.push(`/harga-pendaftaran-obat/show/${row?.values?.id}`)}>
									<span class="icon-ico-eye-open ft-18" />
								</a>
							) : null}
							<a
								class="text-orange wait-pay"
								style={{cursor: "pointer"}}
								onClick={() => history.push(`/harga-pendaftaran-obat/edit/${row?.values?.id}`)}>
								<span class="icon-ico-pencil ft-18" />
							</a>
							{userData.roleId === 8 ? (
								<a
									class="text-orange wait-pay"
									style={{cursor: "pointer"}}
									onClick={() => {
										setDeleteItemData(row?.values, () => setShowDeleteModal(true));
									}}>
									<span class="icon-ico-delete ft-18" />
								</a>
							) : null}
						</div>
					);
				},
			},
		],
		[],
	);

	const dataWithIDSerial =
		data?.data?.rows?.map((item, index) => ({
			...item,
			idSerial: (data?.data?.currentPage - 1) * dataLimit + index + 1,
		})) || [];

	const handleDeleteData = () => {
		mutate(
			{id: deleteItemData.id},
			{
				onSuccess: () => {
					showToast(
						"success",
						`Data harga pendaftaran obat ${deleteItemData["Hospital.name"]} berhasil dihapus`,
						3000,
					);
					window.scrollTo(0, 0);
					setShowDeleteModal(false);
				},
				onError: res => {
					showToast("error", convertErrorMessageFormat(res.response.status, res.response.data.message), null);
				},
			},
		);
	};

	return (
		<DashboardLayout>
			<>
				<ModalConfirmation
					approveLabel="Ya"
					description={`Apakah Anda yakin ingin menghapus data harga pendaftaran obat "${deleteItemData["Hospital.name"]}"?`}
					loading={isDeletingData}
					rejectLabel="Batal"
					visible={showDeleteModal}
					onApprove={handleDeleteData}
					onClose={() => setShowDeleteModal(false)}
					onReject={() => setShowDeleteModal(false)}
				/>
				<div class="text-bold text-dark mb-4">Data Harga Pendaftaran Obat</div>
				<SearchBox
					rightElement={
						userData.roleId === 8 ? (
							<ButtonAddTable
								text="Tambah Harga Pendaftaran Obat"
								onClick={() => history.push("/harga-pendaftaran-obat/tambah")}
							/>
						) : null
					}
					onSearch={keyword => {
						setSearchKeyword(keyword);
						filterHargaPendaftaranObat({page: 1, limit: dataLimit, search: keyword});
					}}
				/>

				<BasicTable
					columns={isMobile ? columnsMobile : columns}
					currentPage={data?.data?.currentPage}
					data={dataWithIDSerial}
					limit={dataLimit}
					loading={isLoading}
					striped={true}
					title="Data Harga Pendaftaran Obat"
					totalItems={data?.data?.totalItems}
					totalPage={data?.data?.totalPages}
					onLimitChange={limitValue => {
						setDataLimit(limitValue);
						filterHargaPendaftaranObat({page: 1, limit: limitValue, search: searchKeyword});
					}}
					onPageChange={selectedPage =>
						filterHargaPendaftaranObat({page: selectedPage, limit: dataLimit, search: searchKeyword})
					}
				/>
			</>
		</DashboardLayout>
	);
};

export {HargaPendaftaranObatList};
