import * as Yup from "yup";

const SUPPORTED_FORMATS = ["application/pdf"];

export const AddFileLabFormSchemaValidation = Yup.object().shape({
	visibility: Yup.string().required("Visibility wajib di pilih"),
	idPatient: Yup.string().required("Pasien wajib di pilih"),
	fileBuffer: Yup.mixed()
		.required("File wajib di upload")
		.test("fileFormat", "Format file tidak mendukung, hanya menerima format .PDF", value => {
			if (typeof value === "string") {
				return true;
			} else {
				return value.every(e => SUPPORTED_FORMATS.includes(e.type));
			}
		}),
});

export const EditFileLabFormSchemaValidation = Yup.object().shape({
	name: Yup.string().required("Nama File wajib di isi"),
	visibility: Yup.string().required("Visibility wajib di pilih"),
	idPatient: Yup.string().required("Pasien wajib di pilih"),
	fileBuffer: Yup.mixed()
		.test("fileFormat", "Format file tidak mendukung, hanya menerima format .PDF", value => {
			if (!value) return true;
			if (typeof value === "string") {
				return true;
			} else {
				return SUPPORTED_FORMATS.includes(value.type);
			}
		})
		.nullable(),
});
