// The environment to use when building the project
const CONFIG = {
	endpoint: {
		APP_ENDPOINT: process.env.REACT_APP_URL,
		API_ENDPOINT: process.env.REACT_APP_API_ENDPOINT,
		GOOGLE_API_ENDPOINT: process.env.REACT_APP_GOOGLE_API_ENDPOINT,
		GOOGLE_API_KEY: process.env.REACT_APP_GOOGLE_API_KEY,
		UNPLASH_API_ENDPOINT: process.env.REACT_APP_UNPLASH_ENDPOINT,
		UNPLASH_API_KEY: process.env.REACT_APP_UNPLASH_API_KEY,
		PATIENT_URL: process.env.REACT_APP_PATIENT_URL,
	},
	zoom: {
		ZOOM_INTEGRATION_LINK: process.env.REACT_APP_ZOOM_INTEGRATION_LINK,
		ZOOM_INTEGRATION_RESPONSE_TYPE: process.env.REACT_APP_ZOOM_INTEGRATION_RESPONSE_TYPE,
		ZOOM_INTEGRATION_CLIENT_ID: process.env.REACT_APP_ZOOM_INTEGRATION_CLIENT_ID,
		ZOOM_INTEGRATION_REDIRECT_URI: process.env.REACT_APP_ZOOM_INTEGRATION_REDIRECT_URI,
	},
};

export default CONFIG;
