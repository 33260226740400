import {ButtonAddTable} from "components/Button";
import {DashboardLayout} from "components/Layout";
import {ModalConfirmation} from "components/Modal";
import {BasicTable, SearchBox} from "components/Table";
import {useRole} from "hooks/Misc";
import {useDeleteTag, useTag} from "hooks/SuperAdmin/Tag";
import {useStateCallback} from "hooks/useStateCallback";
import {useToast} from "hooks/useToast";
import {useMemo, useState} from "react";
import {useHistory} from "react-router-dom";
import {convertErrorMessageFormat} from "utils/converter";

const MasterTagList = () => {
	const [searchKeyword, setSearchKeyword] = useState("");
	const [dataLimit, setDataLimit] = useState(10);
	const {data, isLoading, filterTag, filter} = useTag();
	const {data: roles} = useRole();

	const roleOptions = useMemo(() => {
		if (roles?.success) {
			return roles.data;
		}

		return [];
	}, [roles]);

	// Delete Data State
	const {mutate, isLoading: isDeletingData} = useDeleteTag();
	const [showConfirmModal, setShowConfirmModal] = useState({show: false, type: ""});
	const [selectedItem, setSelectedItem] = useStateCallback({id: null, name: null});
	const {showToast} = useToast();

	const history = useHistory();
	const columns = useMemo(
		() => [
			{
				Header: "No",
				accessor: "idSerial",
			},
			{
				Header: "Tag",
				accessor: "title",
				maxWidth: 300,
			},
			{
				Header: "Nama Role",
				accessor: "tag",
				maxWidth: 300,
				Cell: ({row}) => `${roleOptions.find(opt => opt.id === row?.values?.tag)?.name || ""}`,
			},
			{
				Header: "Jumlah Discount",
				accessor: "discount",
				maxWidth: 300,
				Cell: ({row}) => `${row?.values?.discount}%`,
			},
			{
				centered: true,
				Header: "Aksi",
				accessor: "id",
				width: "200px",
				Cell: ({row}) => (
					<>
						<a
							class="text-orange wait-pay"
							style={{cursor: "pointer"}}
							onClick={() => history.push(`/tag/show/${row?.values?.id}`)}>
							<span class="icon-ico-eye-open ft-18" />
						</a>
						<a
							class="text-orange wait-pay"
							style={{cursor: "pointer"}}
							onClick={() => history.push(`/tag/edit/${row?.values?.id}`)}>
							<span class="icon-ico-pencil ft-18" />
						</a>
						<a
							class="text-orange wait-pay"
							style={{cursor: "pointer"}}
							title={!row.original.can_delete && "Data tidak dapat dihapus karena sudah digunakan"}
							onClick={() => {
								setSelectedItem(row?.values, () => setShowConfirmModal({show: true, type: "delete"}));
							}}>
							<span class="icon-ico-delete ft-18" />
						</a>
					</>
				),
			},
		],
		[roles],
	);

	const dataWithIDSerial =
		data?.data?.rows?.map((item, index) => ({
			...item,
			idSerial: (data?.data?.currentPage - 1) * dataLimit + index + 1,
		})) || [];

	const handleDeleteData = () => {
		mutate(
			{id: selectedItem.id},
			{
				onSuccess: () => {
					showToast("success", `Data tag ${selectedItem.title} berhasil dihapus`, 3000);
					window.scrollTo(0, 0);
					setShowConfirmModal({show: false, type: ""});
				},
				onError: res => {
					showToast("error", convertErrorMessageFormat(res.response.status, res.response.data.message), null);
				},
			},
		);
	};

	const handleConfirm = () => {
		if (showConfirmModal.type === "delete") {
			handleDeleteData();
		}
	};

	return (
		<DashboardLayout>
			<ModalConfirmation
				approveLabel="Ya"
				description={`Apakah Anda yakin ingin menghapus data tag "${selectedItem?.title}"?`}
				loading={isDeletingData}
				rejectLabel="Batal"
				visible={showConfirmModal.show}
				onApprove={handleConfirm}
				onClose={() => setShowConfirmModal({show: false, type: ""})}
				onReject={() => setShowConfirmModal({show: false, type: ""})}
			/>
			<div class="text-bold text-dark mb-4">Data Tag</div>
			<SearchBox
				rightElement={<ButtonAddTable text="Tambah Tag" onClick={() => history.push("/tag/tambah")} />}
				onSearch={keyword => {
					setSearchKeyword(keyword);
					filterTag({...filter, page: 1, limit: dataLimit, search: keyword});
				}}
			/>

			<BasicTable
				columns={columns}
				currentPage={data?.data?.currentPage}
				data={dataWithIDSerial}
				limit={dataLimit}
				loading={isLoading}
				striped={true}
				title="Data Tag"
				totalItems={data?.data?.totalItems}
				totalPage={data?.data?.totalPages}
				onLimitChange={limitValue => {
					setDataLimit(limitValue);
					filterTag({...filter, page: 1, limit: limitValue, search: searchKeyword});
				}}
				onPageChange={selectedPage =>
					filterTag({...filter, page: selectedPage, limit: dataLimit, search: searchKeyword})
				}
			/>
		</DashboardLayout>
	);
};

export {MasterTagList};
