import {useState} from "react";
import {useQuery} from "react-query";
import axios from "services/axios";
import qs from "query-string";

export const QUERY = "medicine/hospital";

export function useListHospitalMedicine(filter) {
	const result = useQuery([QUERY, filter], () =>
		axios.get(`/medicine/hospital?${qs.stringify(filter)}`).then(res => res.data),
	);

	return result;
}

export const fetchHospitalMedicineByID = id => {
	return axios.get(`/medicine/hospital/${id}`).then(res => res.data);
};

export function useDetailHospitalMedicine(id) {
	return useQuery({
		queryKey: id && [QUERY, id],
		queryFn: () => fetchHospitalMedicineByID(id),
		refetchOnMount: false,
		refetchOnWindowFocus: false,
		enabled: !!id,
	});
}

// =================

async function getMedicines(id, filter) {
	if (!id) {
		const {data} = await axios.get(`/medicine`, {params: {...filter, sortBy: "id.desc"}});

		return data;
	}
	const {data} = await axios.get(`/medicine/${id}`);

	return data;
}

export function useListAvailableMedicine(hospitalId, initialFilters, queryOptions) {
	const [filter, filterMedicines] = useState({
		page: 1,
		search: null,
		limit: 10,
		hospitalId,
		...initialFilters,
	});

	const [medicineId, medicineById] = useState(null);
	const fallback = [];
	const {
		data = fallback,
		isLoading,
		isError,
		error,
	} = useQuery(
		["medicines", {...filter, ...initialFilters, hospitalId}, medicineId],
		async () => getMedicines(medicineId, {...filter, ...initialFilters, hospitalId}),
		{
			...queryOptions,
		},
	);

	return {data, isLoading, isError, error, filter, filterMedicines, medicineById};
}

export function useMedicineOptions({hospitalId}) {
	const result = useQuery(
		["medicineOptionsHospital", hospitalId],
		async () =>
			await axios
				.get(`/medicine`, {params: {hospitalId, isActive: true, sortBy: "id.desc"}})
				.then(res => res.data),
	);

	return result;
}
