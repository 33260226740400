import {DashboardLayout} from "components/Layout";
import {BasicTable, SearchBox} from "components/Table";
import {useListHargaAdministrasi} from "hooks/SuperAdmin/HargaAdministrasi";
import {useMemo, useState} from "react";
import {isMobile} from "react-device-detect";
import {useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {convertToCurency} from "utils/converter";

export function HargaAdministrasiListPage() {
	const history = useHistory();

	const selectedHospital = useSelector(state => state.HospitalsReducer.selectedHospital);
	const [filter, setFilter] = useState({
		page: 1,
		search: "",
		limit: 10,
		sortBy: "id.desc",
		hospitalId: selectedHospital?.Hospital?.id,
	});
	const {data, isLoading} = useListHargaAdministrasi(filter);

	const columns = useMemo(
		() => [
			{
				Header: "No",
				accessor: "idSerial",
			},
			{
				Header: "Nama Rumah Sakit",
				accessor: "hospitalId",
				Cell: ({row}) => {
					return <span className="text-bold">{row?.original?.Hospital?.name}</span>;
				},
			},
			{
				Header: "Harga",
				accessor: "price",
				Cell: ({row}) => {
					return <span className="text-bold">Rp {convertToCurency(row.values.price + "")}</span>;
				},
			},
			{
				Header: "Aksi",
				accessor: "id",
				Cell: ({row}) => {
					return (
						<>
							<a
								className="text-orange wait-pay"
								style={{cursor: "pointer"}}
								onClick={() => history.push(`/hospital/harga-administrasi/edit/${row?.values?.id}`)}>
								<span className="icon-ico-pencil ft-18" />
							</a>
						</>
					);
				},
			},
		],
		[],
	);
	const columnsMobile = useMemo(
		() => [
			{
				Header: "Nama Rumah Sakit",
				accessor: "hospitalId",
				Cell: ({row}) => {
					return (
						<div className="col-5">
							<span className="text-bold">{row?.original?.Hospital?.name}</span>
						</div>
					);
				},
			},
			{
				Header: "Harga",
				accessor: "price",
				Cell: ({row}) => {
					return (
						<div className="col-4">
							<span className="text-bold">{convertToCurency(row?.values?.price + "")}</span>
						</div>
					);
				},
			},
			{
				Header: "Action",
				accessor: "id",
				Cell: ({row}) => {
					return (
						<div className="col-3">
							<a
								className="text-orange wait-pay"
								style={{cursor: "pointer"}}
								onClick={() => history.push(`/master-harga-administrasi/edit/${row?.values?.id}`)}>
								<span className="icon-ico-pencil ft-18" />
							</a>
						</div>
					);
				},
			},
		],
		[],
	);

	const dataWithIDSerial = useMemo(() => {
		return (
			data?.data?.rows?.map((item, index) => ({
				...item,
				idSerial: (filter.page - 1) * filter.limit + index + 1,
			})) || []
		);
	}, [data, filter.limit, filter.page]);

	return (
		<DashboardLayout>
			<>
				<div className="text-bold text-dark mb-4">
					Data Harga Administrasi - {selectedHospital.Hospital.name}
				</div>
				<SearchBox
					onSearch={keyword => {
						setFilter({...filter, search: keyword, page: 1});
					}}
				/>

				<BasicTable
					columns={isMobile ? columnsMobile : columns}
					currentPage={data?.data?.currentPage}
					data={dataWithIDSerial}
					limit={filter.limit}
					loading={isLoading}
					striped={true}
					title="Data Harga Administrasi"
					totalItems={data?.data?.totalItems}
					totalPage={data?.data?.totalPages}
					onLimitChange={limitValue => {
						setFilter({...filter, limit: limitValue});
					}}
					onPageChange={selectedPage => {
						setFilter({...filter, page: selectedPage});
					}}
				/>
			</>
		</DashboardLayout>
	);
}
