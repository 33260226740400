import React from "react";
import {SubmitButton, ToggleSwitch} from "components/Button";
import {DashboardLayout} from "components/Layout";
import {FormSchemaValidation} from "config/form/FormValidation/Banner";
import {Formik} from "formik";
import {useAddBanner, useEditBanner, useBannerList} from "hooks/SuperAdmin/Banner";
import {useToast} from "hooks/useToast";
import {useHistory, useParams} from "react-router-dom/cjs/react-router-dom";
import {convertErrorMessageFormat} from "utils/converter";
import grapesjs from "grapesjs";
import grapesjsBlocksBasic from "grapesjs-blocks-basic";
import CONFIG from "config/env";
import {useSelector} from "react-redux";
import clsx from "clsx";

import generateStyle from "./style/generetaStyle";
import generateBlock from "./block/generateBlock";

import "grapesjs/dist/css/grapes.min.css";
// import {loadLocalAssets} from "utils/loader";

const FormInitialValues = {title: "", description: {}, status: true, content: {}, order: null, deviceType: "desktop"};

const BannerActionPage = () => {
	const authReducer = useSelector(state => state.AuthReducer);
	const {action, id} = useParams();
	const history = useHistory();

	const {showToast} = useToast();
	const {mutate: addForm, isLoading} = useAddBanner();
	const {mutate: editForm, isLoading: editLoading} = useEditBanner();
	const {data, setBannerId} = useBannerList();

	const formikRef = React.useRef();

	React.useEffect(() => {
		if (action === "edit" && id) {
			setBannerId(id);
		}
	}, [action, id]);

	React.useEffect(() => {
		if (action === "edit" && data?.data?.id) {
			formikRef?.current.setFieldValue("title", data.data.title);
			formikRef?.current.setFieldValue("description", data.data.description);
			formikRef?.current.setFieldValue("content", data.data.content);
			formikRef?.current.setFieldValue("order", data.data.order);
			formikRef?.current.setFieldValue("status", data.data.status ? "active" : "inactive");
		}
	}, [action, data]);

	const editorBannerRef = React.useRef(null);

	React.useEffect(() => {
		if (!editorBannerRef.current && (action === "edit" ? data?.data?.id : true)) {
			editorBannerRef.current = grapesjs.init({
				storageManager: {
					type: "local",
					options: {
						local: {key: "gjsBannerProject"},
					},
				},
				fromElement: true,
				container: "#gjsBanner",
				height: "80vh",
				plugins: [grapesjsBlocksBasic],
				pluginsOpts: {
					[grapesjsBlocksBasic]: {blocks: ["column1", "column2", "column3", "column3-7"], category: "Layout"},
				},
				assetManager: {
					uploadFile(e) {
						const files = e.dataTransfer ? e.dataTransfer.files : e.target.files;

						const formData = new FormData();

						for (let i = 0; i < files.length; i++) {
							formData.append("file", files[i]);
						}
						fetch(`${CONFIG.endpoint.API_ENDPOINT}/misc/s3-upload`, {
							method: "POST",
							body: formData,
							headers: {
								Authorization: `Bearer ${authReducer.accessToken}`,
							},
						})
							.then(res => res.json())
							.then(data => {
								const assets = data.data.file;

								editorBannerRef.current.AssetManager.add(assets);
							})
							.catch(err => console.error(err));
					},
				},
				deviceManager: {
					devices: [
						{id: "desktop", name: "Desktop", width: ""},
						// {id: "tablet", name: "Tablet", width: "768px"},
						{id: "mobile", name: "Mobile", width: "375px"},
					],
				},
			});

			if (editorBannerRef?.current) {
				const blockManager = editorBannerRef.current.BlockManager;

				generateBlock(blockManager);
				generateStyle(editorBannerRef?.current);
			}

			if (action === "edit" && data?.data?.id) {
				editorBannerRef.current.setComponents(data?.data?.content?.grape_js_html);
				editorBannerRef.current.setStyle(data?.data?.content?.grape_js_css);
				editorBannerRef?.current?.setDevice(data?.data?.deviceType);
			}
		}
	}, [data]);

	const editorDescriptionRef = React.useRef(null);

	React.useEffect(() => {
		if (!editorDescriptionRef.current && (action === "edit" ? data?.data?.id : true)) {
			editorDescriptionRef.current = grapesjs.init({
				storageManager: {
					type: "local",
					options: {
						local: {key: "gjsDescriptionProject"},
					},
				},
				fromElement: true,
				container: "#gjsDescription",
				height: "80vh",
				plugins: [grapesjsBlocksBasic],
				pluginsOpts: {
					[grapesjsBlocksBasic]: {blocks: ["column1", "column2", "column3", "column3-7"], category: "Layout"},
				},
				assetManager: {
					uploadFile(e) {
						const files = e.dataTransfer ? e.dataTransfer.files : e.target.files;

						const formData = new FormData();

						for (let i = 0; i < files.length; i++) {
							formData.append("file", files[i]);
						}
						fetch(`${CONFIG.endpoint.API_ENDPOINT}/misc/s3-upload`, {
							method: "POST",
							body: formData,
							headers: {
								Authorization: `Bearer ${authReducer.accessToken}`,
							},
						})
							.then(res => res.json())
							.then(data => {
								const assets = data.data.file;

								editorDescriptionRef.current.AssetManager.add(assets);
							})
							.catch(err => console.error(err));
					},
				},
				deviceManager: {devices: ["desktop"]},
			});

			if (editorDescriptionRef?.current) {
				const blockManager = editorDescriptionRef.current.BlockManager;

				generateBlock(blockManager);
				generateStyle(editorDescriptionRef?.current);
			}

			if (action === "edit" && data?.data?.id) {
				editorDescriptionRef.current.setComponents(data?.data?.description?.grape_js_html);
				editorDescriptionRef.current.setStyle(data?.data?.description?.grape_js_css);
			}
		}
	}, [data]);

	React.useEffect(() => {
		if (!data?.data?.id) {
			localStorage.removeItem("gjsBannerProject");
			localStorage.removeItem("gjsDescriptionProject");
			localStorage.removeItem("gjsProject");
		}
	}, [data]);

	const handleSubmit = async formValue => {
		const finalData = formValue;

		finalData.status = formValue?.status ? "active" : "inactive";

		// const bannerGjsContent = localStorage.getItem("gjsBannerProject");
		const bannerGjsHtml = editorBannerRef?.current?.getHtml();
		const bannerGjsCss = editorBannerRef?.current?.getCss();

		// finalData.content.grape_js_content = bannerGjsContent;
		finalData.content.grape_js_html = bannerGjsHtml;
		finalData.content.grape_js_css = bannerGjsCss;

		// const descriptionGjsContent = localStorage.getItem("gjsDescriptionProject");
		const descriptionGjsHtml = editorDescriptionRef?.current?.getHtml();
		const descriptionGjsCss = editorDescriptionRef?.current?.getCss();

		// finalData.description.grape_js_content = descriptionGjsContent;
		finalData.description.grape_js_html = descriptionGjsHtml;
		finalData.description.grape_js_css = descriptionGjsCss;

		finalData.deviceType = editorBannerRef?.current?.getDevice();

		if (action === "add") {
			addForm(finalData, {
				onSuccess: () => {
					showToast("success", "Data berhasil ditambahkan", 3000);
					history.push("/banner");
				},
				onError: res => {
					showToast("error", convertErrorMessageFormat(res.response.status, res.response.data.message), null);
				},
			});
		}

		if (action === "edit") {
			finalData.id = id;
			editForm(finalData, {
				onSuccess: () => {
					showToast("success", "Data berhasil diubah", 3000);
					history.push("/banner");
				},
				onError: res => {
					showToast("error", convertErrorMessageFormat(res.response.status, res.response.data.message), null);
				},
			});
		}
	};

	return (
		<DashboardLayout>
			<Formik
				initialValues={FormInitialValues}
				innerRef={formikRef}
				validationSchema={FormSchemaValidation}
				onSubmit={handleSubmit}>
				{({
					handleSubmit,
					handleBlur,
					handleChange,
					setFieldTouched,
					setFieldValue,
					touched,
					values,
					errors,
				}) => (
					<div class="box-white setting-content h-100 no-border p24 w-100">
						<div class="d-flex justify-content-between flex-wrap align-items-center mb-4">
							<div class="ttl-20 text-capitalize">{action === "edit" ? "Edit" : "Tambah"} Banner</div>
						</div>

						<hr />

						<div class="row gx-2 mb-3">
							<div class="col-sm-6 col-6">
								<div class="setting-item">
									<label class="setting-label required">Judul</label>
									<input
										class={clsx(
											"form-control",
											errors?.title && touched?.title && "form-control-error",
										)}
										disabled={isLoading || editLoading}
										name="title"
										placeholder=""
										type="text"
										value={values?.title}
										onBlur={handleBlur}
										onChange={handleChange}
									/>
									{errors.title && touched?.title && (
										<p className="form-error-item-message">{errors.title}</p>
									)}
								</div>
							</div>
							<div class="col-sm-6 col-6">
								<div className="setting-item">
									<label class="setting-label d-block mb-3">Status Aktif</label>
									<ToggleSwitch
										isActive={values?.status}
										size="sm"
										onChange={e => {
											setFieldValue("status", e.status);
										}}
									/>
								</div>
							</div>
							<div class="col-sm-6 col-6">
								<div class="setting-item">
									<label class="setting-label required">Order</label>
									<input
										class={clsx(
											"form-control",
											errors?.order && touched?.order && "form-control-error",
										)}
										disabled={isLoading || editLoading}
										name="order"
										placeholder=""
										type="text"
										value={values?.order}
										onBlur={handleBlur}
										onChange={e => {
											e.target.value = e.target.value.replace(/\D/g, "");
											handleChange(e);
										}}
									/>
									{errors.order && touched?.order && (
										<p className="form-error-item-message">{errors.order}</p>
									)}
								</div>
							</div>
						</div>

						<div className="">
							<label class="setting-label required">Banner</label>
							<div className="mt-1 border pb-4" id="gjsBanner" />
						</div>

						<div className="mt-3">
							<label class="setting-label required">Description</label>
							<div className="mt-1 border pb-4" id="gjsDescription" />
						</div>

						<div class="text-center my-3">
							<SubmitButton
								className="btn btn-orange d-inline-block mw-250"
								disabled={!FormSchemaValidation.isValidSync(values)}
								loading={isLoading || editLoading}
								text="Simpan"
								onClick={handleSubmit}
							/>
						</div>
					</div>
				)}
			</Formik>
		</DashboardLayout>
	);
};

BannerActionPage.displayName = "BannerActionPage";
export {BannerActionPage};
