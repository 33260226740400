import _ from "lodash";
import {useCallback, useEffect, useState} from "react";
import CodeMirror from "@uiw/react-codemirror";
import {html} from "@codemirror/lang-html";
import {ExtensionTag, NodeExtension} from "remirror";

import {getNodeCoordinates} from "../util";

const getEditableStatus = attrs => {
	return typeof attrs === "object" && attrs["data-placeholder-html"];
};

class HTMLExtension extends NodeExtension {
	get name() {
		return "html";
	}

	createTags() {
		return [ExtensionTag.Block];
	}

	createNodeSpec(extra, override) {
		return {
			...override,
			attrs: {html: {default: null}, "data-placeholder-html": {default: true}, ...extra.defaults()},
			content: "",
			draggable: false,
			selectable: true,
			isolation: true,
			atom: true,
			parseDOM: [
				{tag: "div[data-placeholder-html]", getAttrs: extra.parse},
				{
					tag: "div.html-embed-container",
					getAttrs: dom => ({
						"data-placeholder-html": false,
						html: dom.innerHTML,
						...extra.parse,
					}),
				},
				...(override.parseDOM ?? []),
			],
			toDOM: node => {
				if (!node.attrs["data-placeholder-html"] && node.attrs.html) {
					const parser = new DOMParser();
					const doc = parser.parseFromString(node.attrs.html, "text/html");
					const elements = Array.from(doc.body.childNodes);
					const container = document.createElement("div");

					container.classList.add("html-embed-container");
					elements.forEach(el => container.appendChild(el));

					return container;
				}

				return ["div", {"data-placeholder-html": true, class: "embed-placeholder"}];
			},
		};
	}
}

export function HTMLField({view, state}) {
	const {tr} = state ?? {};
	const {dispatch} = view ?? {};

	const [top, setTop] = useState(null);
	const [value, setValue] = useState(null);
	const [isBlur, setIsBlur] = useState(false);

	useEffect(() => {
		const node = state.doc.content.content.find(item => item.type.name === "html" && getEditableStatus(item.attrs));
		const activeEl = view?.dom?.querySelector('[data-placeholder-html="true"]');
		const activeElCoord = activeEl?.offsetTop ?? 0;

		if (node && activeEl && _.isNull(value) && _.isNull(top)) {
			activeEl.classList.remove("ProseMirror-selectednode");
			setTop(activeElCoord);
			setValue(node?.attrs?.html ?? "");
		}
	}, [value, top, state, view]);

	useEffect(() => {
		const setHTMLValue = () => {
			const activeEl = view?.dom?.querySelector('[data-placeholder-html="true"]');
			const indexOfActiveEl = Array.from(view?.dom?.children).indexOf(activeEl);

			const coords = getNodeCoordinates({view, state}, "html", true, indexOfActiveEl);

			let transaction = tr.delete(coords.pos, coords.pos + 1);

			if (!_.isEmpty(value)) {
				const newNode = state.schema.nodes.html.create({html: value, "data-placeholder-html": false}, []);

				transaction = tr
					.insert(coords.pos, newNode)
					.setSelection(tr.selection.constructor.near(tr.doc.resolve(coords.pos + 1)));
			}

			setValue(null);
			setIsBlur(false);
			setTop(null);
			dispatch(transaction);
		};

		if (isBlur) {
			setHTMLValue();
		}
	}, [dispatch, isBlur, state, state.schema.nodes.html, tr, value, view]);

	const handleOnBlur = useCallback(() => {
		setIsBlur(true);
	}, []);

	const handleOnChange = useCallback(val => {
		setValue(val);
	}, []);

	if (_.isNull(value) && _.isNull(top)) return null;

	return (
		<div className="position-absolute embed-field card overflow-hidden" style={{top}}>
			<CodeMirror
				autoFocus
				extensions={[html()]}
				height="250px"
				value={value}
				onBlur={handleOnBlur}
				onChange={handleOnChange}
			/>
		</div>
	);
}

export function HTMLEditButton({view, state, onClick}) {
	const activeEl = view?.dom?.querySelector(".html-embed-container.ProseMirror-selectednode");
	const activeElCoord = activeEl?.offsetTop ?? 0;

	if (!activeEl) return null;

	const handleOnClick = e => {
		const indexOfActiveEl = Array.from(view?.dom?.children).indexOf(activeEl);
		const coords = getNodeCoordinates({view, state}, "html", true, indexOfActiveEl);

		if (typeof onClick === "function") {
			onClick(coords, e);
		}
	};

	return (
		<button
			className="editor-banner-btn-icon editor-html-btn position-absolute m-2 end-0"
			style={{top: activeElCoord, zIndex: 99}}
			onClick={handleOnClick}>
			<span className="icon-ico-pencil ft-18" />
		</button>
	);
}

export const HTML = new HTMLExtension();
