import {useHistory, useParams} from "react-router-dom";
import {DashboardLayout} from "components/Layout";
import {SubmitButton} from "components/Button";
import {CircleLoader} from "components/Loader";
import {FormCard} from "components/Form";
import {useDetailSediaan} from "hooks/SuperAdmin/SediaanObat";

const MasterSediaanObatDetail = () => {
	const history = useHistory();
	const {id} = useParams();
	const {data: detailSediaan, isLoading: loadingFetcing} = useDetailSediaan(id);

	if (loadingFetcing) {
		return (
			<DashboardLayout>
				<FormCard backTitle="Daftar Sediaan Obat" title="Detail Sediaan Obat">
					<CircleLoader />
				</FormCard>
			</DashboardLayout>
		);
	}

	return (
		<DashboardLayout>
			<FormCard
				backTitle="Daftar Sediaan Obat"
				title={`Detail Data Sediaan Obat ${detailSediaan?.data?.name}`}
				onBackButtonClick={() => history.push("/sediaan-obat")}>
				<div class="row gx-2 mb-5">
					<div class="col-sm-12 col-12">
						<div class="setting-item">
							<label class="setting-label ">Nama Sediaan Obat</label>
							<p className="text-bold text-grey">{detailSediaan?.data?.name}</p>
						</div>
					</div>
				</div>

				<div class="text-center my-3">
					<SubmitButton
						className="btn btn-orange d-inline-block mw-250"
						text="Ubah Data Ini"
						onClick={() => history.push(`/sediaan-obat/edit/${detailSediaan?.data?.id}`)}
					/>
				</div>
			</FormCard>
		</DashboardLayout>
	);
};

export {MasterSediaanObatDetail};
