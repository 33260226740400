export const adminAdmissionMenu = {
	top: [
		{
			id: "admin-dashboard",
			title: "Dashboard",
			icon: "icon-ico-dashboard",
			path: "/",
			submenu: [],
		},
		{
			id: "admin-pendaftaran-data-pasien",
			title: "Pendaftaran Data Pasien",
			icon: "icon-ico-dashboard",
			path: "/pendaftaran-pasien",
			submenu: [],
		},
		{
			id: "admin-appointments",
			title: "Appointments",
			icon: "icon-ico-dashboard",
			path: "/admission/appointment/list",
			submenu: [],
		},
		{
			id: "admin-checkin-patients",
			title: "Check-in Pasien",
			icon: "icon-ico-dashboard",
			path: "/admission/checkin-patients/list",
			submenu: [],
		},
	],
	bottom: [],
};
