export function CircleLoader({style = {height: "75vh"}}) {
	return (
		<div className="row" style={style}>
			<div className="d-flex justify-content-center align-items-center">
				<div className="spinner-border" role="status">
					<span className="sr-only">Loading...</span>
				</div>
			</div>
		</div>
	);
}
