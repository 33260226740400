import {FormCard} from "components/Form";
import {DashboardLayout} from "components/Layout";
import {useGroupMemberDetail, useGroupMemberListUser} from "hooks/SuperAdmin/GroupMember";
import {useMemo, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import {BasicTable, SearchBox} from "components/Table";

const GroupMemberDetailPage = () => {
	const history = useHistory();
	const {id} = useParams();
	const {data: dataDetail} = useGroupMemberDetail(id);
	const {data: dataUser, setFilter: setFilterUser, isLoading: isLoadingUser} = useGroupMemberListUser(id);
	const [dataLimit, setDataLimit] = useState(10);
	const [searchKeyword, setSearchKeyword] = useState("");

	const columns = useMemo(
		() => [
			{
				Header: "No",
				accessor: "idSerial",
			},
			{
				Header: "Name",
				accessor: "fullName",
			},
			{
				Header: "Email",
				accessor: "email",
			},
			{
				Header: "No Telepon",
				accessor: "phoneNumber",
			},
		],
		[],
	);

	const dataWithIDSerial =
		dataUser?.data?.map((item, index) => ({
			...item,
			// idSerial: (dataUser?.data?.currentPage - 1) * dataLimit + index + 1,
			idSerial: (1 - 1) * dataLimit + index + 1,
		})) || [];

	return (
		<DashboardLayout>
			<FormCard backTitle="Daftar List User" title={`Detail Data`} onBackButtonClick={history.goBack}>
				<div class="row gx-2 mb-5">
					<div class="col-sm-12 col-12">
						<div class="setting-item">
							<label class="setting-label ">Nama</label>
							<p className="text-bold text-grey">{dataDetail?.data?.name || "-"}</p>
						</div>
					</div>
					<div class="col-sm-12 col-12">
						<SearchBox
							rightElement={null}
							onSearch={keyword => {
								setSearchKeyword(keyword);
								setFilterUser({page: 1, limit: dataLimit, search: keyword});
							}}
						/>
						<BasicTable
							columns={columns}
							currentPage={dataUser?.data?.currentPage}
							data={dataWithIDSerial}
							// limit={dataLimit}
							hidePagination={true}
							loading={isLoadingUser}
							striped={true}
							title="Data User Pasien"
							totalItems={dataUser?.data?.length}
							totalPage={dataUser?.data?.totalPages}
							onLimitChange={limitValue => {
								setDataLimit(limitValue);
								setFilterUser({page: 1, limit: limitValue, search: searchKeyword});
							}}
							onPageChange={selectedPage =>
								setFilterUser({page: selectedPage, limit: dataLimit, search: searchKeyword})
							}
						/>
					</div>
				</div>
			</FormCard>
		</DashboardLayout>
	);
};

export {GroupMemberDetailPage};
