import dayjs from "dayjs";

export function isTokenExpired(state) {
	const {accessToken, accessExpired} = state.AuthReducer;

	if (!accessToken || !accessExpired) return true;

	if (accessExpired === "NEVER") return false;

	let expired = true;

	if (dayjs(accessExpired).isValid()) {
		expired = dayjs() > dayjs(accessExpired);
	}

	return accessToken && expired;
}

export const getCRUDPermissions = (create = false, read = false, update = false, destroy = false) => {
	return {
		create,
		read,
		update,
		destroy,
	};
};
