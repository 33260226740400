import {useState, useMemo} from "react";
import {useQuery} from "react-query";
import axios from "services/axios";

export async function getStaffs(id, filter) {
	const {data} = await axios.get(`/staff/full`, {params: {...filter, sortBy: "desc", orderBy: "id", hospitalId: id}});

	return data;
}

export function useStaffs(hospitalId) {
	const [filter, filterStaffs] = useState({
		page: 1,
		search: null,
		limit: 10,
		orderBy: "id",
		sortBy: "desc",
	});
	const fallback = [];
	const {
		data: staffs = fallback,
		isLoading,
		isError,
		error,
	} = useQuery(["staffs", hospitalId, filter], async () => getStaffs(hospitalId, filter));

	return {staffs, isLoading, isError, error, filter, filterStaffs, hospitalId};
}

export function useStaffsOptions() {
	const {staffs, isLoading} = useStaffs();

	const staffOptions = useMemo(() => {
		if (staffs.success) {
			return staffs?.data?.rows?.map(staff => ({
				value: staff?.id + "",
				label: staff?.name,
				desc: staff?.address,
				image: staff?.logo,
			}));
		}

		return [];
	}, [staffs]);

	return {staffOptions, isLoading};
}

// Fetch Staff By ID
export const fetchStaffByID = staffId => {
	return axios.get(`/staff/${staffId}`).then(res => res.data);
};

export function useDetailStaff(staffId) {
	return useQuery({
		queryKey: staffId && ["staffs", staffId],
		queryFn: key => fetchStaffByID(staffId),
		refetchOnMount: false,
		refetchOnWindowFocus: false,
		enabled: !!staffId,
	});
}
