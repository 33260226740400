import {useState} from "react";
import {useQuery} from "react-query";
import axios from "services/axios";

export const QUERY = "appointment";

async function getAppointments(idHospital, filter) {
	const {data} = await axios.get(`/booking`, {
		params: {
			...filter,
			sortBy: "id.desc",
			idHospital: filter.idHospital || idHospital,
			bookingType: "nurse_action",
		},
	});

	return data;
}

export function useAppointment(idHospital) {
	const [filter, filterAppointments] = useState({
		page: 1,
		search: null,
		limit: 10,
	});
	const fallback = [];
	const {
		data = fallback,
		isLoading,
		isError,
		error,
	} = useQuery([QUERY, filter], async () => getAppointments(idHospital, filter));

	return {data, isLoading, isError, error, filter, filterAppointments};
}

export const fetchAppointment = appointmentId => {
	return axios.get(`/booking/${appointmentId}`).then(res => res.data);
};

export function useDetailAppointment(appointmentId) {
	return useQuery({
		queryKey: appointmentId && [QUERY, appointmentId],
		queryFn: key => fetchAppointment(appointmentId),
		refetchOnMount: false,
		refetchOnWindowFocus: false,
		enabled: !!appointmentId,
	});
}
