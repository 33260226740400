import clsx from "clsx";
import {useHistory} from "react-router-dom";
import {Formik} from "formik";
import * as Yup from "yup";
import {DashboardLayout} from "components/Layout";
import {SubmitButton} from "components/Button";
import {useToast} from "hooks/useToast";
import {convertErrorMessageFormat} from "utils/converter";
import {useAddGroupMember, useEditGroupMember, useGroupMemberDetail} from "hooks/SuperAdmin/GroupMember";
import {useEffect, useMemo, useRef, useState} from "react";
import {useArticleUser} from "hooks/SuperAdmin/Article";
import {BasicTable, SearchBox} from "components/Table";
import {useParams} from "react-router-dom";

const FormSchemaValidation = Yup.object().shape({
	name: Yup.string().required("Nama unit wajib di isi"),
	users: Yup.array().of(Yup.number()).min(1),
});
const FormInitialValues = {name: "", users: []};

export function GroupMemberActionPage() {
	const {action, id} = useParams();
	const {showToast} = useToast();
	const history = useHistory();
	const {data: dataDetail} = useGroupMemberDetail(id);
	const {mutate: mutateAdd, isLoading: isLoadingAdd} = useAddGroupMember();
	const {mutate: mutateEdit, isLoading: isLoadingEdit} = useEditGroupMember(id);
	const formikRef = useRef();
	const handleSubmit = formData => {
		if (action === "add") {
			mutateAdd(formData, {
				onSuccess: () => {
					showToast("success", "Data berhasil ditambahkan", 3000);
					history.push("/group-member");
				},
				onError: res => {
					showToast("error", convertErrorMessageFormat(res.response.status, res.response.data.message), null);
				},
			});
		} else {
			mutateEdit(formData, {
				onSuccess: () => {
					showToast("success", "Data berhasil diubah", 3000);
					history.push("/group-member");
				},
				onError: res => {
					showToast("error", convertErrorMessageFormat(res.response.status, res.response.data.message), null);
				},
			});
		}
	};

	// Fetching User
	const [searchKeyword, setSearchKeyword] = useState("");
	const [dataLimit, setDataLimit] = useState(10);
	const {data: dataUser, isLoading: isLoadingUser, setFilter: setFilterUser} = useArticleUser("patient");
	const [selectedUser, setSelectedUser] = useState([]);

	const columns = useMemo(
		() => [
			{
				Header: "Pilih",
				accessor: "id",
				maxWidth: "20px",
				Cell: ({row}) => (
					<input
						checked={selectedUser.includes(row?.original?.id)}
						className=""
						type="checkbox"
						onChange={e => {
							if (e.target.checked) {
								setSelectedUser(prev => [...prev, row?.original?.id]);
							} else {
								setSelectedUser(prev => [...prev.filter(item => item !== row?.original?.id)]);
							}
						}}
					/>
				),
			},
			{
				Header: "No",
				accessor: "idSerial",
			},
			{
				Header: "Name",
				accessor: "fullName",
			},
			{
				Header: "Email",
				accessor: "email",
			},
			{
				Header: "No Telepon",
				accessor: "phoneNumber",
			},
		],
		[selectedUser],
	);

	const dataWithIDSerial =
		dataUser?.data?.rows?.map((item, index) => ({
			...item,
			idSerial: (dataUser?.data?.currentPage - 1) * dataLimit + index + 1,
		})) || [];

	useEffect(() => {
		formikRef.current?.setFieldValue("users", [...selectedUser]);
	}, [selectedUser]);

	useEffect(() => {
		if (dataDetail?.success) {
			formikRef.current?.setFieldValue("name", dataDetail?.data?.name);
			setSelectedUser([...dataDetail?.data?.users?.map(item => item.id)]);
		}
	}, [dataDetail]);

	return (
		<DashboardLayout>
			<Formik
				initialValues={FormInitialValues}
				innerRef={formikRef}
				validationSchema={FormSchemaValidation}
				onSubmit={handleSubmit}>
				{({handleSubmit, handleBlur, handleChange, values, errors, touched}) => (
					<div class="box-white setting-content h-100 no-border p24">
						<div class="d-flex justify-content-between flex-wrap align-items-center mb-4">
							<div class="ttl-20">{action === "edit" ? "Edit" : "Tambah"} Group Member Baru</div>
						</div>

						<hr />

						<div class="text-bold mb-4">
							<a
								class="text-dark"
								style={{cursor: "pointer"}}
								onClick={() => history.push("/group-member")}>
								<span class="icon-ico-back me-2" />
							</a>
							Daftar List Group Member
						</div>

						<div class="row gx-2 mb-5">
							<div class="col-sm-6 col-6">
								<div class="setting-item">
									<label class="setting-label required">Nama Group Member</label>
									<input
										class={clsx(
											"form-control",
											errors?.name && touched?.name && "form-control-error",
										)}
										disabled={isLoadingAdd || isLoadingEdit}
										name="name"
										placeholder=""
										type="text"
										value={values?.name}
										onBlur={handleBlur}
										onChange={handleChange}
									/>
									{errors.name && touched.name && (
										<p className="form-error-item-message">{errors.name}</p>
									)}
								</div>
							</div>
						</div>
						<div class="row gx-2 mb-5">
							<div class="col-sm-12 col-12">
								<SearchBox
									rightElement={null}
									onSearch={keyword => {
										setSearchKeyword(keyword);
										setFilterUser({page: 1, limit: dataLimit, search: keyword});
									}}
								/>
								<BasicTable
									columns={columns}
									currentPage={dataUser?.data?.currentPage}
									data={dataWithIDSerial}
									limit={dataLimit}
									loading={isLoadingUser}
									striped={true}
									title="Data User Pasien"
									totalItems={dataUser?.data?.totalItems}
									totalPage={dataUser?.data?.totalPages}
									onLimitChange={limitValue => {
										setDataLimit(limitValue);
										setFilterUser({page: 1, limit: limitValue, search: searchKeyword});
									}}
									onPageChange={selectedPage =>
										setFilterUser({page: selectedPage, limit: dataLimit, search: searchKeyword})
									}
								/>
							</div>
						</div>

						<div class="text-center my-3">
							<SubmitButton
								className="btn btn-orange d-inline-block mw-250"
								disabled={!FormSchemaValidation.isValidSync(values)}
								loading={isLoadingAdd || isLoadingEdit}
								text="Simpan"
								onClick={handleSubmit}
							/>
						</div>
					</div>
				)}
			</Formik>
		</DashboardLayout>
	);
}
