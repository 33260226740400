import {SubmitButton} from "components/Button";
import {FormCard} from "components/Form";
import {DashboardLayout} from "components/Layout";
import {CircleLoader} from "components/Loader";
import {useDetailHargaAdministrasi} from "hooks/SuperAdmin/HargaAdministrasi";
import {useHospitals} from "hooks/SuperAdmin/Hospital";
import {useHistory, useParams} from "react-router-dom";
import {convertToCurency} from "utils/converter";

export function MasterHargaAdministrasiDetailPage() {
	const {hospitals} = useHospitals(true, 100);
	const listHospital = hospitals?.data?.rows || [];

	const history = useHistory();
	const {id} = useParams();
	const {data, isLoading} = useDetailHargaAdministrasi(id);

	const hospitalName = listHospital.find(e => e.id === data?.data?.hospitalId)?.name || "";

	if (isLoading) {
		return (
			<DashboardLayout>
				<FormCard backTitle="Daftar list Harga administrasi" title="Detail Harga Administrasi">
					<CircleLoader />
				</FormCard>
			</DashboardLayout>
		);
	}

	return (
		<DashboardLayout>
			<FormCard
				backTitle="Daftar List Harga Administrasi"
				title={`Detail Data Harga Administrasi ${hospitalName}`}
				onBackButtonClick={() => history.push("/master-harga-administrasi")}>
				<div class="row gx-2 mb-5">
					<div class="col-sm-6 col-6">
						<div class="setting-item">
							<label class="setting-label ">Nama Rumah Sakit</label>
							<p className="text-bold text-grey">{hospitalName}</p>
						</div>
					</div>
					<div class="col-sm-6 col-6">
						<div class="setting-item">
							<label class="setting-label ">Harga</label>
							<p className="text-bold text-grey">Rp {convertToCurency(data?.data?.price + "")}</p>
						</div>
					</div>
				</div>

				<div class="text-center my-3">
					<SubmitButton
						className="btn btn-orange d-inline-block mw-250"
						text="Ubah Data Ini"
						onClick={() => history.push(`/master-harga-administrasi/edit/${data?.data?.id}`)}
					/>
				</div>
			</FormCard>
		</DashboardLayout>
	);
}
