import {useState} from "react";
import {useQuery} from "react-query";
import axios from "services/axios";

async function getSchedules(id, filter) {
	const {data} = await axios.get(`/schedule`, {params: filter});

	return data;
}

export function useSchedules(userId) {
	const [filter, filterSchedules] = useState({
		userId,
		page: 1,
		search: null,
		limit: 10,
	});
	const [scheduleId, scheduleById] = useState(null);
	const fallback = [];
	const {
		data = fallback,
		isLoading,
		isError,
		error,
	} = useQuery(["schedules", scheduleId, filter], async () => getSchedules(scheduleId, filter));

	return {data, isLoading, isError, error, filter, filterSchedules, scheduleById};
}

// Fetch Hospital By ID

export const fetchScheduleByID = scheduleId => {
	return axios.get(`/schedule/detail/${scheduleId}`).then(res => res.data);
};

export function useDetailSchedule(scheduleId) {
	return useQuery({
		queryKey: scheduleId && ["schedules", scheduleId],
		queryFn: key => fetchScheduleByID(scheduleId),
		refetchOnMount: true,
		refetchOnWindowFocus: true,
		enabled: !!scheduleId,
	});
}

export function useDisabledTimeOptions(userId) {
	const [filter, filterDisabledTimeOptions] = useState({
		hospitalId: "",
		userId,
	});
	const {isSuccess, data, isLoading} = useQuery(
		["schedules", filter],
		async () => await axios.get(`/schedule/get_disabled_time`, {params: {...filter, userId}}).then(res => res.data),
	);

	const disabledTimes = data?.data;
	let disabledTimesPerDay = [];

	if (data?.success) {
		disabledTimes.forEach(item => {
			let disabledHours = [];
			let disabledHourMinutes = [];

			item.disabledTimes.forEach(time => {
				let startHour = parseInt(time.startTime.split(":")[0]);
				let endHour = parseInt(time.endTime.split(":")[0]);

				while (startHour <= endHour) {
					if (
						startHour === endHour &&
						parseInt(time.endTime.split(":")[1]) - parseInt(time.startTime.split(":")[1]) < 59
					) {
						disabledHourMinutes.push({
							hour: startHour,
							minute: {
								start: parseInt(time.startTime.split(":")[1]),
								end: parseInt(time.endTime.split(":")[1]),
							},
						});
						startHour++;
					} else {
						disabledHours.push(startHour);
						startHour++;
					}
				}
			});
			disabledTimesPerDay.push({
				day: item.day,
				disabledHours,
				disabledHourMinutes,
			});
		});
	}

	return {disabledTimesPerDay, success: isSuccess, isLoading, filterDisabledTimeOptions};
}

async function getTodayPrices(filter) {
	const {data} = await axios.get(`/schedule/get_today_prices`, {params: filter});

	return data;
}

export function useTodayPrices(filter) {
	const fallback = [];
	const {
		data = fallback,
		isLoading,
		isError,
		error,
	} = useQuery(["get_today_prices", filter], async () => getTodayPrices(filter), {
		retry: false,
		retryOnMount: false,
	});

	return {data, isLoading, isError, error, filter};
}

async function getPublicSchedules(filter) {
	const {data} = await axios.get(`/schedule/get_public_schedule`, {params: filter});

	return data;
}

export function usePublicSchedule(filter) {
	const fallback = [];
	const {
		data = fallback,
		isLoading,
		isError,
		error,
	} = useQuery(["get_public_schedule", filter], async () => getPublicSchedules(filter));

	return {data, isLoading, isError, error, filter};
}

async function getAvailableSchedules(filter) {
	const {data} = await axios.get(`/schedule/get_available`, {params: filter});

	return data;
}

export function useAvailableSchedule(filter) {
	const fallback = [];
	const {
		data = fallback,
		isLoading,
		isError,
		error,
	} = useQuery(["get_available", filter], async () => getAvailableSchedules(filter));

	return {data, isLoading, isError, error, filter};
}
