import axios from "services/axios";
import {useMutation, useQueryClient} from "react-query";

function useSubmitLayananObat() {
	return useMutation(values => axios.post("/v2/vendor/submit", values).then(res => res.data));
}

function useAddLayananObat() {
	return useMutation(values => axios.post("/v2/vendor/medicine", values).then(res => res.data));
}

function useEditLayananObat() {
	const queryClient = useQueryClient();

	return useMutation(formData => axios.post("/v2/vendor/medicine/update", formData).then(res => res.data), {
		onSuccess: () => {
			queryClient.invalidateQueries("medicine_service");
			queryClient.removeQueries("medicine_service");
		},
	});
}

function useUpdatePriceLayananObat() {
	const queryClient = useQueryClient();

	return useMutation(formData => axios.post("/medicine_service/update", formData).then(res => res.data), {
		onSuccess: () => {
			queryClient.invalidateQueries("medicine_service");
			queryClient.removeQueries("medicine_service");
		},
	});
}

function useDeleteLayananObat() {
	const queryClient = useQueryClient();

	return useMutation(formData => axios.post("/v2/vendor/medicine/delete", formData).then(res => res.data), {
		onSuccess: () => {
			queryClient.invalidateQueries("medicine_service");
			queryClient.removeQueries("medicine_service");
		},
	});
}

function useApproveLayananObat() {
	const queryClient = useQueryClient();

	return useMutation(formData => axios.post("/medicine_service/approve_reject", formData).then(res => res.data), {
		onSuccess: () => {
			queryClient.invalidateQueries("medicine_service");
			queryClient.removeQueries("medicine_service");
		},
	});
}

export {
	useSubmitLayananObat,
	useAddLayananObat,
	useEditLayananObat,
	useUpdatePriceLayananObat,
	useDeleteLayananObat,
	useApproveLayananObat,
};
