import {createSlice} from "@reduxjs/toolkit";

const initialState = {
	selectedHospital: null,
};

export const hospitalSlice = createSlice({
	name: "@HOSPITAL",
	initialState,
	reducers: {
		setSelectedHospital: (state, action) => {
			state.selectedHospital = action.payload;
		},
	},
});

export const {setSelectedHospital} = hospitalSlice.actions;

export default hospitalSlice.reducer;
