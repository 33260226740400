import {DashboardLayout} from "components/Layout";
import {isMobile} from "react-device-detect";
import {useHistory, useLocation} from "react-router-dom";
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";

import {TabReportComment} from "./TabReportComment";
import {TabReportTopic} from "./TabReportTopic";
import {TabReportDiscussion} from "./TabReportDiscussion";

const findTabIndex = tab => {
	switch (tab) {
		case "discussion":
			return 1;
		case "comment":
			return 2;
		default:
			return 0;
	}
};

const findTabLabel = index => {
	switch (index) {
		case 1:
			return "discussion";
		case 2:
			return "comment";
		default:
			return "topic";
	}
};

const ForumReportListPage = () => {
	const history = useHistory();
	const location = useLocation();
	const activeTab = new URLSearchParams(location?.search).get("tab");
	const tab = findTabIndex(activeTab);

	const handleSelectTab = index => {
		history.push(`/report/forum?tab=${findTabLabel(index)}`);
	};

	return (
		<DashboardLayout>
			<Tabs className="mb-0" selectedIndex={tab} onSelect={handleSelectTab}>
				<div class="" style={isMobile ? {"overflow-x": "auto", "white-space": "nowrap"} : {}}>
					<TabList className="border-0">
						<Tab>Report Topic - Forum</Tab>
						<Tab>Report Discussion - Forum</Tab>
						<Tab>Report Comment - Forum</Tab>
					</TabList>
				</div>

				<div className="box-white setting-content h-100 no-border p24">
					<TabPanel>
						<TabReportTopic />
					</TabPanel>
					<TabPanel>
						<TabReportDiscussion />
					</TabPanel>
					<TabPanel>
						<TabReportComment />
					</TabPanel>
				</div>
			</Tabs>
		</DashboardLayout>
	);
};

export {ForumReportListPage};
