import {useHistory, useParams} from "react-router-dom";
import {DashboardLayout} from "components/Layout";
import {SubmitButton} from "components/Button";
import {CircleLoader} from "components/Loader";
import {FormCard} from "components/Form";
import {useDetailHospitalMedicine} from "hooks/SubAdminDivision/Medicine";
import {convertToCurency} from "utils/converter";

const typeEnum = {
	habis_pakai: "Habis Pakai",
	tidak_habis_pakai: "Tidak Habis Pakai",
};

export function MedicineDetailPage() {
	const history = useHistory();
	const {id} = useParams();
	const {data: detailMedicine, isLoading: loadingFetcing} = useDetailHospitalMedicine(id);

	if (loadingFetcing) {
		return (
			<DashboardLayout>
				<FormCard backTitle="Daftar list unit obat" title="Detail Obat">
					<CircleLoader />
				</FormCard>
			</DashboardLayout>
		);
	}

	return (
		<DashboardLayout>
			<FormCard
				backTitle="Daftar List Obat"
				title={`Detail Data Obat ${detailMedicine?.data?.name}`}
				onBackButtonClick={() => history.push("/sub-admin-divisi/medicine/list")}>
				<div class="row gx-2 mb-5">
					<div class="col-sm-6 col-12">
						<div class="setting-item">
							<label class="setting-label ">Kategori Obat</label>
							<p className="text-bold text-grey">{detailMedicine?.data?.medicine?.category.name}</p>
						</div>
					</div>
					<div class="col-sm-6 col-12">
						<div class="setting-item">
							<label class="setting-label ">Divisi</label>
							<p className="text-bold text-grey">{detailMedicine?.data?.division?.name}</p>
						</div>
					</div>
					<div class="col-sm-12 col-12">
						<div class="setting-item">
							<label class="setting-label ">Unit Obat</label>
							<p className="text-bold text-grey">{detailMedicine?.data?.medicine?.unit.name}</p>
						</div>
					</div>
					<div class="col-sm-12 col-12">
						<div class="setting-item">
							<label class="setting-label ">Nama Obat</label>
							<p className="text-bold text-grey">{detailMedicine?.data?.medicine?.name}</p>
						</div>
					</div>
					<div class="col-sm-12 col-12">
						<div class="setting-item">
							<label class="setting-label ">Harga Beli</label>
							<p className="text-bold text-grey">
								Rp{" "}
								{detailMedicine?.data?.buyPrice
									? convertToCurency(detailMedicine?.data?.buyPrice?.toString())
									: "-"}
							</p>
						</div>
					</div>
					<div class="col-sm-12 col-12">
						<div class="setting-item">
							<label class="setting-label ">Harga Jual</label>
							<p className="text-bold text-grey">
								Rp{" "}
								{detailMedicine?.data?.sellPrice
									? convertToCurency(detailMedicine?.data?.sellPrice?.toString())
									: "-"}
							</p>
						</div>
					</div>
					<div class="col-sm-12 col-12">
						<div class="setting-item">
							<label class="setting-label ">Stok</label>
							<p className="text-bold text-grey">{detailMedicine?.data?.stock}</p>
						</div>
					</div>
					<div class="col-sm-12 col-12">
						<div class="setting-item">
							<label class="setting-label ">Tipe</label>
							<p className="text-bold text-grey">{typeEnum[detailMedicine?.data?.type]}</p>
						</div>
					</div>
				</div>

				<div class="text-center my-3">
					<SubmitButton
						className="btn btn-orange d-inline-block mw-250"
						text="Ubah Data Ini"
						onClick={() => history.push(`/sub-admin-divisi/medicine/edit/${detailMedicine?.data?.id}`)}
					/>
				</div>
			</FormCard>
		</DashboardLayout>
	);
}
