/* eslint-disable no-unused-vars */
import {superAdminMenu} from "./SuperAdmin";
import {adminAdmissionMenu} from "./AdminAdmission";
import {adminLaboratoriumMenu} from "./AdminLaboratorium";
import {adminHospitalMenu} from "./AdminHospital";
import {dokterMenu} from "./Dokter";
import {getSubStaffAdminMenu} from "./SubStaff";
import {getSubSupervisiAdminMenu} from "./SubSupervisi";
import {getSubAdminMenu} from "./SubAdmin";
import {perawatMenu} from "./Perawat";
// export const appsMenu = {
// 	1: superAdminMenu,
// 	2: adminAdmissionMenu,
// 	3: adminLaboratoriumMenu,
// 	4: adminHospitalMenu,
// 	5: dokterMenu,
// };

export const getAppsMenu = (roleId, unitId, divisionId) => {
	switch (roleId) {
		case 1: {
			return adminHospitalMenu;
		}
		case 2: {
			return dokterMenu;
		}
		case 3: {
			return perawatMenu;
		}
		case 4: {
			return getSubAdminMenu(unitId, divisionId);
		}
		case 5: {
			return getSubStaffAdminMenu({roleId, unitId, divisionId});
		}
		case 6: {
			return getSubSupervisiAdminMenu({roleId, unitId, divisionId});
		}
		case 7: {
			return adminAdmissionMenu;
		}
		case 8: {
			return superAdminMenu;
		}
		default: {
			return {top: [], bottom: []};
		}
	}
};

// export const appsMenu = {
// 	1: adminHospitalMenu,
// 	2: dokterMenu,
// 	4: (unitId, divisionId) => getSubAdminMenu(unitId, divisionId),
// 	5: adminLaboratoriumMenu,
// 	6: adminLaboratoriumMenu,
// 	7: adminAdmissionMenu,
// 	8: superAdminMenu,
// };
