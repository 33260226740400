import axios from "services/axios";
import {useMutation, useQueryClient} from "react-query";

export function useAddUnit(options = {}) {
	return useMutation(values => axios.post("/unit/create", values).then(res => res.data));
}
export function useEditUnit(options = {}) {
	const queryClient = useQueryClient();

	return useMutation(formData => axios.post("/unit/update", formData).then(res => res.data), {
		onSuccess: () => {
			queryClient.invalidateQueries("unit");
			queryClient.removeQueries("unit");
		},
	});
}

export function useDeleteUnit(options = {}) {
	const queryClient = useQueryClient();

	return useMutation(formData => axios.post("/unit/delete", formData).then(res => res.data), {
		onSuccess: () => {
			queryClient.invalidateQueries("unit");
			queryClient.removeQueries("unit");
		},
	});
}
