export const superAdminMenu = {
	top: [
		{
			id: "admin-dashboard",
			title: "Dashboard",
			icon: "icon-ico-dashboard",
			path: "/",
			submenu: [],
		},
		{
			id: "admin-master-rumah-sakit",
			title: "Rumah Sakit",
			icon: "icon-aid-kit",
			path: "/master-rumah-sakit",
			submenu: [],
		},
		{
			id: "admin-staff",
			title: "Staff",
			icon: "icon-briefcase",
			path: "/staff",
			submenu: [],
		},
		{
			id: "admin-doctor",
			title: "Dokter",
			icon: "icon-ico-stetoskop",
			path: "/doctor",
			submenu: [],
		},
		{
			id: "admin-pendaftaran-data-pasien",
			title: "Pendaftaran Data Pasien",
			icon: "icon-ico-dashboard",
			path: "/pendaftaran-pasien",
			submenu: [],
		},
		{
			id: "admin-appointment",
			title: "Appointment",
			icon: "icon-ico-dashboard",
			path: "/appointment",
			submenu: [],
		},
		{
			id: "admin-management-file-lab",
			title: "Management File Lab",
			icon: "icon-ico-dashboard",
			path: "/file-labs",
			submenu: [],
		},
		{
			id: "admin-master-data",
			title: "Master Data",
			icon: "icon-briefcase",
			path: "",
			submenu: [
				{
					id: "admin-master-jabatan",
					title: "Master Jabatan",
					icon: "icon-briefcase",
					path: "/master-jabatan",
					submenu: [],
				},
				{
					id: "admin-master-divisi",
					title: "Divisi",
					icon: "icon-ico-dashboard",
					path: "/master-divisi",
					submenu: [],
				},
				{
					id: "admin-master-unit",
					title: "Unit",
					icon: "icon-ico-dashboard",
					path: "/master-unit",
					submenu: [],
				},
				{
					id: "admin-master-vendor",
					title: "Vendor",
					icon: "icon-ico-dashboard",
					path: "/master-vendor",
					submenu: [],
				},
				{
					id: "admin-master-poliklinik",
					title: "Master Poliklinik",
					icon: "icon-briefcase",
					path: "/master-poliklinik",
					submenu: [],
				},
				{
					id: "admin-master-diagnosa",
					title: "Master Diagnosa",
					icon: "icon-briefcase",
					path: "/master-diagnosa",
					submenu: [],
				},
				{
					id: "admin-master-kategori-pemeriksaan",
					title: "Master Kategori Pemeriksaan",
					icon: "icon-briefcase",
					path: "/master-kategori-pemeriksaan",
					submenu: [],
				},
				{
					id: "admin-master-pemeriksaan-penunjang",
					title: "Master Pemeriksaan Penunjang",
					icon: "icon-briefcase",
					path: "/master-pemeriksaan-penunjang",
					submenu: [],
				},
				{
					id: "admin-master-harga-administrasi",
					title: "Master Harga Administrasi",
					icon: "icon-briefcase",
					path: "/master-harga-administrasi",
					submenu: [],
				},
				{
					id: "kategori-obat",
					title: "Kategori Obat",
					icon: "icon-aid-kit",
					path: "/kategori-obat",
					submenu: [],
				},
				{
					id: "unit-obat",
					title: "Unit Obat",
					icon: "icon-aid-kit",
					path: "/unit-obat",
					submenu: [],
				},
				{
					id: "obat",
					title: "Obat",
					icon: "icon-aid-kit",
					path: "/obat",
					submenu: [],
				},
				{
					id: "sediaan-obat",
					title: "Sediaan Obat",
					icon: "icon-aid-kit",
					path: "/sediaan-obat",
					submenu: [],
				},
				{
					id: "layanan-obat",
					title: "Layanan Obat",
					icon: "icon-aid-kit",
					path: "/layanan-obat",
					submenu: [],
				},
				{
					id: "harga-pendaftaran-obat",
					title: "Harga Pendaftaran Obat",
					icon: "icon-aid-kit",
					path: "/harga-pendaftaran-obat",
					submenu: [],
				},
				{
					id: "kategori-tindakan",
					title: "Kategori Tindakan",
					icon: "icon-aid-kit",
					path: "/kategori-tindakan",
					submenu: [],
				},
				{
					id: "tindakan",
					title: "Tindakan",
					icon: "icon-aid-kit",
					path: "/tindakan",
					submenu: [],
				},
				{
					id: "tag",
					title: "Tag",
					icon: "icon-aid-kit",
					path: "/tag",
					submenu: [],
				},
				{
					id: "forum-category",
					title: "Kategori Forum",
					icon: "icon-aid-kit",
					path: "/forum-category",
					submenu: [],
				},
			],
		},
		{
			id: "admin-status",
			title: "Status",
			icon: "icon-briefcase",
			path: "",
			submenu: [
				{
					id: "report",
					title: "Report",
					icon: "icon-briefcase",
					path: "/report-status",
					submenu: [],
				},
			],
		},
		{
			id: "admin-article",
			title: "Article",
			icon: "icon-briefcase",
			path: "",
			submenu: [
				{
					id: "tag-post",
					title: "Tag Post",
					icon: "icon-briefcase",
					path: "/tag-post",
					submenu: [],
				},
				{
					id: "report",
					title: "Report",
					icon: "icon-briefcase",
					path: "/report",
					submenu: [],
				},
				{
					id: "post",
					title: "Post",
					icon: "icon-briefcase",
					path: "/post",
					submenu: [],
				},
				{
					id: "setting-indicator-report",
					title: "Setting Indicator Report",
					icon: "icon-briefcase",
					path: "/setting-indicator-report",
					submenu: [],
				},
			],
		},
		{
			id: "admin-forum",
			title: "Forum",
			icon: "icon-briefcase",
			path: "",
			submenu: [
				{
					id: "report",
					title: "Report",
					icon: "icon-briefcase",
					path: "/report/forum",
					submenu: [],
				},
			],
		},
		{
			id: "admin-settings",
			title: "Settings",
			icon: "icon-ico-setting",
			path: "",
			submenu: [
				{
					id: "admin-settings-form",
					title: "Form",
					icon: "icon-ico-setting",
					path: "/form",
					submenu: [],
				},
				{
					id: "admin-settings-banner",
					title: "Banner",
					icon: "icon-ico-setting",
					path: "/banner",
					submenu: [],
				},
			],
		},
		{
			id: "article-user",
			title: "User",
			icon: "icon-briefcase",
			path: "/user",
			submenu: [],
		},
		{
			id: "group-member",
			title: "Group Member",
			icon: "icon-briefcase",
			path: "/group-member",
			submenu: [],
		},
	],
	bottom: [],
};
