/* eslint-disable no-unused-vars */
import {useState, useRef, useEffect} from "react";
import clsx from "clsx";
import {useHistory, useParams} from "react-router-dom";
import {Formik, Field, FieldArray} from "formik";
import * as Yup from "yup";
import {SubmitButton} from "components/Button";
import {useToast} from "hooks/useToast";
import {convertErrorMessageFormat} from "utils/converter";
import {BasicDropdown} from "components/Dropdown";
import {useHospitalsOptions} from "hooks/SuperAdmin/Hospital";
import {useKonsultasiOptions} from "hooks/SuperAdmin/Konsultasi";
import {useAddScheduleWork} from "hooks/Doctor/ScheduleWork";
import {Collapse} from "react-collapse";
import {TimePicker} from "components/TimePicker";
import {convertToCurency} from "utils/converter";

const FormSchemaValidation = Yup.object().shape({
	type: Yup.string().required("Tipe konsultasi wajib di isi"),
	hospitalId: Yup.string(),
	consultationId: Yup.string().nullable(),
	startDate: Yup.string().required("Tanggal mulai wajib di isi"),
	endDate: Yup.string()
		.required("Tanggal selesai wajib di isi")
		.test("lessThanStartDate", "Tanggal selesai tidak boleh mendahului tanggal mulai", function (value) {
			const startDate = new Date(this.parent.startDate);
			const endDate = new Date(value);

			if (endDate < startDate) return false;

			return true;
		}),
	times: Yup.array().of(
		Yup.object().shape({
			startTime: Yup.string().required("Jam mulai wajib di isi"),
			endTime: Yup.string()
				.required("Jam selesai wajib di isi")
				.test("lessThanStartTime", "Jam selesai tidak boleh mendahului jam mulai", function (value) {
					const startTimeHour = parseInt(this.parent.startTime?.split(":")[0]);
					const startTimeMinute = parseInt(this.parent.startTime?.split(":")[1]);
					const endTimeHour = parseInt(value?.split(":")[0]);
					const endTimeMinute = parseInt(value?.split(":")[1]);

					if (endTimeHour < startTimeHour) return false;
					if (endTimeHour === startTimeHour && endTimeMinute < startTimeMinute) return false;

					return true;
				}),
			quota: Yup.string().required("Kuota wajib di isi"),
		}),
	),
	prices: Yup.array().of(
		Yup.object().shape({
			price: Yup.string().required("Harga wajib di isi"),
		}),
	),
});
const FormInitialValues = {
	userId: "",
	type: "1",
	hospitalId: "",
	consultationId: null,
	startDate: "",
	endDate: "",
	times: [{startTime: "", endTime: "", quota: ""}],
	prices: [{price: "", str_price: ""}],
};

export function ScheduleWorkAddPage({userId, availableHospital, setTab}) {
	const [collapseOpen, setCollapseOpen] = useState(null);
	const {showToast} = useToast();
	const history = useHistory();
	const {mutate, isLoading} = useAddScheduleWork();
	const [locationOptions, setLocationOptions] = useState([]);
	const [consultationOptions, setConsultationOptions] = useState([]);
	const formikRef = useRef();

	const {hospitalOptions: locations, isLoading: isLoadingLocationOptions} = useHospitalsOptions();
	const {data: consultations, isLoading: isLoadingConsultationOptions} = useKonsultasiOptions();

	const handleSubmit = formData => {
		if (formData.type === "1") formData.hospitalId = undefined;
		mutate(formData, {
			onSuccess: () => {
				showToast("success", "Data berhasil ditambahkan", 3000);
				setTab(1);
			},
			onError: res => {
				showToast("error", convertErrorMessageFormat(res.response.status, res.response.data.message), null);
			},
		});
	};

	useEffect(() => {
		formikRef?.current?.setFieldValue("userId", userId);
	}, [userId]);

	useEffect(() => {
		if (locations.length) {
			const hospital = locations[0].value;
			const dataLocations = locations.filter(location => availableHospital.includes(parseInt(location.value)));

			setLocationOptions([...dataLocations]);
			formikRef?.current.setFieldValue("hospitalId", hospital);
		}
	}, [locations, availableHospital]);

	useEffect(() => {
		if (consultations?.success) {
			const dataConsultationOptions = consultations?.data?.rows?.map(consultation => ({
				value: consultation?.id + "",
				label: `${consultation?.name}`,
			}));

			setConsultationOptions([...dataConsultationOptions]);
			formikRef?.current.setFieldValue("consultationId", dataConsultationOptions[0]?.value);
		}
	}, [consultations]);

	const getEndTimeDisabledHour = startTime => {
		let hours = [];

		if (startTime) {
			const startHour = parseInt(startTime.split(":")[0]);
			let countHour = 0;

			while (countHour < startHour) {
				hours.push(countHour++);
			}
		}

		return hours;
	};

	return (
		<Formik
			initialValues={FormInitialValues}
			innerRef={formikRef}
			validationSchema={FormSchemaValidation}
			onSubmit={handleSubmit}>
			{({
				handleSubmit,
				handleBlur,
				handleChange,
				values,
				errors,
				touched,
				setFieldValue,
				setFieldTouched,
				setFieldError,
			}) => (
				<div class="box-white setting-content h-100 no-border p24">
					<div class="d-flex justify-content-between flex-wrap align-items-center mb-4">
						<div class="ttl-20">Buat Jadwal Spesial - Masuk</div>
					</div>

					<hr />

					<div class="text-bold mb-4">
						<a class="text-dark" style={{cursor: "pointer"}} onClick={() => history.push("/doctor")}>
							<span class="icon-ico-back me-2" />
						</a>
						Daftar List Dokter
					</div>

					<div class="row gx-2 mb-5">
						<div class="col-sm-6 col-6">
							<div class="setting-item">
								<label class="setting-label required">Tipe Konsultasi</label>
								<div className="col-sm-6 col-6">
									<label>
										<Field
											className="me-2"
											name="type"
											type="radio"
											value="1"
											onChange={() => {
												setFieldValue("type", "1");
												setFieldTouched("type", true);
												setFieldValue("hospitalId", null);
											}}
										/>
										Online
									</label>
								</div>
								<div class="col-sm-6 col-6">
									<label>
										<Field
											className="me-2"
											name="type"
											type="radio"
											value="2"
											onChange={() => {
												setFieldValue("type", "2");
												setFieldTouched("type", true);
												setFieldValue("hospitalId", locationOptions?.[0]?.value);
											}}
										/>
										Offline
									</label>
								</div>
							</div>
						</div>
						<div className={values.type === "1" ? "d-none" : "col-sm-6 col-6"}>
							<div class="setting-item">
								<label class="setting-label">Lokasi Praktik</label>
								<BasicDropdown
									className={clsx("form-control", errors?.hospitalId && "form-control-error")}
									data={locationOptions}
									disabled={values.type === "1"}
									name={`hospitalId`}
									value={values?.hospitalId}
									onSelect={fieldValue => {
										setFieldValue(`hospitalId`, fieldValue);
									}}
								/>
								{errors?.hospitalId && touched?.hospitalId && (
									<p className="form-error-item-message">{errors?.hospitalId}</p>
								)}
							</div>
						</div>
						<div className="col-sm-6 col-6 d-none">
							<div class="setting-item">
								<label class="setting-label required">Pilih Konsultasi</label>
								<BasicDropdown
									className={clsx("form-control", errors?.consultationId && "form-control-error")}
									data={consultationOptions}
									name={`consultationId`}
									value={values?.consultationId}
									onSelect={fieldValue => {
										setFieldValue(`consultationId`, fieldValue);
									}}
								/>
								{errors?.consultationId && touched?.consultationId && (
									<p className="form-error-item-message">{errors?.consultationId}</p>
								)}
							</div>
						</div>
					</div>

					<div id="accordion">
						<div class="card mt-5">
							<div
								class="card-header d-flex justify-content-between"
								id="headingOne"
								style={{cursor: "pointer"}}
								onClick={() => setCollapseOpen(!collapseOpen)}>
								<h3 class="mb-0">
									<button className="btn" onClick={() => setCollapseOpen(!collapseOpen)}>
										Jadwal Praktik
									</button>
								</h3>
								<h3 style={{"margin-top": "-10px"}}>&#8964;</h3>
							</div>
							<Collapse isOpened={collapseOpen}>
								<div>
									<div class="card-body">
										<div class="row gx-2 mb-5">
											<div class="col-sm-6 col-6">
												<div class="setting-item">
													<label class="setting-label required">Tanggal Mulai</label>
													<input
														class={clsx(
															"form-control",
															errors?.startDate &&
																touched?.startDate &&
																"form-control-error",
														)}
														name="startDate"
														placeholder=""
														type="date"
														value={values?.startDate}
														onBlur={handleBlur}
														onChange={handleChange}
													/>
													{errors.startDate && touched?.startDate && (
														<p className="form-error-item-message">{errors.startDate}</p>
													)}
												</div>
											</div>
											<div class="col-sm-6 col-6">
												<div class="setting-item">
													<label class="setting-label required">Tanggal Selesai</label>
													<input
														class={clsx(
															"form-control",
															errors?.endDate && touched?.endDate && "form-control-error",
														)}
														min={values?.startDate}
														name="endDate"
														placeholder=""
														type="date"
														value={values?.endDate}
														onBlur={handleBlur}
														onChange={handleChange}
													/>
													{errors.endDate && touched?.endDate && (
														<p className="form-error-item-message">{errors.endDate}</p>
													)}
												</div>
											</div>
											<div class="col-sm-12 col-12">
												<div class="setting-item">
													<FieldArray name="times">
														{({insert, remove, push}) => (
															<div>
																{values.times.length > 0 &&
																	values.times.map((price, index) => (
																		<div key={index} className="row">
																			<div className="col-3">
																				<div class="setting-item">
																					<label class="setting-label required">
																						Jam Mulai
																					</label>
																					<TimePicker
																						disabled={isLoading}
																						value={
																							values?.times?.[index]
																								.startTime
																						}
																						onChange={fieldValue => {
																							setFieldTouched(
																								`times.${index}.startTime`,
																								true,
																							);
																							setFieldValue(
																								`times.${index}.startTime`,
																								fieldValue?.format(
																									"HH:mm",
																								),
																							);
																						}}
																					/>
																					{errors?.times?.[index]
																						?.startTime &&
																						touched?.times?.[index]
																							?.startTime && (
																							<p className="form-error-item-message">
																								{
																									errors?.times?.[
																										index
																									]?.startTime
																								}
																							</p>
																						)}
																				</div>
																			</div>
																			<div className="col-3">
																				<div class="setting-item">
																					<label class="setting-label required">
																						Jam Selesai
																					</label>
																					<TimePicker
																						disabled={isLoading}
																						disabledHours={getEndTimeDisabledHour(
																							values?.times?.[index]
																								.startTime,
																						)}
																						disabledMinuteByStart={
																							values?.times?.[index]
																								.startTime
																						}
																						value={
																							values?.times?.[index]
																								.endTime
																						}
																						onChange={fieldValue => {
																							setFieldTouched(
																								`times.${index}.endTime`,
																								true,
																							);
																							setFieldValue(
																								`times.${index}.endTime`,
																								fieldValue?.format(
																									"HH:mm",
																								),
																							);
																						}}
																					/>
																					{errors?.times?.[index]?.endTime &&
																						touched?.times?.[index]
																							?.endTime && (
																							<p className="form-error-item-message">
																								{
																									errors?.times?.[
																										index
																									]?.endTime
																								}
																							</p>
																						)}
																				</div>
																			</div>
																			<div className="col-3">
																				<div class="setting-item">
																					<label class="setting-label required">
																						Kuota
																					</label>
																					<input
																						class={clsx(
																							"form-control",
																							errors?.times?.[index]
																								?.quota &&
																								touched?.times?.[index]
																									?.quota &&
																								"form-control-error",
																						)}
																						disabled={isLoading}
																						name={`times.${index}.quota`}
																						placeholder=""
																						type="text"
																						value={
																							values?.times?.[index]
																								?.quota
																						}
																						onBlur={handleBlur}
																						onChange={e => {
																							const value =
																								e.target.value.replace(
																									/\D/g,
																									"",
																								);

																							setFieldValue(
																								`times.${index}.quota`,
																								value,
																							);
																						}}
																					/>
																					{errors?.times?.[index]?.quota &&
																						touched?.times?.[index]
																							?.quota && (
																							<p className="form-error-item-message">
																								{
																									errors?.times?.[
																										index
																									]?.quota
																								}
																							</p>
																						)}
																				</div>
																			</div>
																			<div className="col-2 row align-items-center">
																				<a
																					className="col-2"
																					style={{
																						cursor: "pointer",
																						fontSize: "24px",
																					}}
																					onClick={() =>
																						push({
																							startTime: "",
																							endTime: "",
																							quota: "",
																						})
																					}>
																					<span class="icon-ico-plus" />
																				</a>
																				<a
																					className={
																						index > 0 ? "col-2" : "d-none"
																					}
																					style={{
																						cursor: "pointer",
																						fontSize: "24px",
																					}}
																					onClick={() => {
																						if (index) remove(index);
																					}}>
																					<span class="icon-ico-delete" />
																				</a>
																			</div>
																		</div>
																	))}
															</div>
														)}
													</FieldArray>
												</div>
											</div>
											<div class="col-sm-12 col-12">
												<div class="setting-item">
													<FieldArray name="prices">
														{({insert, remove, push}) => (
															<div>
																{values.prices.length > 0 &&
																	values.prices.map((price, index) => (
																		<div key={index} className="row">
																			<div className="col-3">
																				<div class="setting-item">
																					<label class="setting-label required">
																						Harga
																					</label>
																					<input
																						class={clsx(
																							"form-control",
																							errors?.prices?.[index]
																								?.startTime &&
																								touched?.prices?.[index]
																									?.price &&
																								"form-control-error",
																						)}
																						disabled={isLoading}
																						name={`prices.${index}.str_price`}
																						placeholder=""
																						type="text"
																						value={
																							values?.prices?.[index]
																								?.str_price
																						}
																						onBlur={handleBlur}
																						onChange={e => {
																							let value =
																								e.target.value.replace(
																									/\D/g,
																									"",
																								);

																							setFieldValue(
																								`prices.${index}.price`,
																								value,
																							);

																							value =
																								convertToCurency(value);

																							setFieldValue(
																								`prices.${index}.str_price`,
																								value,
																							);
																						}}
																					/>
																					{errors?.prices?.[index]?.price &&
																						touched?.prices?.[index]
																							?.price && (
																							<p className="form-error-item-message">
																								{
																									errors?.prices?.[
																										index
																									]?.price
																								}
																							</p>
																						)}
																				</div>
																			</div>
																			<div className="col-2 row align-items-center">
																				<a
																					className="col-2"
																					style={{
																						cursor: "pointer",
																						fontSize: "24px",
																					}}
																					onClick={() =>
																						push({
																							price: "",
																							str_price: "",
																						})
																					}>
																					<span class="icon-ico-plus" />
																				</a>
																				<a
																					className={
																						index > 0 ? "col-2" : "d-none"
																					}
																					style={{
																						cursor: "pointer",
																						fontSize: "24px",
																					}}
																					onClick={() => {
																						if (index) remove(index);
																					}}>
																					<span class="icon-ico-delete" />
																				</a>
																			</div>
																		</div>
																	))}
															</div>
														)}
													</FieldArray>
												</div>
											</div>
										</div>
									</div>
								</div>
							</Collapse>
						</div>
					</div>
					<div class="text-center my-3">
						<SubmitButton
							className="btn btn-orange d-inline-block mw-250"
							disabled={!FormSchemaValidation.isValidSync(values)}
							loading={isLoading}
							text="Simpan"
							onClick={handleSubmit}
						/>
					</div>
				</div>
			)}
		</Formik>
	);
}
