import axios from "services/axios";
import {useMutation, useQueryClient} from "react-query";

import {QUERY_KATEGORI_PEMERIKSAAN} from "./useQueryKategoriPemeriksaan";

export function useAddKategoriPemeriksaan() {
	return useMutation(values => axios.post("/checkup_category/create", values).then(res => res.data));
}
export function useUpdateKategoriPemeriksaan() {
	const queryClient = useQueryClient();

	return useMutation(formData => axios.post("/checkup_category/update", formData).then(res => res.data), {
		onSuccess: () => {
			queryClient.invalidateQueries(QUERY_KATEGORI_PEMERIKSAAN);
		},
	});
}

export function useDeleteKategoriPemeriksaan() {
	const queryClient = useQueryClient();

	return useMutation(formData => axios.post("/checkup_category/delete", formData).then(res => res.data), {
		onSuccess: () => {
			queryClient.invalidateQueries(QUERY_KATEGORI_PEMERIKSAAN);
		},
	});
}

export function useChangeStatusKategoriPemeriksaan() {
	const queryClient = useQueryClient();

	return useMutation(
		formData => axios.post("/checkup_category/change_status_checkup_category", formData).then(res => res.data),
		{
			onSuccess: () => {
				queryClient.invalidateQueries(QUERY_KATEGORI_PEMERIKSAAN);
			},
		},
	);
}
