import {useState} from "react";
import {useQuery} from "react-query";
import axios from "services/axios";

async function getActions(id, filter) {
	if (!id) {
		const {data} = await axios.get(`/action`, {params: {...filter, sortBy: "id.desc"}});

		return data;
	}
	const {data} = await axios.get(`/action/${id}`);

	return data;
}

export function useAction() {
	const [filter, filterActions] = useState({
		page: 1,
		search: null,
		limit: 10,
	});
	const [actionId, actionById] = useState(null);
	const fallback = [];
	const {
		data = fallback,
		isLoading,
		isError,
		error,
	} = useQuery(["action", filter, actionId], async () => getActions(actionId, filter));

	return {data, isLoading, isError, error, filter, filterActions, actionById};
}

export const fetchAction = actionId => {
	return axios.get(`/action/${actionId}`).then(res => res.data);
};

export function useDetailAction(actionId) {
	return useQuery({
		queryKey: actionId && ["action", actionId],
		queryFn: key => fetchAction(actionId),
		refetchOnMount: false,
		refetchOnWindowFocus: false,
		enabled: !!actionId,
	});
}

// options

export function useActionOptions() {
	const result = useQuery(["actionOptions"], async () => await axios.get(`/action`).then(res => res.data));

	return result;
}
