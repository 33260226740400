import {useQuery} from "react-query";
import axios from "services/axios";
import qs from "query-string";

export const QUERY_DIAGNOSA = "diagnosis";

export function useListDiagnosa(filter) {
	const result = useQuery([QUERY_DIAGNOSA, filter], () =>
		axios.get(`/diagnosis?${qs.stringify(filter)}`).then(res => res.data),
	);

	return result;
}

export const fetchDiagnosaByID = id => {
	return axios.get(`/diagnosis/${id}`).then(res => res.data);
};

export function useDetailDiagnosa(id) {
	return useQuery({
		queryKey: id && [QUERY_DIAGNOSA, id],
		queryFn: () => fetchDiagnosaByID(id),
		refetchOnMount: false,
		refetchOnWindowFocus: false,
		enabled: !!id,
	});
}
