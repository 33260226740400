import {useState} from "react";
import {useHistory} from "react-router-dom";
import * as Yup from "yup";
import {Formik} from "formik";
import clsx from "clsx";
import {SubmitButton} from "components/Button";
import {loadLocalAssets} from "utils/loader";
import {useForgotPassword, useForgotPasswordByOtp} from "hooks/Auth";

const FormSchemaValidation = Yup.object().shape({
	email: Yup.string().required("Email atau nomor telepon wajib di isi"),
});
const FormInitialValues = {email: ""};

export function ForgotPassword() {
	const [successSendEmail, setSuccessSendEmail] = useState(false);
	const history = useHistory();
	const {mutate, isLoading} = useForgotPassword();
	const {mutate: forgotPassworbByOtp, isLoading: isLoadingForgotPassworbByOtp} = useForgotPasswordByOtp();
	const [errorSubmit, setErrorSubmit] = useState("");
	const handleSubmit = formData => {
		const emailSchema = Yup.object().shape({
			email: Yup.string().email(),
		});

		const isEmail = emailSchema.isValidSync(formData);

		if (isEmail) {
			mutate(formData, {
				onSuccess: () => {
					setSuccessSendEmail(true);
				},
				onError: res => {
					setErrorSubmit("Email yang Anda masukkan tidak terdaftar");
				},
			});
		} else {
			let phone_number = formData.email;

			if (phone_number.substring(0, 1) === "0") phone_number = phone_number.replace("0", "");
			else if (phone_number.substring(0, 1) === "+62") phone_number = phone_number.replace("+62", "");

			forgotPassworbByOtp(
				{phone_number},
				{
					onSuccess: () => {
						history.push(`/auth/verification-otp`);
					},
					onError: res => {
						setErrorSubmit("Email atau nomor telepon yang Anda masukkan tidak terdaftar");
					},
				},
			);
		}
	};

	return (
		<div className="change-password-wrapper">
			<div className="form-main">
				<div className="inner-fm">
					<img alt="logo" className="img-fluid mb-5" src={loadLocalAssets("img/logo.svg")} />
					{!successSendEmail && (
						<Formik
							initialValues={FormInitialValues}
							validationSchema={FormSchemaValidation}
							onSubmit={handleSubmit}>
							{({handleSubmit, handleBlur, handleChange, values, errors}) => (
								<>
									<div className="ttl-28 mb-4">Lupa Kata Sandi</div>
									<div className="label-fl mb-2">
										Masukkan alamat email Anda dan kami akan mengirimkan instruksi untuk mereset
										kata sandi
									</div>
									<div className="form-login">
										<div className="label-fl mb-2">Email Atau Nomor Telepon</div>
										<input
											className={clsx("form-control", errors?.email && "form-control-error")}
											disabled={isLoading}
											name="email"
											placeholder=""
											type="email"
											value={values?.email}
											onBlur={handleBlur}
											onChange={handleChange}
										/>
										{errors.email && <p className="form-error-item-message">{errors.email}</p>}
										{errorSubmit && <p className="form-error-item-message">{errorSubmit}</p>}
									</div>
									<SubmitButton
										className="btn btn-orange w-100"
										disabled={!FormSchemaValidation.isValidSync(values)}
										loading={isLoading || isLoadingForgotPassworbByOtp}
										text="Kirim"
										onClick={handleSubmit}
									/>
								</>
							)}
						</Formik>
					)}

					{successSendEmail && (
						<>
							<div className="ttl-28 mb-4">Lupa Kata Sandi</div>
							<div className="label-fl mb-2">
								Silakan periksa email Anda kami telah mengirim instruksi untuk merubah kata sandi
							</div>
							<SubmitButton
								className="btn btn-orange w-100"
								text="Oke"
								onClick={() => history.push("/auth/login")}
							/>
						</>
					)}
				</div>
			</div>
			<div className="img-main" style={{backgroundImage: `url(${loadLocalAssets("img/bg-login.jpg")})`}} />
		</div>
	);
}
