import axios from "services/axios";
import {useMutation} from "react-query";

export function useLogin(options = {}) {
	return useMutation(values => axios.post("/auth/login", values).then(res => res.data));
}

export function useLoginAs() {
	return useMutation(values => axios.get(`/patient/${values}/login_as`).then(res => res.data));
}
