import {useMutation, useQueryClient} from "react-query";
import axios from "services/axios";

export function useAddForumCategory(options = {}) {
	return useMutation(values => axios.post("/forum-categories", values).then(res => res.data));
}
export function useEditForumCategory(id, options = {}) {
	const queryClient = useQueryClient();

	return useMutation(formData => axios.put(`/forum-categories/${id}`, formData).then(res => res.data), {
		onSuccess: () => {
			queryClient.invalidateQueries("forum-categories");
			queryClient.removeQueries("forum-categories");
		},
	});
}

export function useDeleteForumCategory(id, options = {}) {
	const queryClient = useQueryClient();

	return useMutation(() => axios.delete(`/forum-categories/${id}`).then(res => res.data), {
		onSuccess: () => {
			queryClient.invalidateQueries("forum-categories");
			queryClient.removeQueries("forum-categories");
		},
	});
}
