import {createContext, useReducer} from "react";

export const ToastStateContext = createContext();

function ToastReducer(state, action) {
	switch (action.type) {
		case "SHOW_TOAST": {
			return {
				...state,
				showToast: true,
				timeOut: action.payload.timeOut,
				message: action.payload.message,
				type: action.payload.type,
			};
		}
		case "HIDE_TOAST": {
			return {
				...state,
				showToast: false,
			};
		}
		default: {
			throw new Error("unhandled action");
		}
	}
}

export function ToastProvider({children}) {
	const [state, dispatch] = useReducer(ToastReducer, {
		showToast: false,
		timeOut: 3000,
		message: null,
		type: "danger",
	});

	return <ToastStateContext.Provider value={[state, dispatch]}>{children}</ToastStateContext.Provider>;
}
