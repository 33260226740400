/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/jsx-key */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import {useState, useRef, useEffect, useCallback} from "react";
import {useHistory, useParams} from "react-router-dom";
import clsx from "clsx";
import {Formik, FieldArray} from "formik";
import * as Yup from "yup";
import {DashboardLayout} from "components/Layout";
import {ModalConfirmation} from "components/Modal";
import {useToast} from "hooks/useToast";
import {convertErrorMessageFormat} from "utils/converter";
import {TimePicker} from "components/TimePicker";
import {useEditScheduleHoliday, useDetailScheduleHoliday, useDeleteScheduleHoliday} from "hooks/Doctor/ScheduleHoliday";
import {useSelector} from "react-redux";

const FormSchemaValidation = Yup.object().shape({
	startDate: Yup.string().required("Tanggal mulai wajib di isi"),
	endDate: Yup.string()
		.required("Tanggal selesai wajib di isi")
		.test("lessThanStartDate", "Tanggal selesai tidak boleh mendahului tanggal mulai", function (value) {
			const startDate = new Date(this.parent.startDate);
			const endDate = new Date(value);

			if (endDate < startDate) return false;

			return true;
		}),
	times: Yup.array().of(
		Yup.object().shape({
			startTime: Yup.string(),
			endTime: Yup.string().test(
				"lessThanStartTime",
				"Jam selesai tidak boleh mendahului jam mulai",
				function (value) {
					const startTimeHour = parseInt(this.parent.startTime?.split(":")[0]);
					const startTimeMinute = parseInt(this.parent.startTime?.split(":")[1]);
					const endTimeHour = parseInt(value?.split(":")[0]);
					const endTimeMinute = parseInt(value?.split(":")[1]);

					if (endTimeHour < startTimeHour) return false;
					if (endTimeHour === startTimeHour && endTimeMinute < startTimeMinute) return false;

					return true;
				},
			),
		}),
	),
	deleteTimes: Yup.array().of(Yup.number()),
	description: Yup.string(),
});
const FormInitialValues = {
	id: "",
	userId: "",
	startDate: "",
	endDate: "",
	times: [{startTime: "", endTime: ""}],
	keterangan: "",
	deleteTimes: [],
};

export function ScheduleSpecialHolidayEditPage() {
	const history = useHistory();
	const userProfile = useSelector(state => state.AuthReducer.profile);
	const {id} = useParams();

	const [disabledHours, setDisabledHours] = useState([]);
	const [disabledHourMinutes, setDisabledHourMinutes] = useState([]);
	const {showToast} = useToast();
	// const history = useHistory();
	const {mutate, isLoading} = useEditScheduleHoliday();
	const {mutate: deleteSchedule, isLoading: isDeletingData} = useDeleteScheduleHoliday();
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const formikRef = useRef();
	const {data: detailScheduleWork} = useDetailScheduleHoliday(id);

	const handleSubmit = formData => {
		if (formData.type === "1") formData.hospitalId = undefined;
		mutate(formData, {
			onSuccess: () => {
				showToast("success", "Jadwal spesial libut berhasil diubah", 3000);
				history.push("/doctor/schedule/list");
			},
			onError: res => {
				showToast("error", convertErrorMessageFormat(res.response.status, res.response.data.message), null);
			},
		});
	};

	const handleDeleteData = () => {
		deleteSchedule(
			{id},
			{
				onSuccess: () => {
					history.push("/doctor/schedule/list");
					setShowDeleteModal(false);
					showToast("success", `Jadwal spesial masuk berhasil dihapus`, 3000);
				},
				onError: res => {
					history.push("/doctor/schedule/list");
					showToast("error", convertErrorMessageFormat(res.response.status, res.response.data.message), null);
				},
			},
		);
	};

	useEffect(() => {
		if (detailScheduleWork?.success) {
			const {ScheduleHolidayTimes} = detailScheduleWork?.data;
			const detailData = detailScheduleWork?.data;

			Object.keys(FormInitialValues).forEach(key => {
				formikRef?.current.setFieldValue(key, detailData?.[key]);
				if (key === "startDate")
					formikRef?.current.setFieldValue(key, new Date(detailData?.[key]).toISOString().split("T")[0]);
				if (key === "endDate")
					formikRef?.current.setFieldValue(key, new Date(detailData?.[key]).toISOString().split("T")[0]);
			});
			formikRef?.current.setFieldValue("times", []);
			formikRef?.current.setFieldValue("deleteTimes", []);

			ScheduleHolidayTimes &&
				ScheduleHolidayTimes.forEach((item, index) => {
					formikRef?.current.setFieldValue(`times.${index}.id`, item.id);
					formikRef?.current.setFieldTouched(`times.${index}.id`, true);
					formikRef?.current.setFieldValue(`times.${index}.startTime`, item.startTime);
					formikRef?.current.setFieldTouched(`times.${index}.startTime`, true);
					formikRef?.current.setFieldValue(`times.${index}.endTime`, item.endTime);
					formikRef?.current.setFieldTouched(`times.${index}.endTime`, true);
				});
		}
	}, [detailScheduleWork, id]);

	useEffect(() => {
		formikRef?.current?.setFieldValue("userId", parseInt(userProfile.id) || null);
	}, [userProfile]);

	useEffect(() => {
		formikRef?.current?.setFieldValue("id", id);
	}, [id]);

	return (
		<>
			<ModalConfirmation
				approveLabel="Ya"
				description={`Apakah anda yakin ingin menghapus jadwal spesial masuk ini?`}
				loading={isDeletingData}
				rejectLabel="Batal"
				visible={showDeleteModal}
				onApprove={handleDeleteData}
				onClose={() => setShowDeleteModal(false)}
				onReject={() => setShowDeleteModal(false)}
			/>
			<DashboardLayout backTo={"/doctor/schedule/list"} title="Buat Jadwal Spesial Masuk">
				<Formik
					initialValues={FormInitialValues}
					innerRef={formikRef}
					validationSchema={FormSchemaValidation}
					onSubmit={handleSubmit}>
					{({
						handleSubmit,
						handleBlur,
						handleChange,
						values,
						errors,
						touched,
						setFieldValue,
						setFieldTouched,
					}) => (
						<div className="ws p15 mb-3">
							<div className="row">
								<div className="col-xl-8">
									<div className="box-white ws p24 mb-3">
										<div className="row">
											<div className="col-sm-6 mb-3">
												<div className="ft-12 text-light-grey mb-2">Tanggal Mulai</div>
												<div className="form-filter position-relative">
													<input
														className={clsx(
															"form-control",
															errors?.startDate &&
																touched?.startDate &&
																"form-control-error",
														)}
														name="startDate"
														placeholder=""
														type="date"
														value={values?.startDate}
														onBlur={handleBlur}
														onChange={handleChange}
													/>
													{errors.startDate && touched.startDate && (
														<p className="form-error-item-message">{errors.startDate}</p>
													)}
												</div>
											</div>
											<div className="col-sm-6 mb-3">
												<div className="ft-12 text-light-grey mb-2">Tanggal Selesai</div>
												<div className="form-filter position-relative">
													<input
														className={clsx(
															"form-control",
															errors?.startDate &&
																touched?.endDate &&
																"form-control-error",
														)}
														min={values?.startDate}
														name="endDate"
														placeholder=""
														type="date"
														value={values?.endDate}
														onBlur={handleBlur}
														onChange={handleChange}
													/>
													{errors.endDate && touched.endDate && (
														<p className="form-error-item-message">{errors.endDate}</p>
													)}
												</div>
											</div>
										</div>

										<div className="collapse show" id="col-2">
											<div className="inner-jadwal">
												<FieldArray name="times">
													{({insert, remove, push}) => (
														<>
															{values?.times.length > 0 &&
																values?.times.map((time, timeIndex) => (
																	<div className="row gx-2">
																		<div className="col-sm-5 mb-2">
																			<div className="ft-12 text-light-grey mb-2">
																				Jam Mulai
																			</div>
																			<div className="position-relative">
																				<TimePicker
																					disabled={isLoading}
																					disabledHourMunites={
																						disabledHourMinutes
																					}
																					disabledHours={disabledHours}
																					placeholder={
																						values?.times?.[timeIndex]
																							.startTime
																					}
																					styleTopIcon={25}
																					value={
																						values?.times?.[timeIndex]
																							.startTime
																					}
																					onChange={fieldValue => {
																						setFieldTouched(
																							`times.${timeIndex}.startTime`,
																							true,
																						);
																						setFieldValue(
																							`times.${timeIndex}.startTime`,
																							fieldValue?.format("HH:mm"),
																						);
																					}}
																				/>
																				{errors?.times?.[timeIndex]
																					?.startTime &&
																					touched?.times?.[timeIndex]
																						?.startTime && (
																						<p className="form-error-item-message">
																							{
																								errors?.times?.[
																									timeIndex
																								]?.startTime
																							}
																						</p>
																					)}
																			</div>
																		</div>
																		<div className="col-sm-5 mb-2">
																			<div className="ft-12 text-light-grey mb-2">
																				Jam Selesai
																			</div>
																			<div className="position-relative">
																				<TimePicker
																					disabled={isLoading}
																					disabledHourMunites={
																						disabledHourMinutes
																					}
																					disabledHours={disabledHours}
																					placeholder={
																						values?.times?.[timeIndex]
																							.endTime
																					}
																					styleTopIcon={25}
																					value={
																						values?.times?.[timeIndex]
																							.endTime
																					}
																					onChange={fieldValue => {
																						setFieldTouched(
																							`times.${timeIndex}.endTime`,
																							true,
																						);
																						setFieldValue(
																							`times.${timeIndex}.endTime`,
																							fieldValue?.format("HH:mm"),
																						);
																					}}
																				/>
																				{errors?.times?.[timeIndex]?.endTime &&
																					touched?.times?.[timeIndex]
																						?.endTime && (
																						<p className="form-error-item-message">
																							{
																								errors?.times?.[
																									timeIndex
																								]?.endTime
																							}
																						</p>
																					)}
																			</div>
																		</div>
																		<div className="col-sm-2 mb-2 d-flex align-items-center">
																			<div className="d-flex">
																				<button
																					className={`btn ft-18 p-1 text-grey ${
																						timeIndex < 1 ? "hidden" : ""
																					}`}
																					onClick={() => {
																						if (timeIndex) {
																							remove(timeIndex);
																							if (time.id) {
																								setFieldValue(
																									"deleteTimes",
																									[
																										...values.deleteTimes,
																										time.id,
																									],
																								);
																							}
																						}
																					}}>
																					<span className="icon-ico-delete" />
																				</button>
																				<button
																					className="btn ft-18 p-1 text-orange"
																					onClick={() =>
																						push({
																							startTime: "",
																							endTime: "",
																						})
																					}>
																					<span className="icon-ico-plus" />
																				</button>
																			</div>
																		</div>
																	</div>
																))}
														</>
													)}
												</FieldArray>
											</div>
										</div>

										<div className="col-sm-12">
											<div className="ft-12 text-light-grey mb-2">Keterangan</div>
											<textarea
												className="form-control"
												name="keterangan"
												placeholder="Cth : Libur hari raya"
												rows="4"
												value={values?.keterangan}
												onBlur={handleBlur}
												onChange={handleChange}
											/>
										</div>
									</div>
								</div>
								<div className="col-xl-4">
									<div className="box-white ws p24 mb-3">
										Pastikan tanggal libur yang anda pilih sudah benar
									</div>

									<button
										className="btn btn-orange w-100 r8 mb-3"
										disabled={!FormSchemaValidation.isValidSync(values)}
										loading={isLoading}
										text="Save"
										onClick={handleSubmit}>
										Simpan
									</button>
									<button
										className="btn btn-grey-border w-100 r8 mb-3"
										onClick={() => setShowDeleteModal(true)}>
										Hapus
									</button>
								</div>
							</div>
						</div>
					)}
				</Formik>
			</DashboardLayout>
		</>
	);
}
