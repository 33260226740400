import axios from "services/axios";
import {useMutation, useQueryClient} from "react-query";

export function useAddJabatan(options = {}) {
	return useMutation(values => axios.post("/position/create", values).then(res => res.data));
}
export function useEditJabatan(options = {}) {
	const queryClient = useQueryClient();

	return useMutation(formData => axios.post("/position/update", formData).then(res => res.data), {
		onSuccess: () => {
			queryClient.invalidateQueries("jabatan");
			queryClient.removeQueries("jabatan");
		},
	});
}

export function useDeleteJabatan(options = {}) {
	const queryClient = useQueryClient();

	return useMutation(formData => axios.post("/position/delete", formData).then(res => res.data), {
		onSuccess: () => {
			queryClient.invalidateQueries("jabatan");
			queryClient.removeQueries("jabatan");
		},
	});
}
