import axios from "services/axios";
import {useMutation, useQueryClient} from "react-query";

import {QUERY_INDICATOR_REPORT} from "./useQueryIndicatorReport";

export function useAddIndicatorReport() {
	return useMutation(values => axios.post("/indicator-report/create", values).then(res => res.data));
}
export function useEditIndicatorReport() {
	const queryClient = useQueryClient();

	return useMutation(formData => axios.post("/indicator-report/update", formData).then(res => res.data), {
		onSuccess: () => {
			queryClient.invalidateQueries(QUERY_INDICATOR_REPORT);
			queryClient.removeQueries(QUERY_INDICATOR_REPORT);
		},
	});
}

export function useDeleteIndicatorReport() {
	const queryClient = useQueryClient();

	return useMutation(formData => axios.post("/indicator-report/delete", formData).then(res => res.data), {
		onSuccess: () => {
			queryClient.invalidateQueries(QUERY_INDICATOR_REPORT);
			queryClient.removeQueries(QUERY_INDICATOR_REPORT);
		},
	});
}
