import {useState} from "react";
import {useQuery} from "react-query";
import axios from "services/axios";
import qs from "query-string";

async function getDivisions(id, filter) {
	if (!id) {
		const {data} = await axios.get(`/division`, {params: {...filter, sortBy: "id.desc"}});

		return data;
	}
	const {data} = await axios.get(`/division/${id}`);

	return data;
}

export const QUERY = "divisi";
export function useListDivisi(filter) {
	return useQuery([QUERY, filter], () => axios.get(`/division/?${qs.stringify(filter)}`).then(res => res.data));
}

export function useDivision() {
	const [filter, filterDivisions] = useState({
		page: 1,
		search: null,
		limit: 10,
	});
	const [divisiId, divisiById] = useState(null);
	const fallback = [];
	const {
		data = fallback,
		isLoading,
		isError,
		error,
	} = useQuery(["divisi", filter, divisiId], async () => getDivisions(divisiId, filter));

	return {data, isLoading, isError, error, filter, filterDivisions, divisiById};
}

export const QUERY_OPTIONS = "divisi-options";
export function useDivisionOptions(filter) {
	const result = useQuery(
		[QUERY_OPTIONS, filter],
		async () => await axios.get(`/division`, {params: filter}).then(res => res.data),
	);

	return result;
}

// GET POSITION BY ID

export const fetchDivision = divisiId => {
	return axios.get(`division/${divisiId}`).then(res => res.data);
};

export function useDetailDivision(divisiId) {
	return useQuery({
		queryKey: divisiId && ["divisi", divisiId],
		queryFn: key => fetchDivision(divisiId),
		refetchOnMount: false,
		refetchOnWindowFocus: false,
		enabled: !!divisiId,
	});
}

export async function getDivisionsByHospital(hospitalId) {
	const {data} = await axios.get(`/division`, {params: {hospitalId, isActive: true}});

	return data;
}
