export const adminHospitalMenu = {
	top: [
		{
			id: "admin-dashboard",
			title: "Dashboard",
			icon: "icon-ico-dashboard",
			path: "/",
			submenu: [],
		},
		{
			id: "admin-staff",
			title: "Staff",
			icon: "icon-briefcase",
			path: "/hospital/staff",
			submenu: [],
		},
		{
			id: "admin-doctor",
			title: "Dokter",
			icon: "icon-briefcase",
			path: "/hospital/doctor",
			submenu: [],
		},
		{
			id: "admin-master-data",
			title: "Master Data",
			icon: "icon-briefcase",
			path: "",
			submenu: [
				{
					id: "/hospital/jabatan/list",
					title: "Jabatan",
					icon: "icon-ico-dashboard",
					path: "/hospital/jabatan/list",
					submenu: [],
				},
				{
					id: "/hospital/poliklinik/list",
					title: "Poliklinik",
					icon: "icon-ico-dashboard",
					path: "/hospital/poliklinik/list",
					submenu: [],
				},
				{
					id: "/hospital/divisi/list",
					title: "Divisi",
					icon: "icon-ico-dashboard",
					path: "/hospital/divisi/list",
					submenu: [],
				},
				{
					id: "/hospital/unit/list",
					title: "Unit",
					icon: "icon-ico-dashboard",
					path: "/hospital/unit/list",
					submenu: [],
				},
				{
					id: "/hospital/diagnosa/list",
					title: "Diagnosa",
					icon: "icon-ico-dashboard",
					path: "/hospital/diagnosa/list",
					submenu: [],
				},
				{
					id: "/hospital/checkup-category/list",
					title: "Kategori Pemeriksaan",
					icon: "icon-ico-dashboard",
					path: "/hospital/checkup-category/list",
					submenu: [],
				},
				{
					id: "/hospital/checkup-support/list",
					title: "Pemeriksaan Penunjang",
					icon: "icon-ico-dashboard",
					path: "/hospital/checkup-support/list",
					submenu: [],
				},
				{
					id: "/hospital/kategori-obat/list",
					title: "Kategori Obat",
					icon: "icon-ico-dashboard",
					path: "/hospital/kategori-obat/list",
					submenu: [],
				},
				{
					id: "/hospital/unit-obat/list",
					title: "Unit Obat",
					icon: "icon-ico-dashboard",
					path: "/hospital/unit-obat/list",
					submenu: [],
				},
				{
					id: "/hospital/obat/list",
					title: "Obat",
					icon: "icon-ico-dashboard",
					path: "/hospital/obat/list",
					submenu: [],
				},
				{
					id: "sediaan-obat",
					title: "Sediaan Obat",
					icon: "icon-aid-kit",
					path: "/sediaan-obat",
					submenu: [],
				},
				{
					id: "/harga-pendaftaran-obat",
					title: "Harga Pendaftaran Obat",
					icon: "icon-ico-dashboard",
					path: "/harga-pendaftaran-obat",
					submenu: [],
				},
				{
					id: "/layanan-obat",
					title: "Layanan Obat",
					icon: "icon-ico-dashboard",
					path: "/layanan-obat",
					submenu: [],
				},
				{
					id: "/hospital/kategori-tindakan/list",
					title: "Kategori Tindakan",
					icon: "icon-ico-dashboard",
					path: "/hospital/kategori-tindakan/list",
					submenu: [],
				},
				{
					id: "/hospital/tindakan/list",
					title: "Tindakan",
					icon: "icon-ico-dashboard",
					path: "/hospital/tindakan/list",
					submenu: [],
				},
			],
		},
		{
			id: "admin-master-manajemen-produk",
			title: "Manajemen Produk",
			icon: "icon-briefcase",
			path: "",
			submenu: [
				{
					id: "/hospital/service-checkup-support/list",
					title: "Layanan Pemeriksaan Penunjang",
					icon: "icon-ico-dashboard",
					path: "/hospital/service-checkup-support/list",
					submenu: [],
				},
				// {
				// 	id: "/hospital/service-medicine/list",
				// 	title: "Layanan Obat/Apotik",
				// 	icon: "icon-ico-dashboard",
				// 	path: "/hospital/service-medicine/list",
				// 	submenu: [],
				// },
				{
					id: "/hospital/layanan-poliklinik-tindakan/list",
					title: "Layanan Poliklinik Tindakan",
					icon: "icon-ico-dashboard",
					path: "/hospital/layanan-poliklinik-tindakan/list",
					submenu: [],
				},
			],
		},
		// {
		// 	id: "admin-hospital-consultation-list",
		// 	title: "Konsultasi",
		// 	icon: "icon-ico-dashboard",
		// 	path: "/hospital/consultation/list",
		// 	submenu: [], //TODO: TEMPORARY_DISABLED, ASK TO FARHAN FOR NEXT DECISION
		// },
		{
			id: "admin-hospital-harga-administrasi-list",
			title: "Harga Administrasi",
			icon: "icon-ico-dashboard",
			path: "/hospital/harga-administrasi/list",
			submenu: [],
		},
		{
			id: "admin-hospital-pendaftaran-data-pasien",
			title: "Pendaftaran Data Pasien",
			icon: "icon-ico-dashboard",
			path: "/pendaftaran-pasien",
			submenu: [],
		},
	],
	bottom: [],
};
