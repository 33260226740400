import {Route, Redirect, useLocation} from "react-router-dom";
import PropTypes from "prop-types";
import {useSelector} from "react-redux";

const adminBlackListRoute = ["/auth/login"];

export default function RouteWrapper({component: Component, isPrivate, ...rest}) {
	const authReducer = useSelector(state => state.AuthReducer);
	const persistedUser = {...authReducer, token: authReducer.accessToken};

	const signed = persistedUser.token || undefined;
	const location = useLocation();
	const {pathname} = location;

	/**
	 * Redirect user to signIn pages if he tries to access a private      route
	 * without emailAuth.
	 */

	if (isPrivate && !signed) {
		return <Redirect to="/auth/login" />;
	}

	/**
	 * Redirect user to Main pages if he tries to access a non private route
	 * (signIn or SignUp) after being authenticated.
	 */
	if (adminBlackListRoute.includes(pathname) && signed) {
		return <Redirect to="/" />;
	}

	/**
	 * If not included on both previous cases, redirect user to the desired route.
	 */
	return <Route {...rest} component={Component} />;
}

RouteWrapper.propTypes = {
	isPrivate: PropTypes.bool,
	component: PropTypes.func.isRequired,
	rest: PropTypes.object,
};
RouteWrapper.defaultProps = {
	isPrivate: false,
	rest: {},
};
