import axios from "services/axios";
import {useMutation, useQueryClient} from "react-query";

import {QUERY_DIAGNOSA} from "./useQueryDiagnosa";

export function useAddDiagnosa() {
	return useMutation(values => axios.post("/diagnosis/create", values).then(res => res.data));
}
export function useUpdateDiagnosa() {
	const queryClient = useQueryClient();

	return useMutation(formData => axios.post("/diagnosis/update", formData).then(res => res.data), {
		onSuccess: () => {
			queryClient.invalidateQueries(QUERY_DIAGNOSA);
		},
	});
}

export function useDeleteDiagnosa() {
	const queryClient = useQueryClient();

	return useMutation(formData => axios.post("/diagnosis/delete", formData).then(res => res.data), {
		onSuccess: () => {
			queryClient.invalidateQueries(QUERY_DIAGNOSA);
		},
	});
}

export function useChangeStatusDiagnosa() {
	const queryClient = useQueryClient();

	return useMutation(formData => axios.post("/diagnosis/change_status_diagnosis", formData).then(res => res.data), {
		onSuccess: () => {
			queryClient.invalidateQueries(QUERY_DIAGNOSA);
		},
	});
}
