import {useState} from "react";
import {useQuery} from "react-query";
import axios from "services/axios";

async function getMedicineUnits(id, filter) {
	if (!id) {
		const {data} = await axios.get(`/medicine/unit`, {params: {...filter, sortBy: "id.desc"}});

		return data;
	}
	const {data} = await axios.get(`/medicine/unit/${id}`);

	return data;
}

export function useMedicineUnit() {
	const [filter, filterMedicineUnits] = useState({
		page: 1,
		search: null,
		limit: 10,
	});
	const [medicineUnitId, medicineUnitById] = useState(null);
	const fallback = [];
	const {
		data = fallback,
		isLoading,
		isError,
		error,
	} = useQuery(["medicineUnit", filter, medicineUnitId], async () => getMedicineUnits(medicineUnitId, filter));

	return {data, isLoading, isError, error, filter, filterMedicineUnits, medicineUnitById};
}

export const fetchMedicineUnit = medicineUnitId => {
	return axios.get(`/medicine/unit/${medicineUnitId}`).then(res => res.data);
};

export function useDetailMedicineUnit(medicineUnitId) {
	return useQuery({
		queryKey: medicineUnitId && ["medicineUnit", medicineUnitId],
		queryFn: key => fetchMedicineUnit(medicineUnitId),
		refetchOnMount: false,
		refetchOnWindowFocus: false,
		enabled: !!medicineUnitId,
	});
}

export function useMedicineUnitOptions() {
	const result = useQuery(
		["medicineUnitOptions"],
		async () => await axios.get(`/medicine/unit`).then(res => res.data),
	);

	return result;
}
