import Config from "config/env";

/**
 * Usage example:
 * loadLocalAssets('img/logo.svg'); -> "http://localhost:3000/img/logo.svg"
 */
export function loadLocalAssets(assetsName = null) {
	if (assetsName) {
		if (process.env.NODE_ENV === "development") return `http://localhost:3100/${assetsName}`;

		return `${Config.endpoint.APP_ENDPOINT}/${assetsName}`;
	}

	return null;
}
