import clsx from "clsx";
import {ButtonAddTable} from "components/Button";
import {DashboardLayout} from "components/Layout";
import {ModalConfirmation} from "components/Modal";
import {BasicTable, SearchBox} from "components/Table";
import {useUpdateLayananObat} from "hooks/AdminHospital/LayananObat";
import {useListHospitalMedicine} from "hooks/AdminHospital/Medicine";
import {useToast} from "hooks/useToast";
import {useMemo, useState} from "react";
import {Button} from "react-bootstrap";
import {isMobile} from "react-device-detect";
import {BsThreeDotsVertical} from "react-icons/bs";
import {useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {convertErrorMessageFormat} from "utils/converter";

export const getStatusApprovalText = status => {
	if (status === "approved") {
		return (
			<Button className="ft-13" disabled={true} size="sm" variant="success">
				Disetujui
			</Button>
		);
	}
	if (status === "rejected") {
		return (
			<Button className="ft-13" disabled={true} size="sm" variant="danger">
				Ditolak
			</Button>
		);
	}

	return (
		<Button className="ft-13" disabled={true} size="sm" variant="secondary">
			Menunggu Persetujuan
		</Button>
	);
};

export function LayananObatListPage() {
	const {showToast} = useToast();
	const history = useHistory();

	const selectedHospital = useSelector(state => state.HospitalsReducer.selectedHospital);

	const [filter, setFilter] = useState({
		page: 1,
		search: "",
		limit: 10,
		sortBy: "id.desc",
		hospitalId: selectedHospital?.Hospital?.id,
	});

	const {isLoading, data: medicines} = useListHospitalMedicine(filter);

	const [selectedItem, setSelectedItem] = useState(null);

	const [approvalChoice, setApprovalChoice] = useState(null);
	const [showApprovalModal, setShowApproval] = useState(false);
	const [showActionMobile, setShowActionMobile] = useState(null);

	const approvalMutation = useUpdateLayananObat();

	const handleClickApproveOrReject = (data, choice) => {
		setSelectedItem(data);
		setApprovalChoice(choice);
		setShowApproval(true);

		if (showActionMobile) setShowActionMobile(null);
	};

	const handleApproval = () => {
		approvalMutation.mutate(
			{
				id: selectedItem.id,
				statusApproval: approvalChoice === 1 ? "approved" : "rejected",
			},
			{
				onSuccess: () => {
					showToast("success", "Data persetujuan layanan obat berhasil diperbarui", 3000);
					window.scrollTo(0, 0);
					setShowApproval(false);
				},
				onError: res => {
					showToast("error", convertErrorMessageFormat(res.response.status, res.response.data.message), null);
				},
			},
		);
	};

	const actionColumnRenderer = row => {
		return (
			<div className="d-block d-md-flex align-items-center ">
				<div className="d-flex align-items-center justify-content-around">
					<a
						className="text-orange wait-pay"
						style={{cursor: "pointer"}}
						onClick={() => history.push(`/hospital/service-medicine/show/${row?.values?.id}`)}>
						<span className="icon-ico-eye-open ft-18" />
					</a>
					<a
						className="text-orange wait-pay"
						style={{cursor: "pointer"}}
						onClick={() => history.push(`/hospital/service-medicine/edit/${row?.values?.id}`)}>
						<span className="icon-ico-pencil ft-18" />
					</a>
				</div>
			</div>
		);
	};

	const approvalColumnRenderer = row => {
		if (row.values.statusApproval !== "pending") return getStatusApprovalText(row.original.statusApproval);

		return (
			<div className="d-flex gap-1">
				<Button
					className="ft-13"
					size="sm"
					variant="success"
					onClick={() => handleClickApproveOrReject(row.original, 1)}>
					Setuju
				</Button>
				<Button
					className="ft-13"
					size="sm"
					variant="danger"
					onClick={() => handleClickApproveOrReject(row.original, 0)}>
					Tolak
				</Button>
			</div>
		);
	};

	const columns = useMemo(
		() => [
			{
				Header: "No",
				accessor: "idSerial",
			},
			{
				Header: "Kategori Obat",
				accessor: "medicine.category.name",
			},
			{
				Header: "Nama Obat",
				accessor: "medicine.name",
			},
			{
				Header: "Harga Beli",
				accessor: "buyPrice",
			},
			{
				Header: "Harga Jual",
				accessor: "sellPrice",
			},
			{
				Header: "Stok",
				accessor: "stock",
			},
			{
				Header: "Status",
				accessor: "statusApproval",
				Cell: ({row}) => approvalColumnRenderer(row),
			},
			{
				Header: "Aksi",
				accessor: "id",
				Cell: ({row}) => {
					return actionColumnRenderer(row);
				},
			},
		],
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[],
	);

	const columnsMobile = useMemo(
		() => [
			{
				Header: "Nama Obat",
				accessor: "medicine.name",
				Cell: ({row}) => (
					<div className="col-6">
						<span>{row.original.medicine.name}</span>
					</div>
				),
			},
			{
				Header: "Status",
				accessor: "statusApproval",
				Cell: ({row}) => {
					return (
						<div className="col-5">
							<span>{getStatusApprovalText(row?.values?.statusApproval)}</span>
						</div>
					);
				},
			},
			{
				Header: "Aksi",
				accessor: "id",
				Cell: ({row}) => {
					return (
						<div className="col-1">
							<div class="dropdown dropstart">
								<span
									style={{padding: "5px"}}
									onClick={() => {
										if (showActionMobile !== null) {
											setShowActionMobile(null);
										} else {
											setShowActionMobile(row.original.id);
										}
									}}>
									<BsThreeDotsVertical />
								</span>

								<ul class={clsx("dropdown-menu", showActionMobile === row.original.id && "show")}>
									{row.original.statusApproval === "pending" && (
										<>
											<li>
												<a
													class="dropdown-item text-table-medium"
													data-placement="top"
													data-toggle="tooltip"
													style={{cursor: "pointer"}}
													title="Setuju"
													onClick={() => handleClickApproveOrReject(row.original, 1)}>
													Setuju
												</a>
											</li>
											<li>
												<a
													class="dropdown-item text-table-medium"
													data-placement="top"
													data-toggle="tooltip"
													style={{cursor: "pointer"}}
													title="Tolak"
													onClick={() => handleClickApproveOrReject(row.original, 0)}>
													Tolak
												</a>
											</li>
										</>
									)}
									<li>
										<a
											class="dropdown-item text-table-medium"
											data-placement="top"
											data-toggle="tooltip"
											style={{cursor: "pointer"}}
											title="Edit"
											onClick={() =>
												history.push("/hospital/service-medicine/edit/" + row.original.id)
											}>
											Edit
										</a>
									</li>
									<li>
										<a
											class="dropdown-item text-table-medium"
											data-placement="top"
											data-toggle="tooltip"
											style={{cursor: "pointer"}}
											title="Detail"
											onClick={() =>
												history.push("/hospital/service-medicine/show/" + row.original.id)
											}>
											Detail
										</a>
									</li>
								</ul>
							</div>
						</div>
					);
				},
			},
		],
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[showActionMobile],
	);

	const rows =
		medicines?.data?.rows?.map((item, index) => ({
			...item,
			idSerial: Math.max(medicines?.data?.currentPage - 1, 0) * filter.limit + index + 1,
		})) || [];

	return (
		<>
			{showApprovalModal && (
				<ModalConfirmation
					approveLabel="Ya"
					description={`Apakah Anda yakin ingin ${
						approvalChoice === 1 ? "menyetujui" : "menolak"
					} layanan obat "${selectedItem.medicine.name}"`}
					loading={approvalMutation.isLoading}
					rejectLabel="Batal"
					title={approvalChoice === 1 ? "Konfirmasi Persetujuan" : "Konfirmasi Penolakan"}
					visible={showApprovalModal}
					onApprove={handleApproval}
					onClose={() => setShowApproval(false)}
					onReject={() => setShowApproval(false)}
				/>
			)}

			<DashboardLayout>
				<div class="text-bold text-dark mb-4">Data Layanan Obat/Apotik - {selectedHospital.Hospital.name}</div>
				<SearchBox
					rightElement={
						<ButtonAddTable
							text="Tambah Layanan"
							onClick={() => history.push("/hospital/service-medicine/tambah")}
						/>
					}
					onSearch={keyword => {
						setFilter(prev => ({...prev, search: keyword}));
					}}
				/>

				<BasicTable
					columns={isMobile ? columnsMobile : columns}
					currentPage={medicines?.data?.currentPage || 1}
					data={rows}
					limit={filter.limit}
					loading={isLoading}
					striped={true}
					title="Data Staff"
					totalItems={medicines?.data?.totalItems}
					totalPage={medicines?.data?.totalPages}
					onLimitChange={limit => {
						setFilter(prev => ({...prev, limit}));
					}}
					onPageChange={selectedPage => {
						setFilter(prev => ({...prev, page: selectedPage}));
					}}
				/>
			</DashboardLayout>
		</>
	);
}
