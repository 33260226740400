import {useState} from "react";

const useDisclosure = defaultValue => {
	const [isOpen, setIsOpen] = useState(defaultValue || false);

	const onOpen = () => setIsOpen(true);
	const onClose = () => setIsOpen(false);

	const onToggle = () => setIsOpen(!isOpen);

	return {isOpen, onOpen, onClose, onToggle};
};

export default useDisclosure;
