import Cleave from "cleave.js/react";
import clsx from "clsx";
import {SubmitButton} from "components/Button";
import {BasicDropdown} from "components/Dropdown";
import {FieldArray} from "formik";
import {useListDivisionById} from "hooks/AdminHospital/Division";
import {useListAvailableMedicine} from "hooks/AdminHospital/Medicine";
import {useMedicineCategoryOptions} from "hooks/AdminHospital/MedicineCategory";
import {useHospitalsOptions} from "hooks/SuperAdmin/Hospital";
import {useVendorOptions} from "hooks/SuperAdmin/LayananObat";
import {useDetailMedicine} from "hooks/SuperAdmin/Medicine";
import {useMedicineUnitOptions} from "hooks/SuperAdmin/MedicineUnit";
import {useSediaanOptions} from "hooks/SuperAdmin/SediaanObat";
import {useEffect, useMemo, useState} from "react";
import {Col, Row} from "react-bootstrap";
import {useSelector} from "react-redux";
import {loadLocalAssets} from "utils/loader";

const EMPTY_FUNC = () => {};
let isDirty = false;

const LayananInformasiObat = ({
	action,
	data = null,
	handleSubmit = EMPTY_FUNC,
	FormSchemaValidation,
	values,
	errors,
	touched,
	setFieldValue,
	setFieldError,
}) => {
	const userData = useSelector(state => state.HospitalsReducer.selectedHospital);

	const {vendorOptions, isLoading: isLoadingVendorOptions} = useVendorOptions(action !== "detail");
	const {hospitalOptions, isLoading: isLoadingHospitalOptions} = useHospitalsOptions(
		action !== "detail",
		userData?.roleId === 1,
	);
	const {data: divisions, isLoading: isLoadingDivisions} = useListDivisionById(
		{
			hospitalId: values?.hospitalId,
			isActive: true,
		},
		action !== "detail",
	);
	const [divisionOptions, setDivisionOptions] = useState([]);
	const {data: medicineCategory, isLoading: isLoadingMedicineCategory} = useMedicineCategoryOptions(
		{
			hospitalId: values?.hospitalId,
		},
		action !== "detail",
	);
	const [medicineCategoryOptions, setMedicineCategoryOptions] = useState([]);

	const [medicineOptions, setMedicineOptions] = useState([]);
	const {data: medicineQuery, isLoading: medicineQueryLoading} = useListAvailableMedicine(
		values?.hospitalId,
		{
			limit: null,
			isActive: true,
			medicineCategoryId: values?.categoryId,
		},
		{
			enabled: action !== "detail" && !!values?.categoryId,
		},
	);
	const {data: unitQuery} = useMedicineUnitOptions();
	const {data: sediaanQuery} = useSediaanOptions();
	const unitOptions = useMemo(() => {
		if (!unitQuery?.success) return [];

		const list = unitQuery?.data?.rows?.map(o => ({
			value: o?.id,
			label: `${o?.name}`,
		}));

		return list;
	}, [unitQuery]);

	const sediaanOptions = useMemo(() => {
		if (!sediaanQuery?.success) return [];

		const list = sediaanQuery?.data?.rows?.map(o => ({
			value: o?.id,
			label: `${o?.name}`,
		}));

		return list;
	}, [sediaanQuery]);

	const {data: detailMedicine} = useDetailMedicine(values?.medicineId, action !== "detail");

	const handleUploadLogo = (e, idx) => {
		const file = e.target.files[0];

		if (!file) return;
		if (!file.type.includes("image")) {
			setFieldError(`details.${idx}.icon`, "Format image tidak support, hanya menerima format .JPG .JPEG .PNG");

			return;
		}
		if (file.size > 5242880) {
			setFieldError(`details.${idx}.icon`, "Ukuran file untuk image maksimal 5 MB");

			return;
		}

		setFieldValue(`details.${idx}.icon`, file);
	};

	const handleViewImage = val => {
		if (!val) return loadLocalAssets("img/drugs.jpg");
		if (typeof val === "object") {
			return URL.createObjectURL(val);
		} else {
			return val;
		}
	};

	useEffect(() => {
		if (divisions?.success) {
			const list = divisions?.data?.rows?.map(d => ({
				value: d?.id + "",
				label: `${d?.name}`,
			}));

			setDivisionOptions([...list]);
		}
	}, [divisions]);

	useEffect(() => {
		if (medicineCategory?.success) {
			const list = medicineCategory?.data?.rows?.map(d => ({
				value: d?.id + "",
				label: `${d?.name}`,
			}));

			setMedicineCategoryOptions([...list]);
		}
	}, [medicineCategory]);

	useEffect(() => {
		if (values?.categoryId && medicineQuery?.success) {
			const list = medicineQuery.data?.rows
				?.filter(e => e.category.id === parseInt(values?.categoryId))
				.map(e => ({label: e.name, value: e.id}));

			setMedicineOptions(list);
		}
	}, [medicineQuery, values?.categoryId]);

	useEffect(() => {
		if (detailMedicine?.success && isDirty) {
			for (let i = 0; i < detailMedicine?.data?.sediaan?.length; i++) {
				const sediaanId = detailMedicine?.data?.sediaan?.[i]?.sediaanId;
				const unitId = detailMedicine?.data?.unitV2?.[i]?.unitId;

				setFieldValue(`details.${i}.sediaanId`, sediaanId);
				setFieldValue(`details.${i}.unitId`, unitId);
				setFieldValue(`details.${i}.qty`, 0);
				setFieldValue(`details.${i}.price`, 0);
			}
		}
	}, [detailMedicine]);

	useEffect(() => {
		if (data?.success) {
			setFieldValue("vendorId", data?.data?.vendorId);
			setFieldValue("hospitalId", data?.data?.hospitalId);
			setFieldValue("divisionId", data?.data?.divisionId);
			setFieldValue("categoryId", data?.data?.categoryId);
			setFieldValue("medicineId", data?.data?.medicineId);
			setFieldValue("description", data?.data?.description);
			setFieldValue("createdBy", data?.data?.createdBy);

			for (let i = 0; i < data?.data?.vendorMedicine?.length; i++) {
				const vendorMedicine = data?.data?.vendorMedicine?.[i];

				setFieldValue(`details.${i}`, {
					id: vendorMedicine?.id,
					icon: vendorMedicine?.icon || null,
					sediaanId: vendorMedicine?.sediaanId,
					unitId: vendorMedicine?.unitId,
					qty: vendorMedicine?.qty,
					price: vendorMedicine?.price,
					sellPrice: vendorMedicine?.sellPrice || vendorMedicine?.price,
				});
			}
		}

		return () => {
			isDirty = false;
		};
	}, [data]);

	return (
		<>
			<div className="text-bold text-black text-decoration-underline mb-4">Informasi Obat</div>
			<div className="row gx-2 mb-3">
				<div className="col-sm-6 col-6">
					<div className="setting-item">
						<label className="setting-label">Vendor</label>
						{action === "detail" ? (
							<div className="text-bold text-grey">
								{data?.data?.vendorId
									? vendorOptions?.find(x => x.value === data.data.vendorId.toString())?.label || "-"
									: "-"}
							</div>
						) : (
							<>
								<BasicDropdown
									className={clsx("form-control", errors?.vendorId && "form-control-error")}
									data={[{label: "", value: ""}, ...vendorOptions] || []}
									disabled={
										isLoadingVendorOptions ||
										(action !== "tambah" && data?.data?.createdBy === "vendor")
									}
									name="vendorId"
									value={values?.vendorId}
									onSelect={fieldValue => {
										setFieldValue("vendorId", fieldValue?.toString());
										isDirty = true;
									}}
								/>
								{errors?.vendorId && touched?.vendorId && (
									<p className="form-error-item-message">{errors.vendorId}</p>
								)}
							</>
						)}
					</div>
					<div className="setting-item">
						<label className={clsx("setting-label", {required: action !== "detail"})}>Rumah Sakit</label>
						{action === "detail" ? (
							<div className="text-bold text-grey">{data?.data?.hospital?.name}</div>
						) : (
							<>
								<BasicDropdown
									className={clsx("form-control", errors?.hospitalId && "form-control-error")}
									data={[{label: "", value: ""}, ...hospitalOptions] || []}
									disabled={
										isLoadingHospitalOptions ||
										(action !== "tambah" && data?.data?.createdBy === "vendor")
									}
									name="hospitalId"
									value={values?.hospitalId}
									onSelect={fieldValue => {
										setFieldValue("hospitalId", fieldValue?.toString());
										setFieldValue("categoryId", "");
										setFieldValue("medicineId", "");
										setFieldValue("details", []);
										setMedicineOptions([]);
										isDirty = true;
									}}
								/>
								{errors?.hospitalId && touched?.hospitalId && (
									<p className="form-error-item-message">{errors.hospitalId}</p>
								)}
							</>
						)}
					</div>
					<div className="setting-item">
						<label className={clsx("setting-label", {required: action !== "detail"})}>Divisi</label>
						{action === "detail" ? (
							<div className="text-bold text-grey">{data?.data?.division?.name}</div>
						) : (
							<>
								<BasicDropdown
									className={clsx("form-control", errors?.divisionId && "form-control-error")}
									data={[{label: "", value: ""}, ...divisionOptions] || []}
									disabled={
										isLoadingDivisions ||
										(action !== "tambah" && data?.data?.createdBy === "vendor")
									}
									name="divisionId"
									value={values?.divisionId}
									onSelect={fieldValue => {
										setFieldValue("divisionId", fieldValue);
										isDirty = true;
									}}
								/>
								{errors?.divisionId && touched?.divisionId && (
									<p className="form-error-item-message">{errors.divisionId}</p>
								)}
							</>
						)}
					</div>
					<div className="setting-item">
						<label className={clsx("setting-label", {required: action !== "detail"})}>Kategori Obat</label>
						{action === "detail" ? (
							<div className="text-bold text-grey">{data?.data?.category?.name}</div>
						) : (
							<>
								<BasicDropdown
									className={clsx("form-control", errors?.categoryId && "form-control-error")}
									data={[{label: "", value: ""}, ...medicineCategoryOptions] || []}
									disabled={
										isLoadingMedicineCategory ||
										(action !== "tambah" && data?.data?.createdBy === "vendor")
									}
									name="categoryId"
									value={values?.categoryId}
									onSelect={fieldValue => {
										setFieldValue("categoryId", fieldValue);
										setFieldValue("medicineId", "");
										setFieldValue("details", []);
										isDirty = true;
									}}
								/>
								{errors?.categoryId && touched?.categoryId && (
									<p className="form-error-item-message">{errors.categoryId}</p>
								)}
							</>
						)}
					</div>
					<div className="setting-item">
						<label className={clsx("setting-label", {required: action !== "detail"})}>Nama Obat</label>
						{action === "detail" ? (
							<div className="text-bold text-grey">{data?.data?.medicine?.name}</div>
						) : (
							<>
								<BasicDropdown
									className={clsx("form-control", errors?.medicineId && "form-control-error")}
									data={[{label: "", value: ""}, ...medicineOptions] || []}
									disabled={
										medicineQueryLoading ||
										(action !== "tambah" && data?.data?.createdBy === "vendor")
									}
									name="medicineId"
									value={values?.medicineId}
									onSelect={fieldValue => {
										setFieldValue("medicineId", fieldValue);
										setFieldValue("details", []);
										isDirty = true;
									}}
								/>
								{errors?.medicineId && touched?.medicineId && (
									<p className="form-error-item-message">{errors.medicineId}</p>
								)}
							</>
						)}
					</div>
				</div>
			</div>

			<FieldArray name="details">
				{({remove, push}) => (
					<>
						{values.details?.map((o, idx) => {
							return (
								<Row key={`details-${idx}`} className="gx-2 mb-3">
									<Col xs={12}>
										<div class="setting-item">
											<label class="setting-label">Image</label>
											<div class="change-img mb-4">
												<div class="for-img-change">
													<div class="bx-img-change">
														<img
															alt="Image Obat"
															class="img-fluid"
															src={handleViewImage(o.icon)}
														/>
													</div>
												</div>
												{action !== "detail" ? (
													<div class="for-info-change">
														<div class="upload-img-patient">
															<input
																id={`upload-${idx}`}
																type="file"
																onChange={e => handleUploadLogo(e, idx)}
															/>
															<label class="btn-upload-img-patient" for={`upload-${idx}`}>
																<img
																	alt="Upload Image"
																	class="img-fluid me-2"
																	src={loadLocalAssets("img/icn-cam.svg")}
																/>
																Upload Image
															</label>
														</div>
														<div class="ft-12 text-grey">
															*Ukuran file: maks 5 Mb (.JPG .JPEG .PNG)
														</div>
													</div>
												) : null}
											</div>
											{errors?.details?.[idx]?.icon && (
												<p className="form-error-item-message">
													{errors?.details?.[idx]?.icon}
												</p>
											)}
										</div>
									</Col>
									<Col>
										<div className="setting-item">
											<label className={clsx("setting-label", {required: action !== "detail"})}>
												Satuan
											</label>
											{action === "detail" ? (
												<div className="text-bold text-grey">
													{sediaanOptions?.find(x => x.value === o.sediaanId)?.label}
												</div>
											) : (
												<>
													<BasicDropdown
														className={clsx(
															"form-control",
															errors?.details?.[idx]?.sediaanId && "form-control-error",
														)}
														data={sediaanOptions}
														disabled={
															action !== "tambah" && data?.data?.createdBy === "vendor"
														}
														name={`details.${idx}.sediaanId`}
														value={o.sediaanId}
														onSelect={fieldValue => {
															setFieldValue(
																`details.${idx}.sediaanId`,
																Number(fieldValue),
															);
															isDirty = true;
														}}
													/>
													{errors?.details?.[idx]?.sediaanId &&
														touched?.details?.[idx]?.sediaanId && (
															<p className="form-error-item-message">
																{errors?.details?.[idx]?.sediaanId}
															</p>
														)}
												</>
											)}
										</div>
									</Col>
									<Col>
										<div className="setting-item">
											<label className={clsx("setting-label", {required: action !== "detail"})}>
												Unit
											</label>
											{action === "detail" ? (
												<div className="text-bold text-grey">
													{unitOptions?.find(x => x.value === o.unitId)?.label}
												</div>
											) : (
												<>
													<BasicDropdown
														className={clsx(
															"form-control",
															errors?.details?.[idx]?.unitId && "form-control-error",
														)}
														data={unitOptions}
														disabled={
															action !== "tambah" && data?.data?.createdBy === "vendor"
														}
														name={`details.${idx}.unitId`}
														value={o.unitId}
														onSelect={fieldValue => {
															setFieldValue(`details.${idx}.unitId`, Number(fieldValue));
															isDirty = true;
														}}
													/>
													{errors?.details?.[idx]?.unitId &&
														touched?.details?.[idx]?.unitId && (
															<p className="form-error-item-message">
																{errors?.details?.[idx]?.unitId}
															</p>
														)}
												</>
											)}
										</div>
									</Col>
									<Col>
										<div className="setting-item">
											<label className={clsx("setting-label", {required: action !== "detail"})}>
												Jumlah
											</label>
											{action === "detail" ? (
												<div className="text-bold text-grey">{o.qty}</div>
											) : (
												<>
													<Cleave
														className={clsx(
															"form-control",
															errors?.details?.[idx]?.qty && "form-control-error",
														)}
														disabled={
															action !== "tambah" && data?.data?.createdBy === "vendor"
														}
														name={`details.${idx}.qty`}
														options={{
															numeral: true,
															numeralThousandsGroupStyle: "thousand",
														}}
														value={o.qty}
														onChange={e => {
															setFieldValue(`details.${idx}.qty`, e.target.rawValue);
															isDirty = true;
														}}
													/>
													{errors?.details?.[idx]?.qty && touched?.details?.[idx]?.qty && (
														<p className="form-error-item-message">
															{errors?.details?.[idx]?.qty}
														</p>
													)}
												</>
											)}
										</div>
									</Col>
									{data?.data?.createdBy === "vendor" || !!values?.vendorId ? (
										<>
											<Col>
												<div className="setting-item">
													<label
														className={clsx("setting-label", {
															required: action !== "detail",
														})}>
														Harga Satuan Vendor
													</label>
													{action === "detail" ? (
														<div className="text-bold text-grey">
															Rp.{" "}
															{data?.data?.vendorMedicine?.[idx]?.price.toLocaleString(
																"id-ID",
															)}
														</div>
													) : (
														<>
															<Cleave
																className={clsx(
																	"form-control",
																	errors?.details?.[idx]?.price &&
																		"form-control-error",
																)}
																disabled={data?.data?.createdBy === "vendor"}
																name={`details.${idx}.proce`}
																options={{
																	numeral: true,
																	numeralThousandsGroupStyle: "thousand",
																	prefix: "Rp ",
																	rawValueTrimPrefix: true,
																}}
																value={o.price}
																onChange={e => {
																	setFieldValue(
																		`details.${idx}.price`,
																		e.target.rawValue,
																	);
																	isDirty = true;
																}}
															/>
														</>
													)}
												</div>
											</Col>
											<Col>
												<div className="setting-item">
													<label
														className={clsx("setting-label", {
															required: action !== "detail",
														})}>
														Total Harga Satuan Vendor
													</label>
													{action === "detail" ? (
														<div className="text-bold text-grey">
															Rp.{" "}
															{(
																data?.data?.vendorMedicine?.[idx]?.price * o.qty
															).toLocaleString("id-ID")}
														</div>
													) : (
														<>
															<Cleave
																className="form-control"
																disabled={true}
																options={{
																	numeral: true,
																	numeralThousandsGroupStyle: "thousand",
																	prefix: "Rp ",
																	rawValueTrimPrefix: true,
																}}
																value={o.price * o.qty}
															/>
														</>
													)}
												</div>
											</Col>
											<Col>
												<div className="setting-item">
													<label
														className={clsx("setting-label", {
															required: action !== "detail",
														})}>
														Harga Satuan
													</label>
													{action === "detail" ? (
														<div className="text-bold text-grey">
															Rp. {o.sellPrice.toLocaleString("id-ID")}
														</div>
													) : (
														<>
															<Cleave
																className={clsx(
																	"form-control",
																	errors?.details?.[idx]?.sellPrice &&
																		"form-control-error",
																)}
																name={`details.${idx}.sellPrice`}
																options={{
																	numeral: true,
																	numeralThousandsGroupStyle: "thousand",
																	prefix: "Rp ",
																	rawValueTrimPrefix: true,
																}}
																value={o.sellPrice}
																onChange={e => {
																	setFieldValue(
																		`details.${idx}.sellPrice`,
																		e.target.rawValue,
																	);
																	isDirty = true;
																}}
															/>
															{errors?.details?.[idx]?.sellPrice &&
																touched?.details?.[idx]?.sellPrice && (
																	<p className="form-error-item-message">
																		{errors?.details?.[idx]?.sellPrice}
																	</p>
																)}
														</>
													)}
												</div>
											</Col>
											<Col>
												<div className="setting-item">
													<label
														className={clsx("setting-label", {
															required: action !== "detail",
														})}>
														Total Harga
													</label>
													{action === "detail" ? (
														<div className="text-bold text-grey">
															Rp. {(o.sellPrice * o.qty).toLocaleString("id-ID")}
														</div>
													) : (
														<>
															<Cleave
																className="form-control"
																disabled={true}
																options={{
																	numeral: true,
																	numeralThousandsGroupStyle: "thousand",
																	prefix: "Rp ",
																	rawValueTrimPrefix: true,
																}}
																value={o.sellPrice * o.qty ? o.sellPrice * o.qty : 0}
																onChange={() => null}
															/>
														</>
													)}
												</div>
											</Col>
										</>
									) : (
										<>
											<Col>
												<div className="setting-item">
													<label
														className={clsx("setting-label", {
															required: action !== "detail",
														})}>
														Harga Satuan
													</label>
													{action === "detail" ? (
														<div className="text-bold text-grey">
															Rp. {o.price.toLocaleString("id-ID")}
														</div>
													) : (
														<>
															<Cleave
																className={clsx(
																	"form-control",
																	errors?.details?.[idx]?.price &&
																		"form-control-error",
																)}
																name={`details.${idx}.price`}
																options={{
																	numeral: true,
																	numeralThousandsGroupStyle: "thousand",
																	prefix: "Rp ",
																	rawValueTrimPrefix: true,
																}}
																value={o.price}
																onChange={e => {
																	setFieldValue(
																		`details.${idx}.price`,
																		e.target.rawValue,
																	);
																	isDirty = true;
																}}
															/>
															{errors?.details?.[idx]?.price &&
																touched?.details?.[idx]?.price && (
																	<p className="form-error-item-message">
																		{errors?.details?.[idx]?.price}
																	</p>
																)}
														</>
													)}
												</div>
											</Col>
											<Col>
												<div className="setting-item">
													<label className="setting-label">Total Harga</label>
													{action === "detail" ? (
														<div className="text-bold text-grey">
															Rp. {(o.price * o.qty).toLocaleString("id-ID")}
														</div>
													) : (
														<>
															<Cleave
																className="form-control"
																disabled={true}
																options={{
																	numeral: true,
																	numeralThousandsGroupStyle: "thousand",
																	prefix: "Rp ",
																	rawValueTrimPrefix: true,
																}}
																value={o.price * o.qty ? o.price * o.qty : 0}
															/>
														</>
													)}
												</div>
											</Col>
										</>
									)}

									{action === "tambah" ||
									(action === "edit" && data?.data?.createdBy !== "vendor") ? (
										<Col className="d-flex align-items-end" xs={1}>
											<div className="d-flex gap-2 mb-4">
												{idx === values?.details.length - 1 ? (
													<a
														className={clsx("text-orange wait-pay")}
														style={{cursor: "pointer"}}
														onClick={() => {
															push({
																icon: null,
																unitId: unitOptions[0].value,
																sediaanId: sediaanOptions[0].value,
																qty: 0,
																price: 0,
															});
															isDirty = true;
														}}>
														<span className="icon-ico-plus ft-18" />
													</a>
												) : null}
												{values?.details.length > 1 ? (
													<a
														className={clsx("text-orange wait-pay")}
														style={{cursor: "pointer"}}
														onClick={() => {
															remove(idx);
															isDirty = true;
														}}>
														<span className="icon-ico-delete ft-18" />
													</a>
												) : null}
											</div>
										</Col>
									) : null}
								</Row>
							);
						})}
					</>
				)}
			</FieldArray>

			<div className="row gx-2 mb-3">
				<div className="col-sm-6 col-6">
					<div className="setting-item">
						<label className={clsx("setting-label")}>Deskripsi</label>
						{action === "detail" ? (
							<div className="text-bold text-grey">{data?.data?.description || "-"}</div>
						) : (
							<>
								<textarea
									className={clsx("form-control", errors?.description && "form-control-error")}
									disabled={action !== "tambah" && data?.data?.createdBy === "vendor"}
									name="description"
									rows={5}
									value={values?.description}
									onChange={e => {
										setFieldValue("description", e.target.value);
									}}
								/>
								{errors?.description && touched?.description && (
									<p className="form-error-item-message">{errors.description}</p>
								)}
							</>
						)}
					</div>
				</div>
			</div>

			{action !== "detail" ? (
				<div className="text-center my-3">
					<SubmitButton
						className="btn btn-orange d-inline-block mw-250"
						disabled={!FormSchemaValidation.isValidSync(values)}
						text="Simpan"
						onClick={handleSubmit}
					/>
				</div>
			) : null}
		</>
	);
};

export {LayananInformasiObat};
