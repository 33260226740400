import {useState} from "react";
import {useQuery} from "react-query";
import axios from "services/axios";

export const QUERY = "appointment";
export const QUERY_PATIENT = "appointment_patient";

async function getAppointments(idUserDoctor, filter) {
	const {data} = await axios.get(`/booking`, {params: {...filter, sortBy: "date.ASC", idUserDoctor}});

	return data;
}

export function useAppointment(idUserDoctor) {
	const [filter, filterAppointments] = useState({
		page: 1,
		search: null,
		limit: 10,
	});
	const fallback = [];
	const {
		data = fallback,
		isLoading,
		isError,
		error,
	} = useQuery([QUERY, filter], async () => getAppointments(idUserDoctor, filter));

	return {data, isLoading, isError, error, filter, filterAppointments};
}

async function getPatientAppointments(idUserPatient, filter) {
	if (!idUserPatient) return {};

	const {data} = await axios.get(`/booking`, {params: {...filter, sortBy: "id.desc", idUserPatient}});

	return data;
}

export function useAppointmentByPatient(idUserPatient) {
	const [filter, filterAppointments] = useState({
		// page: 1,
		search: null,
		// limit: 10,
	});
	const fallback = [];
	const {
		data = fallback,
		isLoading,
		isError,
		error,
	} = useQuery([QUERY_PATIENT, filter, idUserPatient], async () => getPatientAppointments(idUserPatient, filter));

	return {data, isLoading, isError, error, filter, filterAppointments};
}

export const fetchAppointment = appointmentId => {
	return axios.get(`/booking/${appointmentId}`).then(res => res.data);
};

export function useDetailAppointment(appointmentId) {
	return useQuery({
		queryKey: appointmentId && [QUERY, appointmentId],
		queryFn: key => fetchAppointment(appointmentId),
		refetchOnMount: false,
		refetchOnWindowFocus: false,
		enabled: !!appointmentId,
	});
}

// options

export function useAppointmentOptions() {
	const result = useQuery(["appointmentOptions"], async () => await axios.get(`/booking`).then(res => res.data));

	return result;
}
