import clsx from "clsx";
import {SubmitButton} from "components/Button";
import {DashboardLayout} from "components/Layout";
import {Formik} from "formik";
import {useAddTagPost, useEditTagPost, useTagPost} from "hooks/SuperAdmin/Article";
import {useToast} from "hooks/useToast";
import {useEffect, useRef} from "react";
import {useHistory, useParams} from "react-router-dom";
import {convertErrorMessageFormat} from "utils/converter";
import * as Yup from "yup";

const FormSchemaValidation = Yup.object().shape({
	tag: Yup.string().required("Tag wajib di isi"),
});

const FormInitialValues = {tag: ""};

const TagPostActionPage = () => {
	const {showToast} = useToast();
	const history = useHistory();
	const {action, id} = useParams();
	const {mutate: onAdd, isLoading: isLoadingAdd} = useAddTagPost();
	const {mutate: onEdit, isLoading: isLoadingEdit} = useEditTagPost();
	const {data: detailTagPost, setTagPostId} = useTagPost();
	const formikRef = useRef();

	useEffect(() => {
		if (id) {
			setTagPostId(id);
		}
	}, [id]);

	useEffect(() => {
		if (detailTagPost?.data?.id && action === "edit" && formikRef.current) {
			formikRef.current.setFieldValue("tag", detailTagPost.data.tag);
		}
	}, [detailTagPost, action, formikRef]);

	const handleSubmit = formData => {
		if (action === "tambah") {
			onAdd(formData, {
				onSuccess: () => {
					showToast("success", "Data berhasil ditambahkan", 3000);
					history.push("/tag-post");
				},
				onError: res => {
					showToast("error", convertErrorMessageFormat(res.response.status, res.response.data.message), null);
				},
			});
		}
		if (action === "edit") {
			onEdit(
				{id, ...formData},
				{
					onSuccess: () => {
						showToast("success", "Data berhasil diperbarui", 3000);
						history.push("/tag-post");
					},
					onError: res => {
						showToast(
							"error",
							convertErrorMessageFormat(res.response.status, res.response.data.message),
							null,
						);
					},
				},
			);
		}
	};

	return (
		<DashboardLayout>
			<Formik
				initialValues={FormInitialValues}
				innerRef={formikRef}
				validationSchema={FormSchemaValidation}
				onSubmit={handleSubmit}>
				{({handleSubmit, handleBlur, handleChange, values, errors}) => (
					<div class="box-white setting-content h-100 no-border p24">
						<div class="d-flex justify-content-between flex-wrap align-items-center mb-4">
							<div class="ttl-20 text-capitalize">{action} Tag Post</div>
						</div>

						<hr />

						<div class="text-bold mb-4">
							<a class="text-dark" style={{cursor: "pointer"}} onClick={() => history.push("/tag-post")}>
								<span class="icon-ico-back me-2" />
							</a>
							Daftar List Tag Post
						</div>

						<div class="row gx-2 mb-5">
							<div class="col-sm-6 col-6">
								<div class="setting-item">
									<label
										class={clsx("setting-label", {
											required: action !== "detail",
										})}>
										Tag
									</label>
									{action === "detail" ? (
										<p className="text-bold text-grey">{detailTagPost?.data?.tag}</p>
									) : (
										<>
											<input
												class={clsx("form-control", errors?.tag && "form-control-error")}
												disabled={isLoadingAdd || isLoadingEdit}
												name="tag"
												placeholder=""
												type="text"
												value={values?.tag}
												onBlur={handleBlur}
												onChange={handleChange}
											/>
											{errors.tag && <p className="form-error-item-message">{errors.tag}</p>}
										</>
									)}
								</div>
							</div>
						</div>

						{action !== "detail" ? (
							<div class="text-center my-3">
								<SubmitButton
									className="btn btn-orange d-inline-block mw-250"
									disabled={!FormSchemaValidation.isValidSync(values)}
									loading={isLoadingAdd || isLoadingEdit}
									text="Simpan"
									onClick={handleSubmit}
								/>
							</div>
						) : null}
					</div>
				)}
			</Formik>
		</DashboardLayout>
	);
};

export {TagPostActionPage};
