/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import clsx from "clsx";
import {useState, useRef, useEffect, useMemo} from "react";
import {useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {loadLocalAssets} from "utils/loader";
import {Formik, FieldArray, Field} from "formik";
import {DashboardLayout} from "components/Layout";
import {SubmitButton, ToggleSwitch} from "components/Button";
import {BasicDropdown, PatientDropdown, PromiseDropdown} from "components/Dropdown";
import {convertErrorMessageFormat} from "utils/converter";
import {AddStaffFormSchemaValidation} from "config/form/FormValidation/Staff";
import {useToast} from "hooks/useToast";
import {useAddStaff} from "hooks/SuperAdmin/Staff";
import {getDivisionsByHospital} from "hooks/SuperAdmin/Division";
import {getUnitsByHospital} from "hooks/SuperAdmin/Unit";
import {usePoliklinikOptions} from "hooks/SuperAdmin/Poliklinik";
import {useHospitalsOptions} from "hooks/SuperAdmin/Hospital";
import {getJabatanByHospital} from "hooks/SuperAdmin/Jabatan";
import {useUploadFile, useTitles, useRole, useRoleType, useStaffType, useDoctorType, useStaffStatus} from "hooks/Misc";
import {Collapse} from "react-collapse";
import {isMobile} from "react-device-detect";
import {useTagOptions} from "hooks/SuperAdmin/Tag";
import {useListPatient} from "hooks/AdminAdmission/Patient";
import {useDetailPendaftaranPasien} from "hooks/PendaftaranPasien";
import {converISOtoDate} from "utils/date";

const FormInitialValues = {
	title: "Mr",
	nip: "",
	phoneNumber: "",
	profilePhoto: "",
	prefix: "",
	nickname: "",
	fullName: "",
	suffix: "",
	showSuffix: false,
	showNickname: false,
	showFullName: true,
	showPrefix: false,
	dateOfBirth: "",
	address: "",
	noStr: "",
	email: "",
	password: "",
	employeeType: "",
	doctorType: "",
	policlinicId: "",
	bookingOnline: false,
	roleStaff: [
		{
			roleType: 1,
			roleId: 2,
			sub: "division",
			employeeStatus: 1,
		},
	],
};

export function StaffAddPage() {
	const {showToast} = useToast();
	const formikRef = useRef();
	const [logoPreview, setLogoPreview] = useState(null);
	const [infoOpen, setInfoOpen] = useState(null);
	const [tagOpen, setTagOpen] = useState(false);
	const [selectedTag, setSelectedTag] = useState({selected: [], priority: 0});
	const [locationOptions, setLocationOptions] = useState([]);
	const [titleOptions, setTitleOptions] = useState([]);
	const [polyclinicOptions, setPolyclinicOptions] = useState([]);
	const [typeRoleOptions, setTypeRoleOptions] = useState([]);
	const [employeeStatusOptions, setEmployeeStatusOptions] = useState([]);
	const [doctorTypeOptions, setDoctorTypeOptions] = useState([]);
	const [employeeTypeOptions, setEmployeeTypeOptions] = useState([]);
	const [roleManagementOptions, setRoleManagementOptions] = useState([]);
	const [roleHealthcareOptions, setRoleHealthcareOptions] = useState([]);
	const [existedPatiendId, setExistedPatientId] = useState(null);
	const [isUsePatient, setIsUsePatient] = useState(false);
	const history = useHistory();
	const selectedHospital = useSelector(state => state.HospitalsReducer.selectedHospital);

	const {data: polyclinics, isLoading: isLoadingPolyclinicOptions} = usePoliklinikOptions();
	const {data: staffStatuses, isLoading: isLoadingStaffStatusOptions} = useStaffStatus();
	const {data: doctorTypes, isLoading: isLoadingDoctorTypeOptions} = useDoctorType();
	const {data: staffTypes, isLoading: isLoadingStaffTypeOptions} = useStaffType();
	const {data: roleTypes, isLoading: isLoadingRoleTypeOptions} = useRoleType();
	const {data: roles, isLoading: isLoadingRoleOptions} = useRole();
	const {hospitalOptions: locations, isLoading: isLoadingLocationOptions} = useHospitalsOptions();
	const {data: titles, isLoading: isFethingTitles} = useTitles([]);
	const {data: tagData} = useTagOptions();
	const {data: patients, isLoading: isFethingPatients} = useListPatient({page: 1, limit: ""});
	const {data: detailPendaftaranPasien} = useDetailPendaftaranPasien(existedPatiendId);
	const {mutate, isLoading} = useAddStaff();
	const {mutate: uploadFile} = useUploadFile();

	const tagOptions = useMemo(() => {
		if (tagData?.success) {
			return tagData.data.rows.map(x => ({
				label: `${x.title} ${x.discount}%`,
				value: x.id,
			}));
		}

		return [];
	}, [tagData]);

	const existedPatientsOptions = useMemo(() => {
		if (patients?.success) {
			return patients?.data?.rows?.map(patient => ({
				value: patient?.patient?.id + "",
				label: `${patient?.firstName} ${patient?.lastName} [${patient?.patient?.medicalRecordNumber}]`,
			}));
		}

		return [];
	}, [patients]);

	const subId = useMemo(() => {
		return [4, 5, 6];
	}, []);

	const handleSubmitForm = async formValue => {
		const {profilePhoto, ...formDataValue} = formValue;
		let formData = new FormData();

		formData.append("file", profilePhoto);

		const tags = selectedTag.selected.map(tag => ({
			tagId: tag,
			piority: selectedTag.priority === tag,
		}));

		const findRealPatientId = existedPatientsOptions.find(x => x.value === existedPatiendId)?.patientId;

		if (profilePhoto?.name) {
			uploadFile(formData, {
				onSuccess: result => {
					mutate(
						{
							profilePhoto: result.data?.file,
							tags,
							__isUsePatient: isUsePatient,
							patientId: findRealPatientId,
							...formDataValue,
						},
						{
							onSuccess: () => {
								showToast("success", "Data staff berhasil ditambahkan", 3000);
								history.push("/hospital/staff");
							},
							onError: res => {
								showToast(
									"error",
									convertErrorMessageFormat(res.response.status, res.response.data.message),
									null,
								);
								window.scrollTo(0, 0);
							},
						},
					);
				},
			});
		} else {
			mutate(
				{tags, __isUsePatient: isUsePatient, patientId: findRealPatientId, ...formDataValue},
				{
					onSuccess: () => {
						showToast("success", "Data staff berhasil ditambahkan", 3000);
						history.push("/hospital/staff");
					},
					onError: res => {
						showToast(
							"error",
							convertErrorMessageFormat(res.response.status, res.response.data.message),
							null,
						);
						window.scrollTo(0, 0);
					},
				},
			);
		}
	};

	useEffect(() => {
		if (polyclinics?.success) {
			const dataPolyclinic = polyclinics?.data?.rows?.map(polyclinic => ({
				value: polyclinic?.id + "",
				label: `${polyclinic?.name}`,
			}));

			setPolyclinicOptions([{value: null, label: "-"}, ...dataPolyclinic]);
			formikRef?.current.setFieldValue(`policlinicId`, null);
		}
	}, [polyclinics]);

	useEffect(() => {
		if (staffStatuses?.success) {
			const dataStaffStasus = staffStatuses?.data?.map(staffStasus => ({
				value: staffStasus?.id + "",
				label: `${staffStasus?.name}`,
			}));

			setEmployeeStatusOptions([...dataStaffStasus]);
		}
	}, [staffStatuses]);

	useEffect(() => {
		if (doctorTypes?.success) {
			const dataDoctorType = doctorTypes?.data?.map(doctorType => ({
				value: doctorType?.id + "",
				label: `${doctorType?.name}`,
			}));

			setDoctorTypeOptions([...dataDoctorType]);
		}
	}, [doctorTypes]);

	useEffect(() => {
		if (staffTypes?.success) {
			const dataStaffType = staffTypes?.data?.map(staffType => ({
				value: staffType?.id + "",
				label: `${staffType?.name}`,
			}));

			setEmployeeTypeOptions([...dataStaffType]);
			formikRef?.current.setFieldValue(`employeeType`, dataStaffType[0]?.value);
		}
	}, [staffTypes]);

	useEffect(() => {
		if (roleTypes?.success) {
			const dataRoleType = roleTypes?.data?.map(roleType => ({
				value: roleType?.id + "",
				label: `${roleType?.name}`,
			}));

			setTypeRoleOptions([...dataRoleType]);
		}
	}, [roleTypes]);

	useEffect(() => {
		if (roles?.success) {
			let rolesHealthcare = roles.data.filter(role => role.roleTypeId === 1);

			rolesHealthcare = rolesHealthcare.map(role => ({
				value: role.id,
				label: role.name,
			}));
			setRoleHealthcareOptions([...rolesHealthcare]);

			let rolesManagement = roles.data.filter(role => role.roleTypeId === 2);

			rolesManagement = rolesManagement.map(role => ({
				value: role.id,
				label: role.name,
			}));
			setRoleManagementOptions([...rolesManagement]);
		}
	}, [roles]);

	useEffect(() => {
		if (locations.length) {
			setLocationOptions([...locations]);
			formikRef?.current.setFieldValue(`roleStaff[0].hospitalId`, selectedHospital.Hospital.id);
			formikRef?.current.setFieldTouched(`roleStaff[0].hospitalId`, true);
			// formikRef?.current.setFieldTouched(`roleStaff[0].hospitalId`, false);
		}
	}, [locations, selectedHospital]);

	useEffect(() => {
		if (titles?.success) {
			setTitleOptions(
				titles?.data?.map(title => ({
					value: title,
					label: title,
				})),
			);
		}
	}, [titles]);

	useEffect(() => {
		if (detailPendaftaranPasien?.data?.id) {
			const {patient} = detailPendaftaranPasien?.data;

			// delete detailPendaftaranPasien.data.password;

			Object.keys(FormInitialValues).forEach(key => {
				formikRef?.current.setFieldValue(key, detailPendaftaranPasien?.data?.[key] || "");
				if (key === "title" && !detailPendaftaranPasien?.data?.[key]) {
					formikRef?.current.setFieldValue(key, "Mr");
				}
				if (key === "dateOfBirth")
					formikRef?.current.setFieldValue(
						key,
						converISOtoDate(detailPendaftaranPasien?.data?.[key], "YYYY-MM-DD"),
					);
			});
			setLogoPreview(detailPendaftaranPasien?.data?.profilePhoto);
			formikRef.current?.setFieldTouched("nickname", true);

			const {tags} = detailPendaftaranPasien?.data;

			setSelectedTag({
				selected: tags?.map(t => t.tagId),
				priority: tags?.find(t => t.piority)?.tagId || 0,
			});
		}
	}, [detailPendaftaranPasien]);

	const handleUploadLogo = e => {
		const file = e.target.files[0];
		const reader = new FileReader();

		if (!file) return;
		if (!file.type.includes("image")) {
			formikRef?.current?.setFieldError(
				"profilePhoto",
				"Format foto profil staff tidak support, hanya menerima format .JPG .JPEG .PNG",
			);

			return;
		}
		if (file.size > 5242880) {
			formikRef?.current?.setFieldError("profilePhoto", "Ukuran file untuk logo maksimal 5 MB");

			return;
		}

		reader.onload = () => {
			setLogoPreview(reader.result);
		};
		reader.readAsDataURL(file);
		formikRef.current.setFieldValue("profilePhoto", file);
	};

	const getDivisionOptions = async (hospitalId, indexRole) => {
		const {data} = await getDivisionsByHospital(hospitalId);

		if (!data?.rows?.length) {
			formikRef?.current.setFieldTouched(`roleStaff[${indexRole}].hospitalId`, false);

			return [];
		}

		let options = [
			{
				value: null,
				label: "-",
			},
		];

		data?.rows.forEach(item => options.push({value: item.id, label: item.name}));
		formikRef?.current.setFieldTouched(`roleStaff[${indexRole}].hospitalId`, false);

		return options;
	};

	const getUnitOptions = async (hospitalId, indexRole) => {
		const {data} = await getUnitsByHospital(hospitalId);

		if (!data?.rows?.length) {
			formikRef?.current.setFieldTouched(`roleStaff[${indexRole}].hospitalId`, false);

			return [];
		}

		let options = [
			{
				value: null,
				label: "-",
			},
		];

		data?.rows.forEach(item => options.push({value: item.id, label: item.name}));
		formikRef?.current.setFieldTouched(`roleStaff[${indexRole}].hospitalId`, false);

		return options;
	};

	const getJabatanOptions = async (hospitalId, indexRole) => {
		const {data} = await getJabatanByHospital(hospitalId);

		if (!data?.rows?.length) {
			formikRef?.current.setFieldTouched(`roleStaff[${indexRole}].hospitalId`, false);

			return [];
		}

		let options = [
			{
				value: null,
				label: "-",
			},
		];

		data?.rows.forEach(item => options.push({value: item.id, label: item.name}));
		formikRef?.current.setFieldTouched(`roleStaff[${indexRole}].hospitalId`, false);

		return options;
	};

	const findRelatedId = value => {
		return existedPatientsOptions.find(x => x.label.includes(value))?.fullName || value;
	};

	return (
		<DashboardLayout>
			<Formik
				enableReinitialize
				initialValues={FormInitialValues}
				innerRef={formikRef}
				validationSchema={AddStaffFormSchemaValidation}
				onSubmit={handleSubmitForm}>
				{({
					handleSubmit,
					handleBlur,
					handleChange,
					values,
					errors,
					touched,
					setFieldValue,
					setTouched,
					setFieldTouched,
					resetForm,
				}) => (
					<div class="box-white setting-content h-100 no-border p24">
						<div class="d-flex justify-content-between flex-wrap align-items-center mb-4">
							<div class="ttl-20">Tambah Staff Baru</div>
						</div>

						<hr />

						<div class="text-bold mb-4">
							<a
								class="text-dark"
								style={{cursor: "pointer"}}
								onClick={() => history.push("/hospital/staff")}>
								<span class="icon-ico-back me-2" />
							</a>
							Daftar List Staff
						</div>

						<div class="row gx-2 mb-5">
							<div class="col-sm-12 col-lg-6">
								<div class="setting-item">
									<label class="setting-label">Foto Profil Staff</label>
									<div class="change-img mb-4">
										<div class="for-img-change">
											<div class="bx-img-change">
												<img
													alt=""
													class="img-fluid"
													src={
														logoPreview
															? logoPreview
															: loadLocalAssets("img/user-default.jpg")
													}
												/>
											</div>
										</div>
										<div class="for-info-change">
											<div class="upload-img-patient">
												<input
													accept="image/*"
													id="upload"
													type="file"
													onChange={handleUploadLogo}
													onClick={() => setTouched({...touched, profilePhoto: true})}
												/>
												<label class="btn-upload-img-patient" for="upload">
													<img
														alt=""
														class="img-fluid me-2"
														src={loadLocalAssets("img/icn-cam.svg")}
													/>
													Upload
												</label>
											</div>
											<div class="ft-12 text-grey">*Ukuran file: maks 5 Mb (.JPG .JPEG .PNG)</div>
										</div>
									</div>
									{errors?.profilePhoto && touched?.profilePhoto && (
										<p className="form-error-item-message">{errors?.profilePhoto}</p>
									)}
								</div>
								<div class="setting-item">
									<label class="setting-label required">Title</label>
									<BasicDropdown
										className={clsx("form-control", errors?.title && "form-control-error")}
										data={titleOptions}
										name="title"
										value={values?.title}
										onSelect={fieldValue => {
											setFieldValue("title", fieldValue);
										}}
									/>
									{errors.title && <p className="form-error-item-message">{errors.title}</p>}
								</div>
								<div class="setting-item d-flex gap-2">
									<div className="flex-grow-1">
										<label class="setting-label required">Prefix</label>
										<input
											class={clsx(
												"form-control",
												errors?.prefix && touched?.prefix && "form-control-error",
											)}
											disabled={isLoading}
											name="prefix"
											placeholder=""
											type="text"
											value={values?.prefix}
											onBlur={handleBlur}
											onChange={handleChange}
										/>
										{errors.prefix && <p className="form-error-item-message">{errors.prefix}</p>}
									</div>
									<div>
										<label class="setting-label d-block mb-3">Show</label>
										<ToggleSwitch
											isActive={values?.showPrefix}
											size="sm"
											onChange={e => {
												setFieldValue("showPrefix", e.status);
											}}
										/>
									</div>
								</div>
								<div class="setting-item d-flex gap-2">
									<div className="flex-grow-1">
										<label class="setting-label required">Nama Panggilan</label>
										<input
											class={clsx(
												"form-control",
												errors?.nickname && touched?.nickname && "form-control-error",
											)}
											disabled={isLoading}
											name="nickname"
											placeholder=""
											type="text"
											value={values?.nickname}
											onBlur={handleBlur}
											onChange={handleChange}
										/>
										{errors.nickname && touched?.nickname && (
											<p className="form-error-item-message">{errors.nickname}</p>
										)}
									</div>
									<div>
										<label class="setting-label d-block mb-3">Show</label>
										<ToggleSwitch
											isActive={values?.showNickname}
											size="sm"
											onChange={e => {
												setFieldValue("showNickname", e.status);
											}}
										/>
									</div>
								</div>
								<div class="setting-item">
									<div className="d-flex gap-2">
										<div className="flex-grow-1">
											<PatientDropdown
												data={existedPatientsOptions}
												inputText={findRelatedId(values.fullName)}
												label={`Nama Depan & Belakang`}
												loading={isFethingPatients}
												placeholder="Ketikan nama depan.."
												required={true}
												searchKeyValue={["label"]}
												value={findRelatedId(values.fullName)}
												onChange={selectedValue => {
													setExistedPatientId(selectedValue.value);
												}}
												onInput={e => {
													if (!e) {
														setExistedPatientId(null);
														resetForm();
													}
													setFieldValue(`fullName`, e);
												}}
											/>
											{errors.fullName && touched?.fullName && (
												<p className="form-error-item-message">{errors.fullName}</p>
											)}
										</div>
										<div>
											<label class="setting-label d-block mb-3">Show</label>
											<ToggleSwitch
												isActive={values?.showFullName}
												size="sm"
												onChange={e => {
													setFieldValue("showFullName", e.status);
												}}
											/>
										</div>
									</div>
									<input
										checked={isUsePatient}
										className=""
										type="checkbox"
										onClick={e => setIsUsePatient(e.target.checked)}
									/>
									<label class="setting-label ms-2" htmlFor="copy_address">
										Selected from Patient
									</label>
								</div>
								<div class="setting-item d-flex gap-2">
									<div className="flex-grow-1">
										<label class="setting-label required">Suffix</label>
										<input
											class={clsx(
												"form-control",
												errors?.suffix && touched?.suffix && "form-control-error",
											)}
											disabled={isLoading}
											name="suffix"
											placeholder=""
											type="text"
											value={values?.suffix}
											onBlur={handleBlur}
											onChange={handleChange}
										/>
										{errors.suffix && <p className="form-error-item-message">{errors.suffix}</p>}
									</div>
									<div>
										<label class="setting-label d-block mb-3">Show</label>
										<ToggleSwitch
											isActive={values?.showSuffix}
											size="sm"
											onChange={e => {
												setFieldValue("showSuffix", e.status);
											}}
										/>
									</div>
								</div>
								<div class="setting-item">
									<label class="setting-label required">Nomor Telepon</label>
									<input
										class={clsx(
											"form-control",
											errors?.phoneNumber && touched?.phoneNumber && "form-control-error",
										)}
										disabled={isLoading}
										name="phoneNumber"
										placeholder=""
										type="text"
										value={values?.phoneNumber}
										onBlur={handleBlur}
										onChange={e => {
											const value = e.target.value.replace(/\D/g, "");

											setFieldValue("phoneNumber", value);
										}}
									/>
									{errors.phoneNumber && touched?.phoneNumber && (
										<p className="form-error-item-message">{errors.phoneNumber}</p>
									)}
								</div>
							</div>
							<div class="col-sm-6">
								<div class="setting-item">
									<label class="setting-label required">Tanggal Lahir</label>
									<input
										class={clsx(
											"form-control",
											errors?.dateOfBirth && touched?.dateOfBirth && "form-control-error",
										)}
										max={new Date().toISOString().split("T")[0]}
										name="dateOfBirth"
										placeholder=""
										type="date"
										value={values?.dateOfBirth}
										onBlur={handleBlur}
										onChange={handleChange}
									/>
									{errors.dateOfBirth && touched?.dateOfBirth && (
										<p className="form-error-item-message">{errors.dateOfBirth}</p>
									)}
								</div>
								<div class="setting-item">
									<label class="setting-label required">Tipe Karyawan</label>
									<BasicDropdown
										className={clsx(
											"form-control",
											errors?.employeeType && touched?.employeeType && "form-control-error",
										)}
										data={employeeTypeOptions}
										name="employeeType"
										value={values?.employeeType}
										onSelect={fieldValue => {
											setFieldValue("employeeType", fieldValue);
											if (fieldValue != 2) setFieldValue("doctorType", null);
											else setFieldValue("doctorType", doctorTypeOptions[0].value);
										}}
									/>
									{errors.employeeType && touched?.employeeType && (
										<p className="form-error-item-message">{errors.employeeType}</p>
									)}
								</div>
								{values.employeeType == 2 && (
									<div class="setting-item">
										<label class="setting-label required">Tipe Dokter</label>
										<BasicDropdown
											className={clsx(
												"form-control",
												errors?.doctorType && touched?.doctorType && "form-control-error",
											)}
											data={doctorTypeOptions}
											name="doctorType"
											value={values?.doctorType}
											onSelect={fieldValue => {
												setFieldValue("doctorType", fieldValue);
												if (fieldValue == 1) setFieldValue("policlinicId", null);
												else setFieldValue("policlinicId", polyclinicOptions[0].value);
											}}
										/>
										{errors.doctorType && touched?.doctorType && (
											<p className="form-error-item-message">{errors.doctorType}</p>
										)}
									</div>
								)}
								{values.doctorType == 2 && (
									<div class="setting-item">
										<label class="setting-label required">Poliklinik</label>
										<BasicDropdown
											className={clsx(
												"form-control",
												errors?.policlinicId && touched?.policlinicId && "form-control-error",
											)}
											data={polyclinicOptions}
											name="policlinicId"
											value={values?.policlinicId}
											onSelect={fieldValue => {
												setFieldValue("policlinicId", fieldValue);
											}}
										/>
										{errors.policlinicId && (
											<p className="form-error-item-message">{errors.policlinicId}</p>
										)}
									</div>
								)}
								{values.employeeType == 2 && (
									<div class="col-sm-6 col-6">
										<div class="setting-item">
											<label class="setting-label required">Booking Online</label>
											<div className="col-sm-6 col-6">
												<label>
													<input
														checked={values.bookingOnline}
														className="me-2"
														name="bookingOnline"
														type="radio"
														value={true}
														onBlur={handleBlur}
														onChange={handleChange}
													/>
													Yes
												</label>
											</div>
											<div class="col-sm-6 col-6">
												<label>
													<input
														checked={!values.bookingOnline}
														className="me-2"
														name="bookingOnline"
														type="radio"
														value={false}
														onBlur={handleBlur}
														onChange={handleChange}
													/>
													No
												</label>
											</div>
										</div>
									</div>
								)}
								<div class="setting-item">
									<label class="setting-label required">NIP</label>
									<input
										class={clsx(
											"form-control",
											errors?.nip && touched?.nip && "form-control-error",
										)}
										disabled={isLoading}
										name="nip"
										placeholder=""
										type="text"
										value={values?.nip}
										onBlur={handleBlur}
										onChange={handleChange}
									/>
									{errors.nip && touched?.nip && (
										<p className="form-error-item-message">{errors.nip}</p>
									)}
								</div>
								<div class="setting-item">
									<label class="setting-label required">Nomor STR</label>
									<input
										class={clsx(
											"form-control",
											errors?.noStr && touched?.noStr && "form-control-error",
										)}
										disabled={isLoading}
										name="noStr"
										placeholder=""
										type="text"
										value={values?.noStr}
										onBlur={handleBlur}
										onChange={handleChange}
									/>
									{errors.noStr && touched?.noStr && (
										<p className="form-error-item-message">{errors.noStr}</p>
									)}
								</div>
								<div class="setting-item">
									<label class="setting-label required">Alamat</label>
									<textarea
										class={clsx(
											"form-control",
											errors?.address && touched?.address && "form-control-error",
										)}
										name="address"
										rows="4"
										spellCheck={false}
										value={values?.address}
										onBlur={handleBlur}
										onChange={handleChange}
									/>
									{errors.address && touched?.address && (
										<p className="form-error-item-message">{errors.address}</p>
									)}
								</div>
							</div>
							<hr className="my-5" />
							<div class="col-6">
								<div class="setting-item">
									<label class="setting-label required">Email</label>
									<input
										class={clsx(
											"form-control",
											errors?.email && touched?.email && "form-control-error",
										)}
										disabled={isLoading}
										name="email"
										placeholder=""
										type="text"
										value={values?.email}
										onBlur={handleBlur}
										onChange={handleChange}
									/>
									{errors.email && touched?.email && (
										<p className="form-error-item-message">{errors.email}</p>
									)}
								</div>
							</div>
							<div class="col-6">
								<div class="setting-item">
									<label class="setting-label required">Kata Sandi</label>
									<input
										class={clsx(
											"form-control",
											errors?.password && touched?.password && "form-control-error",
										)}
										disabled={isLoading}
										name="password"
										placeholder=""
										type="password"
										value={values?.password}
										onBlur={handleBlur}
										onChange={e => {
											const value = e.target.value.replace(/ /g, "");

											setFieldValue("password", value);
										}}
									/>
									{errors.password && touched?.password && (
										<p className="form-error-item-message">{errors.password}</p>
									)}
								</div>
							</div>
						</div>

						<div id="accordion">
							<div class="card mt-5">
								<div
									class="card-header d-flex justify-content-between"
									id="headingOne"
									style={{cursor: "pointer"}}
									onClick={() => setInfoOpen(!infoOpen)}>
									<h3 class="mb-0">
										<button className="btn" onClick={() => setInfoOpen(!infoOpen)}>
											Info Akun
										</button>
									</h3>
									<h3 style={{"margin-top": "-10px"}}>&#8964;</h3>
								</div>
								<Collapse isOpened={infoOpen}>
									<div>
										<div class="card-body">
											<div class="row gx-2 mb-5">
												<div class="col-sm-12 col-12">
													<div class="setting-item">
														<FieldArray name="roleStaff">
															{({insert, remove, push}) => (
																<div>
																	{values.roleStaff.length > 0 &&
																		values.roleStaff.map((role, index) => (
																			<>
																				<div key={index} className="row">
																					<div class="col-10 row align-items-center">
																						<div className="col-6">
																							<div class="setting-item">
																								<label class="setting-label required">
																									Tipe Role
																								</label>
																								<BasicDropdown
																									className="form-control"
																									data={
																										typeRoleOptions
																									}
																									name={`roleStaff.${index}.roleType`}
																									value={
																										values
																											?.roleStaff[
																											index
																										]?.roleType
																									}
																									onBlur={() =>
																										setFieldTouched(
																											`roleStaff.${index}.hospitalId`,
																											false,
																										)
																									}
																									onSelect={fieldValue => {
																										setFieldValue(
																											`roleStaff.${index}.roleType`,
																											fieldValue,
																										);
																										setFieldValue(
																											`roleStaff.${index}.hospitalId`,
																											selectedHospital
																												.Hospital
																												.id,
																										);
																										setFieldTouched(
																											`roleStaff.${index}.hospitalId`,
																											true,
																										);
																										fieldValue == 1
																											? setFieldValue(
																													`roleStaff.${index}.roleId`,
																													"2",
																											  )
																											: setFieldValue(
																													`roleStaff.${index}.roleId`,
																													"1",
																											  );
																									}}
																								/>
																								{errors?.roleStaff?.[
																									index
																								]?.roleType &&
																									touched
																										?.roleStaff?.[
																										index
																									]?.roleType && (
																										<p className="form-error-item-message">
																											{
																												errors
																													?.roleStaff?.[
																													index
																												]
																													?.roleType
																											}
																										</p>
																									)}
																							</div>
																						</div>
																						<div className="col-6">
																							<div class="setting-item">
																								<label class="setting-label required">
																									Role
																								</label>
																								<BasicDropdown
																									className="form-control"
																									data={
																										values
																											.roleStaff[
																											index
																										].roleType == 1
																											? roleHealthcareOptions
																											: roleManagementOptions
																									}
																									name={`roleStaff.${index}.roleId`}
																									value={
																										values
																											?.roleStaff[
																											index
																										]?.roleId
																									}
																									onBlur={() =>
																										setFieldTouched(
																											`roleStaff.${index}.hospitalId`,
																											false,
																										)
																									}
																									onSelect={fieldValue => {
																										setFieldTouched(
																											`roleStaff.${index}.hospitalId`,
																											true,
																										);
																										setFieldValue(
																											`roleStaff.${index}.roleId`,
																											fieldValue,
																										);
																										if (
																											fieldValue ==
																											8
																										) {
																											setFieldValue(
																												`roleStaff.${index}.unitId`,
																												null,
																											);
																											setFieldValue(
																												`roleStaff.${index}.divisionId`,
																												null,
																											);
																										}
																									}}
																								/>
																							</div>
																						</div>
																						<div className={"d-none"}>
																							<div class="setting-item ">
																								<label class="setting-label required">
																									Lokasi
																								</label>
																								<BasicDropdown
																									className={clsx(
																										"form-control",
																										errors
																											?.roleStaff?.[
																											index
																										]?.hospitalId &&
																											"form-control-error",
																									)}
																									data={
																										locationOptions
																									}
																									disabled={true}
																									name={`roleStaff.${index}.hospitalId`}
																									value={
																										values
																											?.roleStaff[
																											index
																										]?.hospitalId
																									}
																									onBlur={() =>
																										setFieldTouched(
																											`roleStaff.${index}.hospitalId`,
																											false,
																										)
																									}
																									onSelect={fieldValue => {
																										setFieldValue(
																											`roleStaff.${index}.hospitalId`,
																											fieldValue,
																										);
																										setFieldTouched(
																											`roleStaff.${index}.hospitalId`,
																											true,
																										);
																									}}
																								/>
																								{errors?.roleStaff?.[
																									index
																								]?.hospitalId &&
																									touched
																										?.roleStaff?.[
																										index
																									]?.hospitalId && (
																										<p className="form-error-item-message">
																											{
																												errors
																													?.roleStaff?.[
																													index
																												]
																													?.hospitalId
																											}
																										</p>
																									)}
																							</div>
																						</div>
																						{subId.includes(
																							parseInt(
																								values?.roleStaff[index]
																									?.roleId,
																							),
																						) && (
																							<>
																								<div class="col-6">
																									<label for="">
																										<Field
																											className="me-2 col-sm-6"
																											name={`roleStaff.${index}.sub`}
																											type="radio"
																											value="division"
																											onChange={() => {
																												setFieldValue(
																													`roleStaff.${index}.sub`,
																													"division",
																												);
																												setFieldTouched(
																													`roleStaff.${index}.hospitalId`,
																													true,
																												);
																											}}
																										/>
																										Divisi
																									</label>
																									<label
																										className={
																											isMobile
																												? ""
																												: "ms-3"
																										}
																										for="">
																										<Field
																											className="me-2 col-sm-6"
																											name={`roleStaff.${index}.sub`}
																											type="radio"
																											value="unit"
																											onChange={() => {
																												setFieldValue(
																													`roleStaff.${index}.sub`,
																													"unit",
																												);
																												setFieldTouched(
																													`roleStaff.${index}.hospitalId`,
																													true,
																												);
																											}}
																										/>
																										Unit
																									</label>
																								</div>
																								{values.roleStaff[index]
																									.sub ===
																									"division" && (
																									<div
																										className={
																											values
																												.roleStaff[
																												index
																											]
																												?.roleType ===
																											"2"
																												? "col-6 d-none"
																												: "col-6"
																										}>
																										<div class="setting-item">
																											<label class="setting-label">
																												Divisi
																											</label>
																											<PromiseDropdown
																												className="form-control"
																												emptyText="Silahkan aktifkan divisi terlebih dahulu"
																												name={`roleStaff.${index}.divisionId`}
																												promiseData={
																													touched
																														?.roleStaff[
																														index
																													]
																														?.hospitalId &&
																													getDivisionOptions(
																														selectedHospital
																															.Hospital
																															.id,
																														index,
																													)
																												}
																												value={
																													values
																														?.roleStaff[
																														index
																													]
																														?.divisionId
																												}
																												onSelect={fieldValue => {
																													setFieldValue(
																														`roleStaff.${index}.divisionId`,
																														fieldValue,
																													);
																												}}
																											/>
																										</div>
																									</div>
																								)}
																								{values.roleStaff[index]
																									.sub === "unit" && (
																									<div
																										className={
																											values
																												.roleStaff[
																												index
																											]
																												?.roleType ===
																											"2"
																												? "col-6 d-none"
																												: "col-6"
																										}>
																										<div class="setting-item">
																											<label class="setting-label">
																												Unit
																											</label>
																											<PromiseDropdown
																												className="form-control"
																												emptyText="Silahkan aktifkan unit terlebih dahulu"
																												name={`roleStaff.${index}.unitId`}
																												promiseData={
																													touched
																														?.roleStaff[
																														index
																													]
																														?.hospitalId &&
																													getUnitOptions(
																														selectedHospital
																															.Hospital
																															.id,
																														index,
																													)
																												}
																												value={
																													values
																														?.roleStaff[
																														index
																													]
																														?.unitId
																												}
																												onSelect={fieldValue => {
																													setFieldValue(
																														`roleStaff.${index}.unitId`,
																														fieldValue,
																													);
																												}}
																											/>
																										</div>
																									</div>
																								)}
																							</>
																						)}
																						{!subId.includes(
																							parseInt(
																								values?.roleStaff[index]
																									?.roleId,
																							),
																						) && (
																							<>
																								<div
																									className={
																										values
																											.roleStaff[
																											index
																										]?.roleType ===
																										"2"
																											? "col-6 d-none"
																											: "col-6"
																									}>
																									<div class="setting-item">
																										<label class="setting-label">
																											Divisi
																										</label>
																										<PromiseDropdown
																											className="form-control"
																											emptyText="Silahkan aktifkan divisi terlebih dahulu"
																											name={`roleStaff.${index}.divisionId`}
																											promiseData={
																												touched
																													?.roleStaff?.[
																													index
																												]
																													?.hospitalId &&
																												getDivisionOptions(
																													selectedHospital
																														.Hospital
																														.id,
																													index,
																												)
																											}
																											value={
																												values
																													?.roleStaff[
																													index
																												]
																													?.divisionId
																											}
																											onSelect={fieldValue => {
																												setFieldValue(
																													`roleStaff.${index}.divisionId`,
																													fieldValue,
																												);
																											}}
																										/>
																									</div>
																								</div>
																								<div
																									className={
																										values
																											.roleStaff[
																											index
																										]?.roleType ===
																										"2"
																											? "col-6 d-none"
																											: "col-6"
																									}>
																									<div class="setting-item">
																										<label class="setting-label">
																											Unit
																										</label>
																										<PromiseDropdown
																											className="form-control"
																											emptyText="Silahkan aktifkan unit terlebih dahulu"
																											name={`roleStaff.${index}.unitId`}
																											promiseData={
																												touched
																													?.roleStaff?.[
																													index
																												]
																													?.hospitalId &&
																												getUnitOptions(
																													selectedHospital
																														.Hospital
																														.id,
																													index,
																												)
																											}
																											value={
																												values
																													?.roleStaff[
																													index
																												]
																													?.unitId
																											}
																											onSelect={fieldValue => {
																												setFieldValue(
																													`roleStaff.${index}.unitId`,
																													fieldValue,
																												);
																											}}
																										/>
																									</div>
																								</div>
																							</>
																						)}
																						<div className="col-6">
																							<div class="setting-item">
																								<label class="setting-label required">
																									Status Karyawan
																								</label>
																								<BasicDropdown
																									className={clsx(
																										"form-control",
																										errors
																											?.roleStaff?.[
																											index
																										]
																											?.employeeStatus &&
																											"form-control-error",
																									)}
																									data={
																										employeeStatusOptions
																									}
																									name={`roleStaff.${index}.employeeStatus`}
																									value={
																										values
																											?.roleStaff[
																											index
																										]
																											?.employeeStatus
																									}
																									onSelect={fieldValue => {
																										setFieldValue(
																											`roleStaff.${index}.employeeStatus`,
																											fieldValue,
																										);
																									}}
																								/>
																								{errors?.roleStaff?.[
																									index
																								]?.employeeStatus &&
																									touched
																										?.roleStaff?.[
																										index
																									]
																										?.employeeStatus && (
																										<p className="form-error-item-message">
																											{
																												errors
																													?.roleStaff?.[
																													index
																												]
																													?.employeeStatus
																											}
																										</p>
																									)}
																							</div>
																						</div>
																						<div className="col-6">
																							<div class="setting-item">
																								<label class="setting-label">
																									Jabatan
																								</label>
																								<PromiseDropdown
																									className="form-control"
																									emptyText="Silahkan aktifkan jabatan terlebih dahulu"
																									name={`roleStaff.${index}.positionId`}
																									promiseData={
																										touched
																											?.roleStaff?.[
																											index
																										]?.hospitalId &&
																										getJabatanOptions(
																											selectedHospital
																												.Hospital
																												.id,
																											index,
																										)
																									}
																									value={
																										values
																											?.roleStaff[
																											index
																										]?.positionId
																									}
																									onSelect={fieldValue => {
																										setFieldValue(
																											`roleStaff.${index}.positionId`,
																											fieldValue,
																										);
																									}}
																								/>
																								{errors?.roleStaff?.[
																									index
																								]?.positionId &&
																									touched
																										?.roleStaff?.[
																										index
																									]?.positionId && (
																										<p className="form-error-item-message">
																											{
																												errors
																													?.roleStaff?.[
																													index
																												]
																													?.positionId
																											}
																										</p>
																									)}
																							</div>
																						</div>
																					</div>
																					<div className="col-2 row align-items-center">
																						<a
																							className={
																								index
																									? "text-dark"
																									: "text-dark d-none"
																							}
																							style={{
																								cursor: "pointer",
																								fontSize: "24px",
																							}}
																							onClick={() => {
																								if (index)
																									remove(index);
																							}}>
																							<span class="icon-ico-delete me-2" />
																						</a>
																					</div>
																				</div>
																				<hr className="my-5" />
																			</>
																		))}
																	<button
																		className="btn btn-success"
																		type="button"
																		onClick={() => {
																			push({
																				roleType: 1,
																				roleId: 2,
																				sub: "division",
																				divisionId: null,
																				unitId: null,
																				hospitalId:
																					selectedHospital.Hospital.id,
																				employeeStatus: 1,
																				positionId: null,
																			});
																			setFieldTouched(
																				`roleStaff.${values.roleStaff.length}.hospitalId`,
																				true,
																			);
																		}}>
																		Tambah Role
																	</button>
																</div>
															)}
														</FieldArray>
													</div>
												</div>
											</div>
										</div>
									</div>
								</Collapse>
							</div>
						</div>
						{values.employeeType === "2" ? (
							<div id="accordion">
								<div class="card mt-3">
									<div
										class="card-header d-flex justify-content-between"
										id="headingOne"
										style={{cursor: "pointer"}}
										onClick={() => setTagOpen(!tagOpen)}>
										<h3 class="mb-0">
											<button className="btn" onClick={() => setTagOpen(!tagOpen)}>
												Info Tag Discount
											</button>
										</h3>
										<h3 style={{"margin-top": "-10px"}}>&#8964;</h3>
									</div>
									<Collapse isOpened={tagOpen}>
										<div>
											<div class="card-body">
												<div class="row gx-2 mb-5">
													{tagOptions?.map(opt => {
														return (
															<div key={opt.value} class="col-sm-12 col-12">
																<div class="setting-item">
																	<div class="form-check">
																		<input
																			checked={selectedTag.selected.includes(
																				opt.value,
																			)}
																			class="form-check-input"
																			type="checkbox"
																			onChange={e => {
																				if (
																					selectedTag.selected.includes(
																						opt.value,
																					)
																				) {
																					setSelectedTag({
																						...selectedTag,
																						selected:
																							selectedTag.selected.filter(
																								x => x !== opt.value,
																							),
																					});
																				} else {
																					setSelectedTag({
																						...selectedTag,
																						selected: [
																							...selectedTag.selected,
																							opt.value,
																						],
																					});
																				}
																			}}
																		/>
																		<div className="d-flex justify-content-between align-items-center gap-2">
																			<label
																				class="text-green text-normal roboto"
																				for="check-1"
																				style={{maxWidth: "90%"}}>
																				{opt.label}
																			</label>
																			<div title="Priority">
																				<ToggleSwitch
																					isActive={
																						selectedTag.priority ===
																						opt.value
																					}
																					size="sm"
																					onChange={() => {
																						setSelectedTag({
																							...selectedTag,
																							priority:
																								selectedTag.priority ===
																								opt.value
																									? 0
																									: opt.value,
																						});
																					}}
																				/>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														);
													})}
												</div>
											</div>
										</div>
									</Collapse>
								</div>
							</div>
						) : null}
						<div class="text-center my-3">
							<SubmitButton
								className="btn btn-orange d-inline-block mw-250"
								disabled={!AddStaffFormSchemaValidation.isValidSync(values)}
								loading={isLoading}
								text="Simpan"
								onClick={handleSubmit}
							/>
						</div>
					</div>
				)}
			</Formik>
		</DashboardLayout>
	);
}
