import {useState} from "react";
import {BrowserRouter} from "react-router-dom";
import LayoutContextProvider from "contexts/LayoutContextProvider";
import {QueryClient, QueryClientProvider} from "react-query";
import {Provider} from "react-redux";
import {PersistGate} from "redux-persist/lib/integration/react";
import {ToastProvider} from "store/context/ToastContext";

// import configureStore from "./store/store";
import {persistor, store} from "././store";

import "./assets/css/index.css";
import "react-quill/dist/quill.snow.css";
import "react-phone-number-input/style.css";

import Routes from "./routes";
// const {persistor, store} = configureStore();

function App() {
	const [queryClient] = useState(() => new QueryClient({defaultOptions: {queries: {refetchOnWindowFocus: false}}}));

	return (
		<Provider store={store}>
			<LayoutContextProvider>
				<PersistGate persistor={persistor}>
					<QueryClientProvider client={queryClient}>
						<ToastProvider>
							<BrowserRouter>
								<Routes />
							</BrowserRouter>
						</ToastProvider>
					</QueryClientProvider>
				</PersistGate>
			</LayoutContextProvider>
		</Provider>
	);
}

export default App;
