import clsx from "clsx";
import * as Yup from "yup";
import {useEffect, useRef, useState} from "react";
import {useHistory, useRouteMatch} from "react-router-dom";
import {loadLocalAssets} from "utils/loader";
import {Formik} from "formik";
import {DashboardLayout} from "components/Layout";
import {CircleLoader} from "components/Loader";
import {FormCard} from "components/Form";
import {SubmitButton} from "components/Button";
import {useToast} from "hooks/useToast";
import {convertErrorMessageFormat} from "utils/converter";
import {useUpdatePoliklinik, useDetailPoliklinik} from "hooks/SuperAdmin/Poliklinik";
import {useUploadFile} from "hooks/Misc";

const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/gif", "image/png"];
const FILE_SIZE = 5242880; // 5MB

const FormSchemaValidation = Yup.object().shape({
	name: Yup.string().required("Nama poliklinik wajib di isi"),
	icon: Yup.mixed()
		.test("fileFormat", "Format icon tidak support, hanya menerima format .JPG .JPEG .PNG", value => {
			if (typeof value === "string" || value === null || !value) {
				return true;
			} else {
				return SUPPORTED_FORMATS.includes(value?.type);
			}
		})
		.test("fileSize", "Ukuran file untuk icon maksimal 5 MB", value => {
			if (typeof value === "string" || value === null || !value) {
				return true;
			} else {
				return value?.size <= FILE_SIZE;
			}
		}),
});
const FormInitialValues = {name: "", icon: ""};

export function MasterPoliklinikEditPage() {
	const {showToast} = useToast();
	const formikRef = useRef();
	const history = useHistory();
	const [iconPreview, setIconPreview] = useState(null);
	const matchParams = useRouteMatch();
	const {params} = matchParams;
	const {id} = params;
	const {isLoading: loadingFetcing, data: detailJabatan} = useDetailPoliklinik(id);
	const {mutate, isLoading: isSubmitting} = useUpdatePoliklinik();
	const {mutate: uploadFile} = useUploadFile();

	const handleEdit = values => {
		mutate(
			{id, ...values},
			{
				onSuccess: () => {
					showToast("success", "Data berhasil diperbarui");
					history.push("/master-poliklinik");
				},
				onError: res => {
					showToast("error", convertErrorMessageFormat(res.response.status, res.response.data.message), null);
				},
			},
		);
	};

	const handleSubmit = values => {
		if (values.icon && typeof values.icon === "object") {
			let formData = new FormData();

			uploadFile(formData, {
				onSuccess: result => {
					handleEdit({...values, icon: result.data?.file});
				},
			});

			formData.append("file", values.icon);
		} else {
			handleEdit(values);
		}
	};

	useEffect(() => {
		if (detailJabatan) {
			formikRef.current.setFieldValue("name", detailJabatan?.data?.name);
			formikRef.current.setFieldValue("icon", detailJabatan?.data?.icon);
			setIconPreview(detailJabatan?.data?.icon);
		}
	}, [detailJabatan]);

	if (loadingFetcing) {
		return (
			<DashboardLayout>
				<FormCard title="Edit Poliklinik">
					<CircleLoader />
				</FormCard>
			</DashboardLayout>
		);
	}

	const handleUploadLogo = e => {
		const file = e.target.files[0];
		const reader = new FileReader();

		if (!file) return;
		if (!file.type.includes("image")) {
			formikRef?.current?.setFieldError(
				"icon",
				"Format icon tidak support, hanya menerima format .JPG .JPEG .PNG",
			);

			return;
		}
		if (file.size > 5242880) {
			formikRef?.current?.setFieldError("icon", "Ukuran file untuk icon maksimal 5 MB");

			return;
		}

		reader.onload = () => {
			setIconPreview(reader.result);
		};
		reader.readAsDataURL(file);
		formikRef.current.setFieldValue("icon", file);
	};

	return (
		<DashboardLayout>
			<Formik
				initialValues={FormInitialValues}
				innerRef={formikRef}
				validationSchema={FormSchemaValidation}
				onSubmit={handleSubmit}>
				{({handleSubmit, handleBlur, handleChange, values, touched, errors}) => (
					<FormCard
						backTitle="Daftar List Poliklinik"
						title="Edit Poliklinik"
						onBackButtonClick={() => history.push("/master-poliklinik")}>
						<div class="row gx-2 mb-5">
							<div class="col-sm-12 col-lg-6  col-xs-12">
								<div class="setting-item">
									<label class="setting-label">Icon</label>
									<div class="change-img mb-4">
										<div class="for-img-change">
											<div class="bx-img-change">
												<img
													alt="Icon Poliklinik"
													class="img-fluid"
													src={
														iconPreview
															? iconPreview
															: loadLocalAssets("img/user-default.jpg")
													}
												/>
											</div>
										</div>
										<div class="for-info-change">
											<div class="upload-img-patient">
												<input id="upload" type="file" onChange={handleUploadLogo} />
												<label class="btn-upload-img-patient" for="upload">
													<img
														alt="Upload logo"
														class="img-fluid me-2"
														src={loadLocalAssets("img/icn-cam.svg")}
													/>
													Upload icon
												</label>
											</div>
											<div class="ft-12 text-grey">*Ukuran file: maks 5 Mb (.JPG .JPEG .PNG)</div>
										</div>
									</div>
									{errors?.icon && touched?.icon && (
										<p className="form-error-item-message">{errors?.icon}</p>
									)}
								</div>
							</div>
							<div class="col-sm-12 col-lg-6  col-xs-12">
								<div class="setting-item">
									<label class="setting-label required">Nama Poliklinik</label>
									<input
										class={clsx(
											"form-control",
											errors?.name && touched?.name && "form-control-error",
										)}
										disabled={isSubmitting}
										name="name"
										placeholder=""
										type="text"
										value={values?.name}
										onBlur={handleBlur}
										onChange={handleChange}
									/>
									{errors.name && touched?.name && (
										<p className="form-error-item-message">{errors.name}</p>
									)}
								</div>
							</div>
						</div>

						<div class="text-center my-3">
							<SubmitButton
								className="btn btn-orange d-inline-block mw-250"
								disabled={!FormSchemaValidation.isValidSync(values)}
								loading={isSubmitting}
								text="Simpan Perubahan"
								onClick={handleSubmit}
							/>
						</div>
					</FormCard>
				)}
			</Formik>
		</DashboardLayout>
	);
}
