/* eslint-disable no-unused-vars */
import {useState, useEffect} from "react";
import {useHistory, useParams, useLocation} from "react-router-dom";
import {Tab, Tabs, TabList, TabPanel} from "react-tabs";
import {loadLocalAssets} from "utils/loader";
import {FormCard} from "components/Form";
import {DashboardLayout} from "components/Layout";
import {CircleLoader} from "components/Loader";
import {SubmitButton} from "components/Button";
import {useDetailStaff} from "hooks/SuperAdmin/Staff";
import {isMobile} from "react-device-detect";
import "react-tabs/style/react-tabs.css";

import {ScheduleWorkAddPage} from "./Schedule/Work/ScheduleWorkAdd";
import {ScheduleWorkEditPage} from "./Schedule/Work/ScheduleWorkEdit";
import {ScheduleHolidayAddPage} from "./Schedule/Holiday/ScheduleHolidayAdd";
import {ScheduleHolidayEditPage} from "./Schedule/Holiday/ScheduleHolidayEdit";
import {ScheduleListPage} from "./Schedule/ScheduleList";
import {ScheduleAddPage} from "./Schedule/ScheduleAdd";
import {ScheduleEditPage} from "./Schedule/ScheduleEdit";

function DoctorDetail() {
	const history = useHistory();
	const params = useParams();
	const {id} = params;
	const [detail, setDetail] = useState({});
	const [userId, setUserId] = useState(null);

	const {data: detailStaff, isLoading: loadingFetcing} = useDetailStaff(id);

	useEffect(() => {
		if (detailStaff?.success) {
			setDetail({...detailStaff?.data?.staffDetail, ...detailStaff?.data?.userDetail});
			setUserId(detailStaff?.data?.userDetail?.id);
		}
	}, [detailStaff]);

	if (loadingFetcing) {
		return (
			<FormCard backTitle="Daftar List Dokter" title="Detail Data Dokter">
				<CircleLoader />
			</FormCard>
		);
	}

	return (
		// <DashboardLayout>
		<FormCard
			backTitle="Daftar List Dokter"
			title={`Detail Data ${detail?.firstName} ${detail?.lastName}`}
			onBackButtonClick={() => history.push("/doctor")}>
			<div class="row gx-2 mb-5">
				<div class="col-sm-12 col-lg-6">
					<div class="setting-item">
						<label class="setting-label ">Foto Profil</label>
						<div class="change-img mb-4">
							<div class="for-img-change">
								<div class="bx-img-change">
									<img
										alt="Profile"
										class="img-fluid"
										src={
											detail?.profilePhoto
												? detail?.profilePhoto
												: loadLocalAssets("img/user-default.jpg")
										}
									/>
								</div>
							</div>
						</div>
					</div>
					<div class="setting-item">
						<label class="setting-label ">Title</label>
						<p className="text-bold text-grey">{detail?.title}</p>
					</div>
					<div class="setting-item">
						<label class="setting-label ">Nama Depan</label>
						<p className="text-bold text-grey">{detail?.firstName}</p>
					</div>
					<div class="setting-item">
						<label class="setting-label ">Nama Belakang</label>
						<p className="text-bold text-grey">{detail?.lastName}</p>
					</div>
					<div class="setting-item">
						<label class="setting-label ">Nama Panggilan</label>
						<p className="text-bold text-grey">{detail?.nickname}</p>
					</div>
					<div class="setting-item">
						<label class="setting-label ">Email</label>
						<p className="text-bold text-grey">{detail?.email}</p>
					</div>
					<div class="setting-item">
						<label class="setting-label ">Nomor Telepon</label>
						<p className="text-bold text-grey">{detail?.phoneNumber}</p>
					</div>
					<div class="setting-item">
						<label class="setting-label ">Usia</label>
						<p className="text-bold text-grey">{detail?.dobText}</p>
					</div>
				</div>

				<div class="col-sm-12 col-lg-6">
					<div class="setting-item">
						<label class="setting-label ">Status Karyawan</label>
						<p className="text-bold text-grey">{detail?.employeeStatusText}</p>
					</div>
					<div class="setting-item">
						<label class="setting-label ">NIP</label>
						<p className="text-bold text-grey">{detail?.nip}</p>
					</div>
					<div class="setting-item">
						<label class="setting-label ">No STR</label>
						<p className="text-bold text-grey">{detail?.noStr}</p>
					</div>
				</div>
			</div>

			<div class="text-center my-3">
				<SubmitButton
					className="btn btn-orange d-inline-block mw-250"
					text="Ubah Data Ini"
					onClick={() => history.push(`/staff/edit/${id}`)}
				/>
			</div>
		</FormCard>
		// </DashboardLayout>
	);
}

export function DoctorDetailPage() {
	const [tab, setTab] = useState(0);
	const [scheduleId, setScheduleId] = useState();
	const params = useParams();
	const {hash} = useLocation;
	const {id} = params;
	const {data: detailStaff, isLoading: loadingFetcing} = useDetailStaff(id);
	const [userId, setUserId] = useState(null);
	const [availableHospital, setAvailableHospital] = useState(null);

	useEffect(() => {
		if (detailStaff?.success) {
			setUserId(detailStaff?.data?.userDetail?.id);

			let idHospitals = [];

			detailStaff?.data?.roleDetail?.rows?.forEach(item => {
				// eslint-disable-next-line eqeqeq
				if (item.hospitalId && item.roleId == 2) idHospitals.push(item.hospitalId);
			});

			setAvailableHospital([...idHospitals]);
		}
	}, [detailStaff]);

	useEffect(() => {
		if (hash === "add-jadwal-praktik") setTab(2);
		if (hash === "add-jadwal-masuk") setTab(3);
		if (hash === "add-jadwal-libur") setTab(4);
	}, [hash]);

	return (
		<DashboardLayout>
			<Tabs className="mb-0" selectedIndex={tab} onSelect={index => setTab(index)}>
				<div class="" style={isMobile ? {"overflow-x": "auto", "white-space": "nowrap"} : {}}>
					<TabList className="border-0">
						<Tab>Detail Dokter</Tab>
						<Tab>Jadwal Dokter</Tab>
						<Tab>Setting Jadwal Praktik</Tab>
						<Tab>Setting Jadwal Spesial - Masuk</Tab>
						<Tab>Setting Jadwal Spesial - Libur</Tab>
					</TabList>
				</div>

				<div className="box-white setting-content h-100 no-border p24">
					<TabPanel>
						<DoctorDetail />
					</TabPanel>
					<TabPanel>
						<ScheduleListPage
							setScheduleId={id => setScheduleId(id)}
							setTab={index => setTab(index)}
							userId={userId}
						/>
					</TabPanel>
					<TabPanel>
						{scheduleId && (
							<ScheduleEditPage
								availableHospital={availableHospital}
								scheduleId={scheduleId}
								setTab={index => setTab(index)}
								userId={userId}
								onUnmounted={() => setScheduleId(null)}
							/>
						)}
						{!scheduleId && (
							<ScheduleAddPage
								availableHospital={availableHospital}
								setTab={index => setTab(index)}
								userId={userId}
							/>
						)}
					</TabPanel>
					<TabPanel>
						{scheduleId && (
							<ScheduleWorkEditPage
								availableHospital={availableHospital}
								scheduleId={scheduleId}
								setTab={index => setTab(index)}
								userId={userId}
								onUnmounted={() => setScheduleId(null)}
							/>
						)}
						{!scheduleId && (
							<ScheduleWorkAddPage
								availableHospital={availableHospital}
								setTab={index => setTab(index)}
								userId={userId}
							/>
						)}
					</TabPanel>
					<TabPanel>
						{scheduleId && (
							<ScheduleHolidayEditPage
								scheduleId={scheduleId}
								setTab={index => setTab(index)}
								userId={userId}
								onUnmounted={() => setScheduleId(null)}
							/>
						)}
						{!scheduleId && <ScheduleHolidayAddPage setTab={index => setTab(index)} userId={userId} />}
					</TabPanel>
				</div>
			</Tabs>
		</DashboardLayout>
	);
}
