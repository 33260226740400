/* eslint-disable eqeqeq */
/* eslint-disable react/jsx-sort-props */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-unused-vars */
import {useState, useRef, useEffect} from "react";
import {useHistory, useParams} from "react-router-dom";
import {DashboardLayout} from "components/Layout";
import {CircleLoader} from "components/Loader";
import {FormCard} from "components/Form";
import {useDetailAppointment} from "hooks/Doctor/Appointment";
import {loadLocalAssets} from "utils/loader";
import {converToLocalGender} from "utils/converter";
import {ModalContainer} from "components/Modal";
import {useAppointmentByPatient} from "hooks/Doctor/Appointment";
import {useListFileLabByPatientId} from "hooks/AdminLaboratorium/FileLab";
import {ModalViewPDF} from "components/Modal/ModalViewPdf";
import dayjs from "dayjs";
import clsx from "clsx";
import {capitalizeFirstLetter} from "utils/string";

export function AppointmentDetailPage() {
	const timelineRef = useRef();
	const history = useHistory();
	const {id} = useParams();
	const {data: detailAppointment, isLoading: loadingFetcing} = useDetailAppointment(id);
	const [timelineScroll, setTimelineScroll] = useState(0);
	const [openModalDetail, setOpenModalDetail] = useState(false);
	const [patientId, setPatientId] = useState();
	const [patientUserId, setPatientUserId] = useState();
	const {
		data: dataHistory,
		filterAppointments: setFilterAppointment,
		filter: filterAppointment,
	} = useAppointmentByPatient(patientId);
	const [tab, setTab] = useState(1);
	const [searchKey, setSearchKey] = useState("");
	const {data: dataFileLab} = useListFileLabByPatientId(patientUserId, {
		page: 1,
		limit: 10,
		orderBy: "id",
		sort: "desc",
	});
	const [detailHistory, setDetailHistory] = useState({});

	const [previewDocument, setPreviewDocument] = useState({
		show: false,
		fileSrc: null,
		title: undefined,
	});

	useEffect(() => {
		if (detailAppointment?.success) {
			setPatientId(detailAppointment.data.patient.id);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [detailAppointment]);

	useEffect(() => {
		function handleScroll() {
			const {scrollHeight, scrollTop, clientHeight} = timelineRef.current;

			setTimelineScroll((scrollTop / (scrollHeight - clientHeight)) * 100);
		}
		if (timelineRef && timelineRef.current) {
			timelineRef.current.addEventListener("scroll", handleScroll);

			return () => {
				// eslint-disable-next-line react-hooks/exhaustive-deps
				timelineRef.current?.removeEventListener("scroll", handleScroll);
			};
		}
	}, [timelineRef, loadingFetcing]);

	if (loadingFetcing) {
		return (
			<DashboardLayout>
				<FormCard backTitle="Daftar list appointment" title="Rekam Medis">
					<CircleLoader />
				</FormCard>
			</DashboardLayout>
		);
	}

	return (
		<DashboardLayout>
			<ModalViewPDF
				fileSrc={previewDocument.fileSrc}
				title={previewDocument.title}
				visible={previewDocument.show}
				onClose={() => setPreviewDocument({show: false, fileSrc: null, undefined})}
			/>
			<ModalContainer visible={openModalDetail}>
				<div id="open-detail" className="box-popup w430">
					<div className="d-flex justify-content-between align-items-center mb-4">
						<div className="text-bold text-dark">Detail Rekam Medis</div>
						<div className="for-close">
							<button className="btn btn-close" type="button" onClick={() => setOpenModalDetail(false)} />
						</div>
					</div>

					<div className="popup-content scroll">
						<div className="box-border p15 mb-2">
							<div className="text-bold text-dark mb-3">Pemeriksaan Penunjang</div>
							{!dataFileLab?.data?.rows?.length && <p className="text-muted">-</p>}
							{dataFileLab?.data?.rows?.map(item => (
								<div className="position-relative mb-2">
									<input type="text" className="form-control ns pe-5" value={item.name} />
									<div className="icon-right">
										<a
											className="text-orange"
											style={{cursor: "pointer"}}
											onClick={() => {
												setOpenModalDetail(false);
												setPreviewDocument({show: true, fileSrc: item.file, title: item.name});
											}}>
											<span className="icon-ico-eye-open" />
										</a>
									</div>
								</div>
							))}
						</div>
						<div className="box-border p15 mb-2">
							<div className="text-bold text-dark mb-3">Anamnesis</div>
							<div className="mb-2">
								<div className="ft-12 text-light-grey mb-1">Keluhan utama</div>
								<div className="text-dark">{detailHistory?.medicalRecord?.keluhan || "-"}</div>
							</div>
							<div className="mb-2">
								<div className="ft-12 text-light-grey mb-1">Riwayat penyakit</div>
								<div className="text-dark">{detailHistory?.medicalRecord?.riwayat || "-"}</div>
							</div>
							<hr />
							<div className="text-bold text-dark mb-3">Pemeriksaan Fisik</div>
							{!!!detailHistory?.medicalRecord?.bodyCheckups?.length && "-"}
							{detailHistory?.medicalRecord?.bodyCheckups?.map(item => (
								<div className="mb-2">
									<div className="ft-12 text-light-grey mb-1">Pemerikasaan {item.locationName}</div>
									<div className="text-dark">{item.note}</div>
								</div>
							))}
							{detailHistory?.medicalRecord?.bodyCheckupResult && (
								<div className="mb-2">
									<div className="ft-12 text-light-grey mb-1">Note</div>
									<div className="text-dark">{detailHistory?.medicalRecord?.bodyCheckupResult}</div>
								</div>
							)}
						</div>
						<div className="box-border p15 mb-2">
							<div className="text-bold text-dark mb-3">Tata Laksana</div>
							<div className="mb-2">
								<div className="ft-12 text-light-grey mb-1">Tindakan</div>
								<div className="text-dark">
									{detailHistory.medicalRecord?.actionDiv?.Action?.title || "Hanya pemeriksaan biasa"}
								</div>
								<div className="ft-12 text-light-grey mb-1">Note</div>
								<div className="text-dark">
									{detailHistory.medicalRecord?.actionDivisionNote || "-"}
								</div>
							</div>
							<hr />
							{!!detailHistory.medicalRecord?.mrMedicines?.length && (
								<>
									<div className="text-bold text-dark mb-3">Obat</div>
									<div className="obat-row">
										<div className="obat-col-1">
											<div className="text-600 text-grey">Nama obat</div>
										</div>
										<div className="obat-col-2">
											<div className="text-600 text-grey">Jumlah</div>
										</div>
										<div className="obat-col-3">
											<div className="text-600 text-grey">Aturan pakai</div>
										</div>
									</div>
									{detailHistory.medicalRecord?.mrMedicines?.map(item => (
										<div className="obat-row">
											<div className="obat-col-1">
												<div className="text-600 text-dark">
													{item?.medicineHospital?.medicine?.name}
												</div>
												<div className="text-600 text-grey ft-12">{item.note}</div>
											</div>
											<div className="obat-col-2">
												<div className="text-600 text-dark">{item.total}</div>
											</div>
											<div className="obat-col-3">
												<div className="text-600 text-dark">
													{item.frequency} X / {item.period} Hari
												</div>
											</div>
										</div>
									))}
								</>
							)}
						</div>
					</div>
				</div>
			</ModalContainer>

			<div>
				<a
					className="text-bold d-inline-block text-dark mb-4"
					style={{cursor: "pointer"}}
					onClick={() => history.push("/doctor/appointment/list")}>
					<span className="icon-ico-back me-2" />
					List Appointment
				</a>
				<div className="text-bold text-dark mb-4">Rekam Medis</div>

				<div className="row" id="ma">
					<div className="col-lg-8 col-xl-8 col-xxl-9 mb-4 mb-lg-0 mb-xl-0">
						<div className="d-flex flex-wrap align-items-center mb-4">
							<div className="search position-relative mb-3 mb-lg-0 mb-xl-0 mb-sm-0">
								<input
									className="form-control"
									placeholder="Cari nama dokter"
									type="text"
									onChange={e => setSearchKey(e.target.value)}
									onKeyDown={e => {
										if (e.key === "Enter")
											setFilterAppointment({...filterAppointment, search: searchKey});
									}}
								/>
								<div className="search-icon">
									<span className="icon-ico-search" />
								</div>
							</div>
							<div className="text-bold text-dark mb-3 mb-sm-0 mb-lg-0 ms-sm-3 mb-xl-0">
								{" "}
								Detail Rekam Medis
							</div>
						</div>
						<div className="time-line">
							<div className="inner-timeline" style={{overflowY: "scroll"}}>
								<nav className="timeline__nav">
									<ul>
										{!dataHistory?.data?.rows?.filter(item => item.medicalRecord)?.length && (
											<li>
												<span>
													<div className="box-white wb ws p15">Belum ada data</div>
												</span>
											</li>
										)}
										{dataHistory?.data?.rows
											?.filter(item => item.medicalRecord)
											?.map(history => (
												<li>
													<span>
														<div className="box-white scrollTo wb ws p15">
															<div className="d-flex">
																<div className="circle-img">
																	<img
																		className="img-fluid"
																		src={history.doctor?.profilePhoto}
																	/>
																</div>
																<div className="ms-3">
																	<div className="text-600 text-dark">
																		Dr. {history.doctor?.fullName}
																	</div>
																	<div className="text-grey">
																		{dayjs(history.date).format("DD MMMM YYYY")}
																	</div>
																</div>
															</div>
															{!!history.diagnose && (
																<>
																	<hr className="my-2" />
																	<div className="text-light-grey ft-12 mb-0">
																		Diagnosis
																	</div>
																	<div className="text-dark">{history.diagnose}</div>
																</>
															)}
														</div>
													</span>
												</li>
											))}
									</ul>
								</nav>
							</div>
							<div className="box-line">
								<div className="ft-now text-orange">Now</div>
								<div className="for-line">
									<span className="default-line" />
									<span className="draw-line" style={{height: `${timelineScroll}%`}} />
								</div>
								<div className="ft-then">Then</div>
							</div>
							<section className="timeline__section">
								<div ref={timelineRef} className="wrapper" style={{overflowY: "scroll"}}>
									<div className="timeline">
										<ul>
											{!dataHistory?.data?.rows?.filter(item => item.medicalRecord)?.length && (
												<li key={history.id}>
													<div className="milestone" />
													<div className="box-white wb ws p15 text-center">
														Belum ada data
													</div>
												</li>
											)}
											{dataHistory?.data?.rows
												?.filter(item => item.medicalRecord)
												?.map(history => (
													<>
														<li key={history.id}>
															<div className="milestone" />
															<div className="box-white wb ws p15">
																<div className="dokter-info mb-3">
																	<div className="img-part">
																		<div className="d-flex">
																			<div className="circle-img sm">
																				<img
																					className="img-fluid"
																					src={history.doctor?.profilePhoto}
																					// src={loadLocalAssets(
																					// 	"img/dokter-3.png",
																					// )}
																				/>
																			</div>
																			<div className="ms-2">
																				Dr. {history.doctor?.fullName}
																			</div>
																		</div>
																	</div>
																	<div className="ft-12 text-grey">
																		{dayjs(history.date).format("DD MMMM YYYY")}
																	</div>
																</div>
																<div className="box-border p15">
																	<ul className="no-info">
																		<li>
																			{history.patient?.patient
																				?.medicalRecordNumber || "-"}
																		</li>
																		<li>{history.hospital?.name || "-"}</li>
																	</ul>
																	<hr className="my-2" />
																	{!!history.medicalRecord?.diagnose?.name && (
																		<div className="mb-3">
																			<div className="text-light-grey ft-12 mb-1">
																				Diagnosis
																			</div>
																			<div className="text-dark">
																				{history.medicalRecord?.diagnose?.name}
																			</div>
																		</div>
																	)}
																	{!!history.medicalRecord?.riwayat && (
																		<>
																			<div className="mb-3">
																				<div className="text-light-grey ft-12 mb-1">
																					Riwayat penyakit
																				</div>
																				<div className="text-dark">
																					{history.medicalRecord?.riwayat}
																				</div>
																			</div>
																			<hr className="my-2" />
																		</>
																	)}
																	<button
																		className="btn w-100 open-detail text-orange"
																		onClick={() => {
																			setPatientUserId(
																				history?.patient?.patient?.id,
																			);
																			setDetailHistory(history);
																			setOpenModalDetail(true);
																		}}>
																		Lihat Detail
																	</button>
																</div>
															</div>
														</li>
													</>
												))}
										</ul>
									</div>
								</div>
							</section>
						</div>
					</div>

					<div className="col-lg-4 col-xl-4 col-xxl-3">
						<div className="appd-inner">
							{/* {["pasien", "belum_dilayani", "sedang_dilayani"].includes(
								detailAppointment.data?.patientStatus,
							) && (
								<button
									className={clsx(
										"btn text-bold mb-3 btn-orange r8 w-100",
										detailAppointment.data?.type === "online" &&
											detailAppointment.data?.transaction?.paymentStatus != "paid" &&
											"disabled",
										detailAppointment.data?.type === "offline" &&
											detailAppointment.data?.patientStatus === "pasien" &&
											"disabled",
									)}
									style={{cursor: "pointer"}}
									onClick={() => history.push(`/doctor/appointment/${id}/medical-record/create`)}>
									Buat Rekam Medis
								</button>
							)} */}

							<div className="box-white bg-logo ws p24 mb-3">
								<div className="mb-2">
									<div className="ft-12 text-light-grey mb-2">Nomor</div>
									<div className="text-dark">
										{detailAppointment.data?.patient?.patient?.medicalRecordNumber || "-"}
									</div>
								</div>
								<div className="mb-2">
									<div className="ft-12 text-light-grey mb-2">Status</div>
									{detailAppointment.data?.medicalRecord && (
										<div className="box-status serve">Sudah dilayani</div>
									)}
									{!detailAppointment.data?.medicalRecord && (
										<>
											{["pasien", "belum_dilayani", "sedang_dilayani"].includes(
												detailAppointment.data?.patientStatus,
											) && <div className="box-status not-serve">Belum dilayani</div>}
											{!["pasien", "belum_dilayani", "sedang_dilayani"].includes(
												detailAppointment.data?.patientStatus,
											) && <div className="box-status serve">Sudah dilayani</div>}
										</>
									)}
								</div>
								<div className="mb-2">
									<div className="ft-12 text-light-grey mb-2">Nama Pasien</div>
									<div className="text-dark">{detailAppointment.data.patient.fullName}</div>
								</div>
							</div>

							<nav>
								<div className="nav nav-tabs appd-tab no-border" id="nav-tab" role="tablist">
									<button
										aria-controls="nav-home"
										aria-selected="true"
										className={clsx(["nav-link", tab === 1 && "active"])}
										data-bs-target="#nav-home"
										data-bs-toggle="tab"
										id="nav-home-tab"
										role="tab"
										type="button"
										onClick={() => setTab(1)}>
										Info
									</button>
									<button
										aria-controls="nav-profile"
										aria-selected="false"
										className={clsx(["nav-link", tab === 2 && "active"])}
										data-bs-target="#nav-profile"
										data-bs-toggle="tab"
										id="nav-profile-tab"
										role="tab"
										type="button"
										onClick={() => setTab(2)}>
										Next Appointment
									</button>
								</div>
							</nav>
							<div className="tab-content" id="nav-tabContent">
								<div
									aria-labelledby="nav-home-tab"
									className={clsx(["tab-pane fade", tab === 1 && "show active"])}
									id="nav-home"
									role="tabpanel">
									<div className="box-white ws p24">
										<div className="mb-2">
											<div className="ft-12 text-light-grey mb-1">Kelamin</div>
											<div className="text-dark">
												{converToLocalGender(detailAppointment.data.patient.gender)}
											</div>
										</div>
										<div className="mb-2">
											<div className="ft-12 text-light-grey mb-1">Umur</div>
											<div className="text-dark">{detailAppointment.data.patient.dobText}</div>
										</div>
										<div className="mb-2">
											<div className="ft-12 text-light-grey mb-1">Status</div>
											<div className="text-dark">
												{capitalizeFirstLetter(detailAppointment.data.patient.maritalStatus)}
											</div>
										</div>
									</div>
								</div>
								<div
									aria-labelledby="nav-profile-tab"
									className={clsx(["tab-pane fade", tab === 2 && "show active"])}
									id="nav-profile"
									role="tabpanel">
									<div className="box-white ws p24">
										{detailAppointment.data.medicalRecord?.nextAppointment && (
											<div className="row align-items-center">
												<div className="col-10">
													<div className="ft-12 mb-1">Layanan A</div>
													<div className="text-dark ft-12 text-bold">
														{`${
															detailAppointment.data.medicalRecord?.nextAppointment
																?.startTime
														} WIB - ${dayjs(
															detailAppointment.data.medicalRecord?.nextAppointment?.date,
														).format("DD MMMM YYYY")}`}
													</div>
												</div>
												<div className="col-2">
													<button
														className="btn ft-18 d-info text-orange"
														onClick={() =>
															history.push(
																`/doctor/appointment/show/${detailAppointment.data.medicalRecord?.nextAppointment?.id}`,
															)
														}>
														<span className="icon-ico-eye-open" />
													</button>
												</div>
											</div>
										)}
										{!detailAppointment.data.medicalRecord?.nextAppointment && (
											<div className="row align-items-center">
												<div className="col-10">
													<div className="text-dark ft-12 text-bold">Belum ada</div>
												</div>
											</div>
										)}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</DashboardLayout>
	);
}
