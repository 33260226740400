import {useState} from "react";
import {useQuery} from "react-query";
import axios from "services/axios";
import qs from "query-string";

export const QUERY = "rs_action";

async function getActions(id, filter) {
	if (!id) {
		const {data} = await axios.get(`/action`, {params: {...filter, sortBy: "id.desc"}});

		return data;
	}
	const {data} = await axios.get(`/action/${id}`);

	return data;
}

export function useListAvailableAction(hospitalId) {
	const [filter, filterActions] = useState({
		page: 1,
		search: null,
		limit: 10,
		hospitalId,
	});
	const [actionId, actionById] = useState(null);
	const fallback = [];
	const {
		data = fallback,
		isLoading,
		isError,
		error,
	} = useQuery([QUERY, filter, actionId], async () => getActions(actionId, filter));

	return {data, isLoading, isError, error, filter, filterActions, actionById};
}

export const fetchAction = filter => {
	const query = qs.stringify(filter);

	return axios.get(`action/?sortBy=id.desc&${query}`).then(res => res.data);
};

export function useListActionById(filter) {
	return useQuery({
		queryKey: [QUERY, filter],
		queryFn: key => fetchAction(filter),
		refetchOnMount: false,
		enabled: !!Object.keys(filter).length,
	});
}
