import {createSlice} from "@reduxjs/toolkit";

const initialState = {
	userZoom: null,
};

export const zoomSlice = createSlice({
	name: "@ZOOM",
	initialState,
	reducers: {
		setUserZoom: (state, action) => {
			state.userZoom = action.payload;
		},
	},
});

export const {setUserZoom} = zoomSlice.actions;

export default zoomSlice.reducer;
