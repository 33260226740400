import axios from "services/axios";
import {useMutation, useQueryClient} from "react-query";

import {QUERY} from "./useQueryConsultation";

export function useAddConsultation() {
	const queryClient = useQueryClient();

	return useMutation(formData => axios.post("/consultation/add_consultation", formData).then(res => res.data), {
		onSuccess: () => {
			queryClient.invalidateQueries(QUERY);
			queryClient.removeQueries(QUERY);
		},
	});
}

export function useChangeDataStatus() {
	const queryClient = useQueryClient();

	return useMutation(
		formData => axios.post("/consultation/change_status_consultation", formData).then(res => res.data),
		{
			onSuccess: () => {
				queryClient.invalidateQueries(QUERY);
				queryClient.removeQueries(QUERY);
			},
		},
	);
}
