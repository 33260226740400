import axios from "axios";

const BASE_URL = "https://social-helper-bice.vercel.app";
let instance = null;

function makeInstance() {
	if (instance) {
		return instance;
	}
	instance = axios.create({
		baseURL: BASE_URL,
		timeout: 60000,
	});

	return instance;
}

const unplashInstance = makeInstance();

export default unplashInstance;
