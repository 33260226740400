import {FormCard} from "components/Form";
import {DashboardLayout} from "components/Layout";
import {ModalConfirmation} from "components/Modal";
import CONFIG from "config/env";
import dayjs from "dayjs";
import {useDetailArticleReport} from "hooks/SuperAdmin/Article";
import {useSuspendArticle} from "hooks/SuperAdmin/Article/Report/useMutationArticleReport";
import {useToast} from "hooks/useToast";
import {useState} from "react";
import {Button} from "react-bootstrap";
import {useHistory, useParams} from "react-router-dom";
import {convertErrorMessageFormat} from "utils/converter";
import {getUserDetailUrl} from "utils/string";

const ReportStatusDetailPage = () => {
	const history = useHistory();
	const {id} = useParams();
	const {data: detail, isRefetching, refetch} = useDetailArticleReport(id, "post");
	const suspendMutation = useSuspendArticle();
	const [showSuspendModal, setShowSuspendModal] = useState(false);
	const {showToast} = useToast();
	const isLoading = isRefetching || suspendMutation.isLoading;

	const reports = !!detail?.data?.reports
		? detail?.data?.reports?.map(report => ({
				reportBy: report?.user?.fullName,
				reportDate: dayjs(report?.createdAt).format("DD MMMM YYYY"),
				reasonTitle: report?.reasonTitle,
				reasonDescription: report?.reasonDescription,
		  }))
		: [];

	const updateSuspendStatus = () => {
		if (id && !isLoading) {
			suspendMutation.mutate(
				{id, type: "post", isSuspend: !detail?.data?.isSuspend},
				{
					onSuccess: () => {
						refetch();
						toggleShowSuspendModal();
						showToast("success", `Artikel berhasil diperbarui!`, 3000);
					},
					onError: res => {
						toggleShowSuspendModal();
						showToast(
							"error",
							convertErrorMessageFormat(res.response.status, res.response.data.message),
							null,
						);
					},
				},
			);
		}
	};

	const toggleShowSuspendModal = () => setShowSuspendModal(!showSuspendModal);

	return (
		<DashboardLayout>
			<ModalConfirmation
				approveLabel="Ya"
				description={`Apakah Anda yakin ingin  ${detail?.data?.isSuspend ? "unsuspend" : "suspend"} post "${
					detail?.data?.title
				}"?`}
				loading={isLoading}
				rejectLabel="Batal"
				visible={showSuspendModal}
				onApprove={updateSuspendStatus}
				onClose={toggleShowSuspendModal}
				onReject={toggleShowSuspendModal}
			/>
			<FormCard
				backTitle="Daftar List Report"
				title={`Detail Data Report Post`}
				onBackButtonClick={history.goBack}>
				<div class="row gx-2">
					<div class="col-sm-6 col-6">
						<div class="setting-item">
							<label class="setting-label">Author</label>
							<p className="text-bold text-grey d-flex align-items-center justify-content-between">
								{detail?.data?.user?.firstName} - {detail?.data?.user?.email}
								<a
									class="text-orange wait-pay d-flex"
									style={{cursor: "pointer"}}
									onClick={() => history.push(getUserDetailUrl(detail?.data?.user))}>
									<span class="icon-ico-eye-open ft-18" />
								</a>
							</p>
						</div>
					</div>
				</div>
				<div class="row gx-2">
					<div class="col-sm-6 col-6">
						<div class="setting-item">
							<label class="setting-label">Title Post</label>
							<p className="text-bold text-grey d-flex align-items-center justify-content-between">
								{detail?.data?.title}
								<div className="d-flex align-items-center gap-3">
									<a
										class="text-orange wait-pay d-flex"
										href={`${CONFIG.endpoint.PATIENT_URL}/artikel/${detail?.data?.slug}`}
										rel="noreferrer"
										style={{cursor: "pointer"}}
										target="_blank">
										<span class="icon-ico-eye-open ft-18" />
									</a>
									<Button variant="outline-danger" onClick={toggleShowSuspendModal}>
										{detail?.data?.isSuspend ? "Unsuspend" : "Suspend"} Post
									</Button>
								</div>
							</p>
						</div>
					</div>
				</div>

				<hr />

				<div class="row gx-2">
					<div class="col-sm-6 col-6">
						<div class="setting-item">
							<label class="setting-label">Jumlah Report</label>
							<p className="text-bold text-grey">{reports?.length || 0}</p>
						</div>
					</div>
				</div>
				<div class="row gx-2">
					<div class="col-sm-6 col-6">
						<div class="setting-item">
							<label class="setting-label">Report By & Report Date</label>
							{reports?.map(report => (
								<p className="text-bold text-grey mb-0">
									{report?.reportBy} - {report?.reportDate}
								</p>
							))}
						</div>
					</div>
				</div>
				<div class="row gx-2">
					<div class="col-sm-6 col-6">
						<div class="setting-item">
							<label class="setting-label">Reason Report</label>
							<p className="text-bold text-grey mb-0">
								{reports?.map(report => report.reasonTitle)?.join(", ")}
							</p>
						</div>
					</div>
				</div>
			</FormCard>
		</DashboardLayout>
	);
};

export {ReportStatusDetailPage};
