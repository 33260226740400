import dayjs from "dayjs";
import utcPlugin from "dayjs/plugin/utc";
import timeZonePlugin from "dayjs/plugin/timezone";

export function getDayName(dateStr, locale) {
	const date = new Date(dateStr);

	return date.toLocaleDateString(locale, {weekday: "long"});
}

export function converISOtoDate(iso, format) {
	if (format) {
		return dayjs(iso).format(format);
	}

	return dayjs(iso).format("DD MMMM YYYY");
}

export function getTodayDate(format) {
	if (format) {
		return dayjs().format(format);
	}

	return dayjs().format("DD MMMM YYYY");
}

dayjs.extend(utcPlugin);
dayjs.extend(timeZonePlugin);

export function renderTimeWithTimeZone(time, timezone = "Asia/Jakarta") {
	if (!time) return "";

	return dayjs(`${dayjs().format("YYYY-MM-DD")} ${time}`)
		.tz(timezone)
		.format("HH:mm");
}

export function getGMT(timezone = "Asia/Jakarta") {
	const Z = dayjs().tz(timezone).format("Z");

	return "GMT" + Z;
}

export function getDiffTimeMinutes(time1, time2) {
	return (
		dayjs(`${dayjs().format("YYYY MM DD")} ${time2}`).diff(`${dayjs().format("YYYY MM DD")} ${time1}`, "minutes") +
		" Menit"
	);
}
