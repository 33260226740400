import {useState} from "react";
import {useQuery} from "react-query";
import axios from "services/axios";

async function getForumCategories(params) {
	const {data} = await axios.get(`/forum-categories`, {params});

	return data;
}

function useForumCategories(defaultFilter = {}) {
	const [filter, setFilter] = useState({
		page: 1,
		search: null,
		limit: 10,
		sortBy: "id.desc",
		...defaultFilter,
	});
	const query = useQuery(["forum-categories", filter], async () => getForumCategories(filter));

	return {...query, filter, setFilter};
}

const getForumCategory = forumCategoryId => {
	return axios.get(`/forum-categories/${forumCategoryId}`).then(res => res.data);
};

function useDetailForumCategory(forumCategoryId) {
	return useQuery({
		queryKey: forumCategoryId && ["forum-categories", forumCategoryId],
		queryFn: () => getForumCategory(forumCategoryId),
		refetchOnMount: false,
		refetchOnWindowFocus: false,
		enabled: !!forumCategoryId,
	});
}

export {useDetailForumCategory, useForumCategories};
