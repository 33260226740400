import {useMutation, useQueryClient} from "react-query";
import axios from "services/axios";

export function useAddGroupMember() {
	const queryClient = useQueryClient();

	const {mutate, isLoading, isError, data, error} = useMutation(
		async data => await axios.post("/group-member", data),
		{
			onSuccess: () => {
				queryClient.invalidateQueries("groupMember");
				queryClient.removeQueries("groupMember");
			},
		},
	);

	return {mutate, isLoading, isError, data, error};
}

export function useEditGroupMember(id) {
	const queryClient = useQueryClient();

	const {mutate, isLoading, isError, data, error} = useMutation(
		async data => await axios.put(`/group-member/${id}`, data),
		{
			onSuccess: () => {
				queryClient.invalidateQueries("groupMember");
				queryClient.removeQueries("groupMember");
			},
		},
	);

	return {mutate, isLoading, isError, data, error};
}

export function useDeleteGroupMember() {
	const queryClient = useQueryClient();

	return useMutation(
		groupMemberData => axios.delete(`/group-member/${groupMemberData.id}`, groupMemberData).then(res => res.data),
		{
			onSuccess: () => {
				queryClient.invalidateQueries("groupMember");
				queryClient.removeQueries("groupMember");
			},
		},
	);
}
