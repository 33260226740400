import {ToggleSwitch} from "components/Button";
import {DashboardLayout} from "components/Layout";
import {ModalConfirmation} from "components/Modal";
import {BasicTable, SearchBox} from "components/Table";
import {useChangeStatusPemeriksaanPenunjang, useListPemeriksaanPenunjang} from "hooks/SuperAdmin/PemeriksaanPenunjang";
import {useStateCallback} from "hooks/useStateCallback";
import {useToast} from "hooks/useToast";
import {useMemo, useState} from "react";
import {isMobile} from "react-device-detect";
import {useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {convertErrorMessageFormat} from "utils/converter";
import {handleSort} from "utils/sort";

export function PemeriksaanPenunjangListPage() {
	const {showToast} = useToast();
	const history = useHistory();

	const selectedHospital = useSelector(state => state.HospitalsReducer.selectedHospital);
	const [filter, setFilter] = useState({
		page: 1,
		search: "",
		limit: 10,
		sortBy: "id.desc",
		hospitalId: selectedHospital?.Hospital?.id,
	});
	const {data, isLoading} = useListPemeriksaanPenunjang(filter);

	const {mutate, isLoading: isUpdatingData} = useChangeStatusPemeriksaanPenunjang();

	const [selectedItem, setSelectedItem] = useStateCallback({id: null, medicineName: null});
	const [showDeleteModal, setShowDeleteModal] = useState(false);

	const actionColumnRenderer = row => {
		return (
			<div className="d-flex align-items-center">
				<a
					className="text-orange wait-pay"
					style={{cursor: "pointer"}}
					onClick={() => history.push(`/hospital/checkup-support/show/${row?.values?.id}`)}>
					<span className="icon-ico-eye-open ft-18" />
				</a>

				<ToggleSwitch
					isActive={row.original.isActive}
					onChange={() => {
						setSelectedItem(row.original, () => setShowDeleteModal(true));
					}}
				/>
			</div>
		);
	};

	const columns = useMemo(
		() => [
			{
				Header: "Kategori Pemeriksaan",
				accessor: "checkupCategoryId",
				Cell: ({row}) => {
					return <span className="font-bold">{row?.original?.CheckupCategory?.name}</span>;
				},
			},
			{
				Header: "Nama Pemeriksaan",
				accessor: "title",
			},
			{
				centered: true,
				Header: "Aksi",
				accessor: "isActive",
				width: "200px",
				Cell: ({row}) => {
					return actionColumnRenderer(row);
				},
				sortType: handleSort,
			},
		],
		[],
	);
	const columnsMobile = useMemo(
		() => [
			{
				Header: "Nama Pemeriksaan",
				accessor: "title",
				Cell: ({row}) => {
					return (
						<div className="col-4">
							<span className="text-bold">{row?.values?.title}</span>
						</div>
					);
				},
			},
			{
				Header: "Action",
				accessor: "isActive",
				Cell: ({row}) => {
					return <div className="col-8">{actionColumnRenderer(row)}</div>;
				},
				sortType: handleSort,
			},
		],
		[],
	);

	const handeChangeStatusItem = () => {
		mutate(
			{checkupSupportId: selectedItem.id, hospitalId: selectedHospital.Hospital.id},
			{
				onSuccess: () => {
					showToast(
						"success",
						`Data pemeriksaan penunjang "${selectedItem.title}" berhasil di ${
							selectedItem.isActive ? "non-aktifkan" : "aktifkan"
						}`,
						3000,
					);
					window.scrollTo(0, 0);
					setShowDeleteModal(false);
					setSelectedItem({id: null, name: null});
				},
				onError: res => {
					showToast("error", convertErrorMessageFormat(res.response.status, res.response.data.message), null);
				},
			},
		);
	};

	return (
		<DashboardLayout>
			<>
				<ModalConfirmation
					approveLabel="Ya"
					description={`Apakah Anda yakin ingin  ${
						selectedItem.statusActive === "active" ? "non-aktifkan" : "aktifkan"
					} data pemeriksaan penunjang "${selectedItem.title}"?`}
					loading={isUpdatingData}
					rejectLabel="Batal"
					visible={showDeleteModal}
					onApprove={handeChangeStatusItem}
					onClose={() => setShowDeleteModal(false)}
					onReject={() => setShowDeleteModal(false)}
				/>

				<div className="text-bold text-dark mb-4">
					Data Pemeriksaan Penunjang - {selectedHospital.Hospital.name}
				</div>
				<SearchBox
					onSearch={keyword => {
						setFilter({...filter, search: keyword, page: 1});
					}}
				/>

				<BasicTable
					columns={isMobile ? columnsMobile : columns}
					currentPage={data?.data?.currentPage}
					data={data?.data?.rows || []}
					disableSortBy={false}
					limit={filter.limit}
					loading={isLoading}
					striped={true}
					title="Data Pemeriksaan Penunjang"
					totalItems={data?.data?.totalItems}
					totalPage={data?.data?.totalPages}
					onLimitChange={limitValue => {
						setFilter({...filter, limit: limitValue});
					}}
					onPageChange={selectedPage => {
						setFilter({...filter, page: selectedPage});
					}}
				/>
			</>
		</DashboardLayout>
	);
}
