import clsx from "clsx";
import {SubmitButton} from "components/Button";
import {DashboardLayout} from "components/Layout";
import {ModalConfirmation, ModalEditVisibility, ModalUploadFile} from "components/Modal";
import {ModalViewPDF} from "components/Modal/ModalViewPdf";
import {BasicTable, SearchBox} from "components/Table";
import {useDeleteFileLab, useEditFileLab, useListFileLabByPatientId} from "hooks/AdminLaboratorium/FileLab";
import {useDetailPendaftaranPasien} from "hooks/PendaftaranPasien";
import {useStateCallback} from "hooks/useStateCallback";
import {useToast} from "hooks/useToast";
import {useMemo, useState} from "react";
import {isMobile} from "react-device-detect";
import {BsThreeDotsVertical} from "react-icons/bs";
import {useSelector} from "react-redux";
import {Link, useHistory, useParams} from "react-router-dom";
import {getCRUDPermissions} from "utils/auth";
import {convertErrorMessageFormat} from "utils/converter";
import {converISOtoDate} from "utils/date";
import {capitalizeFirstLetter} from "utils/string";

import {getFormattedDobText, getFormattedGenderText} from "./MasterFileLabList";

const visibilityOptions = [
	{
		value: "visible",
		label: "Live",
	},
	{
		value: "hidden",
		label: "Staff Only",
	},
];

export function MasterFileLabDetailPage() {
	const roleId = useSelector(state => state.AuthReducer.roleId);

	const permissions = useMemo(() => {
		if (roleId === 5) return getCRUDPermissions(true, true);
		if (roleId === 6) return getCRUDPermissions(true, true, true);

		return getCRUDPermissions(true, true, true, true);
	}, [roleId]);

	const history = useHistory();
	const {id} = useParams();
	const {data: patient} = useDetailPendaftaranPasien(id);
	const [filter, setFilter] = useState({page: 1, search: "", limit: null});

	const {data: fileLabs, isLoading: loadingFileLabs} = useListFileLabByPatientId(patient?.data?.patient?.id, {
		sortBy: "desc",
		...filter,
	});

	const [previewDocument, setPreviewDocument] = useState({
		show: false,
		fileSrc: null,
		title: undefined,
	});
	const [showActionMobile, setShowActionMobile] = useState(null);
	const [showModalUpload, setShowModalUpload] = useState(false);

	// Delete Item
	const {mutate, isLoading: isDeletingData} = useDeleteFileLab();
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [deleteItemData, setDeleteItemData] = useStateCallback({});

	// Update Visibility item
	const {mutate: updateVisibility, isLoading: isUpdatingVisibility} = useEditFileLab();
	const [selectedItemData, setSelectedItemData] = useStateCallback({});
	const [showEditModal, setShowEditModal] = useState(false);
	const {showToast} = useToast();

	const handleDeleteData = () => {
		mutate(
			{idLabResult: deleteItemData.id},
			{
				onSuccess: () => {
					showToast("success", `Data file "${deleteItemData.name}" berhasil dihapus`, 3000);
					window.scrollTo(0, 0);
					setShowDeleteModal(false);
				},
				onError: res => {
					showToast("error", convertErrorMessageFormat(res.response.status, res.response.data.message), null);
				},
			},
		);
	};

	const handleSelectedPreview = data => {
		setPreviewDocument({
			show: true,
			title: data.name,
			fileSrc: data.file,
		});
	};

	const dataWithIDSerial = useMemo(() => {
		return (
			fileLabs?.data?.rows?.map((item, index) => ({
				...item,
				idSerial: index + 1,
			})) || []
		);
	}, [fileLabs]);

	const columns = useMemo(
		() => [
			{
				Header: "No",
				accessor: "idSerial",
			},
			{
				Header: "File Name",
				accessor: "name",
			},
			{
				Header: "Modified",
				accessor: "updatedAt",
				Cell: ({row}) => converISOtoDate(row?.values?.updatedAt, "DD MMMM YYYY"),
			},
			{
				Header: "Visibility",
				accessor: "visibility",
				Cell: ({row}) => (row?.values?.visibility === "visible" ? "Live" : "Staff Only"),
			},
			{
				Header: "Action",
				accessor: "id",
				Cell: ({row}) => {
					return (
						<>
							<a
								class="text-orange wait-pay mr5"
								data-placement="top"
								data-toggle="tooltip"
								style={{cursor: "pointer"}}
								title="View File"
								onClick={() => handleSelectedPreview(row.original)}>
								<span class="icon-ico-eye-open ft-18" />
							</a>

							{permissions.destroy && (
								<a
									class="text-orange wait-pay mr5"
									data-placement="top"
									data-toggle="tooltip"
									style={{cursor: "pointer"}}
									title="Hapus data"
									onClick={() => {
										setDeleteItemData(row.original, () => setShowDeleteModal(true));
									}}>
									<span class="icon-ico-delete ft-18" />
								</a>
							)}

							{permissions.update && (
								<>
									<a
										class="text-orange wait-pay mr5"
										data-placement="top"
										data-toggle="tooltip"
										style={{cursor: "pointer"}}
										title="Edit data"
										onClick={() => history.push("/file-labs/edit/" + row.original.id)}>
										<span class="icon-ico-pencil ft-18" />
									</a>

									<a
										class="text-orange wait-pay mr5"
										data-placement="top"
										data-toggle="tooltip"
										style={{cursor: "pointer"}}
										title="Edit visibility"
										onClick={() => {
											setSelectedItemData(row.original, () => setShowEditModal(true));
										}}>
										<span class="icon-users ft-18" />
									</a>
								</>
							)}

							<a
								class="text-orange wait-pay"
								data-placement="top"
								data-toggle="tooltip"
								download={row?.original?.name}
								href={row?.original?.file}
								rel="noreferrer"
								style={{cursor: "pointer"}}
								target="_blank"
								title="Donwload file">
								<span class="icon-ico-pdf ft-18" />
							</a>
						</>
					);
				},
			},
		],
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[permissions],
	);

	const columnsMobile = useMemo(
		() => [
			{
				Header: "File Name",
				accessor: "name",
				Cell: ({row}) => {
					return (
						<div class="col-8">
							<div className="text-table-small text-grey text-elipsis">{row?.values?.name}</div>
						</div>
					);
				},
			},
			{
				Header: "Modified",
				accessor: "updatedAt",
				Cell: ({row}) => {
					return (
						<div class="col-3">
							<div className="text-table-medium">
								{row.original.visibility === "visible" ? "Live" : "Staff Only"}
							</div>
							<div
								class="text-grey text-table-small"
								style={{textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap"}}>
								{`${converISOtoDate(row.original?.updatedAt, "DD MMMM YYYY")}`}
							</div>
						</div>
					);
				},
			},

			{
				Header: "Aksi",
				accessor: "id",
				Cell: ({row}) => {
					return (
						<div className="col-2">
							<div class="dropdown dropstart">
								<span
									style={{padding: "5px"}}
									onClick={() => {
										if (showActionMobile !== null) {
											setShowActionMobile(null);
										} else {
											setShowActionMobile(row.original.id);
										}
									}}>
									<BsThreeDotsVertical />
								</span>

								<ul class={clsx("dropdown-menu", showActionMobile === row.original.id && "show")}>
									<li>
										<a
											class="dropdown-item text-table-medium"
											data-placement="top"
											data-toggle="tooltip"
											style={{cursor: "pointer"}}
											title="Lihat data"
											onClick={() => {
												setShowActionMobile(null);
												handleSelectedPreview(row.original);
											}}>
											Lihat Data
										</a>
									</li>

									{permissions.destroy && (
										<li>
											<a
												class="dropdown-item text-table-medium"
												data-placement="top"
												data-toggle="tooltip"
												style={{cursor: "pointer"}}
												title="Hapus data"
												onClick={() => {
													setShowActionMobile(null);
													setDeleteItemData(row.original, () => setShowDeleteModal(true));
												}}>
												Hapus Data
											</a>
										</li>
									)}

									{permissions.update && (
										<>
											<li>
												<a
													class="dropdown-item text-table-medium"
													data-placement="top"
													data-toggle="tooltip"
													style={{cursor: "pointer"}}
													title="Edit data"
													onClick={() => history.push("/file-labs/edit/" + row.original.id)}>
													Edit Data
												</a>
											</li>
											<li>
												<a
													class="dropdown-item text-table-medium"
													data-placement="top"
													data-toggle="tooltip"
													style={{cursor: "pointer"}}
													title="Edit visibility"
													onClick={() => {
														setShowActionMobile(null);
														setSelectedItemData(row.original, () => setShowEditModal(true));
													}}>
													Edit Visibility
												</a>
											</li>
										</>
									)}

									<li>
										<a
											class="dropdown-item text-table-medium"
											data-placement="top"
											data-toggle="tooltip"
											download={row?.original?.name}
											href={row?.original?.file}
											rel="noreferrer"
											target="_blank"
											title="Donwload file">
											Download File
										</a>
									</li>
								</ul>
							</div>
						</div>
					);
				},
			},
		],
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[showActionMobile, permissions],
	);

	return (
		<>
			<ModalUploadFile visible={showModalUpload} onClose={() => setShowModalUpload(false)} />
			<ModalViewPDF
				fileSrc={previewDocument.fileSrc}
				title={previewDocument.title}
				visible={previewDocument.show}
				onClose={() => setPreviewDocument({show: false, fileSrc: null, undefined})}
			/>

			<ModalConfirmation
				description={`Apakah anda yakin ingin menghapus data file "${deleteItemData.name}"?`}
				loading={isDeletingData}
				visible={showDeleteModal}
				onApprove={handleDeleteData}
				onClose={() => {
					setDeleteItemData({}, () => setShowDeleteModal(false));
				}}
				onReject={() => {
					setDeleteItemData({}, () => setShowDeleteModal(false));
				}}
			/>
			<ModalEditVisibility
				disabled={isUpdatingVisibility}
				options={visibilityOptions}
				title={`Edit visibility file ${selectedItemData.name}`}
				value={{
					value: selectedItemData.visibility,
					label: capitalizeFirstLetter(selectedItemData.visibility),
				}}
				visible={showEditModal}
				onChange={selectedValue => setSelectedItemData({...selectedItemData, visibility: selectedValue})}
				onClose={() => {
					setSelectedItemData({}, () => setShowEditModal(false));
				}}
				onSubmit={() => {
					updateVisibility(
						{idLabResult: selectedItemData.id, visibility: selectedItemData.visibility},
						{
							onSuccess: res => {
								window.scrollTo(0, 0);
								showToast(
									"success",
									`Visibility file "${selectedItemData.name}" berhasil diupdate`,
									3000,
								);
							},
							onError: res => {
								showToast(
									"error",
									convertErrorMessageFormat(res.response.status, res.response.data.message),
									null,
								);
							},
						},
					);
					setSelectedItemData({}, () => setShowEditModal(false));
				}}
			/>

			<DashboardLayout>
				<SearchBox
					rightElement={
						<div className="row position-relative search-hospital-container  align-items-center justify-content-end">
							<div className="col-lg-7 col-sm-12" />
							<div className="col-lg-5 col-sm-12">
								<div class="row gx-2 align-items-center justify-content-end">
									<SubmitButton
										text="Upload File"
										onClick={() => history.push(`/file-labs/tambah?idPatient=${id}`)}
									/>
								</div>
							</div>
						</div>
					}
					onSearch={keyword => {
						setFilter({...filter, search: keyword, page: 1});
					}}
				/>

				<div class="box-white ws p15 mb-3">
					<Link to="/file-labs">
						<span class="icon-ico-back me-2 text-dark" />{" "}
						<span className="text-dark">Detail Hasil Laboratorium</span>
					</Link>
				</div>

				<div class="box-white ws p15 mb-3">
					<div className="row">
						<div className="col-6 col-md-3">{patient?.data?.fullName}</div>
						<div className="col-6 col-md-3">
							No. Rekam Medis : {patient?.data?.patient?.medicalRecordNumber}
						</div>
						<div className="col-6 col-md-3">Umur : {getFormattedDobText(patient?.data?.dobText)}</div>
						<div className="col-6 col-md-3">
							Jenis Kelamin : {getFormattedGenderText(patient?.data?.gender)}
						</div>
					</div>
				</div>

				<BasicTable
					columns={isMobile ? columnsMobile : columns}
					currentPage={1}
					data={dataWithIDSerial}
					loading={loadingFileLabs}
					striped={true}
					title="Data File Laboratorium"
					totalItems={fileLabs?.data?.totalItems}
					totalPage={1}
				/>
			</DashboardLayout>
		</>
	);
}
