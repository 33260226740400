import {useState} from "react";
import {useQuery} from "react-query";
import axios from "services/axios";

async function getMedicineCategories(id, filter) {
	if (!id) {
		const {data} = await axios.get(`/medicine/category`, {params: {...filter, sortBy: "id.desc"}});

		return data;
	}
	const {data} = await axios.get(`/medicine/category/${id}`);

	return data;
}

export function useMedicineCategory() {
	const [filter, filterMedicineCategories] = useState({
		page: 1,
		search: null,
		limit: 10,
	});
	const [medicineCategoryId, medicineCategoryById] = useState(null);
	const fallback = [];
	const {
		data = fallback,
		isLoading,
		isError,
		error,
	} = useQuery(["medicineCategory", filter, medicineCategoryId], async () =>
		getMedicineCategories(medicineCategoryId, filter),
	);

	return {data, isLoading, isError, error, filter, filterMedicineCategories, medicineCategoryById};
}

export const fetchMedicineCategory = medicineCategoryId => {
	return axios.get(`/medicine/category/${medicineCategoryId}`).then(res => res.data);
};

export function useDetailMedicineCategory(medicineCategoryId) {
	return useQuery({
		queryKey: medicineCategoryId && ["medicineCategory", medicineCategoryId],
		queryFn: key => fetchMedicineCategory(medicineCategoryId),
		refetchOnMount: false,
		refetchOnWindowFocus: false,
		enabled: !!medicineCategoryId,
	});
}

export function useMedicineCategoryOptions() {
	const result = useQuery(
		["medicineCategoryOptions"],
		async () => await axios.get(`/medicine/category`).then(res => res.data),
	);

	return result;
}
