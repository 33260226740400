import {useState} from "react";
import {useHistory, useLocation} from "react-router-dom";
import {Formik} from "formik";
import clsx from "clsx";
import {SubmitButton} from "components/Button";
import {loadLocalAssets} from "utils/loader";
import {useResetPassword} from "hooks/Auth";
import {ResetPasswordValidationSchema} from "config/form/FormValidation/Auth";

const FormInitialValues = {password: "", password_confirmation: ""};

export function ChangePassword() {
	const history = useHistory();
	const {search} = useLocation();
	const token = new URLSearchParams(search).get("token");
	const {mutate, isLoading} = useResetPassword({token});
	const [showPassword, setShowPassword] = useState(false);
	const [showPasswordConfirmation, setShowPasswordConfirmation] = useState(false);
	const handleSubmit = formData => {
		mutate(formData, {
			onSuccess: () => {
				history.push("/auth/login");
			},
		});
	};

	return (
		<div className="change-password-wrapper">
			<div className="form-main">
				<Formik
					initialValues={FormInitialValues}
					validationSchema={ResetPasswordValidationSchema}
					onSubmit={handleSubmit}>
					{({handleSubmit, handleBlur, handleChange, values, errors, touched}) => (
						<div className="inner-fm">
							<img alt="logo" className="img-fluid mb-5" src={loadLocalAssets("img/logo.svg")} />

							<div className="ttl-28 mb-4">Ubah Kata Sandi</div>
							<div className="form-change-password">
								<div className="label-fl mb-2">Kata Sandi Baru</div>
								<div className="position-relative">
									<input
										className={clsx(
											"form-control",
											errors?.password && touched.password && "form-control-error",
										)}
										disabled={isLoading}
										id="password"
										name="password"
										placeholder=""
										type={showPassword ? "text" : "password"}
										value={values?.password}
										onBlur={handleBlur}
										onChange={handleChange}
									/>
									{errors.password && touched.password && (
										<p className="form-error-item-message">{errors.password}</p>
									)}
									<div className="for-btn-show">
										<button
											className="btn btn-show"
											toggle="#password"
											type="button"
											onClick={() => setShowPassword(!showPassword)}
										/>
									</div>
								</div>
							</div>
							<div className="form-change-password">
								<div className="label-fl mb-2">Konfirmasi Kata Sandi Baru</div>
								<div className="position-relative">
									<input
										className={clsx(
											"form-control",
											errors?.password_confirmation &&
												touched.password_confirmation &&
												"form-control-error",
										)}
										disabled={isLoading}
										id="password_confirmation"
										name="password_confirmation"
										placeholder=""
										type={showPasswordConfirmation ? "text" : "password"}
										value={values?.password_confirmation}
										onBlur={handleBlur}
										onChange={handleChange}
									/>
									{errors.password_confirmation && touched.password_confirmation && (
										<p className="form-error-item-message">{errors.password_confirmation}</p>
									)}
									<div className="for-btn-show">
										<button
											className="btn btn-show"
											toggle="#password_confirmation"
											type="button"
											onClick={() => setShowPasswordConfirmation(!showPasswordConfirmation)}
										/>
									</div>
								</div>
							</div>

							<SubmitButton
								className="btn btn-orange w-100"
								disabled={!ResetPasswordValidationSchema.isValidSync(values)}
								loading={isLoading}
								text="Kirim"
								onClick={handleSubmit}
							/>
						</div>
					)}
				</Formik>
			</div>
			<div className="img-main" style={{backgroundImage: `url(${loadLocalAssets("img/bg-login.jpg")})`}} />
		</div>
	);
}
