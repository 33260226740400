import clsx from "clsx";
import {SubmitButton} from "components/Button";
import {BasicDropdown} from "components/Dropdown";
import {DashboardLayout} from "components/Layout";
import {FormSchemaValidation} from "config/form/FormValidation/Form";
import {Formik} from "formik";
import {useUploadFile} from "hooks/Misc";
import {useAddForm, useEditForm} from "hooks/SuperAdmin/Form";
import {useFormList} from "hooks/SuperAdmin/Form/useQueryForm";
import {useHospitalsOptions} from "hooks/SuperAdmin/Hospital";
import {useToast} from "hooks/useToast";
import {useEffect, useMemo, useRef, useState} from "react";
import ReactQuill from "react-quill";
import {useHistory, useLocation, useParams} from "react-router-dom/cjs/react-router-dom";
import {convertErrorMessageFormat} from "utils/converter";
import {loadLocalAssets} from "utils/loader";

const FormInitialValues = {hospitalId: 0, leftIcon: "", rightIcon: "", header: "", content: ""};

const parseFileData = async data => {
	return new Promise(resolve => {
		if (typeof data === "string") resolve(data);

		const reader = new FileReader();

		reader.onload = () => {
			resolve(reader.result);
		};
		reader.readAsDataURL(data);
	});
};

const FormAddPage = () => {
	const {action, id} = useParams();
	const history = useHistory();
	const {search} = useLocation();
	const searchParams = useMemo(() => new URLSearchParams(search), [search]);

	const {showToast} = useToast();
	const {mutate: addForm, isLoading} = useAddForm();
	const {mutate: editForm, isLoading: editLoading} = useEditForm(id);
	const {data, setFormId} = useFormList();
	const {mutate: uploadFile} = useUploadFile();

	const {hospitalOptions: locations} = useHospitalsOptions();

	const formikRef = useRef();

	const [previewKiri, setPreviewKiri] = useState(""),
		[previewKanan, setPreviewKanan] = useState("");

	useEffect(() => {
		if (locations.length) {
			const hospital = locations[0].value;

			if (action === "tambah") {
				formikRef?.current.setFieldValue("hospitalId", hospital);
			}
		}
	}, [action, locations]);

	useEffect(() => {
		if (action === "edit" && id) {
			setFormId(id);
		}
	}, [action, id]);

	useEffect(() => {
		if (action === "edit" && data?.data?.id) {
			formikRef?.current.setFieldValue("hospitalId", data.data.hospitalId);
			formikRef?.current.setFieldValue("leftIcon", data.data.leftIcon);
			setPreviewKiri(data.data.leftIcon);
			formikRef?.current.setFieldValue("rightIcon", data.data.rightIcon);
			setPreviewKanan(data.data.rightIcon);
			formikRef?.current.setFieldValue("header", data.data.header);
			formikRef?.current.setFieldValue("content", data.data.content);
		}
	}, [action, data]);

	const handleUploadFile = file => {
		let formData = new FormData();

		formData.append("file", file);

		return new Promise(resolve => {
			uploadFile(formData, {
				onSuccess: result => resolve(result.data?.file),
			});
		});
	};

	const handleSubmit = async formValue => {
		const {leftIcon, rightIcon, ...rest} = formValue;
		const finalLeftIcon = typeof leftIcon === "string" ? leftIcon : await handleUploadFile(leftIcon);
		const finalRightIcon = typeof rightIcon === "string" ? rightIcon : await handleUploadFile(rightIcon);

		const finalData = new FormData();

		finalData.append("hospitalId", rest.hospitalId);
		finalData.append("formType", action === "tambah" ? searchParams.get("formType") : data.data.formType);
		finalData.append("leftIcon", finalLeftIcon);
		finalData.append("rightIcon", finalRightIcon);
		finalData.append("header", rest.header);
		finalData.append("content", rest.content);

		if (action === "tambah") {
			addForm(finalData, {
				onSuccess: () => {
					showToast("success", "Data berhasil ditambahkan", 3000);
					history.push("/settings/form");
				},
				onError: res => {
					showToast("error", convertErrorMessageFormat(res.response.status, res.response.data.message), null);
				},
			});
		}

		if (action === "edit") {
			editForm(finalData, {
				onSuccess: () => {
					showToast("success", "Data berhasil diubah", 3000);
					history.push("/settings/form");
				},
				onError: res => {
					showToast("error", convertErrorMessageFormat(res.response.status, res.response.data.message), null);
				},
			});
		}
	};

	return (
		<DashboardLayout>
			<Formik
				initialValues={FormInitialValues}
				innerRef={formikRef}
				validationSchema={FormSchemaValidation}
				onSubmit={handleSubmit}>
				{({
					handleSubmit,
					handleBlur,
					handleChange,
					setFieldTouched,
					setFieldValue,
					touched,
					values,
					errors,
				}) => (
					<div class="box-white setting-content h-100 no-border p24">
						<div class="d-flex justify-content-between flex-wrap align-items-center mb-4">
							<div class="ttl-20 text-capitalize">
								{action} Form {action === "tambah" ? " Baru" : null}
							</div>
						</div>

						<hr />

						<div class="text-bold mb-4">
							<a
								class="text-dark"
								style={{cursor: "pointer"}}
								onClick={() => history.push("/settings/form")}>
								<span class="icon-ico-back me-2" />
							</a>
							Daftar Form
						</div>

						<div class="row gx-2 mb-3">
							<div class="col-sm-6 col-6">
								<div class="setting-item">
									<label class="setting-label">Lokasi Praktik</label>
									<BasicDropdown
										className={clsx(
											"form-control",
											errors?.hospitalId && touched?.hospitalId && "form-control-error",
										)}
										data={locations}
										disabled={isLoading || editLoading}
										name="hospitalId"
										value={values?.hospitalId}
										onBlur={() => setFieldTouched("hospitalId", true)}
										onSelect={fieldValue => setFieldValue(`hospitalId`, fieldValue)}
									/>
									{errors?.hospitalId && touched?.hospitalId && (
										<p className="form-error-item-message">{errors?.hospitalId}</p>
									)}
								</div>
							</div>
						</div>

						<div class="row gx-2 mb-3">
							<div class="col-sm-6 col-6">
								<div class="setting-item">
									<label class="setting-label required">Icon Kiri</label>
									<div class="change-img mb-4">
										<div class="for-img-change">
											<div class="bx-img-change">
												<img
													alt="Icon Kiri"
													class="img-fluid w-100"
													src={
														previewKiri
															? previewKiri
															: loadLocalAssets("img/user-default.jpg")
													}
												/>
											</div>
										</div>
										<div class="for-info-change">
											<div class="upload-img-patient">
												<input
													id="leftIcon"
													type="file"
													onChange={async e => {
														setFieldTouched("leftIcon", true);
														setFieldValue("leftIcon", e.target.files[0]);
														setPreviewKiri(await parseFileData(e.target.files[0]));
													}}
												/>
												<label
													class={clsx(
														"btn-upload-img-patient",
														errors?.leftIcon && touched?.leftIcon && "border-danger",
													)}
													for="leftIcon">
													<img
														alt="Upload logo"
														class="img-fluid me-2"
														src={loadLocalAssets("img/icn-cam.svg")}
													/>
													Upload logo
												</label>
											</div>
											<div class="ft-12 text-grey">*Ukuran file: maks 5 Mb (.JPG .JPEG .PNG)</div>
										</div>
									</div>
									{errors.leftIcon && touched?.leftIcon && (
										<p className="form-error-item-message">{errors.leftIcon}</p>
									)}
								</div>
							</div>
							<div class="col-sm-6 col-6">
								<div class="setting-item">
									<label class="setting-label required">Icon Kanan</label>
									<div class="change-img mb-4">
										<div class="for-img-change">
											<div class="bx-img-change">
												<img
													alt="Icon Kiri"
													class="img-fluid w-100"
													src={
														previewKanan
															? previewKanan
															: loadLocalAssets("img/user-default.jpg")
													}
												/>
											</div>
										</div>
										<div class="for-info-change">
											<div class="upload-img-patient">
												<input
													id="rightIcon"
													type="file"
													onChange={async e => {
														setFieldTouched("rightIcon", true);
														setFieldValue("rightIcon", e.target.files[0]);
														setPreviewKanan(await parseFileData(e.target.files[0]));
													}}
												/>
												<label
													class={clsx(
														"btn-upload-img-patient",
														errors?.rightIcon && touched?.rightIcon && "border-danger",
													)}
													for="rightIcon">
													<img
														alt="Upload logo"
														class="img-fluid me-2"
														src={loadLocalAssets("img/icn-cam.svg")}
													/>
													Upload logo
												</label>
											</div>
											<div class="ft-12 text-grey">*Ukuran file: maks 5 Mb (.JPG .JPEG .PNG)</div>
										</div>
									</div>
									{errors.rightIcon && touched?.rightIcon && (
										<p className="form-error-item-message">{errors.rightIcon}</p>
									)}
								</div>
							</div>
						</div>

						<div class="row gx-2 mb-3">
							<div class="col-sm-12 col-12">
								<div class="setting-item">
									<label class="setting-label required">Header</label>
									<input
										class={clsx(
											"form-control",
											errors?.header && touched?.header && "form-control-error",
										)}
										disabled={isLoading || editLoading}
										name="header"
										placeholder=""
										type="text"
										value={values?.header}
										onBlur={handleBlur}
										onChange={handleChange}
									/>
									{errors.header && touched?.header && (
										<p className="form-error-item-message">{errors.header}</p>
									)}
								</div>
							</div>
						</div>

						<div class="row gx-2 mb-3">
							<div class="col-sm-12 col-12">
								<div class="setting-item">
									<label class="setting-label required">Content</label>
									<ReactQuill
										className="quill-lg"
										disabled={isLoading || editLoading}
										name="content"
										theme="snow"
										value={values?.content}
										onBlur={() => setFieldTouched("content", true)}
										onChange={value => setFieldValue("content", value)}
									/>
									{errors.content && touched?.content && (
										<p className="form-error-item-message">{errors.content}</p>
									)}
								</div>
							</div>
						</div>

						<div class="text-center my-3">
							<SubmitButton
								className="btn btn-orange d-inline-block mw-250"
								disabled={!FormSchemaValidation.isValidSync(values)}
								loading={isLoading || editLoading}
								text="Simpan"
								onClick={handleSubmit}
							/>
						</div>
					</div>
				)}
			</Formik>
		</DashboardLayout>
	);
};

FormAddPage.displayName = "FormAddPage";
export {FormAddPage};
