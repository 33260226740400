import {HeadingExtension} from "remirror/extensions";

export const Heading = new HeadingExtension({
	extraAttributes: {
		style: {
			default: null,
			parseDOM: dom => dom.getAttribute("style"),
			toDOM: attrs => ["style", attrs.style],
		},
	},
});
