import {
	MasterFileLabListPage,
	MasterFileLabAddPage,
	MasterFileLabEditPage,
	MasterFileLabDetailPage,
} from "pages/AdminLaboratorium/MasterFileLab";

export const adminLaboratoriumRoutes = [
	{
		name: "admin-laboratoriun-master-file-lab",
		path: "/file-labs",
		exact: true,
		isPrivate: true,
		component: MasterFileLabListPage,
	},
	{
		name: "admin-laboratoriun-master-file-lab-add",
		path: "/file-labs/tambah",
		exact: true,
		isPrivate: true,
		component: MasterFileLabAddPage,
	},
	{
		name: "admin-laboratoriun-master-file-lab-edit",
		path: "/file-labs/edit/:id",
		exact: true,
		isPrivate: true,
		component: MasterFileLabEditPage,
	},
	{
		name: "admin-laboratoriun-master-file-lab-detail",
		path: "/file-labs/detail/:id",
		exact: true,
		isPrivate: true,
		component: MasterFileLabDetailPage,
	},
];
