import clsx from "clsx";
import {isMobile} from "react-device-detect";
import {useMemo, useState} from "react";
import {useHistory} from "react-router-dom";
import {DashboardLayout} from "components/Layout";
import {BasicTable, SearchBox} from "components/Table";
import {ModalConfirmation, ModalInfo} from "components/Modal";
import {ButtonAddTable} from "components/Button";
import {useJabatan, useDeleteJabatan} from "hooks/SuperAdmin/Jabatan";
import {useToast} from "hooks/useToast";
import {useStateCallback} from "hooks/useStateCallback";
import {convertErrorMessageFormat} from "utils/converter";

export function MasterJabatanListPage() {
	const [searchKeyword, setSearchKeyword] = useState("");
	const [dataLimit, setDataLimit] = useState(10);
	const {data, isLoading, filterJabatans} = useJabatan();

	// Delete Data State
	const {mutate, isLoading: isDeletingData} = useDeleteJabatan();
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [showInfoModal, setShowInfoModal] = useState(false);
	const [deleteItemData, setDeleteItemData] = useStateCallback({id: null, name: null});
	const {showToast} = useToast();

	const history = useHistory();
	const columns = useMemo(
		() => [
			{
				Header: "No",
				accessor: "idSerial",
			},
			{
				Header: "Nama Jabatan",
				accessor: "name",
				maxWidth: 300,
			},

			{
				Header: "Aksi",
				accessor: "id",
				Cell: ({row}) => {
					return (
						<>
							<a
								class="text-orange wait-pay"
								style={{cursor: "pointer"}}
								onClick={() => history.push(`/master-jabatan/show/${row?.values?.id}`)}>
								<span class="icon-ico-eye-open ft-18" />
							</a>
							<a
								class="text-orange wait-pay"
								style={{cursor: "pointer"}}
								onClick={() => history.push(`/master-jabatan/edit/${row?.values?.id}`)}>
								<span class="icon-ico-pencil ft-18" />
							</a>
							<a
								class={clsx("text-orange wait-pay")}
								data-placement="top"
								data-toggle="tooltip"
								style={{cursor: "pointer"}}
								title={!row.original.can_delete && "Data tidak dapat dihapus karena sudah digunakan"}
								onClick={() => {
									if (row.original.can_delete) {
										setDeleteItemData(row?.values, () => setShowDeleteModal(true));
									} else {
										setShowInfoModal(true);
									}
								}}>
								<span class="icon-ico-delete ft-18" />
							</a>
						</>
					);
				},
			},
		],
		[],
	);
	const columnsMobile = useMemo(
		() => [
			{
				Header: "Nama Jabatan",
				accessor: "name",
				Cell: ({row}) => {
					return (
						<div class="col-9">
							<div class="text-bold">{row.values.name}</div>
						</div>
					);
				},
			},

			{
				Header: "Action",
				accessor: "id",
				Cell: ({row}) => {
					return (
						<div className="col-3">
							<a
								class="text-orange wait-pay"
								style={{cursor: "pointer"}}
								onClick={() => history.push(`/master-jabatan/show/${row?.values?.id}`)}>
								<span class="icon-ico-eye-open ft-18" />
							</a>
							<a
								class="text-orange wait-pay"
								style={{cursor: "pointer"}}
								onClick={() => history.push(`/master-jabatan/edit/${row?.values?.id}`)}>
								<span class="icon-ico-pencil ft-18" />
							</a>

							<a
								class="text-orange wait-pay"
								style={{cursor: "pointer"}}
								onClick={() => {
									if (row.original.can_delete) {
										setDeleteItemData(row?.values, () => setShowDeleteModal(true));
									} else {
										setShowInfoModal(true);
									}
								}}>
								<span class="icon-ico-delete ft-18" />
							</a>
						</div>
					);
				},
			},
		],
		[],
	);

	const dataWithIDSerial =
		data?.data?.rows?.map((item, index) => ({
			...item,
			idSerial: (data?.data?.currentPage - 1) * dataLimit + index + 1,
		})) || [];

	const handleDeleteData = () => {
		mutate(
			{id: deleteItemData.id},
			{
				onSuccess: () => {
					showToast("success", `Data jabatan ${deleteItemData.name} berhasil dihapus`, 3000);
					window.scrollTo(0, 0);
					setShowDeleteModal(false);
				},
				onError: res => {
					showToast("error", convertErrorMessageFormat(res.response.status, res.response.data.message), null);
				},
			},
		);
	};

	return (
		<DashboardLayout>
			<>
				<ModalInfo
					approveLabel="OK"
					description="Data tidak dapat dihapus karena sudah digunakan"
					title="Info"
					visible={showInfoModal}
					onApprove={() => setShowInfoModal(false)}
					onClose={() => setShowInfoModal(false)}
				/>
				<ModalConfirmation
					approveLabel="Ya"
					description={`Apakah Anda yakin ingin menghapus data jabatan "${deleteItemData.name}"?`}
					loading={isDeletingData}
					rejectLabel="Batal"
					visible={showDeleteModal}
					onApprove={handleDeleteData}
					onClose={() => setShowDeleteModal(false)}
					onReject={() => setShowDeleteModal(false)}
				/>
				<div class="text-bold text-dark mb-4">Data Jabatan</div>
				<SearchBox
					rightElement={
						<ButtonAddTable text="Tambah Jabatan" onClick={() => history.push("/master-jabatan/tambah")} />
					}
					onSearch={keyword => {
						setSearchKeyword(keyword);
						filterJabatans({page: 1, limit: dataLimit, search: keyword});
					}}
				/>

				<BasicTable
					columns={isMobile ? columnsMobile : columns}
					currentPage={data?.data?.currentPage}
					data={dataWithIDSerial}
					limit={dataLimit}
					loading={isLoading}
					striped={true}
					title="Data Jabatan"
					totalItems={data?.data?.totalItems}
					totalPage={data?.data?.totalPages}
					onLimitChange={limitValue => {
						setDataLimit(limitValue);
						filterJabatans({page: 1, limit: limitValue, search: searchKeyword});
					}}
					onPageChange={selectedPage =>
						filterJabatans({page: selectedPage, limit: dataLimit, search: searchKeyword})
					}
				/>
			</>
		</DashboardLayout>
	);
}
