/* eslint-disable no-unused-vars */
import * as Yup from "yup";

const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/gif", "image/png"];

export const phoneRegExp = /^\+?([ -]?\d+)+|\(\d+\)([ -]\d+)$/g;

export const AddPendaftaranPasienFormSchemaValidation = Yup.object().shape({
	id: Yup.string().nullable(),
	title: Yup.string().required("Title wajib di isi"),
	firstName: Yup.string().required("Nama depan wajib di isi"),
	lastName: Yup.string().required("Nama belakang wajib di isi"),
	nickname: Yup.string().required("Nama panggilan wajib di isi"),
	username: Yup.string()
		.matches(/^[.a-z0-9_-]+$/, "Username tidak valid. Hanya boleh berisi huruf kecil, angka, -, _, dan .")
		.required("Username wajib di isi"),
	dateOfBirth: Yup.string(), //.required("Tanggal lahir wajib di isi"),
	password: Yup.string().when("id", (id, schema) => {
		if (!id) return schema.required("Password wajib di isi");

		return schema;
	}),
	nik: Yup.string()
		.required("ID number/KTP wajib di isi")
		.min(16, "ID number/KTP tidak valid")
		.max(16, "ID number/KTP tidak valid"),
	maritalStatus: Yup.string().required("Status wajib di isi"),
	// email: Yup.string().email("Email tidak valid").required("Email wajib di isi"),
	phoneNumber: Yup.string().required("Nomor telepon wajib di isi").min(9).max(15),
	profilePhoto: Yup.mixed()
		// .required("Image wajib di upload")
		.test("fileFormat", "Format image tidak support, hanya menerima format .JPG .JPEG .PNG", value => {
			if (value) {
				if (typeof value === "string") {
					return true;
				} else {
					return SUPPORTED_FORMATS.includes(value?.type);
				}
			} else return true;
		}),
	// remark: Yup.string(),
	// addressPayment: Yup.object({
	// 	firstName: Yup.string().required("Nama depan wajib di isi"),
	// 	lastName: Yup.string().required("Nama belakang wajib di isi"),
	// 	email: Yup.string().email("Email tidak valid").required("Email wajib di isi"),
	// 	companyName: Yup.string(),
	// 	phoneNumber: Yup.string().required("Nomor telepon wajib di isi").min(9).max(15),
	// 	country: Yup.string().required("Negara wajib di isi"),
	// 	province: Yup.string().required("Provinsi wajib di isi"),
	// 	city: Yup.string().required("Kota wajib di isi"),
	// 	postalCode: Yup.string().required("Kode pos wajib di isi"),
	// 	address: Yup.string().required("Alamat wajib di isi"),
	// 	addressDetail: Yup.string(),
	// }),
	// addressShipment: Yup.object({
	// 	firstName: Yup.string().required("Nama depan wajib di isi"),
	// 	lastName: Yup.string().required("Nama belakang wajib di isi"),
	// 	email: Yup.string().email("Email tidak valid").required("Email wajib di isi"),
	// 	companyName: Yup.string(),
	// 	phoneNumber: Yup.string().required("Nomor telepon wajib di isi").min(9).max(15),
	// 	country: Yup.string().required("Negara wajib di isi"),
	// 	province: Yup.string().required("Provinsi wajib di isi"),
	// 	city: Yup.string().required("Kota wajib di isi"),
	// 	postalCode: Yup.string().required("Kode pos wajib di isi"),
	// 	address: Yup.string().required("Alamat wajib di isi"),
	// 	addressDetail: Yup.string(),
	// }),
	// emergencyContacts: Yup.array().of(
	// 	Yup.object().shape({
	// 		relation: Yup.string(),
	// 		phoneNumber: Yup.string()
	// 			.min(9, "Nomor Telepon minimal 9 karakter")
	// 			.max(15, "Nomor Telepon maximal 15 karakter"),
	// 	}),
	// ),
	// communicationPreferences: Yup.array().nullable(),
	// emailInvoice: Yup.string().email("Email tidak valid").nullable(),
	// referralType: Yup.string().nullable(),
	// referralIdUser: Yup.string().nullable(),
});
