import axios from "services/axios";
import {useState} from "react";
import {useQuery} from "react-query";

async function getIndicatorReport(id, filter) {
	if (!id) {
		const {data} = await axios.get("/indicator-report", {params: {...filter, sortBy: "id.desc"}});

		return data;
	}

	const {data} = await axios.get(`/indicator-report/${id}`);

	return data;
}

export const QUERY_INDICATOR_REPORT = "indicator-report";

export const useIndicatorReport = () => {
	const [filter, setFilter] = useState({
		page: 1,
		search: null,
		limit: 10,
	});
	const [indicatorId, setIndicatorId] = useState(null);
	const {
		data = [],
		isLoading,
		isError,
		error,
	} = useQuery([QUERY_INDICATOR_REPORT, filter, indicatorId], async () => getIndicatorReport(indicatorId, filter));

	return {data, isLoading, isError, error, filter, setFilter, setIndicatorId};
};
