import {authenticationRoutes} from "./Auth";
import {superAdminRoutes} from "./SuperAdmin";
import {adminAdmissionRoutes} from "./AdminAdmission";
import {adminLaboratoriumRoutes} from "./AdminLaboratorium";
import {adminHospitalRoutes} from "./AdminHospital";
import {dokterRoutes} from "./Doctor";
import {homeRoutes} from "./Home";
import {subAdminDivisiRoutes} from "./SubAdminDivisi";
import {perawatRoutes} from "./Perawat";
import {otherRoutes} from "./Other";

export const AllRoutes = {
	0: [...authenticationRoutes, ...homeRoutes, ...otherRoutes],
	8: [...authenticationRoutes, ...homeRoutes, ...superAdminRoutes],
	7: [...authenticationRoutes, ...homeRoutes, ...adminAdmissionRoutes],
	4: [...authenticationRoutes, ...homeRoutes, ...subAdminDivisiRoutes, ...adminLaboratoriumRoutes],
	5: [...authenticationRoutes, ...homeRoutes, ...adminLaboratoriumRoutes, ...subAdminDivisiRoutes],
	6: [...authenticationRoutes, ...homeRoutes, ...adminLaboratoriumRoutes, ...subAdminDivisiRoutes],
	1: [...authenticationRoutes, ...homeRoutes, ...adminHospitalRoutes],
	2: [...authenticationRoutes, ...homeRoutes, ...dokterRoutes],
	3: [...authenticationRoutes, ...homeRoutes, ...perawatRoutes],
};
