import {useEditorState, useEditorView} from "@remirror/react";
import {loadLocalAssets} from "utils/loader";

import {COMMON_TOOLBAR_ITEMS, FORMATTING_TOOLBAR_ITEMS, EMBEDS_TOOLBAR_ITEMS} from "../constant";

export function PostAddEditorToolbar({onClose}) {
	const {config, doc, tr} = useEditorState();
	const view = useEditorView();

	const nodes = config?.schema?.nodes ?? [];

	const handleOnToolbarPress = toolProps => async () => {
		const {type, key, payload} = toolProps;

		if (!key || !nodes[key]) return;

		let node;

		if (type === "toggle") {
			let paragraphNode = nodes.paragraph.createAndFill();
			const linkedNode = Object.keys(nodes).filter(item => item.includes(key));

			if (linkedNode.length > 1) {
				node = nodes[key].create(
					payload,
					linkedNode.filter(item => key !== item).map(item => nodes[item].create()),
				);
			} else {
				node = nodes[key].create(payload, paragraphNode);
			}
		} else {
			node = nodes[key].create(payload, []);
		}

		const endPosition = doc.content.size;
		const firstChild = doc.firstChild;
		const isNeedReplaceFirstNode =
			firstChild &&
			firstChild.type.name === "paragraph" &&
			firstChild.content.size === 0 &&
			key !== "paragraph" &&
			endPosition === 2;

		let transaction;

		if (isNeedReplaceFirstNode) {
			transaction = tr.replaceWith(0, 1, node).setSelection(tr.selection.constructor.near(tr.doc.resolve(0)));
		} else {
			transaction = tr
				.insert(endPosition, node)
				.setSelection(tr.selection.constructor.near(tr.doc.resolve(endPosition + 1)));
		}

		view.dispatch(transaction.scrollIntoView());
		view.focus();
	};

	const renderToolbarItems = key => (item, index) => {
		return (
			<button
				key={`${key}-${index}`}
				className="btn w-100 d-flex align-items-center text-start p-0"
				style={{marginBottom: 12}}
				type="button"
				onClick={handleOnToolbarPress(item)}>
				<div className="toolbar-item-icon">
					<img alt="toolbar-item-icon" src={loadLocalAssets(item.image)} />
				</div>
				<div className="ms-4">
					<div className="toolbar-item-title">{item.title}</div>
					<div className="toolbar-item-description">{item.description}</div>
				</div>
			</button>
		);
	};

	return (
		<div className="sidebar toolbar-sidebar" onClick={onClose}>
			<div className="nav-box" onClick={e => e.stopPropagation()}>
				<div className="sidebar-header">
					<div className="sidebar-header-title">Blocks</div>

					<button className="btn btn-editor btn-editor-toolbar-trigger p-0" type="button" onClick={onClose}>
						<span className="icon-ico-close" />
					</button>
				</div>

				<div className="form d-flex flex-column">
					<div className="toolbar-category-title">Common</div>
					<div className="mb-4">{COMMON_TOOLBAR_ITEMS.map(renderToolbarItems("common"))}</div>

					<div className="toolbar-category-title">Formatting</div>
					<div className="mb-4">{FORMATTING_TOOLBAR_ITEMS.map(renderToolbarItems("formatting"))}</div>

					<div className="toolbar-category-title">Embeds</div>
					<div className="mb-4">{EMBEDS_TOOLBAR_ITEMS.map(renderToolbarItems("embeds"))}</div>
				</div>
			</div>
		</div>
	);
}
