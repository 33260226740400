import clsx from "clsx";
import {ButtonAddTable} from "components/Button";
import {DashboardLayout} from "components/Layout";
import {ModalConfirmation} from "components/Modal";
import {BasicTable, SearchBox} from "components/Table";
import {useDeleteBanner, useBannerList} from "hooks/SuperAdmin/Banner";
import {useStateCallback} from "hooks/useStateCallback";
import {useToast} from "hooks/useToast";
import {useMemo, useState} from "react";
import {useHistory} from "react-router-dom/cjs/react-router-dom";
import {convertErrorMessageFormat} from "utils/converter";

const BannerListPage = () => {
	const history = useHistory();
	const {data, isLoading, setParams} = useBannerList();

	const [searchKeyword, setSearchKeyword] = useState("");
	const [dataLimit, setDataLimit] = useState(10);

	// Delete Data State
	const {mutate, isLoading: isDeletingData} = useDeleteBanner();
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [deleteItemData, setDeleteItemData] = useStateCallback({id: null, title: null});
	const {showToast} = useToast();

	const columns = useMemo(
		() => [
			{
				Header: "No",
				accessor: "idSerial",
			},
			{
				Header: "Judul",
				accessor: "title",
				maxWidth: 300,
			},
			{
				Header: "Order",
				accessor: "order",
			},
			{
				Header: "Device",
				accessor: "deviceType",
			},
			{
				Header: "Aksi",
				accessor: "id",
				Cell: ({row}) => (
					<>
						{/* <a
							class="text-orange wait-pay"
							style={{cursor: "pointer"}}
							onClick={() => history.push(`/banner/show/${row?.values?.id}`)}>
							<span class="icon-ico-eye-open ft-18" />
						</a> */}
						<a
							class="text-orange wait-pay"
							style={{cursor: "pointer"}}
							onClick={() => history.push(`/banner/edit/${row?.values?.id}`)}>
							<span class="icon-ico-pencil ft-18" />
						</a>
						<a
							class={clsx("text-orange wait-pay")}
							style={{cursor: "pointer"}}
							title={!row.original.can_delete && "Data tidak dapat dihapus karena sudah digunakan"}
							onClick={() => {
								setDeleteItemData(row?.values, () => setShowDeleteModal(true));
							}}>
							<span class="icon-ico-delete ft-18" />
						</a>
					</>
				),
			},
		],
		[],
	);

	const dataWithIDSerial =
		data?.data?.rows?.map((item, index) => ({
			...item,
			idSerial: (data?.data?.currentPage - 1) * dataLimit + index + 1,
		})) || [];

	const handleDeleteData = () => {
		mutate(
			{id: deleteItemData.id},
			{
				onSuccess: () => {
					showToast("success", `Data form ${deleteItemData["title"]} berhasil dihapus`, 3000);
					window.scrollTo(0, 0);
					setShowDeleteModal(false);
				},
				onError: res => {
					showToast("error", convertErrorMessageFormat(res.response.status, res.response.data.message), null);
				},
			},
		);
	};

	return (
		<DashboardLayout>
			<ModalConfirmation
				approveLabel="Ya"
				description={`Apakah Anda yakin ingin menghapus data banner "${deleteItemData["title"]}"?`}
				loading={isDeletingData}
				rejectLabel="Batal"
				visible={showDeleteModal}
				onApprove={handleDeleteData}
				onClose={() => setShowDeleteModal(false)}
				onReject={() => setShowDeleteModal(false)}
			/>
			<div class="text-bold text-dark mb-4">Banner</div>
			<SearchBox
				rightElement={<ButtonAddTable text="Tambah" onClick={() => history.push(`banner/add`)} />}
				onSearch={keyword => {
					setSearchKeyword(keyword);
					setParams({search: keyword, limit: dataLimit, page: 1});
				}}
			/>

			<BasicTable
				columns={columns}
				currentPage={data?.data?.currentPage}
				data={dataWithIDSerial}
				limit={dataLimit}
				loading={isLoading}
				striped={true}
				title="Data Form"
				totalItems={data?.data?.totalItems}
				totalPage={data?.data?.totalPages}
				onLimitChange={limitValue => {
					setDataLimit(limitValue);
					setParams({search: searchKeyword, limit: limitValue, page: 1});
				}}
				onPageChange={selectedPage => setParams({search: searchKeyword, limit: dataLimit, page: selectedPage})}
			/>
		</DashboardLayout>
	);
};

BannerListPage.displayName = "BannerListPage";
export {BannerListPage};
