import {useState} from "react";
import {useQuery} from "react-query";
import axios from "services/axios";

async function getHargaPendaftaranObat(id, filter) {
	if (!id) {
		const {data} = await axios.get(`/medicine_registration`, {params: {...filter, sortBy: "id.desc"}});

		return data;
	}
	const {data} = await axios.get(`/medicine_registration/${id}`);

	return data;
}

function useHargaPendaftaranObat() {
	const [filter, filterHargaPendaftaranObat] = useState({
		page: 1,
		search: null,
		limit: 10,
	});
	const [hargaPendaftaranObatId, hargaPendaftaranObatById] = useState(null);
	const fallback = [];
	const {
		data = fallback,
		isLoading,
		isError,
		error,
	} = useQuery(["hargaPendaftaranObat", filter, hargaPendaftaranObatId], async () =>
		getHargaPendaftaranObat(hargaPendaftaranObatId, filter),
	);

	return {data, isLoading, isError, error, filter, filterHargaPendaftaranObat, hargaPendaftaranObatById};
}

const fetchHargaPendaftaranObat = hargaPendaftaranObatId => {
	return axios.get(`/medicine_registration/${hargaPendaftaranObatId}`).then(res => res.data);
};

function useDetailHargaPendaftaranObat(hargaPendaftaranObatId) {
	return useQuery({
		queryKey: hargaPendaftaranObatId && ["hargaPendaftaranObat", hargaPendaftaranObatId],
		queryFn: key => fetchHargaPendaftaranObat(hargaPendaftaranObatId),
		refetchOnMount: false,
		refetchOnWindowFocus: false,
		enabled: !!hargaPendaftaranObatId,
	});
}

export {useHargaPendaftaranObat, fetchHargaPendaftaranObat, useDetailHargaPendaftaranObat};
