import PropTypes from "prop-types";

import {usePagination, DOTS} from "../../hooks/useTablePagination";

export const Pagination = props => {
	const {onPageChange, totalCount, siblingCount = 1, currentPage, pageSize} = props;

	const paginationRange = usePagination({
		currentPage,
		totalCount,
		siblingCount: siblingCount || 1,
		pageSize,
	});

	if (currentPage === 0 || paginationRange.length < 2) {
		return null;
	}

	const onNext = () => {
		onPageChange(currentPage + 1);
	};

	const onPrevious = () => {
		onPageChange(currentPage - 1);
	};

	const lastPage = paginationRange[paginationRange.length - 1];

	return (
		<div class="for-paging">
			<ul class="pagination">
				<li>
					<a
						class={currentPage === 1 && "not-active"}
						disabled={currentPage === 1}
						style={{cursor: "pointer"}}
						onClick={() => currentPage !== 1 && onPrevious()}>
						<span class="icon-ico-chev-left" />
					</a>
				</li>

				{paginationRange.map(pageNumber => {
					if (pageNumber === DOTS) {
						return (
							<li key={`${pageNumber}-page-number`} className="pagination-item dots">
								&#8230;
							</li>
						);
					}

					return (
						<li>
							<a
								class={pageNumber === currentPage && "active"}
								style={(pageNumber === currentPage && {cursor: "default"}) || {cursor: "pointer"}}
								onClick={() => onPageChange(pageNumber)}>
								{pageNumber}
							</a>
						</li>
					);
				})}

				<li>
					<a
						class={currentPage === lastPage && "not-active"}
						disabled={currentPage === lastPage}
						href={null}
						style={{cursor: "pointer"}}
						onClick={() => currentPage !== lastPage && onNext()}>
						<span class="icon-ico-chev-right" />
					</a>
				</li>
			</ul>
		</div>
	);
};

Pagination.propTypes = {
	onPageChange: PropTypes.func.isRequired,
	totalCount: PropTypes.number,
	siblingCount: PropTypes.number,
	currentPage: PropTypes.number,
	pageSize: PropTypes.number,
};
Pagination.defaultProps = {
	totalCount: null,
	siblingCount: null,
	currentPage: null,
	pageSize: null,
};
