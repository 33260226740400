/* eslint-disable no-unused-vars */
import clsx from "clsx";
import * as Yup from "yup";
import {useEffect, useRef} from "react";
import {useHistory} from "react-router-dom";
import {useParams} from "react-router-dom";
import {Formik} from "formik";
import {DashboardLayout} from "components/Layout";
import {CircleLoader} from "components/Loader";
import {FormCard} from "components/Form";
import {SubmitButton} from "components/Button";
import {useToast} from "hooks/useToast";
import {convertErrorMessageFormat} from "utils/converter";
import {useEditJabatan, useDetailJabatan} from "hooks/SuperAdmin/Jabatan";

const FormSchemaValidation = Yup.object().shape({
	name: Yup.string().required("Nama jabatan wajib di isi"),
});
const FormInitialValues = {name: ""};

export function MasterJabatanEditPage() {
	const {showToast} = useToast();
	const formikRef = useRef();
	const history = useHistory();
	const params = useParams();
	const {id} = params;
	const {isLoading: loadingFetcing, error, isError, data: detailJabatan} = useDetailJabatan(id);
	const {mutate, isLoading: isSubmitting} = useEditJabatan();
	const handleSubmit = formData => {
		mutate(
			{id, ...formData},
			{
				onSuccess: () => {
					showToast("success", "Data berhasil diperbarui");
					history.push("/master-jabatan");
				},
				onError: res => {
					showToast("error", convertErrorMessageFormat(res.response.status, res.response.data.message), null);
				},
			},
		);
	};

	useEffect(() => {
		if (detailJabatan) formikRef.current.setFieldValue("name", detailJabatan?.data?.name);
	}, [detailJabatan]);

	if (loadingFetcing) {
		return (
			<DashboardLayout>
				<FormCard title="Edit Jabatan">
					<CircleLoader />
				</FormCard>
			</DashboardLayout>
		);
	}

	return (
		<DashboardLayout>
			<Formik
				initialValues={FormInitialValues}
				innerRef={formikRef}
				validationSchema={FormSchemaValidation}
				onSubmit={handleSubmit}>
				{({handleSubmit, handleBlur, handleChange, values, errors}) => (
					<FormCard
						backTitle="Daftar List Jabatan"
						title="Edit Jabatan"
						onBackButtonClick={() => history.push("/master-jabatan")}>
						<div class="row gx-2 mb-5">
							<div class="col-sm-12 col-lg-6  col-xs-12">
								<div class="setting-item">
									<label class="setting-label required">Nama Jabatan</label>
									<input
										class={clsx("form-control", errors?.name && "form-control-error")}
										disabled={isSubmitting}
										name="name"
										placeholder=""
										type="text"
										value={values?.name}
										onBlur={handleBlur}
										onChange={handleChange}
									/>
									{errors.name && <p className="form-error-item-message">{errors.name}</p>}
								</div>
							</div>
						</div>

						<div class="text-center my-3">
							<SubmitButton
								className="btn btn-orange d-inline-block mw-250"
								disabled={!FormSchemaValidation.isValidSync(values)}
								loading={isSubmitting}
								text="Simpan Perubahan"
								onClick={handleSubmit}
							/>
						</div>
					</FormCard>
				)}
			</Formik>
		</DashboardLayout>
	);
}
