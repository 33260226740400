import {useState} from "react";
import {useQuery} from "react-query";
import axios from "services/axios";

async function getBannerList(id, params) {
	if (!id) {
		const {data} = await axios.get(`/banner`, {params});

		return data;
	}
	const {data} = await axios.get(`/banner/${id}`);

	return data;
}

export function useBannerList() {
	const [params, setParams] = useState({
		page: 1,
		search: null,
		type: 1,
		limit: 10,
		sortBy: "id.desc",
		// sortBy: "order.asc",
	});
	const [bannerId, setBannerId] = useState(null);
	const fallback = [];
	const {
		data = fallback,
		isLoading,
		isError,
		error,
	} = useQuery(["bannerList", params, bannerId], async () => getBannerList(bannerId, params));

	return {data, isLoading, isError, error, params, setParams, setBannerId};
}
