import {useEffect, useState} from "react";

import {BasicDropdown} from "../Dropdown";

import {ModalContainer} from "./ModalContainer";

export function ModalEditVisibility({
	visible,
	onClose,
	title,
	options,
	value,
	onSubmit,
	disabled = false,
	onChange = () => {},
}) {
	const [selectedValue, setSelectedValue] = useState(value);

	useEffect(() => {
		if (value) {
			setSelectedValue(value);
		}
	}, [value]);

	return (
		<ModalContainer visible={visible}>
			<div class="box-popup w650" id="withdraw">
				<div class="d-flex justify-content-between align-items-center mb-4">
					<div class="text-bold text-dark">{title}</div>
					<div class="for-close">
						<button class="btn btn-close" type="button" onClick={onClose} />
					</div>
				</div>

				<div class="popup-content">
					<div className="mb-3">
						<div class="ft-12 text-bold text-grey">Visibility</div>
						<BasicDropdown
							data={options}
							disabled={disabled}
							value={selectedValue}
							onSelect={selected => {
								onChange(selected);
								setSelectedValue(selected);
							}}
						/>
					</div>
					<hr />
					<button class="btn btn-orange r8 w-100" disabled={disabled} type="button" onClick={onSubmit}>
						Update
					</button>
				</div>
			</div>
		</ModalContainer>
	);
}
