import axios from "services/axios";
import {useMutation, useQueryClient} from "react-query";

import {QUERY} from "./useQueryFileLab";

export function useDeleteFileLab(options = {}) {
	const queryClient = useQueryClient();

	return useMutation(formData => axios.post("/patient/lab/delete", formData).then(res => res.data), {
		onSuccess: () => {
			queryClient.invalidateQueries(QUERY);
			queryClient.removeQueries(QUERY);
		},
	});
}

export function useAddFileLab(options = {}) {
	const queryClient = useQueryClient();

	return useMutation(formData => axios.post("/patient/lab/create", formData).then(res => res.data), {
		onSuccess: () => {
			queryClient.invalidateQueries(QUERY);
			queryClient.removeQueries(QUERY);
		},
	});
}
export function useEditFileLab() {
	const queryClient = useQueryClient();

	return useMutation(formData => axios.post("/patient/lab/update", formData).then(res => res.data), {
		onSuccess: () => {
			queryClient.invalidateQueries(QUERY);
			queryClient.removeQueries(QUERY);
		},
	});
}
