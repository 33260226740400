/* eslint-disable no-unused-vars */
import clsx from "clsx";
import {BroadcastButton} from "components/Button";
import {DashboardLayout} from "components/Layout";
import {ModalConfirmation, ModalResetPassword} from "components/Modal";
import {BasicTable, SearchBox} from "components/Table";
import CONFIG from "config/env";
import {useForgotPassword, useForgotPasswordByOtp, useLoginAs} from "hooks/Auth";
import {useDeletePendaftaranPasien, usePatients, useSyncPendaftaranPasien} from "hooks/PendaftaranPasien";
import {useStateCallback} from "hooks/useStateCallback";
import {useToast} from "hooks/useToast";
import {useMemo, useState} from "react";
import {useHistory} from "react-router-dom";
import {convertErrorMessageFormat} from "utils/converter";

export function PendaftaranPasienListPage() {
	const {data, isLoading, filterPatients} = usePatients();
	const [dataLimit, setDataLimit] = useState(10);
	const [searchKeyword, setSearchKeyword] = useState("");
	const {mutate: forgotPassword, isLoading: isLoadingForgotPassword} = useForgotPassword();
	const {mutate: forgotPasswordByOtp, isLoading: isLoadingForgotPasswordByOtp} = useForgotPasswordByOtp();
	const {mutate: loginAs} = useLoginAs();
	const [patientData, setPatientData] = useStateCallback({});
	const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(null);

	// Delete Data State
	const {mutate, isLoading: isDeletingData} = useDeletePendaftaranPasien();
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [showInfoModal, setShowInfoModal] = useState(false);
	const [deleteItemData, setDeleteItemData] = useStateCallback({id: null, name: null});

	const {showToast} = useToast();

	// Sync data
	const {mutate: mutateSync, isLoading: isLoadingSync} = useSyncPendaftaranPasien();

	const handleSyncData = () => {
		mutateSync(
			{},
			{
				onSuccess: () => {
					showToast("success", `Sync Data berhasil`, 3000);
					setShowForgotPasswordModal(false);
				},
				onError: res => {
					showToast("error", convertErrorMessageFormat(res.response.status, res.response.data.message), null);
					setShowForgotPasswordModal(false);
				},
			},
		);
	};

	const handleForgotPasswordByEmail = () => {
		forgotPassword(
			{email: patientData.email},
			{
				onSuccess: () => {
					showToast("success", `Link lupa password berhasil dikirim ke ${patientData.email}`, 3000);
					setShowForgotPasswordModal(false);
				},
				onError: res => {
					showToast("error", convertErrorMessageFormat(res.response.status, res.response.data.message), null);
					setShowForgotPasswordModal(false);
				},
			},
		);
	};

	const handleForgotPasswordByOtp = () => {
		forgotPasswordByOtp(
			{phone_number: patientData.phoneNumber},
			{
				onSuccess: () => {
					showToast("success", `Kode OTP berhasil dikirim ke ${patientData.phoneNumber}`, 3000);
					setShowForgotPasswordModal(false);
				},
				onError: res => {
					showToast("error", convertErrorMessageFormat(res.response.status, res.response.data.message), null);
					setShowForgotPasswordModal(false);
				},
			},
		);
	};

	const handleViewAs = userId => {
		loginAs(userId, {
			onSuccess: res => {
				window.open(`${CONFIG.endpoint.PATIENT_URL}/auth/login?viewAs=${JSON.stringify(res.data)}`, "_self");
			},
		});
	};

	const history = useHistory();
	const columns = useMemo(
		() => [
			{
				Header: "No",
				accessor: "idSerial",
			},
			{
				Header: "No. Rekam Medis",
				accessor: "patient.medicalRecordNumber",
				maxWidth: 150,
			},
			{
				Header: "Nama",
				accessor: "fullName",
				maxWidth: 150,
			},
			{
				Header: "Usia",
				accessor: "dobText",
			},
			{
				Header: "Dibuat Oleh",
				accessor: "createdBy",
				maxWidth: 150,
			},
			{
				Header: "Diubah Oleh",
				accessor: "updatedBy",
				maxWidth: 150,
			},
			{
				Header: "Aksi",
				accessor: "id",
				Cell: ({row}) => {
					return (
						<div className="d-flex flex-row align-items-center">
							<a
								class="text-orange wait-pay d-flex mx-1"
								style={{cursor: "pointer"}}
								onClick={() => history.push(`/pendaftaran-pasien/show/${row?.values?.id}`)}>
								<span class="icon-ico-eye-open ft-18" />
							</a>
							<a
								class="text-orange d-flex wait-pay"
								style={{cursor: "pointer"}}
								onClick={() => history.push(`/pendaftaran-pasien/edit/${row?.values?.id}`)}>
								<span class="icon-ico-pencil ft-18" />
							</a>
							<a
								class={clsx("text-orange d-flex wait-pay")}
								style={{cursor: "pointer"}}
								onClick={() => {
									setDeleteItemData(row?.values, () => setShowDeleteModal(true));
								}}>
								<span class="icon-ico-delete ft-18" />
							</a>
							<a
								class={clsx("text-red wait-pay d-flex mx-1")}
								style={{cursor: "pointer"}}
								onClick={() => {
									setPatientData(row?.original, () => setShowForgotPasswordModal(true));
								}}>
								<span class="icon-ico-key ft-18" />
							</a>
							<div className="ps-2">
								<button
									className="btn btn-orange py-1 ft-12 lh-base"
									onClick={() => handleViewAs(row?.values?.id)}>
									View As
								</button>
							</div>
						</div>
					);
				},
			},
		],
		[],
	);

	const dataWithIDSerial =
		data?.data?.rows?.map((item, index) => ({
			...item,
			idSerial: (data?.data?.currentPage - 1) * dataLimit + index + 1,
			withPatientStatus: true,
		})) || [];

	const handleDeleteData = () => {
		mutate(
			{idUser: deleteItemData.id},
			{
				onSuccess: () => {
					showToast("success", `Data pasien ${deleteItemData.fullName} berhasil dihapus`, 3000);
					window.scrollTo(0, 0);
					setShowDeleteModal(false);
				},
				onError: res => {
					showToast("error", convertErrorMessageFormat(res.response.status, res.response.data.message), null);
				},
			},
		);
	};

	return (
		<DashboardLayout>
			<>
				<ModalResetPassword
					description={`Pilih metode reset password untuk pasien "${patientData.firstName} ${patientData.lastName}"`}
					loading={isLoadingForgotPassword || isLoadingForgotPasswordByOtp}
					visible={showForgotPasswordModal}
					onClose={() => setShowForgotPasswordModal(false)}
					onConfirmByEmail={handleForgotPasswordByEmail}
					onConfirmByOtp={handleForgotPasswordByOtp}
				/>
				<ModalConfirmation
					approveLabel="Ya"
					description={`Apakah Anda yakin ingin menghapus data pasien "${deleteItemData.fullName}"?`}
					loading={isDeletingData}
					rejectLabel="Batal"
					visible={showDeleteModal}
					onApprove={handleDeleteData}
					onClose={() => setShowDeleteModal(false)}
					onReject={() => setShowDeleteModal(false)}
				/>
				<div class="text-bold text-dark mb-4">Data Pendaftaran Pasien</div>
				<SearchBox
					rightElement={<BroadcastButton />}
					// rightElement={<SubmitButton loading={isLoadingSync} text="Sync Data" onClick={handleSyncData} />}
					onSearch={keyword => {
						setSearchKeyword(keyword);
						filterPatients({page: 1, limit: dataLimit, search: keyword});
					}}
				/>
				<BasicTable
					columns={columns}
					currentPage={data?.data?.currentPage}
					data={dataWithIDSerial}
					limit={dataLimit}
					loading={isLoading}
					striped={true}
					totalItems={data?.data?.totalItems}
					totalPage={data?.data?.totalPages}
					onLimitChange={limitValue => {
						setDataLimit(limitValue);
						filterPatients({page: 1, limit: limitValue, search: searchKeyword});
					}}
					onPageChange={selectedPage =>
						filterPatients({page: selectedPage, limit: dataLimit, search: searchKeyword})
					}
				/>
			</>
		</DashboardLayout>
	);
}
