/* eslint-disable no-unused-vars */
import clsx from "clsx";
import {useMemo, useState, useEffect} from "react";
import {useHistory} from "react-router-dom";
import {DashboardLayout} from "components/Layout";
import {BasicTable, SearchBox} from "components/Table";
import {BroadcastButton, ButtonAddTable} from "components/Button";
import {usePatients, useDeletePendaftaranPasien} from "hooks/PendaftaranPasien";
import {ModalConfirmation, ModalResetPassword} from "components/Modal";
import {useToast} from "hooks/useToast";
import {useStateCallback} from "hooks/useStateCallback";
import {convertErrorMessageFormat} from "utils/converter";
import {useForgotPassword, useForgotPasswordByOtp, useForgotPasswordByWhatsapp} from "hooks/Auth";
import {useSelector} from "react-redux";
import {useBookingEdit} from "hooks/AdminAdmission/Booking";

const statusColor = {
	bgColor: {
		pasien: "#c1c1c1",
		belum_dilayani: "#ffa1a1",
		sedang_dilayani: "#ffc2a4",
		menunggu_lab: "#ffc2a4",
		menunggu_pembayaran: "#ffffc0",
		menunggu_obat: "#ffffc0",
		pulang: "#c1ffb6",
	},
	statusText: {
		pasien: "Abu-Abu",
		belum_dilayani: "Merah",
		sedang_dilayani: "Orange Pastel",
		menunggu_lab: "Orange Pastel",
		menunggu_pembayaran: "Kuning Pastel",
		menunggu_obat: "Kuning Pastel",
		pulang: "Hijau",
	},
};

export function PendaftaranPasienListPage() {
	const selectedHospital = useSelector(state => state.HospitalsReducer.selectedHospital);
	const {data, isLoading, filter, filterPatients} = usePatients(selectedHospital?.Hospital?.id);
	const [dataLimit, setDataLimit] = useState(10);
	const [searchKeyword, setSearchKeyword] = useState("");
	const {mutate: forgotPassword, isLoading: isLoadingForgotPassword} = useForgotPassword();
	const [emailForgotPassword, setEmailForgotPassword] = useStateCallback(null);
	const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(null);
	const {mutate: checkIn, isLoading: isLoadingCheckIn} = useBookingEdit();
	const [bookingData, setBookingData] = useStateCallback({id: "", patientName: ""});
	const [showCheckInModal, setShowCheckInModal] = useState(null);
	const {mutate: forgotPasswordByOtp, isLoading: isLoadingForgotPasswordByOtp} = useForgotPasswordByOtp();
	const {mutate: forgotPasswordByWhatsapp, isLoading: isLoadingForgotPasswordByWhatsapp} =
		useForgotPasswordByWhatsapp();
	const [patientData, setPatientData] = useStateCallback({});

	// Delete Data State
	const {mutate, isLoading: isDeletingData} = useDeletePendaftaranPasien();
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [showInfoModal, setShowInfoModal] = useState(false);
	const [deleteItemData, setDeleteItemData] = useStateCallback({id: null, name: null});
	const {showToast} = useToast();

	const handleForgotPasswordByEmail = () => {
		forgotPassword(
			{email: patientData.email},
			{
				onSuccess: () => {
					showToast("success", `Link lupa password berhasil dikirim ke ${patientData.email}`, 3000);
					setShowForgotPasswordModal(false);
				},
				onError: res => {
					showToast("error", convertErrorMessageFormat(res.response.status, res.response.data.message), null);
					setShowForgotPasswordModal(false);
				},
			},
		);
	};

	const handleForgotPasswordByOtp = () => {
		forgotPasswordByOtp(
			{phoneNumber: patientData.phoneNumber, role: "patient"},
			{
				onSuccess: () => {
					showToast("success", `Kode OTP berhasil dikirim ke ${patientData.phoneNumber}`, 3000);
					setShowForgotPasswordModal(false);
				},
				onError: res => {
					showToast("error", convertErrorMessageFormat(res.response.status, res.response.data.message), null);
					setShowForgotPasswordModal(false);
				},
			},
		);
	};

	const handleForgotPasswordByWhatsapp = () => {
		forgotPasswordByWhatsapp(
			{phoneNumber: patientData.phoneNumber},
			{
				onSuccess: () => {
					showToast("success", `Link lupa password berhasil dikirim ke ${patientData.phoneNumber}`, 3000);
					setShowForgotPasswordModal(false);
				},
				onError: res => {
					showToast("error", convertErrorMessageFormat(res.response.status, res.response.data.message), null);
					setShowForgotPasswordModal(false);
				},
			},
		);
	};

	const handleCheckIn = () => {
		checkIn(
			{id: bookingData.id, patientStatus: "sedang_dilayani"},
			{
				onSuccess: () => {
					showToast("success", `Pasien ${bookingData.patientName} berhasil check-in`, 3000);
					setShowCheckInModal(false);
				},
				onError: res => {
					showToast("error", convertErrorMessageFormat(res.response.status, res.response.data.message), null);
					setShowCheckInModal(false);
				},
			},
		);
	};

	const history = useHistory();
	const columns = useMemo(
		() => [
			{
				Header: "No",
				accessor: "idSerial",
			},
			{
				Header: "Patient Status",
				accessor: "patientStatus",
				Cell: ({row}) => {
					return (
						<div
							className="box-status "
							style={{
								background: statusColor.bgColor[row?.original?.patientStatus],
								color: "#fff",
							}}
						/>
					);
				},
			},
			{
				Header: "No. Rekam Medis",
				accessor: "medicalRecordNumber",
			},
			{
				Header: "Nama",
				accessor: "fullName",
			},
			{
				Header: "Umur",
				accessor: "dobText",
			},
			{
				Header: "Dibuat Oleh",
				accessor: "createdBy",
			},
			{
				Header: "Diubah Oleh",
				accessor: "updatedBy",
			},
			{
				Header: "Aksi",
				accessor: "id",
				Cell: ({row}) => {
					return (
						<div className="d-flex flex-row align-items-center">
							<a
								class="text-orange wait-pay"
								style={{cursor: "pointer"}}
								onClick={() => history.push(`/pendaftaran-pasien/show/${row?.values?.id}`)}>
								<span class="icon-ico-eye-open ft-18" />
							</a>
							<a
								class="text-orange wait-pay"
								style={{cursor: "pointer"}}
								onClick={() => history.push(`/pendaftaran-pasien/edit/${row?.values?.id}`)}>
								<span class="icon-ico-pencil ft-18" />
							</a>
							<a
								class={clsx("text-orange wait-pay")}
								style={{cursor: "pointer"}}
								onClick={() => {
									setDeleteItemData(row?.values, () => setShowDeleteModal(true));
								}}>
								<span class="icon-ico-delete ft-18" />
							</a>
							<a
								class={clsx("text-red wait-pay")}
								style={{cursor: "pointer"}}
								onClick={() => {
									setPatientData(row?.original, () => setShowForgotPasswordModal(true));
								}}>
								<span class="icon-ico-key ft-18" />
							</a>
							{["pasien", "belum_dilayani", "sedang_dilayani"].includes(row?.original?.patientStatus) &&
								row?.original?.lastBookingId !== null && (
									<button
										class="btn btn-outline-primary btn-checkin ms-3"
										type="button"
										onClick={() =>
											setBookingData(
												{
													id: row?.original?.lastBookingId,
													patientName: row?.original?.fullName,
												},
												() => {
													setShowCheckInModal(true);
												},
											)
										}>
										Check-in
									</button>
								)}
						</div>
					);
				},
			},
		],
		[],
	);

	const dataWithIDSerial =
		data?.data?.rows?.map((item, index) => ({
			...item,
			idSerial: (data?.data?.currentPage - 1) * dataLimit + index + 1,
			// withPatientStatus: true,
		})) || [];

	const handleDeleteData = () => {
		mutate(
			{idUser: deleteItemData.id},
			{
				onSuccess: () => {
					showToast("success", `Data pasien ${deleteItemData.firstName} berhasil dihapus`, 3000);
					window.scrollTo(0, 0);
					setShowDeleteModal(false);
				},
				onError: res => {
					showToast("error", convertErrorMessageFormat(res.response.status, res.response.data.message), null);
				},
			},
		);
	};

	return (
		<DashboardLayout>
			<>
				<ModalConfirmation
					approveLabel="Ya"
					description={`Apakah pasient "${bookingData.patientName}" ingin melakukan check-in?`}
					loading={isLoadingCheckIn}
					rejectLabel="Batal"
					visible={showCheckInModal}
					onApprove={handleCheckIn}
					onClose={() => setShowCheckInModal(false)}
					onReject={() => setShowCheckInModal(false)}
				/>
				<ModalResetPassword
					description={`Pilih metode reset password untuk pasien "${patientData.firstName} ${patientData.lastName}"`}
					loading={
						isLoadingForgotPassword || isLoadingForgotPasswordByOtp || isLoadingForgotPasswordByWhatsapp
					}
					visible={showForgotPasswordModal}
					onClose={() => setShowForgotPasswordModal(false)}
					onConfirmByEmail={handleForgotPasswordByEmail}
					onConfirmByOtp={handleForgotPasswordByOtp}
					onConfirmByWhatsapp={handleForgotPasswordByWhatsapp}
				/>
				<ModalConfirmation
					approveLabel="Ya"
					description={`Apakah Anda yakin ingin menghapus data pasien "${deleteItemData.firstName}"?`}
					loading={isDeletingData}
					rejectLabel="Batal"
					visible={showDeleteModal}
					onApprove={handleDeleteData}
					onClose={() => setShowDeleteModal(false)}
					onReject={() => setShowDeleteModal(false)}
				/>
				<div class="text-bold text-dark mb-4">Data Pendaftaran Pasien</div>
				<SearchBox
					rightElement={
						<div className="d-flex gap-4">
							<BroadcastButton />
							<ButtonAddTable
								text="Tambah Pasien"
								onClick={() => history.push("/pendaftaran-pasien/tambah")}
							/>
						</div>
					}
					onSearch={keyword => {
						setSearchKeyword(keyword);
						filterPatients({...filter, page: 1, limit: dataLimit, search: keyword});
					}}
				/>
				<BasicTable
					columns={columns}
					currentPage={data?.data?.currentPage}
					data={dataWithIDSerial}
					limit={dataLimit}
					loading={isLoading}
					striped={true}
					title="Data Pendaftaran Pasien"
					totalItems={data?.data?.totalItems}
					totalPage={data?.data?.totalPages}
					onLimitChange={limitValue => {
						setDataLimit(limitValue);
						filterPatients({...filter, page: 1, limit: limitValue, search: searchKeyword});
					}}
					onPageChange={selectedPage =>
						filterPatients({...filter, page: selectedPage, limit: dataLimit, search: searchKeyword})
					}
				/>
			</>
		</DashboardLayout>
	);
}
