import {useEditTag, useDetailTag} from "hooks/SuperAdmin/Tag";
import {useToast} from "hooks/useToast";
import {useEffect, useMemo, useRef} from "react";
import clsx from "clsx";
import {useHistory, useParams} from "react-router-dom";
import {Formik} from "formik";
import * as Yup from "yup";
import {DashboardLayout} from "components/Layout";
import {SubmitButton} from "components/Button";
import {convertErrorMessageFormat} from "utils/converter";
import {useRole} from "hooks/Misc";
import {BasicDropdown} from "components/Dropdown";
import {FormCard} from "components/Form";
import {CircleLoader} from "components/Loader";

const FormSchemaValidation = Yup.object().shape({
	title: Yup.string().required("Nama tag wajib di isi"),
	tag: Yup.number().required("Role wajib di isi").nullable(),
	discount: Yup.number()
		.required("Jumlah discount wajib di isi")
		.min(1, "Jumlah discount wajib diisi")
		.transform(v => (Number.isNaN(v) ? 0 : v))
		.nullable(),
});
const FormInitialValues = {title: "", tag: null, discount: null};

const MasterTagEdit = () => {
	const {showToast} = useToast();
	const history = useHistory();
	const params = useParams();
	const {id} = params;
	const {isLoading: loadingFetcing, data: detailTag} = useDetailTag(id);
	const {mutate, isLoading} = useEditTag();
	const formikRef = useRef();
	const {data: roles} = useRole();

	const roleOptions = useMemo(() => {
		if (roles?.success) {
			const roleData = roles.data.map(role => ({
				value: role.id,
				label: role.name,
			}));

			return [{value: "", label: ""}, ...roleData];
		}

		return [];
	}, [roles]);

	const handleSubmit = formData => {
		mutate(
			{id, ...formData},
			{
				onSuccess: () => {
					showToast("success", "Data berhasil diperbarui", 3000);
					history.push("/tag");
				},
				onError: res => {
					showToast("error", convertErrorMessageFormat(res.response.status, res.response.data.message), null);
				},
			},
		);
	};

	useEffect(() => {
		if (detailTag) {
			formikRef.current?.setFieldValue("title", detailTag?.data?.title);
			formikRef.current?.setFieldValue("tag", detailTag?.data?.tag);
			formikRef.current?.setFieldValue("discount", detailTag?.data?.discount);
		}
	}, [detailTag]);

	if (loadingFetcing) {
		return (
			<DashboardLayout>
				<FormCard title="Edit Tag">
					<CircleLoader />
				</FormCard>
			</DashboardLayout>
		);
	}

	return (
		<DashboardLayout>
			<Formik
				initialValues={FormInitialValues}
				innerRef={formikRef}
				validationSchema={FormSchemaValidation}
				onSubmit={handleSubmit}>
				{({handleSubmit, handleBlur, handleChange, setFieldValue, values, errors, touched, isValid}) => (
					<div class="box-white setting-content h-100 no-border p24">
						<div class="d-flex justify-content-between flex-wrap align-items-center mb-4">
							<div class="ttl-20">Edit Tag</div>
						</div>

						<hr />

						<div class="text-bold mb-4">
							<a class="text-dark" style={{cursor: "pointer"}} onClick={() => history.push("/tag")}>
								<span class="icon-ico-back me-2" />
							</a>
							Daftar Tag
						</div>

						<div class="row gx-2 mb-5">
							<div class="col-sm-6 col-6">
								<div class="setting-item">
									<label class="setting-label required">Nama Tag</label>
									<input
										class={clsx(
											"form-control",
											errors?.title && touched.title && "form-control-error",
										)}
										disabled={isLoading}
										name="title"
										placeholder=""
										type="text"
										value={values?.title}
										onBlur={handleBlur}
										onChange={handleChange}
									/>
									{errors.title && touched.title && (
										<p className="form-error-item-message">{errors.title}</p>
									)}
								</div>
							</div>
							<div class="col-sm-6 col-6">
								<div class="setting-item">
									<label class="setting-label required">Role</label>
									<BasicDropdown
										className={clsx(
											"form-control",
											errors?.tag && touched.tag && "form-control-error",
										)}
										data={roleOptions}
										name="tag"
										value={values?.tag}
										onSelect={fieldValue => {
											setFieldValue("tag", fieldValue);
										}}
									/>
									{errors?.tag && touched?.referralType && (
										<p className="form-error-item-message">{errors?.tag}</p>
									)}
								</div>
							</div>
							<div class="col-sm-6 col-6">
								<div class="setting-item">
									<label class="setting-label required">Jumlah Discount (%)</label>
									<div className="input-group">
										<input
											class={clsx(
												"form-control",
												errors?.discount && touched.discount && "form-control-error",
											)}
											disabled={isLoading}
											name="discount"
											placeholder=""
											type="number"
											value={values?.discount}
											onBlur={handleBlur}
											onChange={e => {
												let value = e.target.value.replace(/\D/g, "");

												setFieldValue("discount", value);
											}}
										/>
										<span class="input-group-text">%</span>
									</div>
									{errors.discount && touched.discount && (
										<p className="form-error-item-message">{errors.discount}</p>
									)}
								</div>
							</div>
						</div>

						<div class="text-center my-3">
							<SubmitButton
								className="btn btn-orange d-inline-block mw-250"
								disabled={!isValid}
								loading={isLoading}
								text="Simpan"
								onClick={handleSubmit}
							/>
						</div>
					</div>
				)}
			</Formik>
		</DashboardLayout>
	);
};

export {MasterTagEdit};
