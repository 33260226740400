import {useState} from "react";
import {useQuery} from "react-query";
import axios from "services/axios";

async function getRelations(id, filter) {
	const {data} = await axios.get(`/misc/relations`, {params: filter});

	return data;
}

export function useRelations() {
	const [filter, filterRelations] = useState({
		page: 1,
		search: null,
		limit: 10,
	});
	const [relationId, relationById] = useState(null);
	const fallback = [];
	const {
		data = fallback,
		isLoading,
		isError,
		error,
	} = useQuery(["relations", relationId, filter], async () => getRelations(relationId, filter));

	return {data, isLoading, isError, error, filter, filterRelations, relationById};
}
