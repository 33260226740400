import {useQuery} from "react-query";
import axios from "services/axios";
import qs from "query-string";

export const QUERY = "checkup_support_hospital";

export function useListLayananPemeriksaanPenunjang(filter) {
	const result = useQuery([QUERY, filter], () =>
		axios.get(`/checkup_support_hospital?${qs.stringify(filter)}`).then(res => res.data),
	);

	return result;
}

export const fetchLayananPemeriksaanPenunjangByID = id => {
	return axios.get(`/checkup_support_hospital/${id}`).then(res => res.data);
};

export function useDetailLayananPemeriksaanPenunjang(id) {
	return useQuery({
		queryKey: id && [QUERY, id],
		queryFn: () => fetchLayananPemeriksaanPenunjangByID(id),
		refetchOnMount: false,
		refetchOnWindowFocus: false,
		enabled: !!id,
	});
}

export function useListLayananPemeriksaanPenunjangByUnit(filter) {
	const result = useQuery([QUERY, filter], () =>
		axios.get(`/checkup_support_hospital/by_unit?${qs.stringify(filter)}`).then(res => res.data),
	);

	return result;
}
