import {createSlice} from "@reduxjs/toolkit";

const initialState = {
	profile: null,
	profileDetails: null,
	accessToken: "",
	accessExpired: "",
	notifications: [],
	totalUnreadNotification: 0,
	isPersisted: false,
	isLoggedIn: false,
	isDisplayProfile: false,
	isShowMenu: true,
	userRoles: [],
	roleId: 0,
	selectedHospital: null,
	permission: "CRUD",
};

export const authSlice = createSlice({
	name: "@AUTHENTICATION",
	initialState,
	reducers: {
		setAuthenticatedUser: (state, action) => {
			if (action.payload.roleId == 4) action.payload.permission = "CRU";
			if (action.payload.roleId == 5) action.payload.permission = "CR";
			if (action.payload.roleId == 6) action.payload.permission = "CRU";

			return {...state, ...action.payload};
		},
		setCurrentRole: (state, action) => {
			state.roleId = action.payload;
			if (action.payload == 4) state.permission = "CRU";
			if (action.payload == 5) state.permission = "CR";
			if (action.payload == 6) state.permission = "CRU";
		},
		setSelectedHospital: (state, action) => {
			state.quizAnswer = action.payload;
		},
		setDisplayProfile: (state, action) => {
			state.isDisplayProfile = action.payload;
		},
		setToggleMenu: (state, action) => {
			state.isShowMenu = action.payload;
		},
		logout: state => {
			return {...initialState, roleId: state.roleId};
		},
	},
});

export const {setAuthenticatedUser, setCurrentRole, setSelectedHospital, setDisplayProfile, setToggleMenu, logout} =
	authSlice.actions;

export default authSlice.reducer;
