import {ModalConfirmation} from "components/Modal";
import {BasicTable, SearchBox} from "components/Table";
import {useArticleUser} from "hooks/SuperAdmin/Article";
import {useSuspendArticleUser} from "hooks/SuperAdmin/Article/User/useMutationArticleUser";
import {useStateCallback} from "hooks/useStateCallback";
import {useToast} from "hooks/useToast";
import {useMemo, useState} from "react";
import {Button} from "react-bootstrap";
import {useHistory} from "react-router-dom";
import {convertErrorMessageFormat} from "utils/converter";

const TabUserPasien = () => {
	const [searchKeyword, setSearchKeyword] = useState("");
	const [dataLimit, setDataLimit] = useState(10);
	const {data, isLoading, setFilter, refetch} = useArticleUser("patient");

	const {mutate, isLoading: isSuspendLoading} = useSuspendArticleUser();
	const [showSuspendModal, setShowSuspendModal] = useState(false);
	const [suspendItemData, setSuspendItemData] = useStateCallback({});
	const {showToast} = useToast();

	const history = useHistory();
	const columns = useMemo(
		() => [
			{
				Header: "No",
				accessor: "idSerial",
			},
			{
				Header: "Name",
				accessor: "fullName",
			},
			{
				Header: "Email",
				accessor: "email",
			},
			{
				Header: "No Telepon",
				accessor: "phoneNumber",
			},
			{
				Header: "Aksi",
				accessor: "id",
				Cell: ({row}) => (
					<div className="d-flex align-items-center gap-3">
						<a
							class="text-orange wait-pay d-flex"
							style={{cursor: "pointer"}}
							onClick={() => history.push(`/user/pasien/${row?.original?.id}`)}>
							<span class="icon-ico-eye-open ft-18" />
						</a>
						<Button
							variant="outline-danger"
							onClick={() => {
								setSuspendItemData(row?.original, () => setShowSuspendModal(true));
							}}>
							{row?.original?.isSuspend ? "Unsuspend" : "Suspend"} User
						</Button>
					</div>
				),
			},
		],
		[],
	);

	const dataWithIDSerial =
		data?.data?.rows?.map((item, index) => ({
			...item,
			idSerial: (data?.data?.currentPage - 1) * dataLimit + index + 1,
		})) || [];

	const handleSuspendData = () => {
		mutate(
			{userId: suspendItemData?.id, isSuspend: !suspendItemData?.isSuspend},
			{
				onSuccess: () => {
					refetch();
					showToast(
						"success",
						`Data user ${suspendItemData?.firstName} berhasil ${
							suspendItemData?.isSuspend ? "unsuspend" : "suspend"
						}`,
						3000,
					);
					window.scrollTo(0, 0);
					setShowSuspendModal(false);
				},
				onError: res => {
					showToast("error", convertErrorMessageFormat(res.response.status, res.response.data.message), null);
					setShowSuspendModal(false);
				},
			},
		);
	};

	return (
		<div>
			<ModalConfirmation
				approveLabel="Ya"
				description={`Apakah Anda yakin ingin ${
					suspendItemData?.isSuspend ? "unsuspend" : "suspend"
				} data user "${suspendItemData?.firstName}"?`}
				loading={isSuspendLoading}
				rejectLabel="Batal"
				visible={showSuspendModal}
				onApprove={handleSuspendData}
				onClose={() => setShowSuspendModal(false)}
				onReject={() => setShowSuspendModal(false)}
			/>

			<h1 className="m-0 mb-3">User - Pasien</h1>
			<SearchBox
				rightElement={null}
				onSearch={keyword => {
					setSearchKeyword(keyword);
					setFilter({page: 1, limit: dataLimit, search: keyword});
				}}
			/>
			<BasicTable
				columns={columns}
				currentPage={data?.data?.currentPage}
				data={dataWithIDSerial}
				limit={dataLimit}
				loading={isLoading}
				striped={true}
				title="Data User Pasien"
				totalItems={data?.data?.totalItems}
				totalPage={data?.data?.totalPages}
				onLimitChange={limitValue => {
					setDataLimit(limitValue);
					setFilter({page: 1, limit: limitValue, search: searchKeyword});
				}}
				onPageChange={selectedPage => setFilter({page: selectedPage, limit: dataLimit, search: searchKeyword})}
			/>
		</div>
	);
};

export {TabUserPasien};
