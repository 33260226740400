import dayjs from "dayjs";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";

export function DatePicker({onChange, onHide = () => {}, children, singleDatePicker = false}) {
	const handleChange = event => {
		if (singleDatePicker) {
			onChange(event.target.value);
		} else {
			const spliteDate = event.target.value.split(" - ");
			const startDate = dayjs(spliteDate[0], "MM/DD/YYYY").format("YYYY-MM-DD");
			const endDate = dayjs(spliteDate[1], "MM/DD/YYYY").format("YYYY-MM-DD");

			onChange({startDate, endDate});
		}
	};

	const handleHide = event => {
		if (singleDatePicker) {
			onHide(event.target.value);
		} else {
			const spliteDate = event.target.value.split(" - ");
			const startDate = dayjs(spliteDate[0], "MM/DD/YYYY").format("YYYY-MM-DD");
			const endDate = dayjs(spliteDate[1], "MM/DD/YYYY").format("YYYY-MM-DD");

			onHide({startDate, endDate});
		}
	};

	return (
		<DateRangePicker
			initialSettings={{startDate: new Date(), singleDatePicker, showDropdowns: true}}
			onApply={handleChange}
			onHide={handleHide}>
			{children}
		</DateRangePicker>
	);
}
