import {useQuery} from "react-query";
import axios from "services/axios";
import qs from "query-string";

export const QUERY_HARGA_ADMINISTRASI = "admin_price";

export function useListHargaAdministrasi(filter) {
	const result = useQuery([QUERY_HARGA_ADMINISTRASI, filter], () =>
		axios.get(`/admin_price?${qs.stringify(filter)}`).then(res => res.data),
	);

	return result;
}

export const fetchHargaAdministrasiByID = id => {
	return axios.get(`/admin_price/${id}`).then(res => res.data);
};

export function useDetailHargaAdministrasi(id) {
	return useQuery({
		queryKey: id && [QUERY_HARGA_ADMINISTRASI, id],
		queryFn: () => fetchHargaAdministrasiByID(id),
		refetchOnMount: false,
		refetchOnWindowFocus: false,
		enabled: !!id,
	});
}
