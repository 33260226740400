import {useState} from "react";
import {useQuery} from "react-query";
import axios from "services/axios";

async function getTag(id, filter) {
	if (!id) {
		const {data} = await axios.get(`/master_tag`, {params: {...filter, sortBy: "id.desc"}});

		return data;
	}
	const {data} = await axios.get(`/master_tag/${id}`);

	return data;
}

function useTag() {
	const [filter, filterTag] = useState({
		page: 1,
		search: null,
		limit: 10,
	});
	const [tagId, tagById] = useState(null);
	const fallback = [];
	const {
		data = fallback,
		isLoading,
		isError,
		error,
	} = useQuery(["tag", filter, tagId], async () => getTag(tagId, filter));

	return {data, isLoading, isError, error, filter, filterTag, tagById};
}

const fetchTag = tagId => {
	return axios.get(`/master_tag/${tagId}`).then(res => res.data);
};

function useDetailTag(tagId) {
	return useQuery({
		queryKey: tagId && ["tag", tagId],
		queryFn: key => fetchTag(tagId),
		refetchOnMount: false,
		refetchOnWindowFocus: false,
		enabled: !!tagId,
	});
}

function useTagOptions() {
	const result = useQuery(["tagOptions"], async () => await axios.get(`/master_tag`).then(res => res.data));

	return result;
}

export {useTag, fetchTag, useDetailTag, useTagOptions};
