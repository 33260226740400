import {ButtonAddTable, ToggleSwitch} from "components/Button";
import {DashboardLayout} from "components/Layout";
import {ModalConfirmation, ModalInfo} from "components/Modal";
import {BasicTable, SearchBox} from "components/Table";
import {useChangeStatusSediaan, useDeleteSediaan, useSediaanObat} from "hooks/SuperAdmin/SediaanObat";
import {useStateCallback} from "hooks/useStateCallback";
import {useToast} from "hooks/useToast";
import {useEffect, useMemo, useState} from "react";
import {isMobile} from "react-device-detect";
import {useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {convertErrorMessageFormat} from "utils/converter";

const MasterSediaanObatList = () => {
	const userData = useSelector(state => state.HospitalsReducer.selectedHospital);

	const [searchKeyword, setSearchKeyword] = useState("");
	const [dataLimit, setDataLimit] = useState(10);
	const {data, isLoading, filterSediaan, filter} = useSediaanObat();

	// Delete Data State
	const {mutate, isLoading: isDeletingData} = useDeleteSediaan();
	const {mutate: queryChangeStatus} = useChangeStatusSediaan();
	const [showConfirmModal, setShowConfirmModal] = useState({show: false, type: ""});
	const [showInfoModal, setShowInfoModal] = useState(false);
	const [selectedItem, setSelectedItem] = useStateCallback({id: null, name: null});
	const {showToast} = useToast();

	useEffect(() => {
		if (userData.roleId === 1) {
			filterSediaan({page: 1, limit: dataLimit, search: "", hospitalId: userData.hospitalId});
		}
	}, [userData]);

	const history = useHistory();
	const columns = useMemo(
		() => [
			{
				Header: "No",
				accessor: "idSerial",
			},
			{
				Header: "Nama Sediaan Obat",
				accessor: "name",
				maxWidth: 300,
			},
			{
				centered: true,
				Header: "Aksi",
				accessor: "id",
				width: "200px",
				Cell: ({row}) => (
					<>
						{userData.roleId === 8 ? (
							<>
								<a
									class="text-orange wait-pay"
									style={{cursor: "pointer"}}
									onClick={() => history.push(`/sediaan-obat/show/${row?.values?.id}`)}>
									<span class="icon-ico-eye-open ft-18" />
								</a>
								<a
									class="text-orange wait-pay"
									style={{cursor: "pointer"}}
									onClick={() => history.push(`/sediaan-obat/edit/${row?.values?.id}`)}>
									<span class="icon-ico-pencil ft-18" />
								</a>
								<a
									class="text-orange wait-pay"
									style={{cursor: "pointer"}}
									title={
										!row.original.can_delete && "Data tidak dapat dihapus karena sudah digunakan"
									}
									onClick={() => {
										if (row.original.can_delete) {
											setSelectedItem(row?.values, () =>
												setShowConfirmModal({show: true, type: "delete"}),
											);
										} else {
											setShowInfoModal(true);
										}
									}}>
									<span class="icon-ico-delete ft-18" />
								</a>
							</>
						) : (
							<ToggleSwitch
								isActive={row.original.isActive}
								onChange={() => {
									setSelectedItem(row?.values, () =>
										setShowConfirmModal({show: true, type: "change status"}),
									);
								}}
							/>
						)}
					</>
				),
			},
		],
		[userData],
	);

	const columnsMobile = useMemo(
		() => [
			{
				Header: "Nama Obat",
				accessor: "name",
				Cell: ({row}) => {
					return (
						<div class="col-9">
							<div class="text-bold">{row.values.name}</div>
						</div>
					);
				},
			},

			{
				centered: true,
				Header: "Action",
				accessor: "id",
				Cell: ({row}) => {
					return (
						<div className="col-3">
							{userData.roleId === 8 ? (
								<>
									<a
										class="text-orange wait-pay"
										style={{cursor: "pointer"}}
										onClick={() => history.push(`/sediaan-obat/show/${row?.values?.id}`)}>
										<span class="icon-ico-eye-open ft-18" />
									</a>
									<a
										class="text-orange wait-pay"
										style={{cursor: "pointer"}}
										onClick={() => history.push(`/sediaan-obat/edit/${row?.values?.id}`)}>
										<span class="icon-ico-pencil ft-18" />
									</a>

									<a
										class="text-orange wait-pay"
										style={{cursor: "pointer"}}
										onClick={() => {
											if (row.original.can_delete) {
												setSelectedItem(row?.values, () =>
													setShowConfirmModal({show: true, type: "delete"}),
												);
											} else {
												setShowInfoModal(true);
											}
										}}>
										<span class="icon-ico-delete ft-18" />
									</a>
								</>
							) : (
								<ToggleSwitch
									isActive={row.original.isActive}
									onChange={() => {
										setSelectedItem({...row?.original, status: row.original.isActive}, () =>
											setShowConfirmModal({show: true, type: "change status"}),
										);
									}}
								/>
							)}
						</div>
					);
				},
			},
		],
		[userData],
	);

	const dataWithIDSerial =
		data?.data?.rows?.map((item, index) => ({
			...item,
			idSerial: (data?.data?.currentPage - 1) * dataLimit + index + 1,
		})) || [];

	const handleDeleteData = () => {
		mutate(
			{id: selectedItem.id},
			{
				onSuccess: () => {
					showToast("success", `Data sediaan obat ${selectedItem.name} berhasil dihapus`, 3000);
					window.scrollTo(0, 0);
					setShowConfirmModal({show: false, type: ""});
				},
				onError: res => {
					showToast("error", convertErrorMessageFormat(res.response.status, res.response.data.message), null);
				},
			},
		);
	};

	const handeChangeStatus = () => {
		queryChangeStatus(
			{sediaanId: selectedItem.id, hospitalId: userData.hospitalId},
			{
				onSuccess: () => {
					showToast(
						"success",
						`Data Sediaan Obat "${selectedItem.name}" berhasil di ${
							selectedItem.status ? "non-aktifkan" : "aktifkan"
						}`,
						3000,
					);
					window.scrollTo(0, 0);
					setShowConfirmModal({show: false, type: ""});
					setSelectedItem({id: null, name: null});
				},
				onError: res => {
					showToast("error", convertErrorMessageFormat(res.response.status, res.response.data.message), null);
				},
			},
		);
	};

	const handleConfirm = () => {
		if (showConfirmModal.type === "delete") {
			handleDeleteData();
		}
		if (showConfirmModal.type === "change status") {
			handeChangeStatus();
		}
	};

	const confirmModalDesc = useMemo(() => {
		if (showConfirmModal.type === "delete") {
			return `Apakah Anda yakin ingin menghapus data sediaan obat "${selectedItem?.name}"?`;
		}

		if (showConfirmModal.type === "change status") {
			return `Apakah Anda yakin ingin  ${selectedItem?.status ? "non-aktifkan" : "aktifkan"} data obat "${
				selectedItem?.name
			}"?`;
		}

		return "";
	}, [selectedItem, showConfirmModal]);

	return (
		<DashboardLayout>
			<ModalInfo
				approveLabel="OK"
				description="Data tidak dapat dihapus karena sudah digunakan"
				title="Info"
				visible={showInfoModal}
				onApprove={() => setShowInfoModal(false)}
				onClose={() => setShowInfoModal(false)}
			/>
			<ModalConfirmation
				approveLabel="Ya"
				description={confirmModalDesc}
				loading={isDeletingData}
				rejectLabel="Batal"
				visible={showConfirmModal.show}
				onApprove={handleConfirm}
				onClose={() => setShowConfirmModal({show: false, type: ""})}
				onReject={() => setShowConfirmModal({show: false, type: ""})}
			/>
			<div class="text-bold text-dark mb-4">Data Sediaan Obat</div>
			<SearchBox
				rightElement={
					userData.roleId === 8 ? (
						<ButtonAddTable
							text="Tambah Sediaan Obat"
							onClick={() => history.push("/sediaan-obat/tambah")}
						/>
					) : null
				}
				onSearch={keyword => {
					setSearchKeyword(keyword);
					filterSediaan({...filter, page: 1, limit: dataLimit, search: keyword});
				}}
			/>

			<BasicTable
				columns={isMobile ? columnsMobile : columns}
				currentPage={data?.data?.currentPage}
				data={dataWithIDSerial}
				limit={dataLimit}
				loading={isLoading}
				striped={true}
				title="Data Obat"
				totalItems={data?.data?.totalItems}
				totalPage={data?.data?.totalPages}
				onLimitChange={limitValue => {
					setDataLimit(limitValue);
					filterSediaan({...filter, page: 1, limit: limitValue, search: searchKeyword});
				}}
				onPageChange={selectedPage =>
					filterSediaan({...filter, page: selectedPage, limit: dataLimit, search: searchKeyword})
				}
			/>
		</DashboardLayout>
	);
};

export {MasterSediaanObatList};
