import {useHistory, useParams} from "react-router-dom";
import {DashboardLayout} from "components/Layout";
import {SubmitButton} from "components/Button";
import {CircleLoader} from "components/Loader";
import {FormCard} from "components/Form";
import {useDetailTag} from "hooks/SuperAdmin/Tag";
import {useMemo} from "react";
import {useRole} from "hooks/Misc";

const MasterTagDetail = () => {
	const history = useHistory();
	const {id} = useParams();
	const {data: detailTag, isLoading: loadingFetcing} = useDetailTag(id);
	const {data: roles} = useRole();

	const roleOptions = useMemo(() => {
		if (roles?.success) {
			return roles.data;
		}

		return [];
	}, [roles]);

	if (loadingFetcing) {
		return (
			<DashboardLayout>
				<FormCard backTitle="Daftar Tag" title="Detail Tag">
					<CircleLoader />
				</FormCard>
			</DashboardLayout>
		);
	}

	return (
		<DashboardLayout>
			<FormCard
				backTitle="Daftar Tag"
				title={`Detail Data Tag ${detailTag?.data?.title}`}
				onBackButtonClick={() => history.push("/tag")}>
				<div class="row gx-2 mb-5">
					<div class="col-sm-12 col-lg-6">
						<div class="setting-item">
							<label class="setting-label ">Nama Tag</label>
							<p className="text-bold text-grey">{detailTag?.data?.title}</p>
						</div>
					</div>
					<div class="col-sm-12 col-lg-6">
						<div class="setting-item">
							<label class="setting-label ">Nama Role</label>
							<p className="text-bold text-grey">
								{roleOptions.find(opt => opt.id === detailTag?.data?.tag)?.name || ""}
							</p>
						</div>
					</div>
					<div class="col-sm-12 col-lg-6">
						<div class="setting-item">
							<label class="setting-label ">Jumlah Discount</label>
							<p className="text-bold text-grey">{detailTag?.data?.discount}%</p>
						</div>
					</div>
				</div>

				<div class="text-center my-3">
					<SubmitButton
						className="btn btn-orange d-inline-block mw-250"
						text="Ubah Data Ini"
						onClick={() => history.push(`/tag/edit/${detailTag?.data?.id}`)}
					/>
				</div>
			</FormCard>
		</DashboardLayout>
	);
};

export {MasterTagDetail};
