import {useState} from "react";
import {useQuery} from "react-query";
import axios from "services/axios";

async function getMedicines(id, filter) {
	if (!id) {
		const {data} = await axios.get(`/v2/medicine`, {params: {...filter, sortBy: "id.desc"}});

		return data;
	}
	const {data} = await axios.get(`/medicine/${id}`);

	return data;
}

export function useMedicine() {
	const [filter, filterMedicines] = useState({
		page: 1,
		search: null,
		limit: 10,
	});
	const [medicineId, medicineById] = useState(null);
	const fallback = [];
	const {
		data = fallback,
		isLoading,
		isError,
		error,
	} = useQuery(["medicine", filter, medicineId], async () => getMedicines(medicineId, filter));

	return {data, isLoading, isError, error, filter, filterMedicines, medicineById};
}

export const fetchMedicine = medicineId => {
	return axios.get(`/v2/medicine/${medicineId}`).then(res => res.data);
};

export function useDetailMedicine(medicineId) {
	return useQuery({
		queryKey: medicineId && ["medicine", medicineId],
		queryFn: key => fetchMedicine(medicineId),
		refetchOnMount: false,
		refetchOnWindowFocus: false,
		enabled: !!medicineId,
	});
}
