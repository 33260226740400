/* eslint-disable no-unused-vars */
import {useState, useRef, useEffect} from "react";
import clsx from "clsx";
import {useHistory, useParams} from "react-router-dom";
import {Formik, Field, FieldArray} from "formik";
import * as Yup from "yup";
import {SubmitButton} from "components/Button";
import {useToast} from "hooks/useToast";
import {convertErrorMessageFormat} from "utils/converter";
import {useEditScheduleHoliday, useDetailScheduleHoliday} from "hooks/Doctor/ScheduleHoliday";
import {useSelector} from "react-redux";
import {TimePicker} from "components/TimePicker";

const FormSchemaValidation = Yup.object().shape({
	startDate: Yup.string().required("Tanggal mulai wajib di isi"),
	endDate: Yup.string()
		.required("Tanggal selesai wajib di isi")
		.test("lessThanStartDate", "Tanggal selesai tidak boleh mendahului tanggal mulai", function (value) {
			const startDate = new Date(this.parent.startDate);
			const endDate = new Date(value);

			if (endDate < startDate) return false;

			return true;
		}),
	times: Yup.array().of(
		Yup.object().shape({
			startTime: Yup.string(),
			endTime: Yup.string().test(
				"lessThanStartTime",
				"Jam selesai tidak boleh mendahului jam mulai",
				function (value) {
					const startTimeHour = parseInt(this.parent.startTime?.split(":")[0]);
					const startTimeMinute = parseInt(this.parent.startTime?.split(":")[1]);
					const endTimeHour = parseInt(value?.split(":")[0]);
					const endTimeMinute = parseInt(value?.split(":")[1]);

					if (endTimeHour < startTimeHour) return false;
					if (endTimeHour === startTimeHour && endTimeMinute < startTimeMinute) return false;

					return true;
				},
			),
		}),
	),
	deleteTimes: Yup.array().of(Yup.number()),
	keterangan: Yup.string().nullable(),
});
const FormInitialValues = {
	id: "",
	userId: "",
	startDate: "",
	endDate: "",
	times: [{startTime: "", endTime: ""}],
	deleteTimes: [],
	keterangan: "",
};

export function ScheduleHolidayEditPage({scheduleId, userId, onUnmounted, setTab}) {
	const {showToast} = useToast();
	const history = useHistory();
	const {mutate, isLoading} = useEditScheduleHoliday();
	const formikRef = useRef();
	const {data: detailScheduleWork} = useDetailScheduleHoliday(scheduleId);

	const handleSubmit = formData => {
		if (formData.type === "1") formData.hospitalId = undefined;
		mutate(formData, {
			onSuccess: () => {
				showToast("success", "Data berhasil ditambahkan", 3000);
				setTab(1);
			},
			onError: res => {
				showToast("error", convertErrorMessageFormat(res.response.status, res.response.data.message), null);
			},
		});
	};

	useEffect(() => {
		if (detailScheduleWork?.success) {
			const {ScheduleHolidayTimes} = detailScheduleWork?.data;
			const detailData = detailScheduleWork?.data;

			Object.keys(FormInitialValues).forEach(key => {
				formikRef?.current.setFieldValue(key, detailData?.[key]);
				if (key === "startDate")
					formikRef?.current.setFieldValue(key, new Date(detailData?.[key]).toISOString().split("T")[0]);
				if (key === "endDate")
					formikRef?.current.setFieldValue(key, new Date(detailData?.[key]).toISOString().split("T")[0]);
			});
			formikRef?.current.setFieldValue("times", []);
			formikRef?.current.setFieldValue("deleteTimes", []);

			ScheduleHolidayTimes &&
				ScheduleHolidayTimes.forEach((item, index) => {
					formikRef?.current.setFieldValue(`times.${index}.id`, item.id);
					formikRef?.current.setFieldValue(`times.${index}.startTime`, item.startTime);
					formikRef?.current.setFieldValue(`times.${index}.endTime`, item.endTime);
				});
		}
	}, [detailScheduleWork]);

	useEffect(() => {
		formikRef?.current?.setFieldValue("userId", userId);
	}, [userId]);

	useEffect(() => {
		return () => onUnmounted();
	}, [scheduleId, onUnmounted]);

	const getEndTimeDisabledHour = startTime => {
		let hours = [];

		if (startTime) {
			const startHour = parseInt(startTime.split(":")[0]);
			let countHour = 0;

			while (countHour < startHour) {
				hours.push(countHour++);
			}
		}

		return hours;
	};

	return (
		<Formik
			initialValues={FormInitialValues}
			innerRef={formikRef}
			validationSchema={FormSchemaValidation}
			onSubmit={handleSubmit}>
			{({handleSubmit, handleBlur, handleChange, values, errors, touched, setFieldValue, setFieldTouched}) => (
				<div class="box-white setting-content h-100 no-border p24">
					<div class="d-flex justify-content-between flex-wrap align-items-center mb-4">
						<div class="ttl-20">Buat Jadwal Spesial - Libur</div>
					</div>

					<hr />

					<div class="text-bold mb-4">
						<a class="text-dark" style={{cursor: "pointer"}} onClick={() => history.push("/doctor")}>
							<span class="icon-ico-back me-2" />
						</a>
						Daftar List Dokter
					</div>

					<div class="row gx-2 mb-5">
						<div class="col-sm-6 col-6">
							<div class="setting-item">
								<label class="setting-label required">Tanggal Mulai</label>
								<input
									class={clsx(
										"form-control",
										errors?.startDate && touched?.startDate && "form-control-error",
									)}
									name="startDate"
									placeholder=""
									type="date"
									value={values?.startDate}
									onBlur={handleBlur}
									onChange={handleChange}
								/>
								{errors.startDate && touched?.startDate && (
									<p className="form-error-item-message">{errors.startDate}</p>
								)}
							</div>
						</div>
						<div class="col-sm-6 col-6">
							<div class="setting-item">
								<label class="setting-label required">Tanggal Selesai</label>
								<input
									class={clsx(
										"form-control",
										errors?.endDate && touched?.endDate && "form-control-error",
									)}
									min={values?.startDate}
									name="endDate"
									placeholder=""
									type="date"
									value={values?.endDate}
									onBlur={handleBlur}
									onChange={handleChange}
								/>
								{errors.endDate && touched?.endDate && (
									<p className="form-error-item-message">{errors.endDate}</p>
								)}
							</div>
						</div>
						<div class="col-sm-12 col-12">
							<div class="setting-item">
								<FieldArray name="times">
									{({insert, remove, push}) => (
										<div>
											{values.times.length > 0 &&
												values.times.map((time, index) => (
													<div key={index} className="row">
														<div className="col-3">
															<div class="setting-item">
																<label class="setting-label">Jam Mulai</label>
																<TimePicker
																	disabled={isLoading}
																	placeholder={values?.times?.[index].startTime}
																	value={values?.times?.[index].startTime}
																	onChange={fieldValue => {
																		setFieldTouched(
																			`times.${index}.startTime`,
																			true,
																		);
																		setFieldValue(
																			`times.${index}.startTime`,
																			fieldValue?.format("HH:mm"),
																		);
																	}}
																/>
																{errors?.times?.[index]?.startTime &&
																	touched?.times?.[index]?.startTime && (
																		<p className="form-error-item-message">
																			{errors?.times?.[index]?.startTime}
																		</p>
																	)}
															</div>
														</div>
														<div className="col-3">
															<div class="setting-item">
																<label class="setting-label">Jam Selesai</label>
																<TimePicker
																	disabled={isLoading}
																	disabledHours={getEndTimeDisabledHour(
																		values?.times?.[index].startTime,
																	)}
																	disabledMinuteByStart={
																		values?.times?.[index].startTime
																	}
																	placeholder={values?.times?.[index].endTime}
																	value={values?.times?.[index].endTime}
																	onChange={fieldValue => {
																		setFieldTouched(`times.${index}.endTime`, true);
																		setFieldValue(
																			`times.${index}.endTime`,
																			fieldValue?.format("HH:mm"),
																		);
																	}}
																/>
																{errors?.times?.[index]?.endTime &&
																	touched?.times?.[index]?.endTime && (
																		<p className="form-error-item-message">
																			{errors?.times?.[index]?.endTime}
																		</p>
																	)}
															</div>
														</div>
														<div className="col-2 row align-items-center">
															<a
																className="col-2"
																style={{
																	cursor: "pointer",
																	fontSize: "24px",
																}}
																onClick={() =>
																	push({
																		startTime: "",
																		endTime: "",
																	})
																}>
																<span class="icon-ico-plus" />
															</a>
															<a
																className={index > 0 ? "col-2" : "d-none"}
																style={{
																	cursor: "pointer",
																	fontSize: "24px",
																}}
																onClick={() => {
																	if (index) {
																		remove(index);
																		if (time.id) {
																			setFieldValue("deleteTimes", [
																				...values.deleteTimes,
																				time.id,
																			]);
																		}
																	}
																}}>
																<span class="icon-ico-delete" />
															</a>
														</div>
													</div>
												))}
										</div>
									)}
								</FieldArray>
							</div>
						</div>
						<div class="col-sm-6 col-6">
							<div class="setting-item">
								<label class="setting-label">Keterangan</label>
								<textarea
									class={clsx(
										"form-control",
										errors?.keterangan && touched?.keterangan && "form-control-error",
									)}
									name="keterangan"
									rows="4"
									spellCheck={false}
									value={values?.keterangan}
									onBlur={handleBlur}
									onChange={handleChange}
								/>
								{errors.keterangan && touched?.keterangan && (
									<p className="form-error-item-message">{errors.keterangan}</p>
								)}
							</div>
						</div>
					</div>

					<div class="text-center my-3">
						<SubmitButton
							className="btn btn-orange d-inline-block mw-250"
							disabled={!FormSchemaValidation.isValidSync(values)}
							loading={isLoading}
							text="Simpan"
							onClick={handleSubmit}
						/>
					</div>
				</div>
			)}
		</Formik>
	);
}
