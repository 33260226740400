import {ModalConfirmation} from "components/Modal";
import {BasicTable, SearchBox} from "components/Table";
import {useStateCallback} from "hooks/useStateCallback";
import {useToast} from "hooks/useToast";
import {useMemo, useState} from "react";
import {useHistory} from "react-router-dom";
import {convertErrorMessageFormat} from "utils/converter";
import {DashboardLayout} from "components/Layout";
import clsx from "clsx";
import {useDeleteGroupMember, useGroupMemberList} from "hooks/SuperAdmin/GroupMember";
import {ButtonAddTable} from "components/Button";

const GroupMemberListPage = () => {
	const [searchKeyword, setSearchKeyword] = useState("");
	const [dataLimit, setDataLimit] = useState(10);
	const {data, isLoading, setFilter} = useGroupMemberList();

	const {mutate: mutateDelete, isLoading: isDeleteLoading} = useDeleteGroupMember();
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [deleteItemData, setDeleteItemData] = useStateCallback({id: null, name: null});
	const {showToast} = useToast();

	const history = useHistory();
	const columns = useMemo(
		() => [
			{
				Header: "No",
				accessor: "idSerial",
			},
			{
				Header: "Name",
				accessor: "name",
			},
			{
				Header: "Jumlah User",
				accessor: "userCount",
			},
			{
				Header: "Aksi",
				accessor: "id",
				Cell: ({row}) => (
					<div className="d-flex align-items-center gap-3">
						<a
							class="text-orange wait-pay d-flex"
							style={{cursor: "pointer"}}
							onClick={() => history.push(`/group-member/${row?.original?.id}/show`)}>
							<span class="icon-ico-eye-open ft-18" />
						</a>
						<a
							class="text-orange wait-pay"
							style={{cursor: "pointer"}}
							onClick={() => history.push(`/group-member/edit/${row?.values?.id}`)}>
							<span class="icon-ico-pencil ft-18" />
						</a>
						<a
							class={clsx("text-orange wait-pay")}
							style={{cursor: "pointer"}}
							title={!row.original.can_delete && "Data tidak dapat dihapus karena sudah digunakan"}
							onClick={() => {
								setDeleteItemData(row?.values, () => setShowDeleteModal(true));
							}}>
							<span class="icon-ico-delete ft-18" />
						</a>
					</div>
				),
			},
		],
		[],
	);

	const dataWithIDSerial =
		data?.data?.rows?.map((item, index) => ({
			...item,
			idSerial: (data?.data?.currentPage - 1) * dataLimit + index + 1,
		})) || [];

	const handleDeleteData = () => {
		mutateDelete(
			{id: deleteItemData?.id},
			{
				onSuccess: () => {
					showToast("success", `Data group member ${deleteItemData?.name} berhasil dihapus`, 3000);
					window.scrollTo(0, 0);
					setShowDeleteModal(false);
				},
				onError: res => {
					showToast("error", convertErrorMessageFormat(res.response.status, res.response.data.message), null);
					setShowDeleteModal(false);
				},
			},
		);
	};

	return (
		<DashboardLayout>
			<div className="box-white setting-content h-100 no-border p24">
				<ModalConfirmation
					approveLabel="Ya"
					description={`Apakah Anda yakin ingin menghapus data group member "${deleteItemData?.name}"?`}
					loading={isDeleteLoading}
					rejectLabel="Batal"
					visible={showDeleteModal}
					onApprove={handleDeleteData}
					onClose={() => setShowDeleteModal(false)}
					onReject={() => setShowDeleteModal(false)}
				/>

				<h1 className="m-0 mb-3">Group Member</h1>
				<SearchBox
					rightElement={
						<ButtonAddTable text="Tambah Group Member" onClick={() => history.push("/group-member/add")} />
					}
					onSearch={keyword => {
						setSearchKeyword(keyword);
						setFilter({page: 1, limit: dataLimit, search: keyword});
					}}
				/>
				<BasicTable
					columns={columns}
					currentPage={data?.data?.currentPage}
					data={dataWithIDSerial}
					limit={dataLimit}
					loading={isLoading}
					striped={true}
					title="Data User Pasien"
					totalItems={data?.data?.totalItems}
					totalPage={data?.data?.totalPages}
					onLimitChange={limitValue => {
						setDataLimit(limitValue);
						setFilter({page: 1, limit: limitValue, search: searchKeyword});
					}}
					onPageChange={selectedPage =>
						setFilter({page: selectedPage, limit: dataLimit, search: searchKeyword})
					}
				/>
			</div>
		</DashboardLayout>
	);
};

export {GroupMemberListPage};
