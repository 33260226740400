import {SubmitButton} from "components/Button";
import {PatientDropdown} from "components/Dropdown";
import {DashboardLayout} from "components/Layout";
import {Formik} from "formik";
import {useListPatient} from "hooks/AdminAdmission/Patient";
import {useDoctors} from "hooks/Doctor";
import {useAddAppoinment, useDetailAppointment, useEditAppoinment} from "hooks/Doctor/Appointment";
import {useToast} from "hooks/useToast";
import {useEffect, useMemo, useRef, useState} from "react";
import {useSelector} from "react-redux";
import {useHistory, useParams} from "react-router-dom";
import {convertErrorMessageFormat, converToLocalGender} from "utils/converter";
import {converISOtoDate, getDayName} from "utils/date";
import * as Yup from "yup";
import {Spinner} from "react-bootstrap";
import axios from "services/axios";
import {useQueryClient} from "react-query";

import {ScheduleSelect} from "./ScheduleSelect";

const FormSchemaValidation = Yup.object().shape({
	idUserPatient: Yup.string().required("Pasien wajib di isi"),
	idUserDoctor: Yup.string().required("Dokter wajib di isi"),
	type: Yup.string().required("Tipe konsultasi wajib di isi"),
});
const FormInitialValues = {idUserPatient: "", idUserDoctor: "", type: "offline"};

export function AppointmentAddPage() {
	const {showToast} = useToast();
	const history = useHistory();
	const params = useParams();

	const formikRef = useRef();
	const queryClient = useQueryClient();

	const [searchPatient, setSearchPatient] = useState("");
	const [searchDoctor, setSearchDoctor] = useState("");
	const [isQueue, setIsQueue] = useState(false);
	const toggleIsQueue = () => setIsQueue(!isQueue);

	const selectedHospital = useSelector(state => state.HospitalsReducer.selectedHospital);

	const appointmentId = params.id;

	const appointmentDetailQuery = useDetailAppointment(appointmentId);
	const appointmentDetail = appointmentDetailQuery.data?.data;

	const isEdit = !!appointmentDetail;

	useEffect(() => {
		if (!appointmentDetail) return;

		formikRef.current.setFieldValue("idUserPatient", appointmentDetail.idUserPatient + "");
		formikRef.current.setFieldValue("idUserDoctor", appointmentDetail.idUserDoctor + "");
		formikRef.current.setFieldValue("type", appointmentDetail.type);
		setSearchPatient(findRelatedId(appointmentDetail.idUserPatient.toString()));
		setSearchDoctor(findDoctorId(appointmentDetail.idUserDoctor.toString()));

		setTimeout(() => {
			formikRef.current.setFieldTouched("idUserPatient", true);
			formikRef.current.setFieldTouched("idUserDoctor", true);
			formikRef.current.setFieldTouched("type", true);
		});
	}, [appointmentDetail]);

	const [selectedDateTime, setSelectedDateTime] = useState({
		date: null,
		time: null,
	});

	const {data: patients, isLoading: isFetchingPatients} = useListPatient({
		page: 1,
		search: null,
		limit: null,
		idHospital: selectedHospital?.hospitalId,
	});
	const patientOptions = useMemo(() => {
		if (!patients?.success) return [];

		return patients?.data?.rows?.map(patient => ({
			value: patient?.patient?.idUser + "",
			label: `${patient?.firstName} ${patient?.lastName} [${patient?.patient?.medicalRecordNumber}]`,
			desc: `${converToLocalGender(patient?.gender)} - ${patient?.email} - ${patient?.phoneNumber}`,
			image: patient?.profilePhoto,
		}));
	}, [patients]);

	const {data: doctors, isLoading: isFetchingDoctors} = useDoctors({
		limit: null,
		hospitalId: selectedHospital?.hospitalId,
	});
	const doctorOptions = useMemo(() => {
		if (!doctors?.success) return [];

		return doctors?.data?.rows?.map(doctor => ({
			value: doctor.id + "",
			label: `Dokter ${doctor.fullName} (${doctor?.staff?.policlinic?.name || "Dokter Umum"})`,
			desc: doctor?.staff?.policlinic?.name || "Dokter Umum",
			image: doctor?.profilePhoto,
		}));
	}, [doctors]);

	const handleSelectDateTime = data => {
		setSelectedDateTime({date: data.selectedDay, time: data.selectedTime});
	};

	const addMutation = useAddAppoinment();
	const updateMutation = useEditAppoinment();

	const onSuccess = () => {
		showToast("success", `Data berhasil ${appointmentDetail ? "diperbarui" : "ditambahkan"}`, 3000);
		history.push("/admission/appointment/list");
	};

	const handleQueue = async id => {
		await axios
			.get(`/v2/booking/${id}/queue`)
			.then(response => {
				const res = response.data;

				if (res.success && res.data !== null) {
					setIsQueue(false);
					onSuccess();
				} else if (!res.success) {
					setIsQueue(false);
					showToast("error", "Gagal mengambil antrian");
				} else {
					setTimeout(() => {
						handleQueue(id);
					}, 3000);
				}
			})
			.catch(res => {
				setIsQueue(false);
				showToast("error", "Gagal mengambil antrian");
				history.push("/admission/appointment/list");
			})
			.finally(() => {
				queryClient.removeQueries("DOCTOR_SCHEDULES");
			});
	};

	const handleSubmit = formData => {
		const {date, time} = selectedDateTime;

		const payload = {
			id: appointmentDetail?.id || null,
			idUserPatient: parseInt(formData.idUserPatient),
			idUserDoctor: parseInt(formData.idUserDoctor),
			idHospital: selectedHospital.Hospital.id,
			date: date?.originalDate,
			startTime: time?.startTime,
			endTime: time?.endTime,
			type: formData.type,
			price: time?.prices[0] ?? 0,
			otherIdentity: null,
			patientIdentity: null,
		};

		const onError = res => {
			showToast("error", convertErrorMessageFormat(res.response.status, res.response.data.message), null);
		};

		if (appointmentDetail) {
			updateMutation.mutate(payload, {
				onSuccess,
				onError,
			});
		} else {
			toggleIsQueue();

			addMutation.mutate(payload, {
				onSuccess: data => {
					handleQueue(data.data);
				},
				onError,
			});
		}
	};

	const findRelatedId = value => {
		return patientOptions.find(x => x.value === value)?.label || value;
	};

	const findDoctorId = value => {
		return doctorOptions.find(x => x.value === value)?.label || value;
	};

	return (
		<DashboardLayout>
			{isQueue ? (
				<div
					className="d-flex align-items-center justify-content-center flex-column gap-3"
					style={{minHeight: "50vh"}}>
					<h1>Sedang mengambil antrian</h1>
					<Spinner />
				</div>
			) : (
				<Formik
					initialValues={FormInitialValues}
					innerRef={formikRef}
					validationSchema={FormSchemaValidation}
					onSubmit={handleSubmit}>
					{({handleSubmit, handleBlur, handleChange, setFieldValue, values, errors}) => (
						<div class="box-white setting-content h-100 no-border p24">
							<div class="d-flex justify-content-between flex-wrap align-items-center mb-4">
								<div class="ttl-20">{isEdit ? "Ubah" : "Tambah"} Appointment</div>
							</div>
							<hr />

							<div class="text-bold mb-4">
								<a
									class="text-dark"
									style={{cursor: "pointer"}}
									onClick={() => history.push("/admission/appointment/list")}>
									<span class="icon-ico-back me-2" />
								</a>
								Daftar List Appointment
							</div>

							<div class="row gx-4 mb-5">
								<div class="col-sm-12 col-lg-6  col-xs-12">
									<div class="setting-item">
										<PatientDropdown
											required
											data={patientOptions}
											disabled={!!isEdit}
											error={errors.idUserPatient}
											label="Pasien"
											loading={isFetchingPatients}
											name="idUserPatient"
											placeholder="Ketikan nama, email atau nomor handphone pasien.."
											searchKeyValue={["label", "desc"]}
											// value={values.idUserPatient}
											// value={findRelatedId(values.idUserPatient)}
											value={searchPatient}
											onBlur={handleBlur}
											onChange={selectedValue => {
												setFieldValue("idUserPatient", selectedValue.value);
												setSearchPatient(findRelatedId(selectedValue.value));
											}}
											onInput={e => {
												setSearchPatient(e);
											}}
										/>
									</div>
								</div>

								{/* <div class="col-sm-12 col-lg-6  col-xs-12">
								<div class="setting-item">
									<label class="setting-label required">Unit</label>
									<select
										className={clsx("form-control", errors?.unitId && "form-control-error")}
										disabled={isLoading}
										name="unitId"
										value={values?.unitId}
										onBlur={handleBlur}
										onChange={handleChange}>
										<option value="">Pilih Unit</option>
										{hospitalUnitOptions.map(option => (
											<option value={option.value}>{option.label}</option>
										))}
									</select>
									{errors.unitId && <p className="form-error-item-message">{errors.unitId}</p>}
								</div>
							</div> */}

								<div class="col-sm-12 col-lg-6  col-xs-12">
									<div class="setting-item">
										<label class="setting-label required">Tipe Konsultasi</label>
										<div className="d-flex">
											<div className="me-4">
												<label>
													<input
														checked={values.type === "offline"}
														className="me-2"
														name="type"
														type="radio"
														// checked
														value="offline"
														onBlur={handleBlur}
														onChange={handleChange}
													/>
													Offline
												</label>
											</div>
											{/* <div>
												<label>
													<input
														checked={values.type === "online"}
														className="me-2"
														name="type"
														type="radio"
														value="online"
														onBlur={handleBlur}
														onChange={handleChange}
													/>
													Telekonsul/Online
												</label>
											</div> */}
										</div>
									</div>
								</div>

								<div class="col-sm-12 col-lg-6  col-xs-12">
									<div class="setting-item">
										<PatientDropdown
											required
											data={doctorOptions}
											error={errors.idUserDoctor}
											label="Dokter"
											loading={isFetchingDoctors}
											name="idUserDoctor"
											placeholder="Ketikan nama dokter..."
											searchKeyValue={["label", "desc"]}
											// value={values.idUserDoctor}
											value={searchDoctor}
											// value={findDoctorId(values.idUserDoctor)}
											onBlur={handleBlur}
											onChange={selectedValue => {
												setFieldValue("idUserDoctor", selectedValue.value);
												setSearchDoctor(findDoctorId(selectedValue.value));
											}}
											onInput={e => {
												setSearchDoctor(e);
											}}
										/>
									</div>
								</div>

								{appointmentDetail && (
									<div class="col-sm-12 col-lg-6  col-xs-12">
										<div class="setting-item">
											<div>
												<span className="fw-bold">Jadwal Terpilih</span>
												<p>
													{`${getDayName(appointmentDetail.date, "ID-id")}, ${converISOtoDate(
														appointmentDetail.date,
													)}`}{" "}
													<br />
													{`${appointmentDetail.startTime} - ${appointmentDetail.endTime}`}{" "}
													WIB
												</p>
											</div>
										</div>
									</div>
								)}

								<div class="col-12 mt-4">
									{values.idUserDoctor && (
										<div class="row">
											<div class="col-8 offset-2">
												<ScheduleSelect
													doctorId={parseInt(values.idUserDoctor)}
													hospitalId={selectedHospital?.Hospital?.id}
													type={values.type}
													onSelectDateTime={handleSelectDateTime}
												/>
											</div>
										</div>
									)}
								</div>
							</div>

							<div class="text-center my-3">
								<SubmitButton
									className="btn btn-orange d-inline-block mw-250"
									disabled={
										!FormSchemaValidation.isValidSync(values) ||
										selectedDateTime.date === null ||
										selectedDateTime.time === null
									}
									loading={addMutation.isLoading}
									text="Simpan"
									onClick={handleSubmit}
								/>
							</div>
						</div>
					)}
				</Formik>
			)}
		</DashboardLayout>
	);
}
