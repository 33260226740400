import {SubmitButton} from "components/Button";
import {FormCard} from "components/Form";
import {DashboardLayout} from "components/Layout";
import {CircleLoader} from "components/Loader";
import {useDetailHospitalMedicine} from "hooks/AdminHospital/Medicine";
import {useHistory, useParams} from "react-router-dom";
import {convertToCurency} from "utils/converter";

import {getStatusApprovalText} from "./LayananObatList";

export function LayananObatDetailPage() {
	const history = useHistory();
	const {id} = useParams();

	const {data, isLoading} = useDetailHospitalMedicine(id);
	const medicineDetail = data?.data;

	if (isLoading) {
		return (
			<DashboardLayout>
				<FormCard backTitle="Daftar list Layanan Obat" title="Detail Layanan Obat">
					<CircleLoader />
				</FormCard>
			</DashboardLayout>
		);
	}

	return (
		<DashboardLayout>
			<FormCard
				backTitle="Daftar List Layanan Obat/Apotik"
				title={`Detail Data Layanan Obat ${medicineDetail?.medicine.name}`}
				onBackButtonClick={() => history.push("/hospital/service-medicine/list")}>
				<div class="row gx-2 mb-5">
					<div class="col-sm-6 col-6">
						<div class="setting-item">
							<label class="setting-label ">Divisi</label>
							<p className="text-bold text-grey">{medicineDetail?.division.name}</p>
						</div>
					</div>
					<div class="col-sm-6 col-6">
						<div class="setting-item">
							<label class="setting-label ">Kategori Obat</label>
							<p className="text-bold text-grey">{medicineDetail?.medicine.category.name}</p>
						</div>
					</div>
					<div class="col-sm-6 col-6">
						<div class="setting-item">
							<label class="setting-label ">Nama Obat</label>
							<p className="text-bold text-grey">{medicineDetail?.medicine.name}</p>
						</div>
					</div>
					<div class="col-sm-6 col-6">
						<div class="setting-item">
							<label class="setting-label ">Satuan Obat</label>
							<p className="text-bold text-grey">{medicineDetail?.medicine.unit.name}</p>
						</div>
					</div>
					<div class="col-sm-6 col-6">
						<div class="setting-item">
							<label class="setting-label ">Harga Beli</label>
							<p className="text-bold text-grey">Rp {convertToCurency(medicineDetail?.buyPrice + "")}</p>
						</div>
					</div>
					<div class="col-sm-6 col-6">
						<div class="setting-item">
							<label class="setting-label ">Harga Jual</label>
							<p className="text-bold text-grey">Rp {convertToCurency(medicineDetail?.sellPrice + "")}</p>
						</div>
					</div>
					<div class="col-sm-6 col-6">
						<div class="setting-item">
							<label class="setting-label ">Status Produk</label>
							<p className="text-bold text-grey">{medicineDetail?.type.split("_").join(" ")}</p>
						</div>
					</div>
					<div class="col-sm-6 col-6">
						<div class="setting-item">
							<label class="setting-label ">Stok</label>
							<p className="text-bold text-grey">{medicineDetail?.stock}</p>
						</div>
					</div>
					<div class="col-sm-6 col-6">
						<div class="setting-item">
							<label class="setting-label ">Status Approval</label>
							<p className="text-bold text-grey">
								{getStatusApprovalText(medicineDetail?.statusApproval)}
							</p>
						</div>
					</div>
				</div>

				<div class="text-center my-3">
					<SubmitButton
						className="btn btn-orange d-inline-block mw-250"
						text="Ubah Data Ini"
						onClick={() => history.push(`/hospital/service-medicine/edit/${medicineDetail?.id}`)}
					/>
				</div>
			</FormCard>
		</DashboardLayout>
	);
}
