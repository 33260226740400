import ReactDOM from "react-dom/client";
import {ExtensionTag, NodeExtension} from "remirror";
import {loadLocalAssets} from "utils/loader";
import {uploadFile} from "hooks/Misc";

const VideoPlaceholder = ({onChange}) => {
	const handleOnSelectVideo = event => {
		if (event.target.classList.value.includes("video-placeholder")) {
			const input = document.createElement("input");

			input.type = "file";
			input.accept = "video/*";

			input.onchange = async event => {
				const file = event.target.files[0];

				if (file) {
					let formData = new FormData();

					formData.append("file", file);

					const response = await uploadFile(formData);

					if (response.success) {
						onChange(response.data.file);
					}
				}
			};

			input.click();
		}
	};

	return (
		<>
			<div className="video-placeholder" onClick={handleOnSelectVideo}>
				<img alt="placeholder-video" src={loadLocalAssets("img/upload-video-icon.svg")} />
				<span>Click to select an video</span>
			</div>
		</>
	);
};

class VideoUploadExtension extends NodeExtension {
	get name() {
		return "videoUpload";
	}

	createTags() {
		return [ExtensionTag.Block];
	}

	createNodeSpec(extra, override) {
		return {
			...override,
			attrs: {src: {default: null}, ...extra.defaults()},
			content: "",
			draggable: false,
			selectable: true,
			isolation: true,
			atom: true,
			parseDOM: [
				{tag: "video.responsive-video", getAttrs: dom => ({src: dom.getAttribute("src")})},
				{tag: "div[data-placeholder-video]", getAttrs: extra.parse},
				...(override.parseDOM ?? []),
			],
			toDOM: node => {
				const {src} = node.attrs;

				if (src) {
					return ["video", {src, controls: "true", class: "responsive-video"}];
				}

				return ["div", {"data-placeholder-video": true}];
			},
		};
	}

	createNodeViews() {
		return (node, view, getPos) => {
			if (node.attrs.src) return null;

			const container = document.createElement("div");

			const root = ReactDOM.createRoot(container);

			const handleChange = newSrc => {
				const tr = view.state.tr.setNodeMarkup(getPos(), null, {
					...node.attrs,
					src: newSrc,
				});

				view.dispatch(tr);
			};

			root.render(<VideoPlaceholder onChange={handleChange} />);

			return {
				dom: container,
				update: nextNode => {
					if (nextNode.attrs.src !== node.attrs.src) {
						root.render(<video controls class="responsive-video" src={nextNode.attrs.src} />);
					}

					return true;
				},
			};
		};
	}
}

export const VideoUpload = new VideoUploadExtension();
