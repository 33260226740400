/* eslint-disable no-unused-vars */
import clsx from "clsx";
import {useMemo, useState} from "react";
import {useHistory} from "react-router-dom";
import {isMobile} from "react-device-detect";
import {DashboardLayout} from "components/Layout";
import {BasicTable, SearchBox} from "components/Table";
import {
	useLayananPoliklinikTindakan,
	useDeleteLayananPoliklinikTindakan,
	useApprovalLayananPoliklinikTindakan,
} from "hooks/AdminHospital/LayananPoliklinikTindakan";
import {ButtonAddTable} from "components/Button";
import {ModalConfirmation, ModalInfo} from "components/Modal";
import {useToast} from "hooks/useToast";
import {useStateCallback} from "hooks/useStateCallback";
import {convertErrorMessageFormat, convertToCurency} from "utils/converter";
import {useSelector} from "react-redux";
import {Button} from "react-bootstrap";

export function LayananPoliklinikTindakanListPage() {
	const selectedHospital = useSelector(state => state.HospitalsReducer.selectedHospital);

	const [searchKeyword, setSearchKeyword] = useState("");
	const [dataLimit, setDataLimit] = useState(10);
	const {data, isLoading, filterLayananPoliklinikTindakan} = useLayananPoliklinikTindakan(
		selectedHospital.Hospital.id,
	);

	// Delete Data State
	const {mutate, isLoading: isDeletingData} = useDeleteLayananPoliklinikTindakan();
	const approvalMutation = useApprovalLayananPoliklinikTindakan();
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [showInfoModal, setShowInfoModal] = useState(false);
	const [deleteItemData, setDeleteItemData] = useStateCallback({id: null, name: null});
	const [selectedItem, setSelectedItem] = useState({id: "", isApprove: 0});
	const [showApproval, setShowApproval] = useState(false);
	const {showToast} = useToast();

	const history = useHistory();

	const handleClickApproveOrReject = (id, isApprove) => {
		setSelectedItem({id, isApprove});
		setShowApproval(true);
	};

	const handleApproval = () => {
		approvalMutation.mutate(
			{
				id: selectedItem.id,
				isApprove: selectedItem.isApprove,
			},
			{
				onSuccess: () => {
					showToast("success", "Data persetujuan layanan obat berhasil diperbarui", 3000);
					window.scrollTo(0, 0);
					setShowApproval(false);
					setSelectedItem({id: "", isApprove: 0});
				},
				onError: res => {
					showToast("error", convertErrorMessageFormat(res.response.status, res.response.data.message), null);
				},
			},
		);
	};

	const approvalColumnRenderer = row => {
		if (row.values.isApprove !== null) {
			return (
				<Button
					className="ft-13"
					disabled={true}
					size="sm"
					variant={row.values.isApprove ? "success" : "danger"}>
					{row.values.isApprove ? "Disetujui" : "Ditolak"}
				</Button>
			);
		}

		return (
			<div className="d-flex gap-1">
				<Button
					className="ft-13"
					size="sm"
					variant="success"
					onClick={() => handleClickApproveOrReject(row.values.id, 1)}>
					Setuju
				</Button>
				<Button
					className="ft-13"
					size="sm"
					variant="danger"
					onClick={() => handleClickApproveOrReject(row.values.id, 0)}>
					Tolak
				</Button>
			</div>
		);
	};

	const columns = useMemo(
		() => [
			{
				Header: "No",
				accessor: "idSerial",
			},
			{
				Header: "Unit",
				accessor: "Unit.name",
			},
			{
				Header: "Poliklinik",
				accessor: "Poliklinik.name",
			},
			{
				Header: "Kategori Tindakan",
				accessor: "ActionCategory.title",
			},
			{
				Header: "Tindakan",
				accessor: "Action.title",
			},
			{
				Header: "Harga Beli",
				accessor: "buyPrice",
				Cell: ({row}) => <>Rp. {convertToCurency(row?.values?.buyPrice?.toString())}</>,
			},
			{
				Header: "Harga Jual",
				accessor: "sellPrice",
				Cell: ({row}) => <>Rp. {convertToCurency(row?.values?.sellPrice?.toString())}</>,
			},
			{
				Header: "Status Produk",
				accessor: "statusProduct",
				Cell: ({row}) => <>{row?.values?.statusProduct === 1 ? "Habis Dipakai" : "Tidak Habis Dipakai"}</>,
			},
			{
				Header: "Stok",
				accessor: "stock",
			},
			{
				Header: "Status",
				accessor: "isApprove",
				Cell: ({row}) => approvalColumnRenderer(row),
			},
			{
				Header: "Aksi",
				accessor: "id",
				Cell: ({row}) => (
					<>
						<a
							class="text-orange wait-pay"
							style={{cursor: "pointer"}}
							onClick={() =>
								history.push(`/hospital/layanan-poliklinik-tindakan/show/${row?.values?.id}`)
							}>
							<span class="icon-ico-eye-open ft-18" />
						</a>
						<a
							class="text-orange wait-pay"
							style={{cursor: "pointer"}}
							onClick={() =>
								history.push(`/hospital/layanan-poliklinik-tindakan/edit/${row?.values?.id}`)
							}>
							<span class="icon-ico-pencil ft-18" />
						</a>
						<a
							class={clsx("text-orange wait-pay")}
							style={{cursor: "pointer"}}
							title={!row.original.can_delete && "Data tidak dapat dihapus karena sudah digunakan"}
							onClick={() => {
								setDeleteItemData(row?.original, () => setShowDeleteModal(true));
								// if (row.original.can_delete) {
								// 	setDeleteItemData(row?.values, () => setShowDeleteModal(true));
								// } else {
								// 	setShowInfoModal(true);
								// }
							}}>
							<span class="icon-ico-delete ft-18" />
						</a>
					</>
				),
			},
		],
		[],
	);

	const columnsMobile = useMemo(
		() => [
			{
				Header: "Nama Kategori Tindakan",
				accessor: "title",
				Cell: ({row}) => {
					return (
						<div class="col-9">
							<div class="text-bold">{row.values.title}</div>
						</div>
					);
				},
			},

			{
				Header: "Action",
				accessor: "id",
				Cell: ({row}) => {
					return (
						<div className="col-3">
							<a
								class="text-orange wait-pay"
								style={{cursor: "pointer"}}
								onClick={() =>
									history.push(`/hospital/layanan-poliklinik-tindakan/show/${row?.values?.id}`)
								}>
								<span class="icon-ico-eye-open ft-18" />
							</a>
							<a
								class="text-orange wait-pay"
								style={{cursor: "pointer"}}
								onClick={() =>
									history.push(`/hospital/layanan-poliklinik-tindakan/edit/${row?.values?.id}`)
								}>
								<span class="icon-ico-pencil ft-18" />
							</a>

							<a
								class="text-orange wait-pay"
								style={{cursor: "pointer"}}
								onClick={() => {
									setDeleteItemData(row?.values, () => setShowDeleteModal(true));
									// if (row.original.can_delete) {
									// 	setDeleteItemData(row?.values, () => setShowDeleteModal(true));
									// } else {
									// 	setShowInfoModal(true);
									// }
								}}>
								<span class="icon-ico-delete ft-18" />
							</a>
						</div>
					);
				},
			},
		],
		[],
	);

	const dataWithIDSerial =
		data?.data?.rows?.map((item, index) => ({
			...item,
			idSerial: (data?.data?.currentPage - 1) * dataLimit + index + 1,
		})) || [];

	const handleDeleteData = () => {
		mutate(
			{id: deleteItemData.id},
			{
				onSuccess: () => {
					showToast("success", `Data kategori tindakan ${deleteItemData.name} berhasil dihapus`, 3000);
					window.scrollTo(0, 0);
					setShowDeleteModal(false);
				},
				onError: res => {
					showToast("error", convertErrorMessageFormat(res.response.status, res.response.data.message), null);
				},
			},
		);
	};

	return (
		<DashboardLayout>
			<>
				<ModalInfo
					approveLabel="OK"
					description="Data tidak dapat dihapus karena sudah digunakan"
					title="Info"
					visible={showInfoModal}
					onApprove={() => setShowInfoModal(false)}
					onClose={() => setShowInfoModal(false)}
				/>
				<ModalConfirmation
					approveLabel="Ya"
					description={`Apakah Anda yakin ingin menghapus data layanan poliklinik tindakan "${deleteItemData?.Poliklinik?.name}"?`}
					loading={isDeletingData}
					rejectLabel="Batal"
					visible={showDeleteModal}
					onApprove={handleDeleteData}
					onClose={() => setShowDeleteModal(false)}
					onReject={() => setShowDeleteModal(false)}
				/>
				{showApproval && (
					<ModalConfirmation
						approveLabel="Ya"
						description={`Apakah Anda yakin ingin ${
							selectedItem.isApprove === 1 ? "menyetujui" : "menolak"
						} layanan tindakan ini`}
						loading={approvalMutation.isLoading}
						rejectLabel="Batal"
						title={selectedItem.isApprove === 1 ? "Konfirmasi Persetujuan" : "Konfirmasi Penolakan"}
						visible={showApproval}
						onApprove={handleApproval}
						onClose={() => setShowApproval(false)}
						onReject={() => setShowApproval(false)}
					/>
				)}
				<div class="text-bold text-dark mb-4">
					Data Layanan Poliklinik Tindakan - {selectedHospital.Hospital.name}
				</div>
				<SearchBox
					rightElement={
						<ButtonAddTable
							text="Tambah Layanan Poliklinik"
							onClick={() => history.push("/hospital/layanan-poliklinik-tindakan/tambah")}
						/>
					}
					onSearch={keyword => {
						setSearchKeyword(keyword);
						filterLayananPoliklinikTindakan({page: 1, limit: dataLimit, search: keyword});
					}}
				/>

				<BasicTable
					columns={isMobile ? columnsMobile : columns}
					currentPage={data?.data?.currentPage}
					data={dataWithIDSerial}
					limit={dataLimit}
					loading={isLoading}
					striped={true}
					title="Data Kategori Tindakan"
					totalItems={data?.data?.totalItems}
					totalPage={data?.data?.totalPages}
					onLimitChange={limitValue => {
						setDataLimit(limitValue);
						filterLayananPoliklinikTindakan({page: 1, limit: limitValue, search: searchKeyword});
					}}
					onPageChange={selectedPage =>
						filterLayananPoliklinikTindakan({page: selectedPage, limit: dataLimit, search: searchKeyword})
					}
				/>
			</>
		</DashboardLayout>
	);
}
