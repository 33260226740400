import {useState} from "react";
import {useQuery} from "react-query";
import axios from "services/axios";

export const QUERY = "zoom";

export async function getUserZoomId(filter) {
	const {data} = await axios.get(`/zoom/get_user_zoom_id`, {params: {...filter}});

	return data;
}

export function useUserZoom(hospitalId) {
	const [filter, filterZooms] = useState({});
	const fallback = [];
	const {
		data = fallback,
		isLoading,
		isError,
		error,
	} = useQuery([QUERY, filter], async () => getUserZoomId(filter), {
		refetchInterval: false,
		refetchIntervalInBackground: false,
		refetchOnMount: false,
		refetchOnWindowFocus: false,
		retry: false,
		retryOnMount: false,
	});

	return {data, isLoading, isError, error, filter, filterZooms};
}
